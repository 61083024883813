import { ActionReducer, createReducer, on } from '@ngrx/store';
import { LoginActions } from '../actions/LoginActions';
import { PasswordState } from '@app/model/app/password/PasswordState';
import { PasswordActions } from '../actions/PasswordActions';

export const initialState: PasswordState = new PasswordState();

export const passwordReducer: ActionReducer<PasswordState> = createReducer(
    initialState,
    on(LoginActions.logoutComplete, (state, action) => {
        return {
            ...initialState
        };
    }),
    on(LoginActions.logoutInitiate, (state, action) => {
        return {
            ...initialState
        };
    }),
    on(LoginActions.lightweightLogout, (state, action) => {
        return {
            ...initialState
        };
    }),
    on(PasswordActions.updateSearchCriteria, (state, action) => {
        return {
            ...state,
            passwordSearchCriteria: action.criteria
        };
    }),
    on(PasswordActions.toggleFavoriteSearch, (state, action) => {
        let copiedState = { ...state };
        let copiedSearchCriteria = { ...copiedState.passwordSearchCriteria };
        copiedSearchCriteria.searchFavorite = copiedSearchCriteria.searchFavorite > 0 ? 0 : 1;
        return {
            ...state,
            passwordSearchCriteria: copiedSearchCriteria
        };
    }),


);