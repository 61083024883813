import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { LabelActions } from '@app/store/actions/LabelActions';
import { environment } from '@environments/environment';
import { BaseAPIService } from '../BaseAPIService';
import { CreateTagNameResponse } from '@app/model/api/label/CreateTagNameResponse';
import { selectStorageState } from '@app/store/selectors/StorageSelectors';
import { FilterItem } from '@app/model/app/FilterItem';
import { LabelQueryService } from './LabelQueryService';
import { RemoveLabelResponse } from '@app/model/api/label/RemoveLabelResponse';
import { UpdateLabelResponse } from '@app/model/api/label/UpdateLabelResponse';
import { BulkLabelDeleteRequest } from '@app/model/api/label/BulkLabelDeleteRequest';
import { BulkLabelDeleteResponse } from '@app/model/api/label/BulkLabelDeleteResponse';


@Injectable({ providedIn: 'root' })
export class LabelService {


    constructor(
        private router: Router,
        private store: Store,
        private baseAPIService: BaseAPIService,
        private labelQueryService: LabelQueryService
    ) {
    }



    createNewLabel(label: string, color: string): Observable<CreateTagNameResponse> {
        let url = environment.API_BASE_URL + "v1/secure/tag/create";
        var params = new Map<string, any>();
        params.set("tag", label);
        params.set("color", color);
        return this.baseAPIService.postRequest<CreateTagNameResponse>(params, url);
    }

    updateLabel(id: number, label: string, color: string): Observable<UpdateLabelResponse> {
        let url = environment.API_BASE_URL + "v1/secure/tag/update";
        var params = new Map<string, any>();
        params.set("tag", id);
        params.set("value", label);
        params.set("color", color);
        params.set("changeColor", true);
        return this.baseAPIService.postRequest<UpdateLabelResponse>(params, url);
    }

    deleteLabel(labelId: number): Observable<RemoveLabelResponse> {
        // console.log("deleting label " + labelId);
        let url = environment.API_BASE_URL + "v1/secure/tag/delete";
        var params = new Map<string, any>();
        params.set("tag", labelId);
        return this.baseAPIService.postRequest<RemoveLabelResponse>(params, url);
    }

    bulkDeleteLabels(labelIds: number[]): Observable<BulkLabelDeleteResponse> {
        let url = environment.API_BASE_URL + "v1/secure/tag/bulkDelete";
        let request = new BulkLabelDeleteRequest();
        request.labelIds.push(...labelIds);
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<BulkLabelDeleteResponse>(body, url);
    }

}