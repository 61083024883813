import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { from, of } from "rxjs";
import { catchError, exhaustMap, map, tap, } from "rxjs/operators";
import { PasswordActions } from "../actions/PasswordActions";
import { PasswordsService } from "@app/services/passwords/PasswordsService";
import { DrawerActions } from "../actions/DrawerActions";
import { AccountActions } from "../actions/AccountActions";
import { PasswordGroupService } from "@app/services/passwords/PasswordGroupService";
import { PasswordDeleteService } from "@app/services/passwords/PasswordDeleteService";
import { PasswordUpdateService } from "@app/services/passwords/PasswordUpdateService";
import { PasswordPageService } from "@app/services/passwords/PasswordPageService";
import { TeamActions } from "../actions/TeamActions";

@Injectable()
export class PasswordEffects {
    constructor(
        private actions$: Actions,
        private passwordPageService: PasswordPageService,
        private passwordGroupService: PasswordGroupService,
        private passwordDeleteService: PasswordDeleteService,
        private passwordUpdateService: PasswordUpdateService,
        private passwordsService: PasswordsService) { }




    /**
     * Restore a password
     */
    readonly restorePassword$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                PasswordActions.restorePassword
            ),
            tap((action) => this.passwordsService.restorePassword(action.data)),
        ), { dispatch: false }
    );

    readonly deletePasswords$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                PasswordActions.deletePasswords
            ),
            tap((action) => this.passwordDeleteService.bulkDeletePasswords(action.passwordIds)),
        ), { dispatch: false }
    );


    readonly removeLabelsFromPassword$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                PasswordActions.removeLabelsFromPasswords
            ),
            exhaustMap((action) => {
                return this.passwordsService.removeLabelsFromPasswords(action.passwordIds, action.labelIds).pipe(
                    map((response) =>
                        PasswordActions.removeLabelsFromPasswordsSuccess({ response }),
                    ),
                    catchError((error) => of(PasswordActions.removeLabelsFromPasswordsError({ error })))
                );
            })
        )
    );


    readonly makePasswordPrivate$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                PasswordActions.makePasswordPrivate
            ),

            // tap((action) => this.passwordsService.makePasswordPrivate(action.passwordId, action.passwordKey).then(response => {
            //     console.log(response.status.code);
            // }
            // ),

            exhaustMap((action) => {
                return from(this.passwordsService.makePasswordPrivate(action.passwordId, action.passwordKey)).pipe(
                    map((response) =>
                        PasswordActions.makePasswordPrivateSuccess({ response }),
                    ),
                    catchError((error) => of(PasswordActions.makePasswordPrivateError({ error })))
                );
            })
        )
    );


    readonly addLabelsToPassword$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                PasswordActions.addLabelsToPasswords
            ),
            exhaustMap((action) => {
                return this.passwordsService.addSameLabelsToAllPasswords(action.passwordIds, action.labelIds).pipe(
                    map((response) =>
                        PasswordActions.addLabelsToPasswordsSuccess({ response }),
                    ),
                    catchError((error) => of(PasswordActions.addLabelsToPasswordsError({ error })))
                );
            })
        )
    );

    readonly transferPasswords$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                PasswordActions.bulkTransferPasswords
            ),
            exhaustMap((action) => {
                return this.passwordUpdateService.transferPasswords(action.passwordIds, action.newGroupId).then(
                    (response) => {
                        return PasswordActions.bulkTransferPasswordsSuccess({ response });
                    }).catch((error) => {
                        return PasswordActions.bulkTransferPasswordsError({ error });
                    });
            })
        )
    );


    /**
     * Update the password sharing for a single password
     */
    readonly updatePasswordSharingSinglePassword$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                TeamActions.updatePasswordSharingSinglePassword
            ),
            exhaustMap((action) => {
                return this.passwordUpdateService.managePasswordGroupMembership(action.passwordId, action.adds, action.removes).then(
                    (response) => {
                        return TeamActions.updatePasswordSharingSinglePasswordSuccess();
                    }).catch((error) => {
                        return TeamActions.updatePasswordSharingSinglePasswordFailure({ error });
                    });
            })
        )
    );

    /**
    * Update the password sharing for a single vault password
    */
    readonly updatePasswordSharingSingleVaultPassword$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                TeamActions.updatePasswordSharingSingleVaultPassword
            ),
            exhaustMap((action) => {
                return this.passwordUpdateService.managePasswordGroupMembershipWithinVault(action.passwordId, action.adds, action.removes).then(
                    (response) => {
                        return TeamActions.updatePasswordSharingSingleVaultPasswordSuccess();
                    }).catch((error) => {
                        return TeamActions.updatePasswordSharingSingleVaultPasswordFailure({ error });
                    });
            })
        )
    );

    /**
     * Bulk update password sharing for multiple passwords
     */
    readonly updatePasswordSharingMultiplePasswords$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                TeamActions.bulkUpdatePasswordMembershipInTeams
            ),
            exhaustMap((action) => {
                return this.passwordUpdateService.bulkManagePasswordGroupMembership(action.entries).then(
                    (response) => {
                        return TeamActions.bulkUpdatePasswordMembershipInTeamsSuccess();
                    }).catch((error) => {
                        return TeamActions.bulkUpdatePasswordMembershipInTeamsFailure({ error });
                    });
            })
        )
    );



    /**
     * Initiate the check if the default groups are created
     */
    readonly checkIfDefaultGroupsAreCreated$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                AccountActions.unlockPackingKeySuccess,
                PasswordActions.test
            ),
            exhaustMap(() => {
                return this.passwordGroupService.getPasswordGroupInitializationStatus().pipe(
                    map((response) =>
                        PasswordActions.checkPasswordGroupInitializationStatus({ response }),
                    ),
                    catchError((error) => of(AccountActions.subscriptionSummaryDataRetrievalError({ error })))
                );
            })
        )
    );

    /**
     * Act on the the check if the default groups are created
     */
    readonly actOnPasswordInitializationStatus$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                PasswordActions.checkPasswordGroupInitializationStatus
            ),
            tap((action) => this.passwordGroupService.checkPasswordGroupInitializationStatus(action.response)),
        ), { dispatch: false }
    );
    /**
         * Toggles the password favorite flag
         */
    readonly togglePasswordFavorite$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                PasswordActions.togglePasswordFavoriteFlag
            ),
            exhaustMap((action) => {
                return this.passwordsService.togglePasswordFavoriteFlag(action.passwordId).pipe(
                    map((response) =>
                        PasswordActions.togglePasswordFavoriteFlagResponse({ response }),
                    ),
                    catchError((error) => of(PasswordActions.togglePasswordFavoriteFlagError({ error })))
                );
            })
        ), { dispatch: false }
    );
}
