import { PasswordVaultData } from "../passwordVault/PasswordVaultData";

export class SharingDisplayGroupItem {
    id: number;
    name: string;
    description: string;
    color: string;
    checked: boolean = false;
    indeterminate: boolean = false;
    action: string = "";
    vault: PasswordVaultData;

}