import { ActionReducer, createReducer, on } from '@ngrx/store';
import { LoginActions } from '../actions/LoginActions';
import { NotificationsState } from '@app/model/app/notifications/NotificationsState';
import { NotificationsActions } from '../actions/NotificationsActions';
import { DrawerActions } from '../actions/DrawerActions';
import { DrawerState } from '@app/model/app/drawer/DrawerState';
import { NavigationState } from '@app/model/app/navigation/NavigationState';
import { NavigationActions } from '../actions/NavigationActions';

export const initialState: NavigationState = new NavigationState();

export const navigationReducer: ActionReducer<NavigationState> = createReducer(
    initialState,
    on(LoginActions.logoutComplete, (state, action) => {
        return {
            ...initialState
        };
    }),

    on(NavigationActions.setLastAuthenticatedPageVisited, (state, action) => {
        return {
            ...state,
            lastAuthenticatedPageVisited: action.page,
        };
    }),



);