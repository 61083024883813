import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '@environments/environment';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { NotificationsActions } from '@app/store/actions/NotificationsActions';


@Injectable({ providedIn: 'root' })
export class NotificationsService {

    constructor(
        private router: Router,
        private store: Store
    ) {
    }

    prepareToShowNotifications(): Observable<boolean> {
        this.store.dispatch(NotificationsActions.readyShowNotifications());
        return of(true);
    }

}