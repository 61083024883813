

export class NotificationsState {
    showNotifications: boolean = false;
    newNotifications: number = 0;
    // API Responses


    // App models


}