import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PasswordsService } from './PasswordsService';
import { BlowfishService } from '../encryption/BlowfishService';
import { AesEncrypt } from '../encryption/AesEncrypt';
import { UpdatePasswordResponse } from '@app/model/api/passwords/UpdatePasswordResponse';
import { PasswordHistory } from '@app/model/app/password/PasswordHistory';
import { AccountService } from '../account/account.service';
import { TeamKeyService } from '../cache/team-key-service.service';
import { ZxcvbnService } from './zxcvbn.service';
import { UpdatePasswordRequest } from '@app/model/api/passwords/UpdatePasswordRequest';
import { Observable, firstValueFrom, lastValueFrom, of } from 'rxjs';
import { PasswordDetailsService } from './PasswordDetailsService';
import { PasswordExtraField } from '@app/model/app/password/PasswordExtraField';
import { BaseResponse } from '@app/model/api/BaseResponse';
import { UpdatePasswordNotesResponse } from '@app/model/api/passwords/UpdatePasswordNotesResponse';
import { MultiAddPasswordResponse } from '@app/model/api/passwords/MultiAddPasswordResponse';
import { EncryptionTools } from '../encryption/EncryptionTools';
import { MultiAddPasswordEntry } from '@app/model/api/passwords/MultiAddPasswordEntry';
import { TransferPasswordsToGroupRequest } from '@app/model/api/passwords/TransferPasswordsToGroupRequest';
import { TransferPasswordToGroupEntry } from '@app/model/api/passwords/TransferPasswordToGroupEntry';
import { MultiManagePasswordGroupMembershipRequest } from '@app/model/api/passwords/MultiManagePasswordGroupMembershipRequest';
import { MultiManagePasswordGroupMembershipResponse } from '@app/model/api/passwords/MultiManagePasswordGroupMembershipResponse';
import { MultiManagePasswordGroupMembershipEntry } from '@app/model/api/passwords/MultiManagePasswordGroupMembershipEntry';
import { StorageActions } from '@app/store/actions/StorageActions';
import { LabelService } from '../labels/LabelService';
import { LabelItem } from '@app/model/app/labels/LabelItem';
import { BulkUpdatePasswordMembershipInTeamItem } from '@app/model/app/password/BulkUpdatePasswordMembershipInTeamItem';
import { ZxcvbnResult } from '@zxcvbn-ts/core';
import { PasswordVersionsEnum } from '@app/model/app/password/PasswordVersionsEnum';
import { InMemoryPassword } from '@app/model/app/password/InMemoryPassword';
import { BulkAttachPasswordHistoryEntry } from '@app/model/api/passwords/BulkAttachPasswordHistoryEntry';


@Injectable({
    providedIn: 'root',
})

export class PasswordUpdateService {
    private v4Iterations: number = 30000;

    constructor(
        private passwordsService: PasswordsService,
        private store: Store,
        private aesEncryptService: AesEncrypt,
        private encryptionTools: EncryptionTools,
        private blowfishEncryptService: BlowfishService,
        private accountService: AccountService,
        private teamKeyService: TeamKeyService,
        private zxvbnService: ZxcvbnService,
        private passwordDetailsService: PasswordDetailsService,
        private labelService: LabelService

    ) {
    }


    async updateMyNotes(pid: number, myNotes: string, passphrase: string): Promise<UpdatePasswordNotesResponse | undefined> {
        // let teamMetaKeys = this.teamKeyService.getMyDefaultTeamMetaKeys();
        // let encryptedNotes = await this.aesEncryptService.aesEncryptv4(myNotes, this.v4Iterations, teamMetaKeys?.teamId as number);
        let encryptedNotes = await this.aesEncryptService.aesEncrypt(myNotes, passphrase);
        return await firstValueFrom(this.passwordsService.updateMyNotes(pid, encryptedNotes));
    }

    async transferPasswords(passwordIds: number[], groupId: number): Promise<BaseResponse | undefined> {

        // For each of the password ids, we need to get the password key
        let teamKey = this.teamKeyService.getTeamMetaKeys(groupId);
        let request = new TransferPasswordsToGroupRequest();
        request.gid = groupId;

        console.log("Transfer Passwords: " + passwordIds + " to group: " + groupId);
        console.log("teamKey: ", teamKey)
        if (teamKey) {


            // encrypt each password and add to the request
            for (let passwordId of passwordIds) {
                let passwordDetail = this.passwordDetailsService.retrievePasswordDetail(passwordId);
                if (passwordDetail) {

                    // For now always update my notes so that version 3 is utilized
                    let notes = passwordDetail.notes;
                    if (!notes) {
                        notes = "";
                    }
                    let results = await this.updateMyNotes(passwordId, notes, passwordDetail.key);

                    let encryptedPasswordKey = await this.aesEncryptService.aesEncrypt(passwordDetail.key, teamKey.groupKey);
                    let encryptedName = this.blowfishEncryptService.encrypt(passwordDetail.name, teamKey.metadataKey);
                    let encryptedUrl = this.blowfishEncryptService.encrypt(passwordDetail.url, teamKey.metadataKey);

                    let entry = new TransferPasswordToGroupEntry();
                    entry.id = passwordId;
                    entry.gk = encryptedPasswordKey;
                    entry.name = encryptedName;
                    entry.website = encryptedUrl;
                    request.entries.push(entry);

                }
            }
        }
        return await firstValueFrom(this.passwordsService.transferPasswords(request));
    }


    async bulkManagePasswordGroupMembership(entries: BulkUpdatePasswordMembershipInTeamItem[]): Promise<MultiManagePasswordGroupMembershipResponse | undefined> {
        let request = new MultiManagePasswordGroupMembershipRequest();

        for (let action of entries) {
            // Adds
            let teamKey = this.teamKeyService.getTeamMetaKeys(action.groupId);
            for (let passwordId of action.adds) {

                let passwordDetail = this.passwordDetailsService.retrievePasswordDetail(passwordId);

                if (teamKey && passwordDetail) {
                    let encryptedPasswordKey = await this.aesEncryptService.aesEncrypt(passwordDetail.key, teamKey.groupKey);

                    let entry = new MultiManagePasswordGroupMembershipEntry();
                    entry.id = passwordId;
                    entry.gk = encryptedPasswordKey;
                    entry.gid = action.groupId;
                    entry.action = 1;
                    request.entries.push(entry);
                }
            };

            // Removes
            for (let passwordId of action.removes) {
                let entry = new MultiManagePasswordGroupMembershipEntry();
                entry.id = passwordId;
                entry.action = 2;
                entry.gid = action.groupId;
                request.entries.push(entry);
            };
        }
        return await firstValueFrom(this.passwordsService.managePasswordGroupMembership(request));

    }


    async managePasswordGroupMembership(passwordId: number, adds: number[], removes: number[]): Promise<MultiManagePasswordGroupMembershipResponse | undefined> {

        let request = new MultiManagePasswordGroupMembershipRequest();
        // Adds
        for (let team of adds) {
            let teamKey = this.teamKeyService.getTeamMetaKeys(team);
            let passwordDetail = this.passwordDetailsService.retrievePasswordDetail(passwordId);

            if (teamKey && passwordDetail.id > 0) {
                let encryptedPasswordKey = await this.aesEncryptService.aesEncrypt(passwordDetail.key, teamKey.groupKey);

                // console.log("adding password");
                // console.log(teamKey.groupKey);
                // console.log(passwordDetail.key);
                let entry = new MultiManagePasswordGroupMembershipEntry();
                entry.id = passwordId;
                entry.gk = encryptedPasswordKey;
                entry.gid = team;
                entry.action = 1;
                request.entries.push(entry);
            } else {
                console.error("Error adding password to team: " + team);
            }
        };

        // Removes
        for (let team of removes) {
            let entry = new MultiManagePasswordGroupMembershipEntry();
            entry.id = passwordId;
            entry.action = 2;
            entry.gid = team;
            request.entries.push(entry);
        };

        return await firstValueFrom(this.passwordsService.managePasswordGroupMembership(request));
    }

    // This will manage the vault-based teams which are able to share a given password.
    async managePasswordGroupMembershipWithinVault(passwordId: number, adds: number[], removes: number[]): Promise<MultiManagePasswordGroupMembershipResponse | undefined> {

        let request = new MultiManagePasswordGroupMembershipRequest();
        // Adds
        for (let team of adds) {
            let teamKey = this.teamKeyService.getTeamMetaKeys(team);
            let passwordDetail = this.passwordDetailsService.retrievePasswordDetail(passwordId);

            if (teamKey && passwordDetail.id > 0) {
                let encryptedPasswordKey = await this.aesEncryptService.aesEncrypt(passwordDetail.key, teamKey.groupKey);
                let entry = new MultiManagePasswordGroupMembershipEntry();
                entry.id = passwordId;
                entry.gk = encryptedPasswordKey;
                entry.gid = team;
                entry.action = 1;
                request.entries.push(entry);
            } else {
                console.error("Error adding password to team: " + team);
            }
        };

        // Removes
        for (let team of removes) {
            let entry = new MultiManagePasswordGroupMembershipEntry();
            entry.id = passwordId;
            entry.action = 2;
            entry.gid = team;
            request.entries.push(entry);
        };

        return await firstValueFrom(this.passwordsService.managePasswordGroupMembership(request));
    }

    initiateCreateSamplePasswordAfterFirstLogin() {

        // Wait up to 20 seconds for the group to be created

        let count = 0;
        let maxCount = 40;
        let interval = setInterval(() => {
            if (count > 3) {
                console.log("Checking");
                let myDefaultGroup = this.passwordDetailsService.retrieveMyDefaultGroup();

                if (myDefaultGroup || count > maxCount) {
                    clearInterval(interval);
                    if (myDefaultGroup) {
                        // Create a sample password
                        this.createSamplePasswordAfterFirstLogin();
                    }
                }
            }
            count++;
        }, 500);

    }

    /**
     * Creates a sample password for the user after their first login
     * 
     */
    createSamplePasswordAfterFirstLogin() {
        // Generate extra data
        let extraData: PasswordExtraField[] = [];
        let secretPassword = "SuperSecretPassword";

        this.zxvbnService.testStringWithFullResults(secretPassword).then((passwordScoreResult) => {


            this.createNewPassword(
                "Sample Password", "Sample Username", secretPassword, "email@email.com",
                "https://passpack.com", "This is a sample password we inserted into your account for illustration purposes.  This section of notes is only visible to you regardless of sharing.",
                "The shared notes are visible to anyone to which you share the password.",
                true, extraData, passwordScoreResult,
                PasswordVersionsEnum.V2).then((response) => {
                    if (response === undefined) {
                        // No op
                    } else if (response.status.code === 0) {
                        // Add a label
                        if (response.status && response.status.codes && response.status.codes.length > 0 && response.status.codes[0].code === 0) {
                            let passwordId = Number(response.status.codes[0].id);

                            this.labelService.createNewLabel("Your Very First Passwords", "green-500").subscribe((response) => {
                                // if (this.consoleLog) console.log("Create label response: ", response);
                                // create a placeholder label item with the response so we can add it to the label.
                                let label = new LabelItem();
                                label.id = response.id;

                                this.passwordsService.addLabelsToSinglePassword(passwordId, [label.id]).subscribe((response) => {
                                    // log to console so it runs
                                    console.log(response);
                                    // Update the feed so the main datastores see the change
                                    // this.store.dispatch(StorageActions.immediatelyRunFeed());
                                    this.store.dispatch(StorageActions.immediatelyRunFeed());
                                });


                            });

                        }



                    } else {
                        // error, but do nothing
                    }
                }).catch((error: any) => {
                    console.error("Error updating password: ", error);
                })
        });
    }


    /**
     * 
     * @param name 
     * @param username 
     * @param password 
     * @param email 
     * @param url 
     * @param notes 
     * @param sharedNotes 
     * @param favorite 
     * @param extra 
     */
    async createNewPassword(name: string, username: string,
        password: string, email: string, url: string,
        notes: string, sharedNotes: string, favorite: boolean,
        extra: PasswordExtraField[], passwordScoreResult: ZxcvbnResult,
        version: number): Promise<MultiAddPasswordResponse | undefined> {
        let entries: MultiAddPasswordEntry[] = [];
        try {

            let history: PasswordHistory[] = [];
            let shouldAddHistory = false;

            if (email || username || password) {
                shouldAddHistory = true;
            }

            let myDefaultTeamMetaKeys = this.teamKeyService.getMyDefaultTeamMetaKeys();
            let encryptedPasswordKey = "";
            let encryptedName = "";
            let encryptedUrl = "";
            let encryptedNotes = "";
            let encryptedData = "";
            let encryptedHistory = "";
            let passwordKey = this.encryptionTools.generateRandomString(64);

            if (shouldAddHistory) {
                // Create the history
                let newHistory = new PasswordHistory();
                newHistory.changedBy = "" + this.accountService.getCustomerId();
                newHistory.changedByCurrentNickname = this.accountService.accountUserInformation.username;
                newHistory.changedDate = new Date().toISOString();
                newHistory.password = password;
                newHistory.username = username;
                newHistory.email = email;
                newHistory.deleted = 0;
                newHistory.sharedNotes = sharedNotes;
                encryptedHistory = await this.aesEncryptService.aesEncrypt(JSON.stringify(newHistory), passwordKey);

                history.push(newHistory);
            }

            if (version === 1) {
                let data = {
                    password: password,
                    username: username,
                    email: email,
                    sharedNotes: sharedNotes,
                    extra: extra,
                    history: history,
                    version: 1
                };
                encryptedData = await this.aesEncryptService.aesEncrypt(JSON.stringify(data), passwordKey);
            } else if (version === 2) {
                let data = {
                    password: password,
                    username: username,
                    email: email,
                    sharedNotes: sharedNotes,
                    extra: extra,
                    // history does not go here in version 2+
                    // history: history,
                    name: name,
                    url: url,
                    version: 2
                };
                encryptedData = await this.aesEncryptService.aesEncrypt(JSON.stringify(data), passwordKey);
            }
            if (myDefaultTeamMetaKeys) {
                encryptedPasswordKey = await this.aesEncryptService.aesEncrypt(passwordKey, myDefaultTeamMetaKeys.groupKey);
                // Version 2 does not separate the name and url into different decryption keys
                if (version === 1) {
                    encryptedName = this.blowfishEncryptService.encrypt(name, myDefaultTeamMetaKeys?.metadataKey);
                    encryptedUrl = this.blowfishEncryptService.encrypt(url, myDefaultTeamMetaKeys?.metadataKey);
                }
                // encryptedNotes = await this.aesEncryptService.aesEncryptv4(notes, this.v4Iterations, myDefaultTeamMetaKeys?.teamId as number);
                // TODO 
                encryptedNotes = await this.aesEncryptService.aesEncrypt(notes, passwordKey);
            }

            // Generate stats
            let score = await this.zxvbnService.testStringWithFullResults(password);
            let stats = JSON.stringify({
                password: {
                    score: score.score,
                    crack_time_display: score.crackTimesDisplay,
                    crack_time: score.crackTimesSeconds
                }
            });

            // create the password
            let entry = new MultiAddPasswordEntry();
            entry.url = encryptedUrl;
            entry.name = encryptedName;
            entry.data = encryptedData;
            entry.notes = encryptedNotes;
            entry.stats = stats;
            // entry.favorite = favorite;
            entry.pkey = encryptedPasswordKey;
            entry.dgpkey = encryptedPasswordKey;
            entry.group = myDefaultTeamMetaKeys?.teamId as number;
            entry.version = version;
            if (version > PasswordVersionsEnum.V1) entry.history = encryptedHistory;
            entries.push(entry);

            return await lastValueFrom(this.passwordsService.createPasswords(entries));

        } catch (e) {
            console.error("Error updating full password: " + e);
            return undefined;
        }
    }

    async convertPasswordToVersion2(passwordDetail: InMemoryPassword): Promise<UpdatePasswordResponse | undefined> {
        if (passwordDetail.version === PasswordVersionsEnum.V1) {

            console.log("Converting password: " + passwordDetail.id + " history");

            let data: BulkAttachPasswordHistoryEntry[] = [];

            // Get the history
            let history = this.passwordDetailsService.retrievePasswordHistory(passwordDetail.id);
            if (history) {
                // loop through the history 
                for (let i = 0; i < history.length; i++) {
                    console.log("Converting password: " + passwordDetail.id + " history + " + i);

                    let historyItem = history[i];
                    let newHistory = new PasswordHistory();
                    newHistory.changedBy = historyItem.changedBy;
                    newHistory.changedByCurrentNickname = historyItem.changedByCurrentNickname;
                    newHistory.changedDate = historyItem.changedDate;
                    newHistory.password = historyItem.password;
                    newHistory.username = historyItem.username;
                    newHistory.email = historyItem.email;
                    newHistory.sharedNotes = historyItem.sharedNotes;
                    newHistory.deleted = historyItem.deleted;
                    let encryptedHistory = await this.aesEncryptService.aesEncrypt(JSON.stringify(newHistory), passwordDetail.key);
                    // Build the request entry
                    let entry = new BulkAttachPasswordHistoryEntry();
                    entry.actorId = historyItem.changedBy as unknown as number;
                    entry.data = encryptedHistory;
                    data.push(entry);
                }

                // The data in the array is ordered oldest to newest, so we need to reverse it
                data.reverse();

                // Now bulk submit the history
                return await firstValueFrom(this.passwordsService.bulkAttachPasswordHistory(passwordDetail.id, data));

            } else {

                return undefined;
            }

        } else {
            return undefined;
        }
    }

    async convertVersion1PasswordToVersion2(passwordDetail: InMemoryPassword): Promise<UpdatePasswordResponse | undefined> {
        return this.updateFullPassword(passwordDetail, passwordDetail.name, passwordDetail.username, passwordDetail.password, passwordDetail.email, passwordDetail.url,
            passwordDetail.notes, passwordDetail.sharedNotes, passwordDetail.favorite == 1 ? true : false, passwordDetail.extra, null, PasswordVersionsEnum.V2, true);
        // return undefined;
    }


    /**
     * Updates the full password
     * @param passwordId 
     * @param password 
     * @param notes 
     * @param favorite 
     * @returns 
     */
    async updateFullPassword(passwordDetail: InMemoryPassword, name: string, username: string,
        password: string, email: string, url: string,
        notes: string, sharedNotes: string, favorite: boolean,
        extra: PasswordExtraField[], passwordScoreResult: ZxcvbnResult | null,
        version: number, conversionOnly: boolean = false): Promise<UpdatePasswordResponse | undefined> {

        console.log("updating password: " + passwordDetail.id + " " + conversionOnly);
        let passwordId = passwordDetail.id;
        let passwordKey = passwordDetail.key;
        let keyGroupId = passwordDetail.keyGroup;

        try {
            if (passwordDetail.version === PasswordVersionsEnum.V1) {
                // Convert the password to version 2
                let response = await this.convertPasswordToVersion2(passwordDetail);
                if (response === undefined) {
                    return undefined;
                }
            }
        } catch (e) {
            console.error("Error updating full password: " + e);
            return undefined;
        }

        try {
            // Get the history
            let history: PasswordHistory[] = [];

            let lastHistoryEntry: PasswordHistory | undefined = undefined;
            let shouldAddHistory = false;
            let encryptedHistory = "";

            // TODO - remove any reference to v1
            if (version === 1) {
                history = this.passwordDetailsService.retrievePasswordHistory(passwordId);
                if (history) {
                    // the history is already sorted by the history call
                    lastHistoryEntry = history[0];
                } else {
                    history = [];
                }

                if ((lastHistoryEntry) &&
                    (
                        lastHistoryEntry.password !== password
                        || lastHistoryEntry.username !== username
                        || ((lastHistoryEntry.email ? lastHistoryEntry.email : '') !== email)
                        || ((lastHistoryEntry.sharedNotes ? lastHistoryEntry.sharedNotes : '') !== sharedNotes)
                    )) {
                    shouldAddHistory = true;
                } else if (!lastHistoryEntry) {
                    shouldAddHistory = true;
                }
            } else if (version >= 2) {
                if (passwordDetail.password !== password
                    || passwordDetail.username !== username
                    || ((passwordDetail.email ? passwordDetail.email : '') !== email)
                    || ((passwordDetail.sharedNotes ? passwordDetail.sharedNotes : '') !== sharedNotes)
                ) {
                    shouldAddHistory = true;
                }
            }

            if (shouldAddHistory) {
                // Create the history
                let newHistory = new PasswordHistory();
                newHistory.changedBy = "" + this.accountService.getCustomerId();
                newHistory.changedByCurrentNickname = this.accountService.accountUserInformation.username;
                newHistory.changedDate = new Date().toISOString();
                newHistory.password = password;
                newHistory.username = username;
                newHistory.email = email;
                newHistory.sharedNotes = sharedNotes;
                newHistory.deleted = 0;
                history.push(newHistory);

                // for version 2+ we only look at the most recent, "newHistory" object to add
                if (!conversionOnly) {
                    encryptedHistory = await this.aesEncryptService.aesEncrypt(JSON.stringify(newHistory), passwordKey);
                }
            }

            // Updates should use this to get the key.  Otherwise we create a new one.
            let teamKey = this.teamKeyService.getTeamMetaKeys(keyGroupId);
            let myDefaultTeamMetaKeys = this.teamKeyService.getMyDefaultTeamMetaKeys();

            let encryptedPasswordKey = "";
            let encryptedName = "";
            let encryptedUrl = "";
            let encryptedNotes = "";
            let encryptedData = "";
            if (version === 1) {
                let data = {
                    password: password,
                    username: username,
                    email: email,
                    sharedNotes: sharedNotes,
                    extra: extra,
                    history: history,
                    version: 1
                };
                encryptedData = await this.aesEncryptService.aesEncrypt(JSON.stringify(data), passwordKey);
            } else if (version === 2) {
                let data = {
                    password: password,
                    username: username,
                    email: email,
                    sharedNotes: sharedNotes,
                    extra: extra,
                    // history does not go here in version 2+
                    //history: history,
                    name: name,
                    url: url,
                    version: 2
                };
                encryptedData = await this.aesEncryptService.aesEncrypt(JSON.stringify(data), passwordKey);
            }
            if (teamKey) {
                encryptedPasswordKey = await this.aesEncryptService.aesEncrypt(passwordKey, teamKey.groupKey);
                if (version === 1) {
                    encryptedName = this.blowfishEncryptService.encrypt(name, teamKey?.metadataKey);
                    encryptedUrl = this.blowfishEncryptService.encrypt(url, teamKey?.metadataKey);
                }
            }
            if (myDefaultTeamMetaKeys) {
                // encryptedNotes = await this.aesEncryptService.aesEncryptv4(notes, this.v4Iterations, myDefaultTeamMetaKeys?.teamId as number);
                encryptedNotes = await this.aesEncryptService.aesEncrypt(notes, passwordKey);
            }

            // Generate stats
            // let score = await this.zxvbnService.testStringWithFullResults(password);
            let score: ZxcvbnResult;
            if (passwordScoreResult) {
                score = passwordScoreResult;
            } else {
                score = await this.zxvbnService.testStringWithFullResults(password);
            }
            let stats = JSON.stringify({
                password: {
                    score: score.score,
                    crack_time_display: score.crackTimesDisplay,
                    crack_time: score.crackTimesSeconds
                }
            });

            // Update the password
            let request = new UpdatePasswordRequest();
            request.id = passwordId;
            request.website = encryptedUrl;
            request.name = encryptedName;
            request.data = encryptedData;
            request.notes = encryptedNotes;
            request.stats = stats;
            request.favorite = favorite;
            request.usernamePasswordUpdated = true
            request.version = version;
            request.history = encryptedHistory;
            request.conversionOnly = conversionOnly;
            return await firstValueFrom(this.passwordsService.updatePassword(request));

        } catch (e) {
            console.error("Error updating full password: " + e);
            return undefined;
        }
    }

}