import { Injectable } from "@angular/core";
import { SubscriptionSummaryResponse } from "@app/model/api/account/billing/SubscriptionSummaryResponse";
import { selectSubscriptionSummaryDetails } from "@app/store/selectors/AccountSelectors";
import { Store } from "@ngrx/store";


@Injectable({ providedIn: 'root' })
export class SubscriptionSummaryService {
    readonly vmSubscriptionSummary$ = this.store.select(selectSubscriptionSummaryDetails);
    subscription: SubscriptionSummaryResponse;
    extractedSubscriptionSku: string;

    constructor(
        private store: Store
    ) {

        // Start the subscription.
        this.vmSubscriptionSummary$.subscribe(x => {
            // Update the subscription summary data
            if (x) {
                if (x.status.code === 0) {
                    this.subscription = x;
                    this.extractedSubscriptionSku = this.subscription.subscriptionSummary.subscriptionSku;
                } else {
                    this.subscription = new SubscriptionSummaryResponse();
                    this.extractedSubscriptionSku = "";
                }
            }
        });
    }

    /**
     * Returns whether or not the subscription is a business plan subscription.
     * @returns 
     */
    isBusinessPlanSubscription(): boolean {
        let sub = this.getSubscriptionLevel();
        if (sub === "Business") {
            return true;
        } else {
            return false;
        }
    }

    getSubscriptionLevel(): string {
        if (this.subscription && this.subscription.subscriptionSummary) {
            // Upper case the first letter of the subscription level
            let sub = this.subscription.subscriptionSummary.subscriptionSku;
            sub =
                sub.charAt(0).toUpperCase()
                + sub.slice(1)
            return sub;
        } else {
            return "No Subscription";
        }
    }
}