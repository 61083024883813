
import { CancelTeamInvitationResponse } from '@app/model/api/organization/CancelTeamInvitationResponse';
import { GetOrganizationLicensesResponse } from '@app/model/api/organization/GetOrganizationLicensesResponse';
import { GetOrganizationListResponse } from '@app/model/api/organization/GetOrganizationListResponse';
import { GetOrganizationSettingsResponse } from '@app/model/api/organization/GetOrganizationSettingsResponse';
import { OrganizationDataEntry } from '@app/model/api/organization/OrganizationDataEntry';
import { RemoveOrganizationLicenseResponse } from '@app/model/api/organization/RemoveOrganizationLicenseResponse';
import { RemoveVerifiedDomainAccountResponse } from '@app/model/api/organization/RemoveVerifiedAccountResponse';
import { ResendTeamInvitationResponse } from '@app/model/api/organization/ResendTeamInvitationResponse';
import { UpdateOrganizationMemberRolesRequest } from '@app/model/api/organization/UpdateOrganizationMemberRolesRequest';
import { UpdateOrganizationMemberRolesResponse } from '@app/model/api/organization/UpdateOrganizationMemberRolesResponse';
import { NumberArray } from '@app/model/app/NumberArray';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const OrganizationActions = createActionGroup({
    source: 'Organization',
    events: {
        // Page opens
        'License Page Loaded': emptyProps(),
        'Organization Settings Page Loaded': emptyProps(),

        // Other
        'Cancel Invitations': props<{ customerIds: number[] }>(),
        'Cancel Invitations Success': props<{ response: CancelTeamInvitationResponse }>(),
        'Cancel Invitations Error': props<{ error: any }>(),
        'Resend Invitations': props<{ customerIds: number[] }>(),
        'Resend Invitations Success': props<{ response: ResendTeamInvitationResponse }>(),
        'Resend Invitations Error': props<{ error: any }>(),

        'Remove Organization Licenses': props<{ customerIds: number[] }>(),
        'Remove Organization Licenses Success': props<{ response: RemoveOrganizationLicenseResponse }>(),
        'Remove Organization Licenses Error': props<{ error: any }>(),

        'Remove Verified Domain Account': props<{ customerIds: number[] }>(),
        'Remove Verified Domain Account Success': props<{ response: RemoveVerifiedDomainAccountResponse }>(),
        'Remove Verified Domain Account Error': props<{ error: any }>(),

        'Purchased Additional Licenses': emptyProps(),

        'Load Organization Settings': emptyProps(),
        'Organization Settings Loaded': props<{ response: GetOrganizationSettingsResponse }>(),
        'Organization Settings Load Error': props<{ error: any }>(),

        'Invitations Sent': emptyProps(),
        'Need Reload Organization Details': emptyProps(),
        'Organization Licenses Retrieved': props<{ response: GetOrganizationLicensesResponse }>(),
        'Organization Licenses Retrieval Error': props<{ error: any }>(),
        'Organization List Retrieved': props<{ response: GetOrganizationListResponse }>(),
        'Organization List Retrieval Error': props<{ error: any }>(),
        'Set Active Organization': props<{ activeOrganization: OrganizationDataEntry }>(),

        'Update Team Member Roles': props<{ request: UpdateOrganizationMemberRolesRequest, adminRolesAdded: NumberArray }>(),
        'Update Team Member Roles Response': props<{ response: UpdateOrganizationMemberRolesResponse }>(),
        'Update Team Member Roles Error': props<{ error: any }>(),

        'Add API User As Vault Member': props<{ apiServiceAccountId: number }>(),
        'Add API User As Vault Member Success': emptyProps(),
        'Add API User As Vault Member Error': props<{ error: any }>(),

    },
});