import { Injectable } from '@angular/core';
import { Geo } from '@app/model/api/Geo';

import * as moment from 'moment';


@Injectable({
    providedIn: 'root',
})

export class DisplayService {


    /**
     * Extracts the base domain for the favicon
     * @param url 
     * @returns 
     */
    generateFaviconUrl(url: string): string {

        if (!url) {
            url = "none";
        } else {
            //let domain = 'https://' + url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0];
            // no action
        }

        // Clean up the domain - remove the protocol and remove the path
        // split the domain by the protocol
        let domainParts = url.split('://');
        //if the domainParts has more than 1 part, then remove the protocol
        if (domainParts.length > 1) {
            url = domainParts[1];
        } else {
            url = domainParts[0];
        }

        // get rid of anything after the parameters
        let paramParts = url.split('?');
        if (paramParts.length > 1) {
            url = paramParts[0];
        }

        // Now get rid of the path
        let pathParts = url.split('/');
        if (pathParts.length > 1) {
            url = pathParts[0];
        }



        // console.log("URL: " + url);

        let encodedDomain = btoa(url).replaceAll("\/", "-");
        return "https://img.passpack.com/favicon/v2/32/" + encodedDomain;
    }

    /**
     * Converts the geo object to a description
     * @param geo 
     * @returns 
     */
    geoToDescription(geo: Geo): string {

        if (geo) {
            let result = "";
            if (geo.city) {
                result += geo.city;
            }
            if (geo.stateProvince) {
                result += (result.length > 0 ? ", " : "") + geo.stateProvince;
            }
            if (geo.countryName) {
                result += (result.length > 0 ? ", " : "") + geo.countryName;
            }
            return result;
        } else {
            return "";
        }
    }

    /**
   * Converts the score to a description
   * @param inputScore 
   * @returns 
   */
    passwordScoreToDescription(inputScore: number): string {
        switch (inputScore) {
            case 0: return 'Very Weak';
            case 1: return 'Weak';
            case 2: return 'Medium';
            case 3: return 'Strong';
            case 4: return 'Very Strong';
            default: return 'Very Weak';
        }
    };

    calculateMomentFromNow(input: string): string {
        if (!input) {
            return "";
        } else
            return moment(input, "MM/DD/YYYY hh:mm:ss.SSS ZZ").fromNow();
    }

    calculateTimeInMyLocale(input: string): string {
        if (!input) {
            return "";
        } else
            return moment(input, "MM/DD/YYYY hh:mm:ss.SSS ZZ").format('MMMM Do YYYY, h:mm:ss a');
    }

    calculateTimeInMyLocaleNoSec(input: string): string {
        if (!input) {
            return "";
        } else
            return moment(input, "MM/DD/YYYY hh:mm:ss.SSS ZZ").format('MMMM Do YYYY, h:mm a');
    }

    calculateHistoryTimeInMyLocale(input: string): string {
        if (!input) {
            return "";
        } else
            return moment(input, "YYYY-MM-DDThh:mm:ss.SSSZZ").format('MMMM Do YYYY, h:mm:ss a');
    }




    /**
       * Converts the default role to a description
       * @param defaultRole 
       * @returns 
       */
    convertTeamDefaultRoleToDescription(defaultRole: number): string {
        switch (defaultRole) {
            case 0:
                return "Default";
            case 1:
                return "Owner";
            case 2:
                return "Administrator";
            case 3:
                return "Read / Write";
            case 4:
                return "Read Only";

            default:
                return "None";
        }
    }

    convertTeamDefaultRoleToDescriptionShort(defaultRole: number): string {
        switch (defaultRole) {
            case 0:
                return "Default";
            case 1:
                return "Owner";
            case 2:
                return "A";
            case 3:
                return "R/W";
            case 4:
                return "R/O";

            default:
                return "None";
        }
    }

    convertTeamShareTypeToDescription(shareType: number): string {
        switch (shareType) {
            case 0:
                return "Unknown";
            case 1:
                return "Individual Shared to Connection";
            case 2:
                return "Individual Shared From Connection";
            case 3:
                return "Team Shared to Connection";
            case 4:
                return "Team Shared from Connection";

            default:
                return "None";
        }
    }

    convertPasswordActionToDescription(action: number): string {
        switch (action) {
            case 1:
                return "Password created";
            case 2:
                return "Password updated";
            case 3:
                return "Password deleted";
            case 506:
                return "Password transfer to group";
            case 507:
                return "Password transfer from group";
            case 508:
                return "Password add to group";
            case 509:
                return "Password remove from group";
            case 700:
                return "Password opened";
            case 701:
                return "Password data copied";
            case 702:
                return "Extra data copied";
            case 703:
                return "History data copied";
            case 704:
                return "Quick data copied";
            case 802:
                return "Password Archived";
            case 803:
                return "Password Unarchived";
            default:
                return "";
        }
    }

    convertMinToDisplay(inputMin: number): string {
        if (5 === inputMin) {
            return "5 Minutes";
        } else if (15 === inputMin) {
            return "15 Minutes";
        } else if (30 === inputMin) {
            return "30 Minutes";
        } else if (60 === inputMin) {
            return "1 Hour";
        } else if (1440 === inputMin) {
            return "1 Day";
        } else if (10080 === inputMin) {
            return "1 Week";
        } else {
            return inputMin + " Minutes";
        }
    }

}