import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StorageService } from './storage.service';
import { StorageActions } from '@app/store/actions/StorageActions';
import { Database } from 'sql.js';
import { LabelFeedResponse } from '@app/model/api/dataFeed/LabelFeedResponse';
import { LabelFeedEntry } from '@app/model/api/dataFeed/LabelFeedEntry';

@Injectable({
    providedIn: 'root'
})
export class LabelStorageService {
    consoleLog: boolean = false;
    constructor(
        private store: Store,
        private storageService: StorageService
    ) { }


    /**
     * Process the label feed and store the lastupdate
     * @param data 
     */
    processLabelFeed(data: LabelFeedResponse) {
        if (data && data.tags && data.tags.length > 0) {
            let toAdd: LabelFeedEntry[] = [];
            let toDelete: number[] = [];
            let inMemToDelete: number[] = [];

            data.tags.forEach(tag => {
                switch (tag.action) {
                    case 1:
                    // add
                    case 2:
                        // update
                        // add or update should upsert
                        toAdd.push(tag);
                        // All adds first delete in mem
                        inMemToDelete.push(tag.id);
                        break;
                    case 3:
                        // delete
                        toDelete.push(tag.id);
                        // console.log("pushing delete of " + tag.id);
                        // inMemToDelete.push(tag.id);
                        break;
                }
            });


            ////////////////////////////////////////////
            // Send to the indexeddb
            ////////////////////////////////////////////
            // Adds or updates
            this.storageService.appDbService?.labels.bulkPut(toAdd).then() // handle success
                .catch(function (error) { console.log(error); }); // handle failure

            // Deletes
            this.storageService.appDbService?.labels.bulkDelete(toDelete).then() // handle success
                .catch(function (error) { console.log(error); }); // handle failure


            ////////////////////////////////////////////
            // Send to in memory store
            ////////////////////////////////////////////
            let database = this.storageService.inMemorySQLService?.getDatabase();
            if (database) {
                let start = new Date().getTime();
                // Deletes
                // Deletes go first and also delete the adds so that any reductions are made
                this.processInMemorySQLDeletes(database, toDelete);
                this.processInMemorySQLDeletes(database, inMemToDelete);

                // Adds
                this.processInMemorySQLAdds(database, toAdd);

                // Dump
                // this.dumpTables(database);
                this.dumpCounts(database);

                let end = new Date().getTime();
                if (this.consoleLog) console.log("Time to process label feed: " + (end - start) + " ms");
            } else {
                console.log("Database not ready");
            }
            ////////////////////////////////////////////
            // Notify the store that we are done loading
            if (toAdd.length > 0 || toDelete.length > 0) {
                // console.log("dispatching updateLastLabelChangeTimestamp ");
                this.store.dispatch(StorageActions.updateLastLabelChangeTimestamp());
            }

        }
    }

    /**
         * Helper method to dump the counts of rows
         * @param database 
         */
    private dumpCounts(database: Database) {
        let self = this;
        database.each("SELECT count(1) as count FROM labels2passwords;", {},
            function (row) {
                if (self.consoleLog) console.log("labels2passwords rows-> " + row.count);
            },
            () => void (0)
        );
    }

    /**
     * Helper method to dump the contents
     * @param database 
     */
    dumpTables() {
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {
            let self = this;
            let counter = 0;
            database.each("SELECT labelId, passwordId FROM labels2passwords;", {},
                function (row) {
                    if (self.consoleLog) console.log("data Table l2p -> " + row.labelId + " " + row.passwordId + " " + ++counter);
                },
                () => void (0)
            );

            database.each("SELECT * FROM labels;", {},
                function (row) {
                    if (self.consoleLog) console.log("data Table label -> " + row.id + " " + row.label + " " + row.color);
                },
                () => void (0)
            );
        }
    }


    /**
    *  Removes the entries from the in memory database.
    * @param database 
    * @param toDelete 
    */
    private processInMemorySQLDeletes(database: Database, toDelete: number[]) {
        if (null != toDelete && toDelete.length > 0) {
            ////////////////////////////////////////////////
            // Handle the team2passwords table
            const sql = `DELETE FROM labels2passwords where labelId = :labelId`;
            const sql2 = `DELETE FROM labels where id = :labelId`;

            const stmt = database.prepare(sql);
            const stmt2 = database.prepare(sql2);
            toDelete.forEach((labelId) => {
                stmt.run({ ':labelId': labelId });
                stmt2.run({ ':labelId': labelId });
            });
            stmt.free();
            stmt2.free();
        }
    }

    /**
     * Adds the entries to the in memory database.
     * @param database 
     * @param toAdd 
     */
    private processInMemorySQLAdds(database: Database, toAdd: LabelFeedEntry[]) {
        if (null != toAdd && toAdd.length > 0) {
            ////////////////////////////////////////////////
            // Handle the team2passwords table
            if (this.consoleLog) console.log("Adding labels");
            const l2psql = `INSERT OR REPLACE INTO labels2passwords (passwordId, labelId) VALUES (:passwordId, :labelId)`;
            const lsql = `INSERT OR REPLACE INTO labels (id, label, color) VALUES (:id, :label, :color)`;

            const l2pStatement = database.prepare(l2psql);
            const lStatement = database.prepare(lsql);
            toAdd.forEach((label) => {
                if (this.consoleLog) console.log("Adding label: " + label.id + " " + label.tag + " " + label.color);
                lStatement.run({ ':id': label.id, ':label': label.tag, ':color': label.color });

                label.pids.forEach((pid) => {
                    l2pStatement.run({ ':passwordId': pid, ':labelId': label.id });
                });
            });
            l2pStatement.free();
            lStatement.free();
        }
    }

}