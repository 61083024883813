import { Injectable } from '@angular/core';

import { EncryptionToolsJS } from '../encryption/EncryptionToolsJS';


@Injectable({ providedIn: 'root' })
export class RandomStringGeneratorService {
    private encryptionTools = new EncryptionToolsJS();


    generateRandomStringWithDatePrefix(length: number): string {
        let date = new Date();
        let datePrefix = date.getFullYear().toString() + (date.getMonth() + 1).toString().padStart(2, '0') + date.getDate().toString().padStart(2, '0') + date.getHours().toString().padStart(2, '0') + date.getMinutes().toString().padStart(2, '0');
        return datePrefix + this.encryptionTools.generateRandomString(length - datePrefix.length);
    }

    generateRandomString(length: number): string {
        return this.encryptionTools.generateRandomString(length);
    }

}