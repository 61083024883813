
export class LoginFunnelState {
    initialized: boolean = false;
    loggedIn: boolean = false;
    locked: boolean = false;
    packingKeyLoaded: boolean = false;
    packingKeyDecoded: boolean = false;
    mfaCodeRequired: boolean = false;
    mfaCodeEntered: boolean = false;
    needCreatePackingKey: boolean = false;
    needVerifyAccount: boolean = false;
    canViewAccount: boolean = false;
    lastStatusCode: number = 0;
    loggingOut: boolean = false;
    computing: boolean = false;
    errorMessage: string = "";
    username: string = "";
    password: string = "";

}