import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, concatMap, exhaustMap, filter, map, mergeMap, switchMapTo, tap, withLatestFrom } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { IdleTimerService } from "@app/services/timers/IdleTimerService";
import { ActionUpdateService } from "@app/services/action/ActionUpdateService";
import { ActionActions } from "../actions/ActionActions";

@Injectable()
export class ActionEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private actionUpdateService: ActionUpdateService,
        private idleTimerService: IdleTimerService) { }



    /**
     * Send Action Update
     */
    readonly sendActionUpdate$ = createEffect(
        () => this.actions$.pipe(
            ofType(ActionActions.sendActionUpdate),
            tap((action) => this.actionUpdateService.postActionUpdate(action.data).subscribe()),
        ), { dispatch: false }
    );




}
