import { BaseResponse } from "../BaseResponse";
import { StatusEntry } from "./StatusEntry";

export class StatusResponse extends BaseResponse {
    goodSession: boolean;
    rcv: string;
    rcv2: string;
    rcva: string;
    customerId: number;
    entries: StatusEntry[];
}