
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAPIService } from './BaseAPIService';
import { environment } from '@environments/environment';
import { HttpParams } from '@angular/common/http';
import { BaseResponse } from '@app/model/api/BaseResponse';


@Injectable({ providedIn: 'root' })
export class CsrfService {
    private csrfToken: string = "";
    constructor(
        private baseAPIService: BaseAPIService,
        private store: Store,
    ) { }

    /**
     * Returns the current CSRF token
     * @returns 
     */
    public getCsrfToken() {
        return this.csrfToken;
    }

    /**
     * Sets the current CSRF token if the incoming one is not null;
     * @param token 
     */
    public setCsrfToken(token: string | null) {
        if (token != null) {
            this.csrfToken = token;
        }
    }

    /**
     * Retrieves a new CSRF token from the server
     */
    retrieveCsrfToken(): void {
        let url = environment.API_BASE_URL + "csrf/generate";
        var params = new HttpParams();
        this.baseAPIService.getRequest<BaseResponse>(params, url).subscribe(response => {
            //This will set the csrf token in the csrf interceptor
        });

    }
}