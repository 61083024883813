import { PasswordVault } from "./PasswordVault";

export class PasswordVaultData extends PasswordVault {
    id: string;
    encryptionKey: string;
    dataKey: string;
    role: number;
    ownerName: string;
    ownerOrganizationId: string;
    ownerCustomerId: number;
    decryptedPrivateKey: string;
    decryptedPublicKey: string;
}