import { ActionReducer, createReducer, on } from '@ngrx/store';
import { LoginActions } from '../actions/LoginActions';
import { TeamActions } from '../actions/TeamActions';
import { TeamState } from '@app/model/app/teams/TeamState';

export const initialState: TeamState = new TeamState();

export const teamReducer: ActionReducer<TeamState> = createReducer(
    initialState,
    on(LoginActions.logoutComplete, (state, action) => {
        return {
            ...initialState
        };
    }),
    on(LoginActions.logoutInitiate, (state, action) => {
        return {
            ...initialState
        };
    }),
    on(LoginActions.lightweightLogout, (state, action) => {
        return {
            ...initialState
        };
    }),
    on(TeamActions.updateSearchCriteria, (state, action) => {
        return {
            ...state,
            teamSearchCriteria: action.criteria
        };
    }),


);