import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class StatusFeedStateManagement {

    constructor(
        private storageService: StorageService,
        private store: Store
    ) {
    }

    /**
     * Returns the last update for the data feed.
     * @param feed 
     * @returns 
     */
    async getLastUpdateForDataFeed(feed: number): Promise<string> {
        let lastUpdate = "0";
        // console.log("query for feed: ", feed);
        // query the database and find what we have. If nothing then return the default value of "0"
        // if (null != this.storageService.appDbService) {
        await this.storageService.appDbService?.dataFeed.where('feed').equals(feed).first().then((result) => {
            // console.log("query result", result);
            if (result) {
                lastUpdate = result.ts;
            }
        });

        // console.log("last update: " + lastUpdate);

        return lastUpdate;
    }

    /**
     * Store the last update for the data feed.
     * @param feed 
     * @param ts 
     */
    storeLastUpdateForDataFeed(feed: number, ts: string): void {
        this.storageService.appDbService?.dataFeed.put({ feed: feed, ts: ts }).then();
    }

    async getLastUpdatesForStatusFeed(): Promise<Map<number, string>> {

        let map = new Map<number, string>();
        // query the database and find what we have. If nothing then return the default value of "0"
        await this.storageService.appDbService?.statusFeed.toArray().then((results) => {

            if (results) {
                results.forEach(entry => {
                    // console.log("Setting last update for feed: ", entry.feed, " to ", entry.ts);
                    map.set(entry.feed, entry.ts);
                });
            }
        });
        return map;
    }

    /**
     * Store the last update for the feed.
     * @param feedNumber 
     * @param ts 
     */
    storeLastUpdateForStatusFeed(feedNumber: number, ts: string): void {
        this.storageService.appDbService?.statusFeed.put({ feed: feedNumber, ts: ts }).then();
    }

}