

import { DecryptionResult } from '@app/model/app/encryption/DecryptionResult';
import { Decoder, Encoder } from '@as-com/pson';
import * as ByteBuffer from 'bytebuffer';
import { AES_CBC, Pbkdf2HmacSha256, bytes_to_base64 } from 'asmcrypto.js';

import { EncryptionToolsJS } from './EncryptionToolsJS';
// Must import buffer for PSON to work.
import { Buffer } from "buffer"

// Create an instance of EncryptionToolsJS
const encryptionTools = new EncryptionToolsJS();


class AESJS {
    showTiming = false;
    consoleLog = false;
    ivSaltLengthV6 = 32;
    ivSaltLengthV5 = 32;
    ivSaltLengthV4 = 32;
    ivSaltLengthV1 = 16;

    constructor() {
        // Initialize any properties here if needed
    }
    ensureBuffer() {
        console.log("ensureBuffer");
        require("buffer");
        // This creates a small buffer just to ensure the Buffer class is loaded
        return Buffer.from('');
    }

    aesEncryptv6(inputData, passphrase, iterations) {
        if (this.consoleLog) console.log("encrypting data v6");
        var promise = new Promise((resolve, reject) => {
            // let wrapper = { value: data };
            try {
                var version = 6;
                var iv = new Uint8Array(this.ivSaltLengthV6);
                var salt = new Uint8Array(this.ivSaltLengthV6);
                self.crypto.getRandomValues(salt);
                self.crypto.getRandomValues(iv);

                // convert the data to an arraybuffer
                let val = encryptionTools.string_to_bytes(inputData);
                if (this.consoleLog) {
                    console.log("encrypting v6 input: " + inputData);
                    console.log("val", val);
                }

                // Key length is 32 which is 256 bits
                var key = Pbkdf2HmacSha256(encryptionTools.string_to_bytes(passphrase), salt, iterations, 32);
                self.crypto.subtle.importKey("raw", key, "AES-GCM", true, ["encrypt", "decrypt"])
                    .then(function (key) {
                        //returns the symmetric key
                        self.crypto.subtle.encrypt(
                            {
                                name: "AES-GCM",
                                iv: iv, //The initialization vector you used to encrypt
                            },
                            key, //from generateKey or importKey above
                            val //ArrayBuffer of the data
                        )
                            .then(function (encrypted) {
                                var encodedData = ByteBuffer.wrap(encrypted).toBase64();
                                var payload = [version, iterations, bytes_to_base64(iv), bytes_to_base64(salt), encodedData].join("#");

                                resolve(payload);
                            })
                            .catch(function (err) {
                                reject(err);
                            });
                    })
                    .catch(function (err) {
                        reject(err);
                    });

            } catch (err) {
                reject(err);
            }

        });
        return promise;

    };




    aesDecryptv6(inputDataElements, encryptedData, passphrase) {
        if (this.consoleLog) console.log("aesDecryptv6", encryptedData);

        const startTime = this.showTiming ? performance.now() : 0;
        var promise = new Promise((resolve, reject) => {

            let decryptionResult = new DecryptionResult();

            /*
                Input data elements are:
                0: 6
                1: iterations
                2: iv
                3: salt
                4: encrypted data
            */

            try {
                const iterations = parseInt(inputDataElements[1]);
                const iv = encryptionTools.decode(inputDataElements[2]);
                let saltAsEncodedString = inputDataElements[3];
                const salt = encryptionTools.decode(saltAsEncodedString);
                const cipherText = ByteBuffer.fromBase64(inputDataElements[4]).toArrayBuffer();
                // console.log("cipherText", inputDataElements[4]);
                // console.log("iterations", iterations);


                // key length 32 is 256 bits
                let key4 = Pbkdf2HmacSha256(encryptionTools.string_to_bytes(passphrase), salt, iterations, 32);

                if (this.consoleLog) console.log("key length: " + key4.length);


                self.crypto.subtle.importKey("raw", key4, "AES-GCM", true, ["encrypt", "decrypt"])
                    .then(function (importedKey) {
                        self.crypto.subtle.decrypt(
                            {
                                name: "AES-GCM",
                                iv: iv, //The initialization vector you used to encrypt
                            },
                            importedKey, //from generateKey or importKey above
                            cipherText //ArrayBuffer of the data
                        )
                            .then(function (decrypted) {
                                // Convert the arraybuffer, decrypted, into a string
                                const decryptedString = encryptionTools.bytes_to_string(new Uint8Array(decrypted));
                                decryptionResult.success = true;
                                decryptionResult.data = decryptedString;
                                const endTime = self.showTiming ? performance.now() : 0;
                                const elapsedTime = endTime - startTime;
                                if (self.showTiming) console.log(`AES v6 decryption took ${elapsedTime.toFixed(2)}ms`);
                                resolve(decryptionResult);
                            })
                            .catch(function (err) {
                                console.error("error3: " + err + " input: " + encryptedData + ", passphrase: " + passphrase);
                                decryptionResult.success = false;
                                decryptionResult.error = err;
                                reject(decryptionResult);
                            });
                    }).catch(function (err) {
                        console.error("error2: " + err);
                        decryptionResult.success = false;
                        decryptionResult.error = err;
                        reject(decryptionResult);
                    });
                // console.log("done");

            } catch (e) {
                console.error("decrypt error: " + e);
                decryptionResult.success = false;
                decryptionResult.error = e;
                reject(decryptionResult);
            }
        });

        return promise;

    }

    aesDecryptv5(inputDataElements, encryptedData, passphrase) {
        // console.log("aesDecryptv5", encryptedData);

        const startTime = this.showTiming ? performance.now() : 0;
        var promise = new Promise((resolve, reject) => {

            let decryptionResult = new DecryptionResult();

            /*
                Input data elements are:
                0: 5
                1: iterations
                2: iv
                3: salt
                4: encrypted data
            */

            try {
                const iterations = parseInt(inputDataElements[1]);
                const iv = encryptionTools.decode(inputDataElements[2]);
                let saltAsEncodedString = inputDataElements[3];
                const salt = encryptionTools.decode(saltAsEncodedString);
                const cipherText = ByteBuffer.fromBase64(inputDataElements[4]).toArrayBuffer();
                // console.log("cipherText", inputDataElements[4]);


                // key length 32 is 256 bits
                let key4 = Pbkdf2HmacSha256(encryptionTools.string_to_bytes(passphrase), salt, iterations, 32);

                if (this.consoleLog) console.log("key length: " + key4.length);


                self.crypto.subtle.importKey("raw", key4, "AES-GCM", true, ["encrypt", "decrypt"])
                    .then(function (importedKey) {
                        self.crypto.subtle.decrypt(
                            {
                                name: "AES-GCM",
                                iv: iv, //The initialization vector you used to encrypt
                            },
                            importedKey, //from generateKey or importKey above
                            cipherText //ArrayBuffer of the data
                        )
                            .then(function (decrypted) {
                                // console.log("decr: ", decrypted);
                                const payload = new Decoder([], false).decode(decrypted);
                                // console.log("output: " + payload.value);
                                // resolve(payload.value);
                                decryptionResult.success = true;
                                decryptionResult.data = payload.value;
                                // console.log("decryptionResult: " + decryptionResult.data);
                                const endTime = self.showTiming ? performance.now() : 0;
                                const elapsedTime = endTime - startTime;
                                if (self.showTiming) console.log(`AES v5 decryption took ${elapsedTime.toFixed(2)}ms`);
                                resolve(decryptionResult);
                            })
                            .catch(function (err) {
                                console.error("error3: " + err + " input: " + encryptedData + ", passphrase: " + passphrase);
                                decryptionResult.success = false;
                                decryptionResult.error = err;
                                reject(decryptionResult);
                            });
                    }).catch(function (err) {
                        console.error("error2: " + err);
                        decryptionResult.success = false;
                        decryptionResult.error = err;
                        reject(decryptionResult);
                    });
                // console.log("done");

            } catch (e) {
                console.error("decrypt error: " + e);
                decryptionResult.success = false;
                decryptionResult.error = e;
                reject(decryptionResult);
            }
        });

        return promise;

    }



    aesDecryptv3(inputDataElements, encryptedData, passphrase) {
        const startTime = this.showTiming ? performance.now() : 0;

        var promise = new Promise((resolve, reject) => {
            let decryptionResult = new DecryptionResult();
            // if (tmp[0] === '3') {
            // 3 is the "new" version of the old pp8 system.
            try {
                const iv = encryptionTools.decode(inputDataElements[1]);
                const salt = encryptionTools.decode(inputDataElements[2]);
                const cipherText = ByteBuffer.fromBase64(inputDataElements[3]).toArrayBuffer();
                const key4 = Pbkdf2HmacSha256(encryptionTools.string_to_bytes(passphrase), salt, 0x3E8, 32);


                self.crypto.subtle.importKey("raw", key4, "AES-GCM", true, ["encrypt", "decrypt"])
                    .then(function (importedKey) {
                        self.crypto.subtle.decrypt(
                            {
                                name: "AES-GCM",
                                iv: iv, //The initialization vector you used to encrypt
                            },
                            importedKey, //from generateKey or importKey above
                            cipherText //ArrayBuffer of the data
                        )
                            .then(function (decrypted) {
                                // console.log("decr: " + decrypted);
                                const payload = new Decoder([], false).decode(decrypted);
                                // console.log("output: " + payload.value);
                                // resolve(payload.value);
                                decryptionResult.success = true;
                                decryptionResult.data = payload.value;

                                // console.log("decryptionResult: " + decryptionResult.data);
                                const endTime = self.showTiming ? performance.now() : 0;
                                const elapsedTime = endTime - startTime;
                                if (self.showTiming) console.log(`AES v3 decryption took ${elapsedTime.toFixed(2)}ms `);
                                resolve(decryptionResult);
                            })
                            .catch(function (err) {
                                console.error("error3: " + err + " input: " + encryptedData + ", passphrase: " + passphrase);
                                decryptionResult.success = false;
                                decryptionResult.error = err;
                                reject(decryptionResult);
                            });
                    }).catch(function (err) {
                        console.error("error2: " + err);
                        decryptionResult.success = false;
                        decryptionResult.error = err;
                        reject(decryptionResult);
                    });
                // console.log("done");
            } catch (e) {
                console.error("decrypt error: " + e);
                decryptionResult.success = false;
                decryptionResult.error = e;
                reject(decryptionResult);
            }
        });
        return promise;

    }

    aesDecryptv2(inputDataElements, encryptedData, passphrase) {
        const startTime = this.showTiming ? performance.now() : 0;
        var promise = new Promise((resolve, reject) => {
            let decryptionResult = new DecryptionResult();
            try {

                var iv = encryptionTools.decode(inputDataElements[1]);
                // var salt2 = b642ab(inputDataElements[2]);
                var salt = encryptionTools.decode(inputDataElements[2]);
                var cipherText = encryptionTools.decode(inputDataElements[3]);
                const key = Pbkdf2HmacSha256(encryptionTools.string_to_bytes(passphrase), salt, 0x3E8, 16);

                let decrypted = AES_CBC.decrypt(cipherText, key, true, iv);
                let payload2 = encryptionTools.bytes_to_string(decrypted);

                decryptionResult.success = true;
                decryptionResult.data = payload2;
                // console.log("decryptionResult: " + decryptionResult.data);

                const endTime = self.showTiming ? performance.now() : 0;
                const elapsedTime = endTime - startTime;
                if (self.showTiming) console.log(`AES v2 decryption took ${elapsedTime.toFixed(2)}ms`);
                resolve(decryptionResult);
            } catch (e) {
                console.error("Error", e);
                console.error(encryptedData);
                decryptionResult.success = false;
                decryptionResult.error = e;
                reject(decryptionResult);
            }
        });
        return promise;
    }
}

export { AESJS };
