
export class OrganizationPermissionEnum {
    static readonly PERMISSION_READ: string = "r";
    static readonly PERMISSION_READ_WRITE: string = "rw";
    static readonly PERMISSION_ORG: string = "settings.organization";
    static readonly PERMISSION_ORG_SETTINGS: string = "settings.organization.settings";
    static readonly PERMISSION_ORG_BILLING: string = "settings.organization.billing";
    static readonly PERMISSION_ORG_LICENSE_MANAGEMENT: string = "settings.organization.license-management";
    static readonly PERMISSION_ORG_API_ACCESS: string = "settings.organization.api-access";
    static readonly PERMISSION_ORG_REPORTS: string = "settings.organization.reports";

}
