import { Injectable } from '@angular/core';
import { LocalStorageService } from './dataStorage/local-storage.service';
import { SessionStorageKeys } from '@app/model/app/SessionStorageKeys';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    readonly environment = environment;
    consoleLog: boolean = false;
    constructor(
        private localStorageService: LocalStorageService
    ) {

    }

    /**
     * Saves the lastAuthenticatedPage visited to sessino storage
     * @param page
     */
    setLastAuthenticatedPageVisited(page: string): void {
        if (this.consoleLog) console.log("setting lastAuthenticatedPageVisited: " + page);
        this.localStorageService.saveStateToSessionStorage(SessionStorageKeys.LastAuthenticatedPageVisited, page);
    }

    /**
     * Retrieves the last authenticated page visited from session storage
     * @returns
     */
    getLastAuthenticatedPageVisited(): string {
        let page = this.localStorageService.loadStateFromSessionStorage(SessionStorageKeys.LastAuthenticatedPageVisited);
        if (this.consoleLog) console.log("returning lastAuthenticatedPageVisited: " + page);
        if (!page || page == "" || page.includes("/passwords")) {
            page = "/passwords";
        } else if (page.includes("/teams")) {
            page = "/teams";
        }
        return page;
    }
}