import { Routes } from '@angular/router';
import { AuthGuard } from './services/authGuard/auth.guard';

const authenticatedComponent = () => import('./components/layout/authenticated/authenticated.component').then((m) => m.AuthenticatedComponent);

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    loadComponent: () =>
      import('./components/layout/noauth/noauth.component').then(
        (m) => m.NoauthComponent
      ),
    children: [
      {
        path: 'locked',
        loadChildren: () =>
          import('./components/auth/locked/locked.routes').then((m) => m.routes),
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./components/auth/login/login.routes').then((m) => m.routes),
      },
      {
        path: 'logout',
        loadChildren: () =>
          import('./components/auth/logout/logout.routes').then((m) => m.routes),
      },
      {
        path: 'register',
        loadChildren: () =>
          import('./components/register/register.routes').then((m) => m.routes),
      },

    ],
  },
  {
    path: 'confirm',
    data: { preload: true, loadAfterSeconds: 5 },
    loadComponent: () =>
      import('./components/layout/noauth/noauth.component').then(
        (m) => m.NoauthComponent
      ),
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./components/confirm/confirm.routes').then((m) => m.routes),
      },
    ],
  },
  {
    path: 'reset',
    data: { preload: true, loadAfterSeconds: 5 },
    loadComponent: () =>
      import('./components/layout/noauth/noauth.component').then(
        (m) => m.NoauthComponent
      ),
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./components/reset/reset.routes').then((m) => m.routes),
      },
    ],
  },
  {
    path: 'labels',
    canActivate: [AuthGuard],
    data: { preload: true, loadAfterSeconds: 5 },
    loadComponent: authenticatedComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./components/labels/labels.routes').then((m) => m.routes),
      },
    ],
  },
  {
    path: 'passwords',
    canActivate: [AuthGuard],
    data: { preload: true, loadAfterSeconds: 5 },
    loadComponent: authenticatedComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./components/passwords/passwords.routes').then((m) => m.routes),
      },
    ],
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    data: { preload: true, loadAfterSeconds: 5 },
    loadComponent: authenticatedComponent,
    children: [
      {
        path: '',
        data: { preload: true, loadAfterSeconds: 5 },
        loadChildren: () =>
          import('./components/reports/reports.routes').then((m) => m.routes),
      },
    ],
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    data: { preload: true },
    loadComponent: authenticatedComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./components/settings/settings.routes').then((m) => m.routes),
      },
    ],
  },
  {
    path: 'teams',
    canActivate: [AuthGuard],
    data: { preload: true, loadAfterSeconds: 5 },
    loadComponent: authenticatedComponent,
    children: [
      {
        path: '',
        data: { preload: true, loadAfterSeconds: 5 },
        loadChildren: () =>
          import('./components/teams/teams.routes').then((m) => m.routes),
      },
    ],
  },

  {
    path: '**',
    redirectTo: 'login',
  },
];
