import { Injectable } from '@angular/core';
import { BaseAPIService } from './BaseAPIService';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ShowLimitsResponse } from '@app/model/api/account/limits/ShowLimitsResponse';

@Injectable({ providedIn: 'root' })
export class LimitsService {

    constructor(
        private baseAPIService: BaseAPIService,
    ) {
    }

    /**
     * Get the limits
     * @returns 
     */
    getLimits(): Observable<ShowLimitsResponse> {
        let url = environment.API_BASE_URL + "v1/secure/limits/show";
        var params = new HttpParams();
        return this.baseAPIService.getRequest<ShowLimitsResponse>(params, url);
    }


}