import { Injectable } from '@angular/core';
import * as ByteBuffer from 'bytebuffer';
import { Pbkdf2HmacSha256 } from 'asmcrypto.js';
import { Decoder } from '@as-com/pson';
import { DecryptionResult } from '@app/model/app/encryption/DecryptionResult';
import { TeamKeyService } from '../cache/team-key-service.service';
import { EncryptionToolsJS } from './EncryptionToolsJS';
import { AESJS } from './AESJS';

@Injectable({ providedIn: 'root' })
export class AesDecrypt2 {
    private worker: Worker | null = null;
    private pendingRequests = new Map<number, { resolve: (value: DecryptionResult) => void, reject: (reason: any) => void }>();
    private nextRequestId = 0;
    private aesJS = new AESJS();
    private encryptionTools = new EncryptionToolsJS();

    private consoleLog: boolean = false;

    constructor(
        private teamKeyService: TeamKeyService
    ) {
        // Initialize the worker if supported
        if (typeof Worker !== 'undefined') {
            this.initWorker();
        }
    }

    private initWorker() {
        try {
            this.worker = new Worker(new URL('./aes-decrypt.worker.ts', import.meta.url), { type: 'module' });
            this.worker.onmessage = ({ data }) => {
                const { result, id } = data;
                const request = this.pendingRequests.get(id);
                if (request) {
                    this.pendingRequests.delete(id);
                    request.resolve(result);
                }
            };

            this.worker.onerror = (error) => {
                console.error('Worker error:', error);
                // Resolve all pending requests with an error
                this.pendingRequests.forEach((request) => {
                    const errorResult = new DecryptionResult();
                    errorResult.success = false;
                    errorResult.error = 'Worker error: ' + error.message;
                    request.reject(errorResult);
                });
                this.pendingRequests.clear();

                // Try to reinitialize the worker
                this.worker = null;
                this.initWorker();
            };
        } catch (error) {
            console.error('Failed to initialize worker:', error);
            this.worker = null;
        }
    }

    async aesDecrypt(data: string, passphrase: string): Promise<DecryptionResult> {
        // If worker is available, use it
        // test to see if the beginning starts with "4#" if so, use the sync method
        if (this.worker && !data.startsWith("4#")) {
            return this.aesDecryptWithWorker(data, passphrase);
            // return this.aesDecryptSync(data, passphrase);

        } else {
            // Fall back to synchronous decryption
            return this.aesDecryptSync(data, passphrase);
        }
    }

    private async aesDecryptWithWorker(data: string, passphrase: string): Promise<DecryptionResult> {
        const requestId = this.nextRequestId++;

        return new Promise<DecryptionResult>((resolve, reject) => {
            this.pendingRequests.set(requestId, { resolve, reject });

            this.worker!.postMessage({
                encryptedData: data,
                passphrase: passphrase,
                id: requestId
            });
        });
    }

    private async aesDecryptSync(encryptedData: string, passphrase: string): Promise<DecryptionResult> {
        const tmp = encryptedData.split("#");
        if (tmp[0] === '6') {
            // 5 is the "new" version of the new system.
            return this.aesJS.aesDecryptv6(tmp, encryptedData, passphrase);
        } else if (tmp[0] === '5') {
            // 5 is the "new" version of the new system.
            return this.aesJS.aesDecryptv5(tmp, encryptedData, passphrase);
        } else if (tmp[0] === '4') {
            // 4 is the "deprecated" version 
            return this.aesDecryptv4(tmp, encryptedData, passphrase);
        } else if (tmp[0] === '3') {
            // 3 is the "new" version of the old pp8 system.
            return this.aesJS.aesDecryptv3(tmp, encryptedData, passphrase);
        } else {
            return this.aesJS.aesDecryptv2(tmp, encryptedData, passphrase);
        }
    }

    async aesDecryptv4(inputDataElements: string[], encryptedData: string, passphrase: string): Promise<DecryptionResult> {
        var promise = new Promise<DecryptionResult>((resolve, reject) => {
            let decryptionResult = new DecryptionResult();
            /*
                Input data elements are:
                0: 4
                1: iterations
                2: keyId
                3: iv
                4: salt
                5: encrypted data
            */
            console.log("decrypting: " + encryptedData);
            try {
                const iterations = parseInt(inputDataElements[1]);
                const keyId = parseInt(inputDataElements[2]);
                const iv = this.encryptionTools.decode(inputDataElements[3]);
                let saltAsEncodedString = inputDataElements[4];
                const salt = this.encryptionTools.decode(saltAsEncodedString);
                const cipherText = ByteBuffer.fromBase64(inputDataElements[5]).toArrayBuffer();


                // Try to find the key
                let teamMetaKeys = this.teamKeyService.getTeamMetaKeys(keyId);
                if (!teamMetaKeys) {
                    reject("Key Not Found: " + keyId);
                } else {

                    let passphrase = teamMetaKeys.groupKey;
                    // TODO - undo

                    // key length 32 is 256 bits
                    let key4 = Pbkdf2HmacSha256(this.encryptionTools.string_to_bytes(passphrase), salt, iterations, 32);

                    if (this.consoleLog) console.log("key length: " + key4.length);

                    //Pbkdf2HmacSha256(this.encryptionTools.string_to_bytes(passphrase), salt, iterations, 32);

                    self.crypto.subtle.importKey("raw", key4, "AES-GCM", true, ["encrypt", "decrypt"])
                        .then(function (importedKey) {
                            self.crypto.subtle.decrypt(
                                {
                                    name: "AES-GCM",
                                    iv: iv, //The initialization vector you used to encrypt
                                },
                                importedKey, //from generateKey or importKey above
                                cipherText //ArrayBuffer of the data
                            )
                                .then(function (decrypted) {
                                    // console.log("decr: " + decrypted);
                                    const payload = new Decoder([], false).decode(decrypted);
                                    // console.log("output: " + payload.value);
                                    // resolve(payload.value);
                                    decryptionResult.success = true;
                                    decryptionResult.data = payload.value;
                                    resolve(decryptionResult);
                                })
                                .catch(function (err) {
                                    console.error("error3: " + err + " input: " + encryptedData + ", passphrase: " + passphrase);
                                    decryptionResult.success = false;
                                    decryptionResult.error = err as unknown as string;
                                    reject(decryptionResult);
                                });
                        }).catch(function (err) {
                            console.error("error2: " + err);
                            decryptionResult.success = false;
                            decryptionResult.error = err as unknown as string;
                            reject(decryptionResult);
                        });
                    // console.log("done");
                }
            } catch (e) {
                console.error("decrypt error: " + e);
                decryptionResult.success = false;
                decryptionResult.error = e as unknown as string;
                reject(decryptionResult);
            }

        });
        return promise;

    }



}