import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable, of } from "rxjs";
import { catchError, concatMap, exhaustMap, filter, map, mergeMap, switchMapTo, tap, withLatestFrom } from "rxjs/operators";
import { LoginActions } from "../actions/LoginActions";
import { Store } from "@ngrx/store";
import { selectLoginPageModel, } from "../selectors/LoginSelectors";
import { AccountService } from "@app/services/account/account.service";
import { AccountActions } from "../actions/AccountActions";
import { Router } from "@angular/router";
import { NavigationService } from "@app/services/NavigationService";
import { AppService } from "@app/services/app/appservice.service";

@Injectable()
export class LoginEffects {
    consoleLog: boolean = false;
    constructor(
        private actions$: Actions,
        private store: Store,
        private navigationService: NavigationService,
        private router: Router,
        private accountService: AccountService,
        private appService: AppService
    ) { }

    readonly loadLoginStateIfNotLoaded$ = createEffect(
        () => this.actions$.pipe(
            ofType(LoginActions.loginPageOpened),
            // select the state from the store
            concatLatestFrom(() => this.store.select(selectLoginPageModel)),
            // if the state is not initialized, then load it
            tap(([action, selectLoginPageModel]) => { if (this.consoleLog) console.log("loadlogin initialized " + selectLoginPageModel.state.initialized); }),
            filter(([, selectLoginPageModel]) => !selectLoginPageModel.state.initialized),
            //initialize the state by loading account details
            map(() => LoginActions.loadAccountDetails()),
        )
    );


    readonly loginAfterNewAccountVerification$ = createEffect(
        () => this.actions$.pipe(
            ofType(AccountActions.verifyNewAccountEmailSuccess),
            // select the state from the store
            concatLatestFrom(() => this.store.select(selectLoginPageModel)),
            // if the state is not initialized, then load it
            exhaustMap(([action, selectLoginPageModel]) => {
                return this.accountService.login(selectLoginPageModel.state.username, selectLoginPageModel.state.password).pipe(
                    map((response) => LoginActions.accountLoginCompleted({ loginResponse: response })),
                    catchError((error: { message: string }) =>
                        of(LoginActions.loginPageError({ error }))
                    )
                );
            }
            )
        )
    );


    readonly performLogin$ = createEffect(
        () => this.actions$.pipe(
            ofType(LoginActions.login),
            exhaustMap((action) => {
                return this.accountService.login(action.username, action.password).pipe(
                    map((response) => LoginActions.accountLoginCompleted({ loginResponse: response })),
                    catchError((error: { message: string }) =>
                        of(LoginActions.loginPageError({ error }))
                    )
                );
            }
            )
        )
    );

    readonly checkLoginReturnCode$ = createEffect(
        () => this.actions$.pipe(
            ofType(LoginActions.accountLoginCompleted),
            tap((action) => this.accountService.checkLoginResponse(action.loginResponse)),
        ), { dispatch: false }
    );

    readonly submitMFA$ = createEffect(
        () => this.actions$.pipe(
            ofType(LoginActions.submitMFA),
            exhaustMap((action) => {
                return this.accountService.submitToken(action.token).pipe(
                    map((response) => LoginActions.mFACodeEntered({ mfaResponse: response })),
                    catchError((error: { message: string }) =>
                        of(LoginActions.loginPageError({ error }))
                    )
                );
            }
            )
        )
    );


    /**
           * checks if they may view account
           */
    readonly checkIfMayViewAccount$ = createEffect(
        () => this.actions$.pipe(
            ofType(AccountActions.unlockPackingKeySuccess),
            concatLatestFrom(() => this.store.select(selectLoginPageModel)),
            // Check packing key entered
            tap(([action, selectLoginPageModel]) => { if (this.consoleLog) console.log("mayview initialized: " + JSON.stringify(selectLoginPageModel.state)); }),
            filter(([, selectLoginPageModel]) => selectLoginPageModel.state.packingKeyDecoded),
            // Check if MFA is required and if so, if it has been entered
            filter(([, selectLoginPageModel]) => (
                selectLoginPageModel.state.mfaCodeRequired && selectLoginPageModel.state.mfaCodeEntered)
                || !selectLoginPageModel.state.mfaCodeRequired
                || !selectLoginPageModel.state.locked
            ),
            map(() => LoginActions.setMayViewAccount({ canViewAccount: true })),
        )

    );

    /**
     * See if we should redirect to a content page
     */
    readonly redirectToAuthenticatedContent$ = createEffect(
        () => this.actions$.pipe(
            ofType(LoginActions.setMayViewAccount),
            concatLatestFrom(() => this.store.select(selectLoginPageModel)),
            filter(([, selectLoginPageModel]) => selectLoginPageModel.state.canViewAccount),
            tap((action) => this.router.navigate([this.navigationService.getLastAuthenticatedPageVisited()])),
        )
        , { dispatch: false }
    );



    /**
     * Retrieve the app features
     */

    readonly getAccountFeatures$ = createEffect(
        () => this.actions$.pipe(
            ofType(LoginActions.accountLoginCompleted,
                LoginActions.packingKeyPageOpened,
                AccountActions.packingKeyDecryptedFromStorage,
            ),
            exhaustMap((action) => {
                return this.appService.getAppFeatures().pipe(
                    map((response) => LoginActions.appFeaturesRetrieved({ response: response })),
                    catchError((error: { message: string }) =>
                        of(LoginActions.loginPageError({ error }))
                    )
                );
            }
            )
        )
    );
    /**
     * Process the app features
     */

    readonly processAppFeatures$ = createEffect(
        () => this.actions$.pipe(
            ofType(LoginActions.appFeaturesRetrieved),
            tap((action) => this.appService.processAppFeaturesResponse(action.response)),
        ), { dispatch: false }
    );
}
