import { SyncStatus } from "@app/model/app/database/SyncStatus";
import { ListPasswordVaultEntry } from "@app/model/api/passwordVault/ListPasswordVaultEntry";

export class PasswordVaultEntry extends ListPasswordVaultEntry {
    syncedInMemory: number = SyncStatus.SYNC_STATUS_NOT_SYNCED;


    constructor(entry: ListPasswordVaultEntry) {
        super(entry);
    }
}