import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

import { BaseAPIService } from '../BaseAPIService';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { SystemMessageListResponse } from '@app/model/api/system/SystemMessageListResponse';
import { SystemMessageListEntry } from '@app/model/api/system/SystemMessageListEntry';


@Injectable({ providedIn: 'root' })
export class SiteMessageService {
    consoleLog: boolean = false;
    private environment = environment;

    constructor(
        private router: Router,
        private baseAPIService: BaseAPIService,

    ) {

    }



    /*
    Helper functions to get at user site messages
    */
    getSiteMessages(): Observable<SystemMessageListResponse> {
        let url = environment.API_BASE_URL + "v1/siteMessages";
        var params = new HttpParams();

        return this.baseAPIService.getRequest<SystemMessageListResponse>(params, url);
    }
}