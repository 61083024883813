import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { from, of } from "rxjs";
import { catchError, exhaustMap, map, tap, } from "rxjs/operators";
import { PasswordActions } from "../actions/PasswordActions";
import { PasswordsService } from "@app/services/passwords/PasswordsService";
import { DrawerActions } from "../actions/DrawerActions";
import { AccountActions } from "../actions/AccountActions";
import { PasswordGroupService } from "@app/services/passwords/PasswordGroupService";
import { PasswordDeleteService } from "@app/services/passwords/PasswordDeleteService";
import { PasswordUpdateService } from "@app/services/passwords/PasswordUpdateService";
import { PasswordPageService } from "@app/services/passwords/PasswordPageService";
import { TeamActions } from "../actions/TeamActions";

@Injectable()
export class PasswordVaultEffects {
    constructor(
        private actions$: Actions,
        private passwordPageService: PasswordPageService,
        private passwordGroupService: PasswordGroupService,
        private passwordDeleteService: PasswordDeleteService,
        private passwordUpdateService: PasswordUpdateService,
        private passwordsService: PasswordsService) { }




    // /**
    //  * Restore a password
    //  */
    // readonly restorePassword$ = createEffect(
    //     () => this.actions$.pipe(
    //         ofType(
    //             PasswordActions.restorePassword
    //         ),
    //         tap((action) => this.passwordsService.restorePassword(action.data)),
    //     ), { dispatch: false }
    // );

}
