

export class LabelState {
    showLabels: boolean = false;

    // API Responses


    // App models


}