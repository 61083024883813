import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable, of } from "rxjs";
import { catchError, concatMap, exhaustMap, filter, map, mergeMap, switchMapTo, tap, withLatestFrom } from "rxjs/operators";
import { LoginActions } from "../actions/LoginActions";
import { Store } from "@ngrx/store";
import { LimitsService } from "@app/services/limits.service";
import { LimitsActions } from "../actions/LimitsActions";

@Injectable()
export class LimitsEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private limitsService: LimitsService) { }



    /**
     * Loads the limits data from the server
     */
    readonly loadOrganizationList$ = createEffect(
        () => this.actions$.pipe(
            ofType(LoginActions.packingKeyPageOpened),
            exhaustMap(() => {
                return this.limitsService.getLimits().pipe(
                    map((response) =>
                        LimitsActions.limitsDataRetrieved({ response })
                    ),
                    catchError((error) => of(LimitsActions.limitsDataRetrievalError({ error })))
                );
            })
        )
    );

}
