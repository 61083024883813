export class OrganizationLicenseListEntryDisplay {
    username: string;
    name: string;
    email: string;
    status: string;
    customerId: number;
    roles: string[];
    mfa: string;
    mfaIcons: string[];
    domainVerified: boolean;
    lastLogin: string;
    checked: boolean;
    memberOtherOrganization: boolean = false;
    hasResetPackingKey: boolean = false;
    hasResetPackingKeyReason: string = '';
    packingKeyResetDisabled: boolean = false;
}