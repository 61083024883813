import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of, Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpParams } from '@angular/common/http';

import { BaseAPIService } from '../BaseAPIService';

import { GetAppFeaturesResponse } from '@app/model/api/app/GetAppFeaturesResponse';


@Injectable({ providedIn: 'root' })
export class AppService {
    consoleLog: boolean = false;
    features: string[] = [];

    constructor(
        private router: Router,
        private baseAPIService: BaseAPIService,
    ) {

    }

    /**
     * Queries the server for the app features.
     * @returns 
     */
    getAppFeatures(): Observable<GetAppFeaturesResponse> {
        let url = environment.API_BASE_URL + "v1/secure/app/features";
        var params = new HttpParams();
        return this.baseAPIService.getRequestNoErrorHandling<GetAppFeaturesResponse>(params, url);
    }

    /**
     * Processes the app features response.
     */
    processAppFeaturesResponse(response: GetAppFeaturesResponse) {
        this.features = response.features;
    }

    /**
     * Checks if the app has a feature.
     * @param feature 
     * @returns 
     */
    hasFeature(feature: string): boolean {
        return this.features.includes(feature);
    }




}