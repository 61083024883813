
import { ShowLimitsResponse } from '@app/model/api/account/limits/ShowLimitsResponse';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const LimitsActions = createActionGroup({
    source: 'Limits',
    events: {
        // Page opens

        // Other
        'Limits Data Retrieved': props<{ response: ShowLimitsResponse }>(),
        'Limits Data Retrieval Error': props<{ error: any }>(),

    },
});