import { ActionReducer, createReducer, on } from '@ngrx/store';
import { LoginActions } from '../actions/LoginActions';
import { OrganizationState } from '@app/model/app/organization/OrganizationState';
import { OrganizationActions } from '../actions/OrganizationActions';

export const initialState: OrganizationState = new OrganizationState();

export const organizationReducer: ActionReducer<OrganizationState> = createReducer(
    initialState,
    on(LoginActions.logoutComplete, (state, action) => {
        return {
            ...initialState
        };
    }),
    on(OrganizationActions.organizationListRetrieved, (state, action) => {
        return {
            ...state,
            organizationListResponse: action.response
        };
    }),
    on(OrganizationActions.setActiveOrganization, (state, action) => {
        return {
            ...state,
            activeOrganization: action.activeOrganization
        };
    }),
    on(OrganizationActions.organizationLicensesRetrieved, (state, action) => {
        return {
            ...state,
            organizationLicenseListResponse: action.response
        };
    }),
    on(OrganizationActions.organizationSettingsLoaded, (state, action) => {
        return {
            ...state,
            organizationSettingsResponse: action.response
        };
    }),



);