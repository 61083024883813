import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { GetSettingsDataResponse } from "@app/model/api/account/GetSettingsDataResponse";
import { AccountActions } from "@app/store/actions/AccountActions";
import { selectSettingsData } from "@app/store/selectors/AccountSelectors";
import { Idle, DEFAULT_INTERRUPTSOURCES, LocalStorage } from "@ng-idle/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { LocalStorageService } from "../dataStorage/local-storage.service";
import { SessionStorageKeys } from "@app/model/app/SessionStorageKeys";


@Injectable({ providedIn: 'root' })

export class IdleTimerService implements OnInit, OnDestroy {
    private shouldRun: boolean = false;
    readonly vmSettings$ = this.store.select(selectSettingsData);
    vmSettingsSubscription: Subscription;
    onTimeoutSubscription: Subscription;
    onTimeoutWarningSubscription: Subscription;
    onInterruptSubscription: Subscription;
    private settings: GetSettingsDataResponse;
    consoleLog: boolean = false;

    constructor(
        private store: Store,
        private idle: Idle,
        private localStorageService: LocalStorageService
    ) {

        // set idle parameters
        // idle.setIdle(5); // how long can they be inactive before considered idle, in seconds
        // idle.setTimeout(5); // how long can they be idle before considered timed out, in seconds
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

        // do something when the user becomes idle
        this.onInterruptSubscription = idle.onInterrupt.subscribe(() => {
            // this.store.dispatch(AccountActions.idleInterrupt());
            this.interrupted();
        });

        // do something when the user has timed out
        this.onTimeoutSubscription = idle.onTimeout.subscribe(() => {
            console.log("idle timeout");
            this.store.dispatch(AccountActions.idleTimeout());
        });
        // do something as the timeout countdown does its thing
        this.onTimeoutWarningSubscription = idle.onTimeoutWarning.subscribe(seconds => {
            console.warn("idle timeout warning: " + seconds);
            // this.countdown = seconds;
        });

        // Start the subscription.
        this.vmSettingsSubscription = this.vmSettings$.subscribe(x => {
            let populateDefault = false;
            // Update the active user data
            if (x) {
                if (x.status.code === 0) {
                    this.settings = x;
                    this.resetTimer();
                } else {
                    this.settings = new GetSettingsDataResponse();
                }
            }
        });
    }

    ngOnInit() {
        // this.reset();
    }
    ngOnDestroy() {
        console.log("IdleTimerService destroyed");
        if (this.vmSettingsSubscription) {
            this.vmSettingsSubscription.unsubscribe();
        }
        if (this.onTimeoutSubscription) {
            this.onTimeoutSubscription.unsubscribe();
        }
        if (this.onTimeoutWarningSubscription) {
            this.onTimeoutWarningSubscription.unsubscribe();
        }
        if (this.onInterruptSubscription) {
            this.onInterruptSubscription.unsubscribe();
        }
    }


    interrupted() {
        // logs the new interrupt
        // Store the current time as the last idle interrupt time.
        if (this.consoleLog) console.log("idle interrupt");

        let now = new Date().getTime();
        this.localStorageService.saveStateToLocalStorage(SessionStorageKeys.LastIdleInterruptTime, now);
    }

    /**
     * Stops the idle timer
     */
    stopIdleTimer() {
        this.shouldRun = false;
        if (this.consoleLog) console.log("Stopping idle timer");
        this.idle.stop();
    }

    /**
     * Starts the idle timer
     */
    startIdleTimer(): void {
        if (this.consoleLog) console.log("Starting idle timer");
        this.shouldRun = true;

        // Stop it first
        this.idle.stop();

        //idleTimeoutInSeconds is a grace period
        let idleTimeoutInSeconds = 5;
        this.idle.setIdle(this.retrieveTimeoutInSecondsFromSettings());
        this.idle.setTimeout(idleTimeoutInSeconds);
        this.idle.watch();
    }

    resetTimer() {

        // get the existing idle interrupt time
        let lastIdleInterruptTime = this.localStorageService.loadStateFromLocalStorage(SessionStorageKeys.LastIdleInterruptTime);
        if (this.consoleLog) console.log("lastIdleInterruptTime: " + lastIdleInterruptTime);
        if (lastIdleInterruptTime) {
            // the lastIdleInterruptTime is in milliseconds
            let now = new Date().getTime();
            let idleTimeoutInSeconds = this.retrieveTimeoutInSecondsFromSettings();
            if (this.consoleLog) console.log("idleTimeoutInSeconds", idleTimeoutInSeconds);
            let idleTimeoutInMilliseconds = idleTimeoutInSeconds * 1000;
            let shouldTimeoutAt = lastIdleInterruptTime + idleTimeoutInMilliseconds;
            if (this.consoleLog) {
                console.log("now", now);
                console.log("shouldTimeoutAt", shouldTimeoutAt);
                console.log("diff", now - shouldTimeoutAt);
            }

            if (now > shouldTimeoutAt) {
                // we've timed out
                console.log("idle timeout");
                this.store.dispatch(AccountActions.idleTimeout());
            }
        }


        // we'll call this method when we want to start/reset the idle process

        this.startIdleTimer();
    }

    retrieveTimeoutInSecondsFromSettings(): number {
        // Default to 15 minutes
        let timeout = 15 * 60;

        // See if there is a lock time in the settings.
        if (this.settings && this.settings.data) {
            try {
                let settingsObj = JSON.parse(this.settings.data);
                if (settingsObj && settingsObj.lockTime && settingsObj.lockTime.effective) {
                    // The lock time is in minutes - need to convert to seconds.
                    timeout = settingsObj.lockTime.effective * 60
                }
            } catch (e) {
                console.error(e);
            }
        }

        if (this.consoleLog) console.log("Will idle timeout in " + timeout + " seconds");
        return timeout;
    }

}