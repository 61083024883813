import { Pbkdf2HmacSha256, RSA, RSA_OAEP, Sha256, Sha512, base64_to_bytes, bytes_to_string, string_to_bytes } from 'asmcrypto.js';
import { Buffer } from "buffer";
import { EncryptionResult } from "@app/model/app/encryption/EncryptionResult";
import * as ByteBuffer from 'bytebuffer';
class RSAJS {
    showTiming = false;
    consoleLog = false;


    urlEncodeUrl(str) {
        return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
    }

    rsaEncryptV2(data, publicKey) {
        let payload = new EncryptionResult();
        if (this.consoleLog) console.log("RSA Encrypt version 2 starting");
        var promise = new Promise((resolve, reject) => {
            try {
                let publicKeyParts = publicKey.split("|");
                let keyData = {
                    kty: "RSA",
                    alg: "RSA-OAEP-256",  // Your desired JWA algorithm
                    ext: true,            // Is key extractable or not
                    key_ops: ["encrypt"], // Array of supposed key usages
                    // The "e" component, public exponent, spec restricts is to the only value, 65537
                    // Public key components
                    e: "AQAB",                      // The "e" component, public exponent, spec restricts is to the only value, 65537
                    n: this.urlEncodeUrl(publicKeyParts[1]),
                }
                let algorithm = {   //these are the algorithm options
                    name: "RSA-OAEP",
                    hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
                }
                if (this.consoleLog) console.log("keyData: " + JSON.stringify(keyData) + " algorithm: " + JSON.stringify(algorithm) + " data: " + data);

                self.crypto.subtle.importKey("jwk", keyData, algorithm, true, ["encrypt"])
                    .then(function (key) {
                        //returns the symmetric key
                        self.crypto.subtle.encrypt(
                            {
                                name: "RSA-OAEP",
                                label: string_to_bytes("zz")
                            },
                            key, //from generateKey or importKey above
                            string_to_bytes(data) //ArrayBuffer of the data
                        ).then(function (encrypted) {
                            var encryptedData = ByteBuffer.wrap(encrypted).toBase64();
                            payload.success = true;
                            payload.data = encryptedData;
                            resolve(payload);
                        }).catch(function (err) {
                            console.error("ERROR 3 " + err);
                            payload.success = false;
                            payload.error = "3 " + err.message;
                            reject(payload);
                        });
                    })
                    .catch(function (err) {
                        console.error("Error getting key: " + err);
                        payload.success = false;
                        payload.error = "2 " + err.message;
                        reject(payload);
                    });


            } catch (error) {
                console.error("error333: " + error);
                payload.success = false;
                payload.error = error.message;
                reject(payload);
            }

        });

        return promise;
    }
    rsaEncryptV3(data, publicKey) {
        let payload = new EncryptionResult();
        if (this.consoleLog) console.log("RSA Encrypt version 3 starting");
        var promise = new Promise((resolve, reject) => {
            try {
                let publicKeyParts = publicKey.split("|");
                let keyData = {
                    kty: "RSA",
                    alg: "RSA-OAEP-512",  // Your desired JWA algorithm
                    ext: true,            // Is key extractable or not
                    key_ops: ["encrypt"], // Array of supposed key usages
                    // The "e" component, public exponent, spec restricts is to the only value, 65537
                    // Public key components
                    e: "AQAB",                      // The "e" component, public exponent, spec restricts is to the only value, 65537
                    n: this.urlEncodeUrl(publicKeyParts[1]),
                }
                let algorithm = {   //these are the algorithm options
                    name: "RSA-OAEP",
                    hash: { name: "SHA-512" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
                }
                if (this.consoleLog) console.log("keyData: " + JSON.stringify(keyData) + " algorithm: " + JSON.stringify(algorithm) + " data: " + data);

                self.crypto.subtle.importKey("jwk", keyData, algorithm, true, ["encrypt"])
                    .then(function (key) {
                        //returns the symmetric key
                        self.crypto.subtle.encrypt(
                            {
                                name: "RSA-OAEP",
                                label: string_to_bytes("zz")
                            },
                            key, //from generateKey or importKey above
                            string_to_bytes(data) //ArrayBuffer of the data
                        ).then(function (encrypted) {
                            var encryptedData = ByteBuffer.wrap(encrypted).toBase64();
                            payload.success = true;
                            payload.data = encryptedData;
                            resolve(payload);
                        }).catch(function (err) {
                            console.error("ERROR 3 " + err);
                            payload.success = false;
                            payload.error = "3 " + err.message;
                            reject(payload);
                        });
                    })
                    .catch(function (err) {
                        console.error("Error getting key: " + err);
                        payload.success = false;
                        payload.error = "2 " + err.message;;
                        reject(payload);
                    });


            } catch (error) {
                console.error("error333: " + error);
                payload.success = false;
                payload.error = error.message;
                reject(payload);
            }

        });

        return promise;
    }
    rsaEncryptV4(data, publicKey) {
        let payload = new EncryptionResult();
        if (this.consoleLog) console.log("RSA Encrypt version 4 starting");
        var promise = new Promise((resolve, reject) => {
            try {
                let publicKeyParts = publicKey.split("|");
                let keyData = {
                    kty: "RSA",
                    alg: "RSA-OAEP-512",  // Your desired JWA algorithm
                    ext: true,            // Is key extractable or not
                    key_ops: ["encrypt"], // Array of supposed key usages
                    // The "e" component, public exponent, spec restricts is to the only value, 65537
                    // Public key components
                    e: "AQAB",                      // The "e" component, public exponent, spec restricts is to the only value, 65537
                    n: this.urlEncodeUrl(publicKeyParts[1]),
                }
                let algorithm = {   //these are the algorithm options
                    name: "RSA-OAEP",
                    hash: { name: "SHA-512" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
                }
                if (this.consoleLog) console.log("keyData: " + JSON.stringify(keyData) + " algorithm: " + JSON.stringify(algorithm) + " data: " + data);

                self.crypto.subtle.importKey("jwk", keyData, algorithm, true, ["encrypt"])
                    .then(function (key) {
                        //returns the symmetric key
                        self.crypto.subtle.encrypt(
                            {
                                name: "RSA-OAEP",
                                // label: string_to_bytes("zz")
                            },
                            key, //from generateKey or importKey above
                            string_to_bytes(data) //ArrayBuffer of the data
                        ).then(function (encrypted) {
                            var encryptedData = ByteBuffer.wrap(encrypted).toBase64();
                            payload.success = true;
                            payload.data = encryptedData;
                            resolve(payload);
                        }).catch(function (err) {
                            console.error("ERROR 3 " + err);
                            payload.success = false;
                            payload.error = "3 " + err.message;;
                            reject(payload);
                        });
                    })
                    .catch(function (err) {
                        console.error("Error getting key: " + err);
                        payload.success = false;
                        payload.error = "2 " + err.message;;
                        reject(payload);
                    });


            } catch (error) {
                console.error("error333: " + error);
                payload.success = false;
                payload.error = error.message;
                reject(payload);
            }

        });

        return promise;
    }

    rsaDecryptV4(data, privateKey) {
        let payload = { success: false };
        const startTime = this.showTiming ? performance.now() : 0;
        try {
            let privateKey4 = [
                base64_to_bytes(privateKey[1]),
                base64_to_bytes(privateKey[2]),
                base64_to_bytes(privateKey[3]),
                base64_to_bytes(privateKey[4]),
                base64_to_bytes(privateKey[5]),
                base64_to_bytes(privateKey[6]),
                base64_to_bytes(privateKey[7]),
                base64_to_bytes(privateKey[8])
            ];

            let hash = new Sha512();
            let rsaDecrypt = new RSA_OAEP(privateKey4, hash);
            payload.data = bytes_to_string(rsaDecrypt.decrypt(base64_to_bytes(data)));
            payload.success = true;
        } catch (error) {
            console.log("error: ", error);
            payload.success = false;
            payload.error = error.message;
        }
        const endTime = this.showTiming ? performance.now() : 0;
        if (this.showTiming) console.log(`RSA v4 decryption took ${endTime - startTime}ms`);
        return payload;
    }

    rsaDecryptV3(data, privateKey) {
        let payload = { success: false };
        const startTime = this.showTiming ? performance.now() : 0;
        try {
            let privateKey3 = [
                base64_to_bytes(privateKey[1]),
                base64_to_bytes(privateKey[2]),
                base64_to_bytes(privateKey[3]),
                base64_to_bytes(privateKey[4]),
                base64_to_bytes(privateKey[5]),
                base64_to_bytes(privateKey[6]),
                base64_to_bytes(privateKey[7]),
                base64_to_bytes(privateKey[8])
            ];

            let hash = new Sha512();
            let rsaDecrypt = new RSA_OAEP(privateKey3, hash, string_to_bytes("zz"));
            payload.data = bytes_to_string(rsaDecrypt.decrypt(base64_to_bytes(data)));
            payload.success = true;
        } catch (error) {
            console.log("error: " + error);
            payload.success = false;
            payload.error = error.message;
        }
        const endTime = this.showTiming ? performance.now() : 0;
        if (this.showTiming) console.log(`RSA v3 decryption took ${endTime - startTime}ms`);
        return payload;
    }

    rsaDecryptV2(data, privateKey) {
        let payload = { success: false };
        const startTime = this.showTiming ? performance.now() : 0;
        try {
            let privateKey2 = [
                base64_to_bytes(privateKey[1]),
                base64_to_bytes(privateKey[2]),
                base64_to_bytes(privateKey[3]),
                base64_to_bytes(privateKey[4]),
                base64_to_bytes(privateKey[5]),
                base64_to_bytes(privateKey[6]),
                base64_to_bytes(privateKey[7]),
                base64_to_bytes(privateKey[8])
            ];

            let hash = new Sha256();
            let rsaDecrypt = new RSA_OAEP(privateKey2, hash, string_to_bytes("zz"));
            payload.data = bytes_to_string(rsaDecrypt.decrypt(base64_to_bytes(data)));
            payload.success = true;
        } catch (error) {
            console.log("error: " + error);
            payload.success = false;
            payload.error = error.message;
        }
        const endTime = this.showTiming ? performance.now() : 0;
        if (this.showTiming) console.log(`RSA v2 decryption took ${endTime - startTime}ms`);
        return payload;
    }

}

export { RSAJS };
