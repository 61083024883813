import { Injectable } from '@angular/core';
import * as ByteBuffer from 'bytebuffer';
import { AES_CBC, Pbkdf2HmacSha256, Pbkdf2HmacSha512 } from 'asmcrypto.js';
import { Decoder } from '@as-com/pson';
import { EncryptionTools } from './EncryptionTools';
import { DecryptionResult } from '@app/model/app/encryption/DecryptionResult';
import { TeamKeyService } from '../cache/team-key-service.service';


@Injectable({ providedIn: 'root' })
export class AesDecrypt2 {
    // private cryptoJS: CryptoJS;
    private keyStr: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    private encryptionTools: EncryptionTools = new EncryptionTools();

    private consoleLog: boolean = false;

    constructor(
        private teamKeyService: TeamKeyService

    ) { }


    async aesDecrypt(encryptedData: string, passphrase: string): Promise<DecryptionResult> {
        // console.log("decrypting data");

        const tmp = encryptedData.split("#");
        if (tmp[0] === '4') {
            // 4 is the "new" version of the new system.
            return this.aesDecryptv4(tmp, encryptedData, passphrase);
        } else if (tmp[0] === '3') {
            // 3 is the "new" version of the old pp8 system.
            return this.aesDecryptv3(tmp, encryptedData, passphrase);
        } else {
            return this.aesDecryptv2(tmp, encryptedData, passphrase);
        }
    }
    async aesDecryptv4(inputDataElements: string[], encryptedData: string, passphrase: string): Promise<DecryptionResult> {
        var promise = new Promise<DecryptionResult>((resolve, reject) => {
            let decryptionResult = new DecryptionResult();
            /*
                Input data elements are:
                0: 4
                1: iterations
                2: keyId
                3: iv
                4: salt
                5: encrypted data
            */

            try {
                const iterations = parseInt(inputDataElements[1]);
                const keyId = parseInt(inputDataElements[2]);
                const iv = this.decode(inputDataElements[3]);
                let saltAsEncodedString = inputDataElements[4];
                const salt = this.decode(saltAsEncodedString);
                const cipherText = ByteBuffer.fromBase64(inputDataElements[5]).toArrayBuffer();


                // Try to find the key
                let teamMetaKeys = this.teamKeyService.getTeamMetaKeys(keyId);
                if (!teamMetaKeys) {
                    reject("Key Not Found: " + keyId);
                } else {

                    let passphrase = teamMetaKeys.groupKey;
                    // TODO - undo

                    // key length 32 is 256 bits
                    let key4 = Pbkdf2HmacSha256(this.encryptionTools.string_to_bytes(passphrase), salt, iterations, 32);

                    if (this.consoleLog) console.log("key length: " + key4.length);

                    //Pbkdf2HmacSha256(this.encryptionTools.string_to_bytes(passphrase), salt, iterations, 32);

                    self.crypto.subtle.importKey("raw", key4, "AES-GCM", true, ["encrypt", "decrypt"])
                        .then(function (importedKey) {
                            self.crypto.subtle.decrypt(
                                {
                                    name: "AES-GCM",
                                    iv: iv, //The initialization vector you used to encrypt
                                },
                                importedKey, //from generateKey or importKey above
                                cipherText //ArrayBuffer of the data
                            )
                                .then(function (decrypted) {
                                    // console.log("decr: " + decrypted);
                                    const payload = new Decoder([], false).decode(decrypted);
                                    // console.log("output: " + payload.value);
                                    // resolve(payload.value);
                                    decryptionResult.success = true;
                                    decryptionResult.data = payload.value;
                                    resolve(decryptionResult);
                                })
                                .catch(function (err) {
                                    console.error("error3: " + err + " input: " + encryptedData + ", passphrase: " + passphrase);
                                    decryptionResult.success = false;
                                    decryptionResult.error = err as unknown as string;
                                    reject(decryptionResult);
                                });
                        }).catch(function (err) {
                            console.error("error2: " + err);
                            decryptionResult.success = false;
                            decryptionResult.error = err as unknown as string;
                            reject(decryptionResult);
                        });
                    // console.log("done");
                }
            } catch (e) {
                console.error("decrypt error: " + e);
                decryptionResult.success = false;
                decryptionResult.error = e as unknown as string;
                reject(decryptionResult);
            }

        });
        return promise;

    }



    async aesDecryptv3(inputDataElements: string[], encryptedData: string, passphrase: string): Promise<DecryptionResult> {
        var promise = new Promise<DecryptionResult>((resolve, reject) => {
            let decryptionResult = new DecryptionResult();
            // if (tmp[0] === '3') {
            // 3 is the "new" version of the old pp8 system.
            try {
                // console.log("decrypting data");
                // console.log("1: " + tmp[1]);
                // console.log("2: " + tmp[2]);
                const iv = this.decode(inputDataElements[1]);
                const salt = this.decode(inputDataElements[2]);
                const cipherText = ByteBuffer.fromBase64(inputDataElements[3]).toArrayBuffer();
                const key4 = Pbkdf2HmacSha256(this.encryptionTools.string_to_bytes(passphrase), salt, 0x3E8, 32);
                // console.log("iv: " + iv); //OK
                // console.log("passphrase: " + this.decode(passphrase) + "-");//??
                // console.log("salt: " + salt); // OK
                // console.log(passphrase + " key4 " + key4);
                //console.log("cipherText: " + cipherText);

                self.crypto.subtle.importKey("raw", key4, "AES-GCM", true, ["encrypt", "decrypt"])
                    .then(function (importedKey) {
                        self.crypto.subtle.decrypt(
                            {
                                name: "AES-GCM",
                                iv: iv, //The initialization vector you used to encrypt
                            },
                            importedKey, //from generateKey or importKey above
                            cipherText //ArrayBuffer of the data
                        )
                            .then(function (decrypted) {
                                // console.log("decr: " + decrypted);
                                const payload = new Decoder([], false).decode(decrypted);
                                // console.log("output: " + payload.value);
                                // resolve(payload.value);
                                decryptionResult.success = true;
                                decryptionResult.data = payload.value;
                                resolve(decryptionResult);
                            })
                            .catch(function (err) {
                                console.error("error3: " + err + " input: " + encryptedData + ", passphrase: " + passphrase);
                                decryptionResult.success = false;
                                decryptionResult.error = err as unknown as string;
                                reject(decryptionResult);
                            });
                    }).catch(function (err) {
                        console.error("error2: " + err);
                        decryptionResult.success = false;
                        decryptionResult.error = err as unknown as string;
                        reject(decryptionResult);
                    });
                // console.log("done");
            } catch (e) {
                console.error("decrypt error: " + e);
                decryptionResult.success = false;
                decryptionResult.error = e as unknown as string;
                reject(decryptionResult);
            }

        });
        return promise;

    }

    async aesDecryptv2(inputDataElements: string[], encryptedData: string, passphrase: string): Promise<DecryptionResult> {
        var promise = new Promise<DecryptionResult>((resolve, reject) => {
            let decryptionResult = new DecryptionResult();
            try {
                var iv = this.decode(inputDataElements[1]);
                var salt2 = this.encryptionTools.b642ab(inputDataElements[2]);
                var salt = this.decode(inputDataElements[2]);
                var cipherText = this.decode(inputDataElements[3]);
                const key = Pbkdf2HmacSha256(this.encryptionTools.string_to_bytes(passphrase), salt, 0x3E8, 16);
                // console.log("iv: " + iv);
                // console.log("salt: " + salt2);
                // console.log("cipherText: " + cipherText);
                // console.log("key: " + key);

                let decrypted = AES_CBC.decrypt(cipherText, key, true, iv);
                let payload2 = this.encryptionTools.bytes_to_string(decrypted);

                decryptionResult.success = true;
                decryptionResult.data = payload2;
                resolve(decryptionResult);
            } catch (e) {
                console.log("Error", e);
                decryptionResult.success = false;
                decryptionResult.error = e as unknown as string;
                reject(decryptionResult);
            }
        });
        return promise;
    }


    /** Base 64 redo here */


    removePaddingChars(input: string): string {
        // console.log("removing padding: " + input);
        // console.log(this.keyStr);
        // console.log("in: " + input.charAt(input.length - 1));
        try {
            const lkey = this.keyStr.indexOf(input.charAt(input.length - 1));
            if (lkey === 64) {
                return input.substring(0, input.length - 1);
            }
        } catch (e) {
            console.log("removePaddingChars error: " + e);
        }
        return input;
    }


    decode(input: string): Uint8Array {
        //get last chars to see if are valid - could be 2 padding
        input = this.removePaddingChars(input);
        input = this.removePaddingChars(input);

        let inputLength = input.length;
        const byteCount = (inputLength / 4) * 3;

        let uarray;
        let chr1, chr2, chr3;
        let enc1, enc2, enc3, enc4;
        let j = 0;

        // if (arrayBuffer)
        //     uarray = new Uint8Array(arrayBuffer);
        // else
        uarray = new Uint8Array(byteCount);
        try {
            //console.log("decoding: input: " + input);
            input = input.replace(/[^A-Za-z0-9+/=]/g, "");
            //console.log("input2: " + input);
            for (let i = 0; i < byteCount; i += 3) {
                //get the 3 octects in 4 ascii chars
                enc1 = this.keyStr.indexOf(input.charAt(j++));
                enc2 = this.keyStr.indexOf(input.charAt(j++));
                enc3 = this.keyStr.indexOf(input.charAt(j++));
                enc4 = this.keyStr.indexOf(input.charAt(j++));

                chr1 = (enc1 << 2) | (enc2 >> 4);
                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                chr3 = ((enc3 & 3) << 6) | enc4;

                uarray[i] = chr1;
                if (enc3 !== 64) uarray[i + 1] = chr2;
                if (enc4 !== 64) uarray[i + 2] = chr3;
            }
        } catch (e) {
            console.error("decode error: " + e);
        }

        return uarray;
    }



}