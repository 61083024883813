import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '@environments/environment';
import * as _ from 'lodash';
import { LoginResponse } from '@app/model/api/login/LoginResponse';
import { HttpParams } from '@angular/common/http';
import { AccountUserInformationResponse } from '@app/model/api/account/AccountUserInformationResponse';
import { AccountKeyDataResponse } from '@app/model/api/account/AccountKeyDataResponse';
import { AccountLicenseSummaryResponse } from '@app/model/api/account/AccountLicenseSummaryResponse';
import { OOBPreferenceResponse } from '@app/model/api/account/token/OOBPreferenceResponse';
import { GetSettingsDataResponse } from '@app/model/api/account/GetSettingsDataResponse';
import { SubscriptionSummaryResponse } from '@app/model/api/account/billing/SubscriptionSummaryResponse';
import { BaseAPIService } from '../BaseAPIService';
import { Store } from '@ngrx/store';
import { LoginActions } from '@app/store/actions/LoginActions';
import { AesDecrypt2 } from '@app/services/encryption/AesDecrypt2';
import { AccountActions } from '@app/store/actions/AccountActions';
import { EncryptionDecryptionKeyValues } from '@app/model/app/EncryptionDecryptionKeyValues';
import { selectAccountUserInformationDetails, selectPackingKeyData, selectSettingsData } from '@app/store/selectors/AccountSelectors';
import { AccountKeys } from '@app/model/app/account/AccountKeys';
import { LocalStorageService } from '../dataStorage/local-storage.service';
import { AesEncrypt } from '@app/services/encryption/AesEncrypt';
import { LoginTokenResponse } from '@app/model/api/login/LoginTokenResponse';
import { StorageKeys } from '@app/model/app/storage/StorageKeys';
import { ActionUpdateRequest } from '@app/model/api/action/ActionUpdateRequest';
import { ActionActions } from '@app/store/actions/ActionActions';
import { BaseResponse } from '@app/model/api/BaseResponse';
import { LoginHistoryResponse } from '@app/model/api/account/LoginHistoryResponse';
import { RetrieveMfaAuthCodeResponse } from '@app/model/api/account/mfa/RetrieveMfaAuthCodeResponse';
import { RetrieveBackupOOBCodesResponse } from '@app/model/api/account/mfa/RetrieveBackupOOBCodesResponse';
import { ResetBackupTokensResponse } from '@app/model/api/account/mfa/ResetBackupTokensResponse';
import { APIErrorCodeTranslatorService } from '../APIErrorCodeTranslatorService';
import { ResetPasswordRequest } from '@app/model/api/account/ResetPasswordRequest';
import { ConfirmLostPasswordRequest } from '@app/model/api/account/ConfirmLostPasswordRequest';
import { ConfirmLostPasswordResponse } from '@app/model/api/account/ConfirmLostPasswordResponse';
import { PasswordChangeRequest } from '@app/model/api/account/PasswordChangeRequest';
import { UpdateSettingsDataResponse } from '@app/model/api/account/UpdateSettingsDataResponse';
import { UpdateSettingsDataRequest } from '@app/model/api/account/UpdateSettingsDataRequest';
import { UpdateAvatarRequest } from '@app/model/api/account/UpdateAvatarRequest';
import { ConfirmEmailRequest } from '@app/model/api/account/ConfirmEmailRequest';
import { PreferredLoginMethod } from '@app/model/app/auth/PreferredLoginMethod';
import { PasswordVaultService } from '../passwordVault/password-vault-service.service';


@Injectable({ providedIn: 'root' })
export class AccountService implements OnDestroy {
    consoleLog: boolean = false;
    readonly vmAccountUserInformationDetails$ = this.store.select(selectAccountUserInformationDetails);
    accountUserInformation: AccountUserInformationResponse = new AccountUserInformationResponse();
    readonly vmSettings$ = this.store.select(selectSettingsData);
    readonly vmPackingKey$ = this.store.select(selectPackingKeyData);
    packingKeySubscription: Subscription;
    settingsSubscription: Subscription;
    accountUserInformationSubscription: Subscription;
    packingKeyResponse: AccountKeyDataResponse | undefined = undefined;


    private settings: GetSettingsDataResponse;
    private hasIndividualShares: boolean = false;


    constructor(
        private router: Router,
        private baseAPIService: BaseAPIService,
        private localStorageService: LocalStorageService,
        private aesEncrypt: AesEncrypt,
        private store: Store,
        private aPIErrorCodeTranslatorService: APIErrorCodeTranslatorService,
        private aesDecrypt2: AesDecrypt2,

    ) {
        this.accountUserInformationSubscription = this.vmAccountUserInformationDetails$.subscribe(x => {
            // Update the active user information
            if (x) {
                this.accountUserInformation = x;
            } else this.accountUserInformation = new AccountUserInformationResponse();
        });
        this.settingsSubscription = this.vmSettings$.subscribe(x => {
            // Update the active user data
            if (x) {
                if (x.status.code === 0) {
                    this.settings = x;
                } else {
                    this.settings = new GetSettingsDataResponse();
                }
            }
        });
        this.packingKeySubscription = this.vmPackingKey$.subscribe(x => {
            this.packingKeyResponse = x;
        });
    }

    ngOnDestroy(): void {
        if (this.packingKeySubscription) {
            this.packingKeySubscription.unsubscribe();
        }
        if (this.settingsSubscription) {
            this.settingsSubscription.unsubscribe();
        }
        if (this.accountUserInformationSubscription) {
            this.accountUserInformationSubscription.unsubscribe();
        }
    }

    setHasIndividualShares(hasIndividualShares: boolean): void {
        this.hasIndividualShares = hasIndividualShares;
    }
    getHasIndividualShares(): boolean {
        return this.hasIndividualShares;
    }

    getSettings(): GetSettingsDataResponse {
        return this.settings;
    }

    // getAvatarImageUrl(): string {
    //     let url = environment.API_BASE_URL + "v1/secure/avatar/" + this.accountUserInformation.customerId;
    //     return url;
    // }

    getAvatarImageUrlAsImage(): Observable<any> {
        let url = environment.API_BASE_URL + "v1/secure/avatar/" + this.accountUserInformation.customerId;

        var params = new HttpParams();
        return this.baseAPIService.getRequestRawImage(params, url);
    }

    updateAvatar(base64EncodedData: string): Observable<BaseResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/avatar/update";
        let request = new UpdateAvatarRequest();
        request.base64Data = base64EncodedData;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<BaseResponse>(body, url);
    }

    getAvatarImageAlt(): string {
        return "Avatar Image";
    }

    /*
    Helper functions to get at user information details without a subscription
    */
    getCustomerId(): number {
        let customerId = 0;
        if (this.accountUserInformation !== null && this.accountUserInformation !== undefined) {
            customerId = this.accountUserInformation.customerId;
        }
        return customerId;
    }

    updateName(newName: string): Observable<BaseResponse> {
        // try to get the first and last name from the input.  Split based on the spaces
        if (newName !== null && newName !== undefined && newName.length > 0) {
            let names = newName.split(" ");
            let firstName = "";
            let lastName = "";
            if (names.length > 0) {
                firstName = names[0];
                if (names.length > 1) {
                    // Last name should be all the remaining names
                    lastName = "";
                    for (let i = 1; i < names.length; i++) {
                        lastName += names[i] + " ";
                    }
                }
            }

            let url = environment.API_BASE_URL + "v1/secure/account/name";
            var params = new Map<string, any>();
            params.set('firstName', firstName);
            params.set('lastName', lastName);

            return this.baseAPIService.postRequest<BaseResponse>(params, url);
        } else {
            let response = new BaseResponse();
            response.status = { code: 100, codes: [], message: "Invalid name" };
            return of(response);
        }
    }

    mfaSetup(tech: string): Observable<RetrieveMfaAuthCodeResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/token/" + tech + "/setup";
        var params = new Map<string, any>();
        return this.baseAPIService.postRequest<RetrieveMfaAuthCodeResponse>(params, url);
    }
    mfaCheckAndFinalize(tech: string, code: string): Observable<RetrieveMfaAuthCodeResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/token/" + tech + "/checkAndFinalize";
        var params = new Map<string, any>();
        params.set('code', code);
        return this.baseAPIService.postRequest<RetrieveMfaAuthCodeResponse>(params, url);
    }
    mfaCheckAndFinalizeYubikey(code: string): Observable<BaseResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/token/yubikey/register/" + code;
        var params = new Map<string, any>();
        return this.baseAPIService.putRequestNoErrorHandling<BaseResponse>(params, url);
    }

    removeYubikeyMFA(target: string): Observable<BaseResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/token/yubikey/deregister/" + target;
        return this.baseAPIService.deleteRequestNoErrorHandling<BaseResponse>(url);
    }
    removeGoogleMFA(): Observable<BaseResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/ga/reset";
        var params = new Map<string, any>();
        return this.baseAPIService.postRequest<BaseResponse>(params, url);
    }
    removeMicrosoftMFA(): Observable<BaseResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/ma/reset";
        var params = new Map<string, any>();
        return this.baseAPIService.postRequest<BaseResponse>(params, url);
    }

    confirmEmail(code: string): Observable<BaseResponse> {
        let url = environment.API_BASE_URL + "v1/confirm/email";
        let request = new ConfirmEmailRequest();
        request.code = code;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<BaseResponse>(body, url);
    }

    updatePassword(oldPassword: string, newPassword: string): Observable<BaseResponse> {
        let url = environment.API_BASE_URL + "v2/secure/account/password";
        let request = new PasswordChangeRequest();
        request.oldPassword = oldPassword;
        request.newPassword = newPassword;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<BaseResponse>(body, url);
    }

    updateEmail(newEmail: string): Observable<BaseResponse> {
        if (newEmail !== null && newEmail !== undefined && newEmail.length > 0) {
            let url = environment.API_BASE_URL + "v1/secure/account/email";
            var params = new Map<string, any>();
            params.set('email', newEmail);
            return this.baseAPIService.postRequest<BaseResponse>(params, url);

        } else {
            let response = new BaseResponse();
            response.status = { code: 100, codes: [], message: "Invalid email" };
            return of(response);
        }
    }

    updateUsername(newUsername: string): Observable<BaseResponse> {
        if (newUsername !== null && newUsername !== undefined && newUsername.length > 0) {
            let url = environment.API_BASE_URL + "v1/secure/account/username";
            var params = new Map<string, any>();
            params.set('username', newUsername);
            return this.baseAPIService.postRequest<BaseResponse>(params, url);

        } else {
            let response = new BaseResponse();
            response.status = { code: 100, codes: [], message: "Invalid username" };
            return of(response);
        }
    }



    cancelAllEmailVerifications(): void {
        let url = environment.API_BASE_URL + "v1/secure/account/emailVerifications/cancel";
        var params = new Map<string, any>();
        this.baseAPIService.postRequest<BaseResponse>(params, url).subscribe(response => {
            this.store.dispatch(AccountActions.needReloadAccountDetails());
        });
    }

    deleteAccount(): void {
        // this.store.dispatch(AccountActions.deleteAccount());
        // this.router.navigate(['/login']);

        let url = environment.API_BASE_URL + "v1/secure/account/delete";
        var params = new Map<string, any>();
        params.set('username', this.accountUserInformation.username);
        this.baseAPIService.postRequest<BaseResponse>(params, url).subscribe(response => {
            this.store.dispatch(LoginActions.logoutInitiate());
        });
    }


    /**
     * Retrieves the login history
     * @returns 
     */
    getLoginHistory(): Observable<LoginHistoryResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/logins";
        var params = new HttpParams();
        return this.baseAPIService.getRequest<LoginHistoryResponse>(params, url);
    }


    /**
         * Retrieve oob Preference 
         * @returns 
         */
    getOOBPreference(): Observable<OOBPreferenceResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/oob/preference";
        var params = new HttpParams();
        return this.baseAPIService.getRequest<OOBPreferenceResponse>(params, url);
    }

    getBackupTokens(): Observable<RetrieveBackupOOBCodesResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/token/backups";
        var params = new HttpParams();
        return this.baseAPIService.getRequest<RetrieveBackupOOBCodesResponse>(params, url);
    }

    resetBackupTokens(): Observable<ResetBackupTokensResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/token/backups/reset";
        var params = new Map<string, any>();
        return this.baseAPIService.postRequest<ResetBackupTokensResponse>(params, url);
    }
    /**
         * Retrieve subscription summary
         * @returns 
         */
    getSubscriptionSummary(): Observable<SubscriptionSummaryResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/billing/subscription/summary";
        var params = new HttpParams();
        return this.baseAPIService.getRequest<SubscriptionSummaryResponse>(params, url);
    }

    /**
         * Get the license summary
         * @returns 
         */
    getSettingsData(): Observable<GetSettingsDataResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/data/settings";
        var params = new Map<string, any>();
        return this.baseAPIService.postRequest<GetSettingsDataResponse>(params, url);
    }

    /**
     * Get the license summary
     * @returns 
     */
    getLicenseSummary(): Observable<AccountLicenseSummaryResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/license/summary";
        var params = new Map<string, any>();
        return this.baseAPIService.postRequest<AccountLicenseSummaryResponse>(params, url);
    }

    /**
     * Get the packing key
     * @returns 
     */
    getPackingKeyData(): Observable<AccountKeyDataResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/data/key";
        var params = new HttpParams();
        return this.baseAPIService.getRequestNoErrorHandling<AccountKeyDataResponse>(params, url);
    }

    checkPackingKeyResponse(response: AccountKeyDataResponse): void {
        if (response.status.code === 0) {
            // do nothing.  Business as normal.
        } else if (response.status.code === 430) {
            console.log("Need to create packing key");
            this.store.dispatch(AccountActions.setNeedCreatePackingKey({ needCreatePackingKey: true }));
        } else {
            //this.store.dispatch(AccountActions.packingKeyError({ error: "Error loading packing key" }));
        }
    }

    /**
     * Initiate password reset
     */
    initiatePasswordReset(emailAddress: string): Observable<BaseResponse> {
        let url = environment.API_BASE_URL + "v1/resetPassword";
        let request = new ResetPasswordRequest();
        request.email = emailAddress;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<BaseResponse>(body, url);
    }

    /**
     * 
     * Confirm the password request
     * 
     * @param  
     */
    confirmPasswordReset(code: string, password: string): Observable<ConfirmLostPasswordResponse> {
        let url = environment.API_BASE_URL + "v1/resetPasswordConfirm";
        let request = new ConfirmLostPasswordRequest();
        request.code = code;
        request.password = password;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<ConfirmLostPasswordResponse>(body, url);
    }

    /**
     * 
     * Verify the email address.
     * 
     * @param code 
     * @returns 
     */
    verifyNewAccountEmail(code: string): void {
        let url = environment.API_BASE_URL + "v1/emailVerification";
        var params = new Map<string, any>();
        params.set('code', code);
        this.baseAPIService.postRequest<BaseResponse>(params, url).subscribe((response) => {
            if (response.status.code === 0) {
                //done
                this.store.dispatch(AccountActions.verifyNewAccountEmailSuccess());
            } else {
                let extra = "";
                if (response.status.message) {
                    extra = response.status.message + ". ";
                }
                let errorMessage = "Error in verification. " + extra
                    + this.aPIErrorCodeTranslatorService.generateErrorMessage(response.status.code);

                this.store.dispatch(AccountActions.verifyNewAccountEmailError({ error: errorMessage }));
            }
        })
    }

    /**
     * 
     * Checks the login response and handles the setup to email verification if required.
     * @param loginResponse 
     */
    checkLoginResponse(loginResponse: LoginResponse): void {
        console.log("Checking login response");
        if (loginResponse) {
            if (loginResponse.status.code === 0) {
                if (this.consoleLog) console.log("Login OK");

                // Save the method of login as username
                this.localStorageService.saveStateToLocalStorage(StorageKeys.PREFERRED_METHOD_OF_LOGIN, PreferredLoginMethod.USERNAME);
                this.localStorageService.removeStateFromLocalStorage(StorageKeys.ATTEMPTED_METHOD_OF_LOGIN);

            } else if (loginResponse.status.code === 482) {
                console.log("Requires account verification");
                this.store.dispatch(LoginActions.setRequireEmailVerification({ requireEmailVerification: true }));
            }
        }

    }

    /**
     * Retrieves the account user information.
     * @returns 
     */


    getAccountDetail(): Observable<AccountUserInformationResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account";
        var offsetval = new Date().getTimezoneOffset();
        var params = new HttpParams();
        params.set('offset', offsetval);

        return this.baseAPIService.getRequestNoErrorHandling<AccountUserInformationResponse>(params, url).pipe(map(accountResponse => {

            // If the response is 424 that means we are required to enter an MFA
            if (!_.isNumber(accountResponse) && _.has(accountResponse, 'status.code')) {
                if (accountResponse.status.code === 99) {
                    // must enter mfa
                    this.store.dispatch(LoginActions.setRequireMFA({ requireMFA: true }));
                } else if (accountResponse.status.code === 0) {
                    if (this.consoleLog) console.log("Account detail OK");
                    // No MFA required
                    this.store.dispatch(LoginActions.setRequireMFA({ requireMFA: false }));
                } else {
                    console.log("bad account data " + accountResponse.status.code);
                }
            } else {
                if (this.consoleLog) console.log("account " + accountResponse);
            }
            return accountResponse;
        }));
    }

    /**
     * Initial log in.  This will tell us if the credentials are good.
     * Then the user must enter 2fa code and packing key or other steps.
     * @param username 
     * @param password 
     * @returns 
     */
    login(username: string, password: string): Observable<LoginResponse> {
        //Log in
        let url = environment.API_BASE_URL + "v1/login";
        var offsetval = new Date().getTimezoneOffset();
        var params = new Map<string, any>();
        params.set('username', username);
        params.set('password', password);
        params.set('offset', offsetval);
        params.set('clientId', environment.CLIENT_ID);
        return this.baseAPIService.postRequestNoErrorHandling<LoginResponse>(params, url);
    }

    /**
     * Logout should reset everything and clear the session with the server.
     */
    logout(redirect: boolean = true) {
        if (this.consoleLog) console.log("account service logout");
        try {

            this.localStorageService.clearAllState();
            this.store.dispatch(LoginActions.logoutComplete());

            if (redirect) {
                // Send the logout to the backend.
                let url = environment.API_BASE_URL + "v1/logout";
                let params = new Map<string, any>();
                this.baseAPIService.postRequestNoErrorHandling(params, url).subscribe(response => {
                    // Navigate to login
                    let now = new Date();
                    window.location.href = '/' + "?t=" + now.getTime();
                });
            }

        } catch (e) {
            console.log(e);
        }
    }

    updateAccountSettingsData(setting: string, value: any): Observable<UpdateSettingsDataResponse> {
        let url = environment.API_BASE_URL + "v2/secure/account/data/settings/update";
        let request = new UpdateSettingsDataRequest();
        request.setting = setting;
        request.value = value + "";
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<UpdateSettingsDataResponse>(body, url);
    }

    /**
     * Submit the MFA token to the server for verification.
     * @param token 
     * @returns 
     */
    submitToken(token: string): Observable<LoginTokenResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/token";
        var params = new Map<string, any>();
        params.set('token', token);
        return this.baseAPIService.postRequestNoErrorHandling<LoginResponse>(params, url).pipe(map(loginResponse => {
            if (!_.isNumber(loginResponse) && _.has(loginResponse, 'status.code')) {
                if (loginResponse.status.code === 0) {
                    if (this.consoleLog) console.log("MFA OK");
                } else {
                    if (this.consoleLog) console.log("bad token submission " + loginResponse.status.code);
                }
            }
            return loginResponse;
        }));
    }

    unlockPackingKey(packingKey: string): Observable<String> {
        // if (this.consoleLog) 

        // let packingKeyDataObservable = this.store.select(selectPackingKeyData);
        // packingKeyDataObservable.subscribe(packingKeyData => {
        if (this.consoleLog) console.log("Unlocking packing key: " + packingKey);

        // console.log("packingKeyData: " + JSON.stringify(packingKeyData));
        // console.log("this.packing chcek data: " + packingKeyData?.data);
        // console.log("packingKey: " + packingKey);

        let self = this;
        var continueProcessing = false;
        let packingKeyData = this.packingKeyResponse;
        if (packingKeyData?.data === null || packingKeyData?.data === undefined) {
            self.store.dispatch(AccountActions.unlockPackingKeyError({ error: "Please refresh your screen to retrieve your encrypted packing key from Passpack." }));
        } else {
            // Do a test decryption to ensure we have the proper key
            self.aesDecrypt2.aesDecrypt(packingKeyData?.data, packingKey).then(function (decryptionResult) {
                // console.log("result: " + decryptionResult);
                if (decryptionResult.data === EncryptionDecryptionKeyValues.packingKeyCheckValue) {
                    // console.log("packing key is valid");
                    // If the packing key is valid then continue processing
                    if (packingKeyData?.privateKey !== null && packingKeyData?.privateKey !== undefined) {
                        // Decrypt the private key
                        self.aesDecrypt2.aesDecrypt(packingKeyData?.privateKey, packingKey).then(function (privKeyResult) {
                            // console.log("result priv key: " + privKeyResult);
                            let data = new AccountKeys();
                            data.decryptedDbKey = "";
                            data.packingKeyDecryptedPrivateKey = privKeyResult.data;
                            data.packingKeyPassphrase = packingKey;
                            data.packingKeyCheck = packingKeyData?.data;
                            data.packingKeyPublicKey = packingKeyData?.publicKey;

                            // Persist the packing key locally so we can not force the user to re-enter key data if they simply refresh the screen
                            self.storePackingKeyLocally(packingKey);

                            // Notify backend of successful packing key unlock
                            let actionUpdateRequest: ActionUpdateRequest = new ActionUpdateRequest();
                            actionUpdateRequest.action = "packingKeyEntered";
                            let params = {};
                            actionUpdateRequest.data = params;
                            self.store.dispatch(ActionActions.sendActionUpdate({ data: actionUpdateRequest }));

                            // Finally, notify of packing key unlock.
                            // this should be last since we send the unlock flag as part of storing locally.
                            // console.log("Sending success");
                            self.store.dispatch(AccountActions.unlockPackingKeySuccess({ data: data }));

                        }).catch(function (err) {
                            console.log("error: " + err);
                            self.store.dispatch(AccountActions.unlockPackingKeyError({ error: "Invalid packing key (2). Please try again." }));
                        });
                    }
                }
            }).catch(function (err) {
                console.log("error: ", err);
                self.store.dispatch(AccountActions.unlockPackingKeyError({ error: "Invalid packing key. Please try again." }));
            });


        }
        // });
        return of("OK");
    }

    /**
     * Attempts to decode the sessionToken using the status token. If successful then dispatches the packing key to the store
     */
    public tryUnlockAccount(): void {

        // Decrypt the local token and return the packing key
        let response = this.accountUserInformation;
        if (this.consoleLog) console.log("TRY UNLOCK ACCOUNT");
        if (undefined !== response && null !== response) {
            if (response.statusToken !== null && response.statusToken !== undefined) {
                let sessionToken = this.localStorageService.loadStateFromLocalStorage(StorageKeys.SESSION_TOKEN);
                if (sessionToken !== null && sessionToken !== undefined) {
                    this.aesDecrypt2.aesDecrypt(sessionToken, response.statusToken)
                        .then(
                            (decryptedData) => {
                                if (this.consoleLog) console.log("Decrypted: " + decryptedData);
                                this.store.dispatch(AccountActions.packingKeyDecryptedFromStorage({ packingKey: decryptedData.data }));
                            })
                        .catch(function (err) {
                            console.log("error: " + JSON.stringify(err));
                            // return "";
                        })
                } else {
                    console.error("cannot unlock because session token is undefined");
                }
            } else {
                console.error("cannot unlock because no status token");
            }
            // return "OK";
        } else {
            console.error("cannot unlock because no response");
        }
    }


    processLicenseSummary(response: AccountLicenseSummaryResponse): void {
        // console.log("Updating processing license summary", response);
        if (null == response || undefined == response) {
            // console.log("license summary is null");
            this.store.dispatch(AccountActions.updateValidSubscription({ validSubscription: false }));
        } else if (response.status.code !== 0) {
            // console.log("license summary requires handling: " + response.status.code);
            switch (response.status.code) {
                case 492:
                    // No subscription.  Org is not subscribed to any subscription
                    // console.log("response: 492");
                    this.store.dispatch(AccountActions.updateValidSubscription({ validSubscription: false }));
                    break;
                case 494:
                // subscription expired
                case 495:
                    // no license to use
                    // console.log("response: 495");
                    this.store.dispatch(AccountActions.updateValidSubscription({ validSubscription: false }));
                    break;
                default:
            }
        } else {
            // check the contents.  We only want to know if we are licensed here.
            if (this.consoleLog) console.log(response.licenseSummary);
            if (response.licenseSummary && response.licenseSummary.licensed === true) {
                //licensed
                // console.log("licensed");
                this.store.dispatch(AccountActions.updateValidSubscription({ validSubscription: true }));
            } else {
                // console.log("response: invalid");
                this.store.dispatch(AccountActions.updateValidSubscription({ validSubscription: false }));
            }
        }
    }

    /**
     * Takes the subscription and parses it out to the stores
     * @param response 
     */
    processSubscriptionSummary(response: SubscriptionSummaryResponse): void {
        try {
            // clear it out
            this.localStorageService.removeStateFromSessionStorage(StorageKeys.SUBSCRIPTION_LEVEL);


            let keepProcessing: boolean = true;

            // check for error messages
            if (response.status.code !== 0) {
                console.log("subscription summary requires handling: " + response.status.code);
                keepProcessing = false;

                switch (response.status.code) {
                    case 492:
                        // No subscription.  Org is not subscribed to any subscription
                        this.store.dispatch(AccountActions.updateValidSubscription({ validSubscription: false }));
                        break;
                    case 494:
                    // subscription expired
                    case 495:
                        // no license to use
                        this.store.dispatch(AccountActions.updateValidSubscription({ validSubscription: false }));
                        break;
                    default:
                }
            }

            // try to add it back in
            if (keepProcessing && response.subscriptionSummary !== null
                && response.subscriptionSummary !== undefined
                && response.subscriptionSummary.subscriptionSku
                && response.subscriptionSummary.expired === false) {
                this.localStorageService.saveStateToSessionStorage(StorageKeys.SUBSCRIPTION_LEVEL, response.subscriptionSummary.subscriptionSku);
                // this.store.dispatch(AccountActions.updateValidSubscription({ validSubscription: true }));
            } else {
                this.store.dispatch(AccountActions.updateValidSubscription({ validSubscription: false }));
            }
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Handles the idle timeout.
     * 
     * - removes the stored packing key from the session storage
     * - informs the store
     * - reloads the page
     */
    handleIdleTimeout() {
        if (this.consoleLog) console.log("Handling idle timeout");
        this.lockSystem();
        this.router.navigate(['/locked'], { queryParams: {} });
    }

    handleLockScreen() {
        // if (this.consoleLog) 
        // console.log("Handling lock screen");
        this.lockSystem();
        this.router.navigate(['/locked'], { queryParams: {} });
    }

    private lockSystem() {
        // Prevents the ability to reload the page and forces a lock to occur
        this.localStorageService.removeStateFromLocalStorage(StorageKeys.SESSION_TOKEN);
        this.store.dispatch(LoginActions.lockSystem());
    }

    /**
     * Stores the packing key locally in the session storage
     * @param packingKey 
     */
    private storePackingKeyLocally(packingKey: string) {
        // Always replace in case of another logn / user
        if (undefined !== this.accountUserInformation && null !== this.accountUserInformation) {
            let response = this.accountUserInformation as AccountUserInformationResponse;
            if (response.statusToken !== null && response.statusToken !== undefined) {
                this.aesEncrypt.aesEncrypt(packingKey, response.statusToken).then(encryptedData => {
                    if (this.consoleLog) console.log("saving to local storage");
                    this.localStorageService.saveStateToLocalStorage(StorageKeys.SESSION_TOKEN, encryptedData);
                });
            }
        }
    }




}