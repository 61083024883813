export class PasswordHistoryForDataExport_v1 {
    changedBy: string;
    changedByCurrentNickname: string;
    changedDate: string;
    changedDateDisplay: string;
    deleted: number;
    email: string;
    password: string;
    username: string;
    sharedNotes: string;
}