import { Injectable } from '@angular/core';
import { AesDecrypt2 } from './AesDecrypt2';
import { PasswordVaultData } from '@app/model/app/passwordVault/PasswordVaultData';
import { DecryptionResult } from '@app/model/app/encryption/DecryptionResult';
import { PasswordGroup } from '@app/model/app/passwordGroup/PasswordGroup';
import { RSADecrypt } from './RsaDecrypt';
import { InMemoryTeam } from '@app/model/app/passwordGroup/InMemoryTeam';
import { TeamVersionsEnum } from '@app/model/app/passwordGroup/TeamVersionsEnum';
import { TeamEncryptedData } from '@app/model/app/teams/TeamEncryptedData';

@Injectable({ providedIn: 'root' })
export class TeamDecryption {
    consoleLog: boolean = false;
    showTimings: boolean = false;

    constructor(
        private aesDecrypt: AesDecrypt2,
        private rsaDecrypt: RSADecrypt
    ) {
    }

    async decryptTeam(group: PasswordGroup,
        decryptedPrivateKey: string,
        vaultDataMap: Map<string, PasswordVaultData>
    ): Promise<InMemoryTeam> {
        const startTime = this.showTimings ? performance.now() : 0;
        let elapsedTimeData = 0;
        let elapsedTimeMD = 0;
        let elapsedTimeRSA = 0;

        let decryptedFromVault: boolean = false;
        let vaultsReady: boolean = false;
        let shouldDecryptViaVault: boolean = false;
        let decryptedMetadataKey: string = '';
        let decryptedGroupKey: string = '';
        let groupDecryptionKey: string = decryptedPrivateKey;
        let decryptedGroup: DecryptionResult | null = null;
        let teamData: TeamEncryptedData = new TeamEncryptedData();

        // If this is a vault password and we have the vault key then use it to get to the decryption key it is not the person's private key

        //if (group.vaultId && vaultDataMap.has(group.vaultId)) {
        if (group.vaultId && group.vaultId.length > 0) {
            shouldDecryptViaVault = true;
            // This is a group within a vault. The decryptedprivatekey from above is invalid if the map contains the vault. The map has the keys if they are an admin / have encryption rights to the team.
            // console.log("vault data map", vaultDataMap);
            if (vaultDataMap.has(group.vaultId)) {
                let vaultData = vaultDataMap.get(group.vaultId);
                if (vaultData) {
                    decryptedFromVault = true;
                    groupDecryptionKey = vaultData.decryptedPrivateKey;
                    vaultsReady = true;
                }
            } else {
                console.log("vault keys are unavailable for vault " + group.vaultId + ".  Using the private key from the user.");
                shouldDecryptViaVault = false;
                groupDecryptionKey = decryptedPrivateKey;
            }
        }

        if (this.consoleLog) console.log("group: " + group.id + " is from vault " + group.vaultId + " decryptdFromVault: " + decryptedFromVault + " groupDecryptionKey " + groupDecryptionKey);

        // Don't attempt to decrypt if the vault is required but not ready.
        if (!shouldDecryptViaVault || (shouldDecryptViaVault && vaultsReady)) {

            if (groupDecryptionKey && groupDecryptionKey.length > 0) {

                const startTimeRSA = this.showTimings ? performance.now() : 0;
                decryptedGroup = await this.rsaDecrypt.rsaDecrypt(group.encryptedGroupKey, groupDecryptionKey);
                if (!decryptedGroup || !decryptedGroup.success) {
                    console.error("error with password group " + group.id + " vault? " + decryptedFromVault + ". error: " + decryptedGroup.error);//;+ ". group decryption key " + groupDecryptionKey + ".");
                    console.error("group", group);
                } else {
                    decryptedGroupKey = decryptedGroup.data;
                }
                // CApture the end of RSA decryption if this showTimings is true
                elapsedTimeRSA = this.showTimings ? performance.now() - startTimeRSA : 0;


                const startTimeMD = this.showTimings ? performance.now() : 0;
                if (group.metadataKey && group.metadataKey.length > 0 && decryptedGroup.success) {
                    let metadataKey = await this.aesDecrypt.aesDecrypt(group.metadataKey, decryptedGroup.data);
                    if (!metadataKey || !metadataKey.success) {
                        console.error("error with password group meta " + group.id + " " + metadataKey.error);
                    } else {
                        decryptedMetadataKey = metadataKey.data;
                    }
                }
                // CApture the end of AES decryption if this showTimings is true
                elapsedTimeMD = this.showTimings ? performance.now() - startTimeMD : 0;
            }

            if (group.version === TeamVersionsEnum.V2 && decryptedGroupKey && decryptedGroupKey.length > 0) {
                // decrypt the data

                const startTimeData = this.showTimings ? performance.now() : 0;
                let data = await this.aesDecrypt.aesDecrypt(group.data, decryptedGroupKey);
                if (!data || !data.success) {
                    console.error("error with password group data " + group.id + " " + data.error);
                } else {
                    // console.log("data", data.data);
                    teamData = JSON.parse(data.data) as TeamEncryptedData;
                }
                // CApture the end of AES decryption if this showTimings is true
                elapsedTimeData = this.showTimings ? performance.now() - startTimeData : 0;

            }


            const elapsedTime = this.showTimings ? performance.now() - startTime : 0;
            if (this.consoleLog)
                console.log(`Team decryption took ${elapsedTime.toFixed(2)}ms for team ${group.id} RSA: ${elapsedTimeRSA.toFixed(2)}ms MD: ${elapsedTimeMD.toFixed(2)}ms DATA: ${elapsedTimeData.toFixed(2)}ms`);
        }

        var promise = new Promise<InMemoryTeam>((resolve, reject) => {
            if (!decryptedGroup) {
                // Did not decrypt
                reject("error decrypting team " + group.id + " no decryption result");

            } else {
                if (decryptedGroup.success) {
                    let team = new InMemoryTeam();
                    team.encryptedGroupKey = group.encryptedGroupKey;
                    team.metadataKey = group.metadataKey;
                    team.id = group.id;
                    if (group.version === TeamVersionsEnum.V2) {
                        team.name = teamData.name;
                        team.color = teamData.color;
                        team.description = teamData.description;
                        team.defaultRole = teamData.defaultRole;
                        team.defaultTeam = teamData.defaultTeam;
                    } else if (group.version === TeamVersionsEnum.V1) {
                        team.name = group.name;
                        team.color = group.color;
                        team.description = group.description;
                        team.defaultRole = group.defaultRole;
                        team.defaultTeam = group.defaultTeam;
                    }
                    team.owner = group.owner;
                    team.ownerId = group.ownerId;
                    team.ownerNick = group.ownerNick;
                    team.role = group.role;
                    team.status = group.status;
                    team.individual = group.individual;
                    team.individualShareId = group.individualShareId;
                    team.pids = group.pids;
                    team.vaultId = group.vaultId;
                    team.encryptedTeamKey = group.encryptedGroupKey;
                    team.roles = group.roles;
                    team.action = group.action;
                    team.encryptedGroupKey = group.encryptedGroupKey;
                    team.metadataKey = group.metadataKey;
                    team.decryptedGroupKey = decryptedGroupKey;
                    team.decryptedMetadataKey = decryptedMetadataKey;
                    team.decryptedFromVault = decryptedFromVault;
                    team.version = group.version;




                    resolve(team);
                } else {
                    reject("error decrypting team " + group.id + " " + decryptedGroup.error);
                }
            }
        });

        return promise;
    }
}