import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppDBService } from './AppDbService';
import { StorageService } from './storage.service';
import { PasswordGroupFeedResponse } from '@app/model/api/dataFeed/PasswordGroupFeedResponse';
import { PasswordGroupFeedEntry } from '@app/model/api/dataFeed/PasswordGroupFeedEntry';
import { StorageActions } from '@app/store/actions/StorageActions';
import { Database, Statement } from 'sql.js';
import { PasswordGroup } from '@app/model/app/passwordGroup/PasswordGroup';
import { SyncStatus } from '@app/model/app/database/SyncStatus';
import { AccountKeysService } from '../account/AccountKeysService';
import { from, mergeMap, Observable, of, reduce, map } from 'rxjs';
import { AccountService } from '../account/account.service';
import { PasswordVaultService } from '../passwordVault/password-vault-service.service';
import { TeamDecryption } from '../encryption/TeamDecryption';
import { TeamVersionsEnum } from '@app/model/app/passwordGroup/TeamVersionsEnum';
import { TeamsUpdateService } from '../teams/TeamsUpdateService';
import { TeamsService } from '../teams/TeamsService';

@Injectable({
    providedIn: 'root'
})
export class PasswordGroupFeedStorageService implements OnInit, OnDestroy {
    private consoleLog: boolean = false;
    private feedIteration: number = 0;

    constructor(
        private store: Store,
        private storageService: StorageService,
        private accountKeysService: AccountKeysService,
        private accountService: AccountService,
        private passwordVaultService: PasswordVaultService,
        private teamDecyption: TeamDecryption,
        private teamsService: TeamsService
    ) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }


    /**
       * Process the password group feed and store the lastupdate
       * @param data 
       */
    processPasswordGroupFeed(data: PasswordGroupFeedResponse) {
        if (data && data.groups && data.groups.length > 0) {
            if (this.consoleLog) console.log("Feed Iteration: " + this.feedIteration);
            if (this.consoleLog) console.log("processPasswordGroupFeed");
            let toAdd: PasswordGroup[] = [];
            let toDelete: number[] = [];
            let toDeleteInMem: number[] = [];
            let start = new Date().getTime();

            data.groups.forEach(group => {
                switch (group.action) {
                    case 1:
                    // add
                    case 2:
                        // update
                        // add or update should upsert
                        let g2 = new PasswordGroup(group);
                        // explicitely set the sync flag
                        g2.syncedInMemory = SyncStatus.SYNC_STATUS_NOT_SYNCED;
                        toAdd.push(g2);
                        // These should be cleaned first so the new ones can be added
                        toDeleteInMem.push(group.id);
                        break;
                    case 3:
                        // delete
                        toDelete.push(group.id);
                        break;
                }
            });

            ////////////////////////////////////////////
            // Send to the indexeddb
            ////////////////////////////////////////////
            // Adds or updates
            this.storageService.appDbService?.passwordGroups.bulkPut(toAdd).then() // handle success
                .catch(function (error) { console.log(error); }); // handle failure

            // Deletes
            this.storageService.appDbService?.passwordGroups.bulkDelete(toDelete).then() // handle success
                .catch(function (error) { console.log(error); }); // handle failure

            ////////////////////////////////////////////
            // Send to in memory store
            ////////////////////////////////////////////
            let database = this.storageService.inMemorySQLService?.getDatabase();
            if (database) {

                // Deletes
                // Needs to be processed first
                this.processInMemorySQLDeletes(database, toDeleteInMem);
                this.processInMemorySQLDeletes(database, toDelete);

                // Adds
                this.processInMemorySQLAdds(database, toAdd);

                // Dump
                //this.dumpTables(database);
                this.dumpCounts(database);
            }

            let end = new Date().getTime();
            if (this.consoleLog) console.log("Time to process password group feed: " + (end - start) + " ms");

            ////////////////////////////////////////////
            // Notify the store that we are done loading
            this.store.dispatch(StorageActions.passwordGroupFeedStored());
            // this.store.dispatch(StorageActions.updateDataFeedPasswordGroupLastUpdate({ data: data.lastDate }));
            // Fire an event here to indicate that the data has changed
            if (this.consoleLog) console.log("password group feed stored ");
            this.store.dispatch(StorageActions.localDataChanged());


            // Increment the feed iteration
            this.feedIteration++;
        }
    }

    /**
     *  Removes the entries from the in memory database.
     * @param database 
     * @param toDelete 
     */
    private processInMemorySQLDeletes(database: Database, toDelete: number[]) {
        if (null != toDelete && toDelete.length > 0) {
            if (this.consoleLog) console.log("processInMemorySQLDeletes");
            ////////////////////////////////////////////////
            // Handle the team2passwords table
            const deleteTeams2PasswordsSQL = `DELETE FROM teams2passwords where teamId = :teamId`;
            const deleteTeamPermissionsSQL = `DELETE FROM teamPermissions where teamId = :teamId`;
            const deleteTeamsSQL = `DELETE FROM teams where id = :teamId`;

            const stmt = database.prepare(deleteTeams2PasswordsSQL);
            const stmt2 = database.prepare(deleteTeamPermissionsSQL);
            const stmt3 = database.prepare(deleteTeamsSQL);
            toDelete.forEach((teamId) => {
                stmt.run({ ':teamId': teamId });
                stmt2.run({ ':teamId': teamId });
                stmt3.run({ ':teamId': teamId });

            });
            // free the statements
            stmt.free();
            stmt2.free();
            stmt3.free();
        }
    }

    updateTableCounts() {
        if (this.consoleLog) console.log("updateTableCounts");

        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) this.dumpCounts(database);
    }
    /**
     * Helper method to dump the counts of rows
     * @param database 
     */
    private dumpCounts(database: Database) {
        let self = this;
        database.each("SELECT count(1) as count FROM teams2passwords;", {},
            function (row) {
                if (self.consoleLog) console.log("team2password rows-> " + row.count);
            },
            () => void (0)
        );

        database.each("SELECT count(1) as count FROM teams;", {},
            function (row) {
                if (self.consoleLog) console.log("teams rows-> " + row.count);
                self.store.dispatch(StorageActions.updateLoadedPasswordGroupCount({ data: Number(row.count?.toString()) }));
            },
            () => void (0)
        );

        let db = this.storageService.appDbService;
        if (db) {
            let results = db.passwordGroups.count();
            results.then((count) => {
                self.store.dispatch(StorageActions.updatePreloadPasswordGroupCount({ data: Number(count?.toString()) }));
            });
        }


    }

    /**
     * Helper method to dump the contents
     * @param database 
     */
    dumpTables(database: Database) {
        let counter = 0;
        let self = this;
        // database.each("SELECT teamId, passwordId FROM teams2passwords;", {},
        //     function (row) {
        //         console.log("data Table teams2passwords -> " + row.teamId + " " + row.passwordId + " " + ++counter);
        //     },
        //     () => void (0)
        // );

        database.each("SELECT * FROM teams;", {},
            function (row) {
                if (self.consoleLog) console.log("data Table teams -> " + JSON.stringify(row) + " " + ++counter);
            },
            () => void (0)
        );

        // Reset the counter
        counter = 0;

        database.each("SELECT * FROM teamPermissions;", {},
            function (row) {
                if (self.consoleLog) console.log("data Table teamPermissions -> " + JSON.stringify(row) + " " + ++counter);
            },
            () => void (0)
        );
    }

    dumpTables2() {
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) this.dumpTables(database);
    }

    /**
     * Adds the entries to the in memory database.
     * @param database 
     * @param toAdd 
     */
    private processInMemorySQLAdds(database: Database, toAdd: PasswordGroupFeedEntry[]) {
        if (null != toAdd && toAdd.length > 0) {
            ////////////////////////////////////////////////
            // Handle the team2passwords table
            const sqlTeam2Passwords = `INSERT OR REPLACE INTO teams2passwords (passwordId, teamId) VALUES (:passwordId, :teamId)`;
            const sqlTeamPermissions = `INSERT OR REPLACE INTO teamPermissions (teamId, userId, username, shareType, perm) VALUES (:teamId, :userId, :username, :shareType, :perm)`;

            const stmtTeam2Passwords = database.prepare(sqlTeam2Passwords);
            const stmtTeamPermissions = database.prepare(sqlTeamPermissions);
            // toAdd.forEach((group) => {
            //     group.pids.forEach((pid) => {
            //         stmtTeam2Passwords.run({ ':passwordId': pid, ':teamId': group.id });
            //     });
            //     // Add the permissions
            //     group.roles.forEach((role) => {
            //         stmtTeamPermissions.run({ ':teamId': group.id, ':userId': role.id, ':username': role.username, ':shareType': role.shareType, ':perm': role.role });
            //     });
            // });

            let MAX_CONCURRENT = 2;
            from(toAdd).pipe(
                mergeMap((group: PasswordGroupFeedEntry): Observable<any> => {

                    group.pids.forEach((pid) => {
                        stmtTeam2Passwords.run({ ':passwordId': pid, ':teamId': group.id });
                    });
                    // Add the permissions
                    group.roles.forEach((role) => {
                        stmtTeamPermissions.run({ ':teamId': group.id, ':userId': role.id, ':username': role.username, ':shareType': role.shareType, ':perm': role.role });
                    });
                    return of(group.id);
                }, MAX_CONCURRENT)
            ).subscribe(
                x => { if (this.consoleLog) console.log(x) }
            )


            // free the statements
            stmtTeam2Passwords.free();
            stmtTeamPermissions.free();
        }
    }

    /**
     * Finds the unsynced records in the index db and syncs them to the in memory database.
     *  @param syncToMemoryLoadCount
     */
    loadUnsyncedGroupData(syncToMemoryLoadCount: number): Promise<number> {
        var promise = new Promise<number>((resolve, reject) => {
            try {
                if (this.consoleLog) console.log("loadUnsyncedGroupData");

                if (this.storageService.appDbService) {
                    let db = this.storageService.appDbService;

                    // Retrieve the records in the database
                    db.passwordGroups.where('syncedInMemory').equals(SyncStatus.SYNC_STATUS_NOT_SYNCED).toArray().then((records) => {
                        if (records.length > 0) {
                            // Load the account keys
                            let accountKeysData = this.accountKeysService.getAccountKeysData();
                            if (this.consoleLog) console.log("accountKeys ", JSON.stringify(accountKeysData));
                            if (accountKeysData && accountKeysData.packingKeyDecryptedPrivateKey && accountKeysData.packingKeyDecryptedPrivateKey.length > 0) {
                                this.processUnsyncedPasswordGroup(db, records, accountKeysData.packingKeyDecryptedPrivateKey);
                            } else {
                                if (this.consoleLog) console.log("no account keys... skipping");
                            }
                        }

                        // // Dump the counts
                        this.updateTableCounts();
                        resolve(records.length);
                    });
                } else { resolve(0); }
            } catch (error) {
                console.log("Error in loadUnsyncedGroupData: " + error);
                reject(0);
            }
        });
        return promise;
    }



    /**
     * Processes the unsynced password group. Decrypts and loads into the in mem db.
     * @param db 
     * @param record 
     * @param decryptedPrivateKey 
     */
    processUnsyncedPasswordGroup(db: AppDBService, records: PasswordGroup[], decryptedPrivateKey: string) {
        let recordsProcessed = 0;
        let startTime = performance.now();
        let vaultMap = this.passwordVaultService.generateIdToVaultsWithEncryptionKeysForCurrentVaults();

        if (this.consoleLog) console.log("processUnsyncedPasswordGroup", records);

        // Define maximum concurrent operations
        const MAX_CONCURRENT = 20;

        // Use RxJS to process records in parallel with controlled concurrency
        from(records).pipe(
            mergeMap((record: PasswordGroup): Observable<any> => {
                return from(this.teamDecyption.decryptTeam(record, decryptedPrivateKey, vaultMap)).pipe(
                    map(team => {
                        // If this is an individual share then mark it as such
                        if (record.individual) {
                            this.accountService.setHasIndividualShares(true);
                        }

                        //Create the SQL Statement
                        const sqlLoadStatement = this.storageService.inMemorySQLService?.getDatabase().prepare(`INSERT OR REPLACE INTO teams (id, color, defaultRole, description, groupKey, groupStatus, metadataKey,  name, owner, ownerId, role, individual, ownerNick, status, vaultId, directoryId, decryptedFromVault, encryptedTeamKey, version, defaultTeam) VALUES \
                                    (:id, :color, :defaultRole, :description, :groupKey, :groupStatus, :metadataKey, :name, :owner, :ownerId, :role, :individual, :ownerNick, :status, :vaultId, :directoryId, :decryptedFromVault, :encryptedTeamKey, :version, :defaultTeam); `);

                        sqlLoadStatement?.run({
                            ':id': record.id, ':color': team.color, ':defaultRole': team.defaultRole, ':description': team.description,
                            ':groupKey': team.decryptedGroupKey, ':groupStatus': record.groupStatus, ':metadataKey': team.decryptedMetadataKey,
                            ':name': team.name, ':owner': record.owner, ':ownerId': record.ownerId, ':role': record.role,
                            ':individual': record.individual ? 1 : 0, ':ownerNick': record.ownerNick, ':status': record.status
                            , ':vaultId': record.vaultId ? record.vaultId : ""
                            , ':directoryId': record.directoryId ? record.directoryId : ""
                            , ":decryptedFromVault": team.decryptedFromVault ? 1 : 0
                            , ':encryptedTeamKey': record.encryptedTeamKey ? record.encryptedTeamKey : ""
                            , ':version': record.version, ':defaultTeam': record.defaultTeam ? 1 : 0
                        });
                        sqlLoadStatement?.free();
                        if (this.consoleLog) console.log("Added record " + record.id + " to in memory db " + record.name);

                        // update the indexdb to indicate it is now loaded
                        db.transaction('rw', db.passwordGroups, async () => {
                            db.passwordGroups.update(record.id, { syncedInMemory: SyncStatus.SYNC_STATUS_SYNCED_IN_MEM })
                                .then(() => { if (this.consoleLog) console.log("updated") })
                                .catch((error) => { console.log(error); });
                        });

                        // Look to see if we need to convert this password to a new version
                        if (this.consoleLog) console.log("Team: " + record.id + " owner: " + record.owner + " version: " + record.version + " me: " + this.accountService.getCustomerId());
                        if (record.ownerId === this.accountService.getCustomerId()
                            && record.version === TeamVersionsEnum.V1) {
                            if (this.consoleLog) console.log("NEED UPGRADE Team " + record.id);
                            this.teamsService.convertVersion1TeamToVersion2(team);
                        }

                        return record.id;
                    })
                );
            }, MAX_CONCURRENT) // Process up to MAX_CONCURRENT records in parallel
        ).subscribe({
            next: (id) => {
                if (this.consoleLog) console.log(`Processed team ID: ${id}`);
                recordsProcessed++;
            },
            error: (error) => {
                console.error("Error processing teams:", error);
            },
            complete: () => {
                let endTime = performance.now();
                let elapsedTime = endTime - startTime;
                console.log(`All teams processed. Processed ${recordsProcessed} records in ${elapsedTime.toFixed(2)}ms`);


                // Tell the store we have new data for each processed record
                this.store.dispatch(StorageActions.updateLastPasswordGroupChangeTimestamp());

                this.updateTableCounts();
                // Tell the store we have new data
                this.store.dispatch(StorageActions.localDataChanged());

            }
        });
    }



}