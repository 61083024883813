import { TeamVersionsEnum } from "@app/model/app/passwordGroup/TeamVersionsEnum";
import { GroupDetailRoleEntry } from "./GroupDetailRoleEntry";

export class PasswordGroupFeedEntry {
    groupStatus: number;
    encryptedGroupKey: string;
    metadataKey: string;
    id: number;
    name: string;
    color: string;
    description: string;
    owner: string;
    ownerId: number;
    ownerNick: string;
    role: number;
    defaultRole: number;
    status: number;
    individual: boolean;
    individualShareId: number;
    pids: number[];
    vaultId: string;
    encryptedTeamKey: string;
    roles: GroupDetailRoleEntry[];
    action: number;
    version: number = TeamVersionsEnum.V1;
    data: string;
    defaultTeam: boolean = false;


    constructor(group: PasswordGroupFeedEntry) {
        this.groupStatus = group.groupStatus;
        this.encryptedGroupKey = group.encryptedGroupKey;
        this.metadataKey = group.metadataKey;
        this.id = group.id;
        this.name = group.name;
        this.color = group.color;
        this.description = group.description;
        this.owner = group.owner;
        this.ownerId = group.ownerId;
        this.ownerNick = group.ownerNick;
        this.role = group.role;
        this.defaultRole = group.defaultRole;
        this.status = group.status;
        this.individual = group.individual;
        this.individualShareId = group.individualShareId;
        this.pids = group.pids;
        this.vaultId = group.vaultId;
        this.encryptedTeamKey = group.encryptedTeamKey;
        this.roles = group.roles;
        this.action = group.action;
        this.version = group.version;
        this.data = group.data;
        this.defaultTeam = group.defaultTeam;
    }
}