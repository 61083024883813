import { Injectable } from '@angular/core';
import { string_to_bytes } from 'asmcrypto.js';
// Must import buffer for PSON to work.
import { EncryptionResult } from '@app/model/app/encryption/EncryptionResult';
import * as ByteBuffer from 'bytebuffer';

@Injectable({ providedIn: 'root' })

export class RSAEncrypt {
    private consoleLog: boolean = false;

    async rsaEncrypt(data: string, publicKey: string): Promise<EncryptionResult> {
        if (this.consoleLog) console.log("rsaEncrypt: " + data + " " + publicKey);
        var promise = new Promise<EncryptionResult>((resolve, reject) => {
            try {
                var pubKeyParts = publicKey.split("|");

                if (pubKeyParts[0] === '3') {
                    this.rsaEncryptV3(data, publicKey).then((result) => {
                        if (this.consoleLog) console.log("payload: " + JSON.stringify(result));
                        resolve(result);
                    });
                } else {
                    this.rsaEncryptV2(data, publicKey).then((result) => {
                        if (this.consoleLog) console.log("payload: " + JSON.stringify(result));
                        resolve(result);
                    });
                }

            } catch (error) {
                console.error("error: " + error);
                let encryptionResult: EncryptionResult = new EncryptionResult();
                encryptionResult.success = false;
                encryptionResult.error = "444" + error as unknown as string;
                reject(encryptionResult);
            }
        });

        return promise;
    }

    private urlEncodeUrl(str: string): string {
        return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
    }

    private rsaEncryptV2(data: string, publicKey: string): Promise<EncryptionResult> {
        let payload: EncryptionResult = new EncryptionResult();
        if (this.consoleLog) console.log("RSA Encrypt version 2 starting");
        var promise = new Promise<EncryptionResult>((resolve, reject) => {
            try {
                let publicKeyParts = publicKey.split("|");
                let keyData = {
                    kty: "RSA",
                    alg: "RSA-OAEP-256",  // Your desired JWA algorithm
                    ext: true,            // Is key extractable or not
                    key_ops: ["encrypt"], // Array of supposed key usages
                    // The "e" component, public exponent, spec restricts is to the only value, 65537
                    // Public key components
                    e: "AQAB",                      // The "e" component, public exponent, spec restricts is to the only value, 65537
                    n: this.urlEncodeUrl(publicKeyParts[1]),
                }
                let algorithm = {   //these are the algorithm options
                    name: "RSA-OAEP",
                    hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
                }
                if (this.consoleLog) console.log("keyData: " + JSON.stringify(keyData) + " algorithm: " + JSON.stringify(algorithm) + " data: " + data);

                self.crypto.subtle.importKey("jwk", keyData, algorithm, true, ["encrypt"])
                    .then(function (key) {
                        //returns the symmetric key
                        self.crypto.subtle.encrypt(
                            {
                                name: "RSA-OAEP",
                                label: string_to_bytes("zz")
                            },
                            key, //from generateKey or importKey above
                            string_to_bytes(data) //ArrayBuffer of the data
                        ).then(function (encrypted) {
                            var encryptedData = ByteBuffer.wrap(encrypted).toBase64();
                            payload.success = true;
                            payload.data = encryptedData;
                            resolve(payload);
                        }).catch(function (err) {
                            console.error("ERROR 3 " + err);
                            payload.success = false;
                            payload.error = "3 " + err as unknown as string;
                            reject(payload);
                        });
                    })
                    .catch(function (err) {
                        console.error("Error getting key: " + err);
                        payload.success = false;
                        payload.error = "2 " + err as unknown as string;
                        reject(payload);
                    });


            } catch (error) {
                console.error("error333: " + error);
                payload.success = false;
                payload.error = error as unknown as string;
                reject(payload);
            }

        });

        return promise;
    }
    private rsaEncryptV3(data: string, publicKey: string): Promise<EncryptionResult> {
        let payload: EncryptionResult = new EncryptionResult();
        if (this.consoleLog) console.log("RSA Encrypt version 3 starting");
        var promise = new Promise<EncryptionResult>((resolve, reject) => {
            try {
                let publicKeyParts = publicKey.split("|");
                let keyData = {
                    kty: "RSA",
                    alg: "RSA-OAEP-512",  // Your desired JWA algorithm
                    ext: true,            // Is key extractable or not
                    key_ops: ["encrypt"], // Array of supposed key usages
                    // The "e" component, public exponent, spec restricts is to the only value, 65537
                    // Public key components
                    e: "AQAB",                      // The "e" component, public exponent, spec restricts is to the only value, 65537
                    n: this.urlEncodeUrl(publicKeyParts[1]),
                }
                let algorithm = {   //these are the algorithm options
                    name: "RSA-OAEP",
                    hash: { name: "SHA-512" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
                }
                if (this.consoleLog) console.log("keyData: " + JSON.stringify(keyData) + " algorithm: " + JSON.stringify(algorithm) + " data: " + data);

                self.crypto.subtle.importKey("jwk", keyData, algorithm, true, ["encrypt"])
                    .then(function (key) {
                        //returns the symmetric key
                        self.crypto.subtle.encrypt(
                            {
                                name: "RSA-OAEP",
                                label: string_to_bytes("zz")
                            },
                            key, //from generateKey or importKey above
                            string_to_bytes(data) //ArrayBuffer of the data
                        ).then(function (encrypted) {
                            var encryptedData = ByteBuffer.wrap(encrypted).toBase64();
                            payload.success = true;
                            payload.data = encryptedData;
                            resolve(payload);
                        }).catch(function (err) {
                            console.error("ERROR 3 " + err);
                            payload.success = false;
                            payload.error = "3 " + err as unknown as string;
                            reject(payload);
                        });
                    })
                    .catch(function (err) {
                        console.error("Error getting key: " + err);
                        payload.success = false;
                        payload.error = "2 " + err as unknown as string;
                        reject(payload);
                    });


            } catch (error) {
                console.error("error333: " + error);
                payload.success = false;
                payload.error = error as unknown as string;
                reject(payload);
            }

        });

        return promise;
    }


}