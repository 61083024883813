import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAccountKeysData } from '@app/store/selectors/AccountSelectors';
import { Subscription } from 'rxjs';
import { AccountKeys } from '@app/model/app/account/AccountKeys';

@Injectable({
    providedIn: 'root'
})
export class AccountKeysService implements OnInit, OnDestroy {
    readonly selectAccountKeysData$ = this.store.select(selectAccountKeysData);
    private accountKeysSubscription: Subscription;
    private accountKeysData: AccountKeys = new AccountKeys();
    private consoleLog: boolean = false;

    constructor(
        private store: Store,
    ) {
        this.accountKeysSubscription = this.selectAccountKeysData$.subscribe(x => {
            // Update the account keys
            if (x) {
                if (this.consoleLog) console.log("--------Updating the account keys-------");
                this.accountKeysData = x;
            } else this.accountKeysData = new AccountKeys();;
        });
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        if (this.accountKeysSubscription) {
            this.accountKeysSubscription.unsubscribe();
        }
    }

    /**
     * Returns a copy of the account keys to effectively make it read only.
     * @returns 
     */
    getAccountKeysData(): AccountKeys {
        return { ...this.accountKeysData };
    }
}