import { ActionReducer, createReducer, on } from '@ngrx/store';
import { LoginActions } from '../actions/LoginActions';
import { NotificationsActions } from '../actions/NotificationsActions';
import { DrawerActions } from '../actions/DrawerActions';
import { DrawerState } from '@app/model/app/drawer/DrawerState';
import { DrawerComponentsEnum } from '@app/model/app/drawer/DrawerComponentsEnum';

export const initialState: DrawerState = new DrawerState();

export const drawerReducer: ActionReducer<DrawerState> = createReducer(
    initialState,
    on(LoginActions.logoutComplete, (state, action) => {
        return {
            ...initialState
        };
    }),
    on(NotificationsActions.showNotifications, (state, action) => {
        return {
            ...state,
            rightDrawerComponent: DrawerComponentsEnum.NOTIFICATIONS,
        };
    }),

    on(DrawerActions.showRightDrawer, (state, action) => {
        return {
            ...state,
            showRightDrawer: true,
        };
    }),
    on(DrawerActions.hideRightDrawer, (state, action) => {
        return {
            ...state,
            showRightDrawer: false,
        };
    }),


);