import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { RSAEncrypt } from '../encryption/RsaEncrypt';
import { AesEncrypt } from '../encryption/AesEncrypt';
import { BaseAPIService } from '../BaseAPIService';
import { environment } from '@environments/environment';
import { Observable, firstValueFrom } from 'rxjs';
import { BulkUserPublicKeyRequest } from '@app/model/api/teams/MultiManageTeamMembershipRequest';
import { MultiManageTeamMembershipResponse } from '@app/model/api/teams/MultiManageTeamMembershipResponse';
import { MultiManageTeamMembeshipEntry } from '@app/model/api/teams/MultiManageTeamMembershipEntry';
import { PasswordsService } from '../passwords/PasswordsService';
import { TeamMembershipTuple } from '@app/model/app/teams/TeamMembershipTuple';
import { TeamKeyService } from '../cache/team-key-service.service';
import { BulkUserPublicKeyResponse } from '@app/model/api/connection/BulkUserPublicKeyResponse';
import { MultiManageTeamMembershipRequest } from '@app/model/api/connection/BulkUserPublicKeyRequest';
import { MultiManagePasswordGroupMembershipRequest } from '@app/model/api/passwords/MultiManagePasswordGroupMembershipRequest';
import { PasswordDetailsService } from '../passwords/PasswordDetailsService';
import { MultiManagePasswordGroupMembershipEntry } from '@app/model/api/passwords/MultiManagePasswordGroupMembershipEntry';
import { MultiManagePasswordGroupMembershipResponse } from '@app/model/api/passwords/MultiManagePasswordGroupMembershipResponse';
import { TeamPublicKeyService } from './TeamPublicKeyService';
import { TeamQueryService } from './TeamQueryService';
import { BulkUserPublicKeyEntry } from '@app/model/api/connection/BulkUserPublicKeyEntry';
import { TeamActions } from '@app/store/actions/TeamActions';
import { PasswordVaultRolesEnum } from '@app/model/app/passwordVault/PasswordVaultRolesEnum';
import { PasswordVaultService } from '../passwordVault/password-vault-service.service';
import { InMemoryTeam } from '@app/model/app/passwordGroup/InMemoryTeam';

@Injectable({ providedIn: 'root' })
export class TeamsUpdateService implements OnDestroy {
    private consoleLog: boolean = false;

    constructor(
        private store: Store,
        private aesEncrypt: AesEncrypt,
        private rsaEncrypt: RSAEncrypt,
        private passwordDetailService: PasswordDetailsService,
        private teamKeyService: TeamKeyService,
        private passwordsService: PasswordsService,
        private teamPublicKeyService: TeamPublicKeyService,
        private teamQueryService: TeamQueryService,
        private passwordVaultService: PasswordVaultService
    ) {

    }
    ngOnDestroy(): void {

    }


    /**
     * Manage the team membership with licensees
     * @param groupId 
     * @param adds 
     * @param updates 
     * @param removes 
     * @returns 
     */
    async manageTeamMembership(groupId: number, adds: TeamMembershipTuple[], updates: TeamMembershipTuple[], removes: number[]): Promise<MultiManageTeamMembershipResponse | undefined> {

        console.log("manageTeamMembership", groupId, adds, updates, removes);
        let request = new MultiManageTeamMembershipRequest();
        let teamKey = this.teamKeyService.getTeamMetaKeys(groupId);
        let team = this.teamQueryService.getTeamDetail(groupId);
        let publicKeysToAddToVault: BulkUserPublicKeyEntry[] = [];

        // Determine if the team is a vault team.
        let isVaultTeam = false;
        if (team && team.vault && team.vault.id) {
            isVaultTeam = true;
        }

        // Adds
        if (adds && adds.length > 0) {
            let addIds = adds.map((add) => add.memberId);
            let publicKeysResponse: BulkUserPublicKeyResponse = await firstValueFrom(this.teamPublicKeyService.getConnectionPublicKeys(addIds));
            // console.log("PublicKeysResponse", publicKeysResponse);
            let connectionKeys = publicKeysResponse.publicKeys;

            for (let tuple of adds) {
                // console.log("Member:", tuple.memberId);
                try {
                    // Find the connection key in the response of the public keys
                    // find the tuple.memberId in the connectionKeys
                    let connectionPublicKey = connectionKeys.find((entry) => entry.connectionId == tuple.memberId);
                    if (teamKey && connectionPublicKey) {

                        if (isVaultTeam) {
                            // Adds to an array of the users we want to ensure have access to the vault
                            publicKeysToAddToVault.push(connectionPublicKey);
                        }

                        let encryptedGroupKey = await this.rsaEncrypt.rsaEncrypt(teamKey.groupKey, connectionPublicKey.publicKey);

                        let entry = new MultiManageTeamMembeshipEntry();
                        entry.gid = groupId;
                        entry.action = 1;
                        entry.memberId = tuple.memberId
                        entry.role = tuple.role;
                        entry.gk = encryptedGroupKey.data;
                        request.entries.push(entry);

                    }
                } catch (e) {
                    console.error(e);
                }
            };
        }

        // Updates
        for (let tuple of updates) {
            let entry = new MultiManageTeamMembeshipEntry();
            entry.gid = groupId;
            entry.action = 3;
            entry.memberId = tuple.memberId
            entry.role = tuple.role;
            request.entries.push(entry);
        };

        // Removes
        for (let memberId of removes) {
            let entry = new MultiManageTeamMembeshipEntry();
            entry.gid = groupId;
            entry.action = 2;
            entry.gid = groupId;
            entry.memberId = memberId;
            request.entries.push(entry);
        };

        // If the array publicKeysToAddToVault is not empty, we need to add the public keys to the vault
        if (publicKeysToAddToVault.length > 0) {
            console.log("Want dispatch ensureTeamMemberIsAddedToSharedVault");
            // this.store.dispatch(
            //     TeamActions.ensureTeamMemberIsAddedToSharedVault(
            //         { vaultId: team.vault.id, dataKey: team.vault.dataKey, role: PasswordVaultRolesEnum.USER, keys: publicKeysToAddToVault }));
            this.passwordVaultService.ensureTeamMemberIsAddedToSharedVault(team.vault.id, team.vault.dataKey, PasswordVaultRolesEnum.USER, publicKeysToAddToVault).then(() => {
                console.log("ensureTeamMemberIsAddedToSharedVault success");
            });
        }

        return await firstValueFrom(this.passwordsService.manageTeamMembership(request));
    }



    /**
     * Manages the passwords found in a team
     * @param groupId 
     * @param adds 
     * @param removes 
     * @returns 
     */
    async managePasswordsInTeam(groupId: number, adds: number[], removes: number[]): Promise<MultiManagePasswordGroupMembershipResponse | undefined> {
        let request = new MultiManagePasswordGroupMembershipRequest();
        // Adds
        let teamKey = this.teamKeyService.getTeamMetaKeys(groupId);

        for (let passwordId of adds) {
            let passwordDetail = this.passwordDetailService.retrievePasswordDetail(passwordId);

            if (teamKey && passwordDetail) {
                let encryptedPasswordKey = await this.aesEncrypt.aesEncrypt(passwordDetail.key, teamKey.groupKey);

                let entry = new MultiManagePasswordGroupMembershipEntry();
                entry.id = passwordId;
                entry.gk = encryptedPasswordKey;
                entry.gid = groupId;
                entry.action = 1;
                request.entries.push(entry);
            }
        };

        // Removes
        for (let passwordId of removes) {
            let entry = new MultiManagePasswordGroupMembershipEntry();
            entry.id = passwordId;
            entry.action = 2;
            entry.gid = groupId;
            request.entries.push(entry);
        };

        return await firstValueFrom(this.passwordsService.managePasswordGroupMembership(request));
    }



}