import { Injectable } from '@angular/core';
import { AESJS } from './AESJS';

@Injectable({ providedIn: 'root' })
export class AesEncrypt {
    consoleLog: boolean = false;
    private worker: Worker | null = null;
    private pendingRequests = new Map<number, { resolve: (value: string) => void, reject: (reason: any) => void }>();
    private nextRequestId = 0;
    private aesJS = new AESJS();
    private iterations = 101003;

    constructor(
    ) {
        // Initialize the worker if supported
        if (typeof Worker !== 'undefined') {
            this.initWorker();
        }

        // // TEst things out. 
        // let passphrase = "16483032198792392_1741991155814_10bcdd73205442929b75ca0adfcd27ad";
        // let data = "1234567890";
        // this.aesJS.aesEncryptv6(data, passphrase, 101003).then((encryptedText) => {
        //     console.log("encrypted--", encryptedText);
        //     const tmp = encryptedText.split("#");

        //     let decrypted = this.aesJS.aesDecryptv6(tmp, encryptedText, passphrase).then((result) => {
        //         console.log("decrypted--", result);
        //     });
        // });

    }
    private initWorker() {
        try {
            this.worker = new Worker(new URL('./aes-encrypt.worker.ts', import.meta.url), { type: 'module' });
            this.worker.onmessage = ({ data }) => {
                const { result, id } = data;
                const request = this.pendingRequests.get(id);
                if (request) {
                    this.pendingRequests.delete(id);
                    request.resolve(result);
                }
            };

            this.worker.onerror = (error) => {
                console.error('Worker error:', error);
                // Resolve all pending requests with an error
                this.pendingRequests.forEach((request) => {

                    request.reject(error.message);
                });
                this.pendingRequests.clear();

                // Try to reinitialize the worker
                this.worker = null;
                this.initWorker();
            };
        } catch (error) {
            console.error('Failed to initialize worker:', error);
            this.worker = null;
        }
    }
    async aesEncrypt(data: string, passphrase: string, version: number = 6): Promise<string> {
        // console.log("aesEncrypt: " + data + " passphrase: " + passphrase + " version: " + version);
        if (this.worker) {
            return this.aesEncryptWithWorker(data, passphrase, this.iterations, version);
            // return this.aesDecryptSync(data, passphrase);

        } else {
            // Fall back to synchronous decryption
            return this.aesEncryptSync(data, passphrase, version);
        }

    }

    private async aesEncryptWithWorker(inputData: string, passphrase: string, iterations: number, version: number): Promise<string> {
        const requestId = this.nextRequestId++;

        return new Promise<string>((resolve, reject) => {
            this.pendingRequests.set(requestId, { resolve, reject });

            this.worker!.postMessage({
                dataToEncrypt: inputData,
                passphrase: passphrase,
                version: version,
                iterations: iterations,
                id: requestId
            });
        });
    }


    private async aesEncryptSync(data: string, passphrase: string, version: number): Promise<string> {
        // if (version === 6) {
        return this.aesJS.aesEncryptv6(data, passphrase, this.iterations);

        // } else if (version === 5) {
        //     let iterations = 101003;
        //     return this.aesJS.aesEncryptv5(data, passphrase, iterations);
        // } else if (version === 4) {
        //     //return this.aesEncryptv4(data, passphrase);
        //     // No support for v4
        //     return this.aesJS.aesEncryptv3(data, passphrase)
        // } else {
        //     return this.aesJS.aesEncryptv3(data, passphrase);
        // }
    }

}