import { HttpInterceptorFn } from '@angular/common/http';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { CsrfService } from '@app/services/CsrfService.service';
import { ok } from 'assert';
import { Observable, tap } from 'rxjs';


export const CsrfInterceptorInterceptor: HttpInterceptorFn = (req, next) => {
  const csrfService = inject(CsrfService);
  const consoleLog = false;

  if (consoleLog) console.log("REQ TYPE: " + req.method);
  // Determine if this is a request or response
  if (req instanceof HttpRequest) {

    if (consoleLog) console.log("RequestType: " + req.method);
    if (req.method == "POST" || req.method == "PUT" || req.method == "DELETE") {
      if (consoleLog) console.log("Checking and injecting token: " + csrfService.getCsrfToken());

      // Add the csrf token to the request.
      req = req.clone({
        setHeaders: {
          'x-csrf-token': csrfService.getCsrfToken()
        }
      });
    }
  }


  return next(req).pipe(tap((event: HttpEvent<any>) => {
    if (event instanceof HttpResponse) {

      // Update the CSRF it is a get and successful
      // NOTE: We need to capture this regardless of HTTP status code
      if (req.method == "GET" ) {

        if (consoleLog) console.info('HttpResponse::event =', event, ';');
        // get the x-csrf-token from the response headers
        const csrfToken = event.headers.get('x-csrf-token');
        if (consoleLog) console.info('csrfToken =', csrfToken, ';');
        // set the csrf token in the service
        csrfService.setCsrfToken(csrfToken);
      }
    }
  }));
};
