import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable, of } from "rxjs";
import { catchError, concatMap, exhaustMap, filter, map, mergeMap, switchMapTo, tap, withLatestFrom } from "rxjs/operators";
import { LoginActions } from "../actions/LoginActions";
import { Store } from "@ngrx/store";
import { AccountService } from "@app/services/account/account.service";
import { AccountActions } from "../actions/AccountActions";
import { selectAccountUserInformationDetails, selectOOBPreference, selectPackingKeyData } from "../selectors/AccountSelectors";
import { StorageService } from "@app/services/dataStorage/storage.service";
import { IdleTimerService } from "@app/services/timers/IdleTimerService";
import { SiteMessageService } from "@app/services/site/SiteMessageService.service";
import { OrganizationActions } from "../actions/OrganizationActions";
import { PreferenceService } from "@app/services/PreferenceService.service";
import { PasswordVaultService } from "@app/services/passwordVault/password-vault-service.service";

@Injectable()
export class AccountEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private accountService: AccountService,
        private siteMessageService: SiteMessageService,
        private storageService: StorageService,
        private idleTimerService: IdleTimerService,
        private preferenceService: PreferenceService,
        private passwordVaultService: PasswordVaultService
    ) { }


    /**
     * Initiates the logout process
     */
    readonly initiateLogout$ = createEffect(
        () => this.actions$.pipe(
            ofType(LoginActions.logoutInitiate),
            tap(() => this.accountService.logout(true)),
        ), { dispatch: false }
    );
    readonly initiateLightweightLogout$ = createEffect(
        () => this.actions$.pipe(
            ofType(LoginActions.lightweightLogout),
            tap(() => this.accountService.logout(false)),
        ), { dispatch: false }
    );




    /**
     * Cleans the database
     */
    readonly cleanStorageUponLogout$ = createEffect(
        () => this.actions$.pipe(
            ofType(LoginActions.logoutInitiate, LoginActions.lightweightLogout),
            tap(() => this.storageService.cleanForLogout()),
        ), { dispatch: false }
    );

    /**
    * Start the idle timer
    */
    readonly startIdleTimer$ = createEffect(
        () => this.actions$.pipe(
            ofType(LoginActions.setMayViewAccount),
            tap(() => this.idleTimerService.startIdleTimer()),
        )
        , { dispatch: false }
    );

    /**
     * Stop the idle timer
     */
    readonly stopIdleTimer$ = createEffect(
        () => this.actions$.pipe(
            ofType(LoginActions.logoutInitiate, LoginActions.lightweightLogout),
            tap(() => this.idleTimerService.stopIdleTimer()),
        ), { dispatch: false }
    );

    /**
     * Handle the idle timeout
     */
    readonly handleIdleTimeout$ = createEffect(
        () => this.actions$.pipe(
            ofType(AccountActions.idleTimeout),
            tap(() => this.accountService.handleIdleTimeout()),
        ), { dispatch: false }
    );

    /**
   * Handle the manual lock screen
   */
    readonly handleLockScreen$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                AccountActions.lockScreen),
            tap(() => this.accountService.handleLockScreen()),
        ), { dispatch: false }
    );

    readonly loadSiteMessages$ = createEffect(
        () => this.actions$.pipe(
            ofType(LoginActions.loginPageOpened),
            exhaustMap(() => {
                return this.siteMessageService.getSiteMessages().pipe(
                    map((response) =>
                        AccountActions.siteMessagesLoaded({ response }),
                    )
                );
            })
        )
    );

    /**
   * Retrieve Account Preferences
   */
    readonly retrieveAccountPreferences$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                LoginActions.accountLoginCompleted,
                AccountActions.unlockPackingKeySuccess,
                AccountActions.retrieveAccountPreferences),
            tap(() => this.preferenceService.updateAllPreferences()),
        ), { dispatch: false }
    );


    /**
       * Deletes the account
       */
    readonly deleteAccount$ = createEffect(
        () => this.actions$.pipe(
            ofType(AccountActions.deleteAccount),
            tap(() => this.accountService.deleteAccount()),
        ), { dispatch: false }
    );


    /**
     * Verifies the new account email
     */
    readonly verifyNewAccountEmail$ = createEffect(
        () => this.actions$.pipe(
            ofType(AccountActions.verifyNewAccountEmail),
            tap((action) => this.accountService.verifyNewAccountEmail(action.code)),
        ), { dispatch: false }
    );



    /**
     * Loads the account details from the server
     * want to load if undefined or if defined and the code is not 0
     */
    readonly loadAccountDetails$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                LoginActions.loadAccountDetails
                , LoginActions.accountLoginCompleted
                , LoginActions.mFACodeEntered
            ),
            concatLatestFrom(() => this.store.select(selectAccountUserInformationDetails)),
            filter(([action, selectAccountUserInformationDetails]) => (undefined === selectAccountUserInformationDetails || selectAccountUserInformationDetails!.status.code !== 0)),
            exhaustMap(() => {
                return this.accountService.getAccountDetail().pipe(
                    map((response) => AccountActions.accountDetailsLoaded({ accountDetails: response })),
                    catchError((error: { message: string }) =>
                        of(AccountActions.accountDetailsLoadError({ error }))
                    )
                );
            })
        )
    );

    readonly reloadAccountDetails = createEffect(
        () => this.actions$.pipe(
            ofType(AccountActions.needReloadAccountDetails),
            exhaustMap(() => {
                return this.accountService.getAccountDetail().pipe(
                    map((response) => AccountActions.accountDetailsLoaded({ accountDetails: response })),
                    catchError((error: { message: string }) =>
                        of(AccountActions.accountDetailsLoadError({ error }))
                    )
                );
            })
        )
    );

    /**
     * Resets the local data
     */
    readonly resetLocalData$ = createEffect(
        () => this.actions$.pipe(
            ofType(AccountActions.accountDetailsLoaded),
            tap(() => this.storageService.switchLoggedInUser()),
        ), { dispatch: false }
    );



    /**
     * Loads the packing key from the server
     */
    readonly loadPackingKeyData$ = createEffect(
        () => this.actions$.pipe(
            ofType(LoginActions.packingKeyPageOpened),
            //concatLatestFrom(() => this.store.select(selectPackingKeyData)),
            // filter(([, selectPackingKeyData]) => (undefined === selectPackingKeyData || selectPackingKeyData!.status.code !== 0)),
            exhaustMap(() => {
                return this.accountService.getPackingKeyData().pipe(
                    map((response) =>
                        AccountActions.packingKeyRetrieved({ response })
                    ),
                    catchError((error) => of(AccountActions.packingKeyRetrievalError({ error })))
                );
            })
        )
    );

    /**
     * Check the response from the packing key
     */
    readonly checkPackingKeyDataResponse$ = createEffect(
        () => this.actions$.pipe(
            ofType(AccountActions.packingKeyRetrieved),
            tap((action) => this.accountService.checkPackingKeyResponse(action.response)),
        ), { dispatch: false }
    );


    /**
     * Try to unlock the account
     */
    readonly automaticallyAttemptToUnlockAccount$ = createEffect(
        () => this.actions$.pipe(
            ofType(AccountActions.packingKeyRetrieved),
            concatLatestFrom(() => this.store.select(selectPackingKeyData)),
            filter(([, selectPackingKeyData]) => (undefined !== selectPackingKeyData || selectPackingKeyData!.status.code === 0)),
            tap(() => this.accountService.tryUnlockAccount()),
        ), { dispatch: false }
    );


    /**
         * Loads the license summary data from the server
         */
    readonly loadLicenseSummaryData$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                LoginActions.packingKeyPageOpened,
                AccountActions.packingKeyDecryptedFromStorage,
                AccountActions.reloadLicenseSummary),
            exhaustMap(() => {
                return this.accountService.getLicenseSummary().pipe(
                    map((response) =>
                        AccountActions.licenseSummaryRetrieved({ response })
                    ),
                    catchError((error) => of(AccountActions.licenseRetrievalError({ error })))
                );
            })
        )
    );

    readonly checkLicenseSummaryData$ = createEffect(
        () => this.actions$.pipe(
            ofType(AccountActions.licenseSummaryRetrieved),
            tap((action) => this.accountService.processLicenseSummary(action.response)),
        ), { dispatch: false }
    );

    /**
             * Loads the oob preference from the server
             */
    readonly loadOOBPreferenceData$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                LoginActions.accountLoginCompleted,
                LoginActions.packingKeyPageOpened,
                AccountActions.packingKeyDecryptedFromStorage
            ),
            concatLatestFrom(() => this.store.select(selectOOBPreference)),
            filter(([, selectOOBPreference]) => (undefined === selectOOBPreference || selectOOBPreference!.status.code !== 0)),
            exhaustMap(() => {
                return this.accountService.getOOBPreference().pipe(
                    map((response) =>
                        AccountActions.oOBPreferenceRetrieved({ response })
                    ),
                    catchError((error) => of(AccountActions.oOBPreferenceRetrievalError({ error })))
                );
            })
        )
    );


    /**
            * Loads the oob preference from the server
            */
    readonly reloadOOBPreferences$ = createEffect(
        () => this.actions$.pipe(
            ofType(AccountActions.reloadOOBPreference),
            exhaustMap(() => {
                return this.accountService.getOOBPreference().pipe(
                    map((response) =>
                        AccountActions.oOBPreferenceRetrieved({ response })
                    ),
                    catchError((error) => of(AccountActions.oOBPreferenceRetrievalError({ error })))
                );
            })
        )
    );

    /**
            * Loads the account settings from the server
            */
    readonly loadAccountSettingsData$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                LoginActions.packingKeyPageOpened
                , AccountActions.packingKeyDecryptedFromStorage
                , AccountActions.reloadAccountSettings
            ),
            exhaustMap(() => {
                return this.accountService.getSettingsData().pipe(
                    map((response) =>
                        AccountActions.settingsDataRetrieved({ response })
                    ),
                    catchError((error) => of(AccountActions.settingsDataRetrievalError({ error })))
                );
            })
        )
    );

    /**
            * Loads the subscription summary from the server
            */
    readonly loadSubscriptionSummary$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                LoginActions.packingKeyPageOpened,
                AccountActions.packingKeyDecryptedFromStorage,
                OrganizationActions.invitationsSent,
                OrganizationActions.resendInvitationsSuccess,
                OrganizationActions.cancelInvitationsSuccess,
                OrganizationActions.removeOrganizationLicensesSuccess,
                OrganizationActions.removeVerifiedDomainAccountSuccess,
                OrganizationActions.purchasedAdditionalLicenses,
                AccountActions.updatedSubscriptionRenewalSetting,
                AccountActions.subscriptionRenewed,
                AccountActions.beginBusinessTrial,
                AccountActions.businessTrialCancelled,
                AccountActions.subscriptionUpgradedToBusinessPlan,
            ),
            exhaustMap(() => {
                return this.accountService.getSubscriptionSummary().pipe(
                    map((response) =>
                        AccountActions.subscriptionSummaryDataRetrieved({ response }),
                    ),
                    catchError((error) => of(AccountActions.subscriptionSummaryDataRetrievalError({ error })))
                );
            })
        )
    );

    /**
            * Extracts subscription information
            */
    readonly extractSubscriptionInformation$ = createEffect(
        () => this.actions$.pipe(
            ofType(AccountActions.subscriptionSummaryDataRetrieved),
            tap((action) => this.accountService.processSubscriptionSummary(action.response)),
        ), { dispatch: false }
    );

    /**
            * Unlocks the packing key
            */
    readonly unlockPackingKey$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                AccountActions.unlockPackingKey,
                AccountActions.packingKeyDecryptedFromStorage
            ),
            tap((action) => this.accountService.unlockPackingKey(action.packingKey)),
        ), { dispatch: false }
    );





}
