
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const LabelActions = createActionGroup({
    source: 'Label',
    events: {
        // Page opens


        // Other

        'Delete Label': props<{ labelId: number }>(),
        'Bulk Delete Labels': props<{ labelIds: number[] }>(),
        'Label Deleted': emptyProps(),
        'New Label Created': emptyProps(),

    },
});