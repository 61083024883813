import { Injectable } from '@angular/core';
import { InMemoryPassword } from '@app/model/app/password/InMemoryPassword';
import { Password } from '@app/model/app/password/Password';
import { AesDecrypt2 } from './AesDecrypt2';
import { EncryptedPasswordContents } from '@app/model/app/password/EncryptedPasswordContents';
import { PasswordStats } from '@app/model/app/password/PasswordStats';
import { BlowfishService } from './BlowfishService';
import { TeamKeys } from '@app/model/app/teams/TeamKeys';
import { DeletedPassword } from '@app/model/app/password/DeletedPassword';
import { InMemoryDeletedPassword } from '@app/model/app/password/InMemoryDeletedPassword';
import { PasswordVersionsEnum } from '@app/model/app/password/PasswordVersionsEnum';
import { PasswordVaultData } from '@app/model/app/passwordVault/PasswordVaultData';
import { DecryptionResult } from '@app/model/app/encryption/DecryptionResult';
import { ArchivedPassword } from '@app/model/app/password/ArchivedPassword';
import { InMemoryArchivedPassword } from '@app/model/app/password/InMemoryArchivedPassword';

@Injectable({ providedIn: 'root' })
export class PasswordDecryption {
    consoleLog: boolean = false;

    constructor(
        private aesDecrypt: AesDecrypt2,
        private blowfishService: BlowfishService,
    ) {
    }


    async decryptArchivedPassword(
        indexDbPassword: ArchivedPassword,
        vaultDataMap: Map<string, PasswordVaultData>)
        : Promise<InMemoryArchivedPassword> {
        let password: InMemoryArchivedPassword = new InMemoryArchivedPassword();
        let decryptedFromVault: boolean = false;
        let passwordDecryptionKey: DecryptionResult | null = null;

        // If this is a vault password and we have the vault key then use it to get to the key
        if (indexDbPassword.vault && vaultDataMap.has(indexDbPassword.vault)) {
            let vaultData = vaultDataMap.get(indexDbPassword.vault);
            if (vaultData) {
                if (this.consoleLog) console.log(vaultData);

                passwordDecryptionKey = await this.aesDecrypt.aesDecrypt(indexDbPassword.key, vaultData.dataKey);
                if (passwordDecryptionKey && passwordDecryptionKey.success) decryptedFromVault = true;
                if (this.consoleLog) console.log("Password " + indexDbPassword.id + " decrypted from vault: " + decryptedFromVault + " decryption:", passwordDecryptionKey);
            }
        }
        if (this.consoleLog) console.log("Password " + indexDbPassword.id + " decrypted from vault: " + decryptedFromVault + " decryption:")



        var promise = new Promise<InMemoryArchivedPassword>((resolve, reject) => {
            // if (teamMetaKeys && teamMetaKeys.groupKey && teamMetaKeys.groupKey.length > 0) {

            //     this.aesDecrypt.aesDecrypt(indexDbPassword.key, teamMetaKeys.groupKey).then((passwordDecryptionResultKey) => {
            // console.log("password: " + indexDbPassword.id + " " + passwordDecryptionResultKey.success + " " + passwordDecryptionResultKey.data);
            if (null != passwordDecryptionKey) {
                this.aesDecrypt.aesDecrypt(indexDbPassword.data, passwordDecryptionKey.data).then((passwordDecryptionResultData) => {

                    // Get the password data
                    if (!passwordDecryptionResultData.success) {
                        console.error(" error decrypting password data: " + indexDbPassword.id + ", error: " + passwordDecryptionResultData.error);
                        reject(password);
                    } else {
                        let passwordData: EncryptedPasswordContents = new EncryptedPasswordContents();
                        try {
                            passwordData = passwordDecryptionResultData.data as unknown as EncryptedPasswordContents;
                        } catch (e) {
                            console.error("error converting to json: " + e);
                        }
                        if (typeof passwordDecryptionResultData.data === 'string') {
                            if (this.consoleLog) console.log("converting to json");
                            passwordData = JSON.parse(passwordDecryptionResultData.data) as EncryptedPasswordContents;
                        }
                        // console.log("password data: ", passwordData);
                        password.id = indexDbPassword.id;
                        password.key = (null != passwordDecryptionKey) ? passwordDecryptionKey.data : '';
                        password.username = passwordData.username;
                        password.metadataKey = "";

                        // Get the name and url
                        password.name = passwordData.name;
                        password.url = passwordData.url;

                        resolve(password);

                    }
                });
            } else {
                console.error(" error decrypting password key: " + indexDbPassword.id);
                reject(password);
            }
        })

        //     } else {
        //         if (this.consoleLog) console.log("no team meta keys found for keygroup: ");
        //         reject(password);
        //     }
        // });
        return promise;

    }

    async decryptDeletedPassword(
        indexDbPassword: DeletedPassword,
        teamMetaKeys: TeamKeys | undefined,
        vaultDataMap: Map<string, PasswordVaultData>)
        : Promise<InMemoryDeletedPassword> {
        let password: InMemoryDeletedPassword = new InMemoryDeletedPassword();
        let decryptedFromVault: boolean = false;
        let passwordDecryptionKey: DecryptionResult | null = null;

        // If this is a vault password and we have the vault key then use it to get to the key
        if (indexDbPassword.vault && vaultDataMap.has(indexDbPassword.vault)) {
            let vaultData = vaultDataMap.get(indexDbPassword.vault);
            if (vaultData) {
                if (this.consoleLog) console.log(vaultData);

                passwordDecryptionKey = await this.aesDecrypt.aesDecrypt(indexDbPassword.key, vaultData.dataKey);
                if (passwordDecryptionKey && passwordDecryptionKey.success) decryptedFromVault = true;
                if (this.consoleLog) console.log("Password " + indexDbPassword.id + " decrypted from vault: " + decryptedFromVault + " decryption:", passwordDecryptionKey);
            }
        }
        if (this.consoleLog) console.log("Password " + indexDbPassword.id + " decrypted from vault: " + decryptedFromVault + " decryption:", teamMetaKeys)

        // Otherwise, use the team key
        if ((null == passwordDecryptionKey || !passwordDecryptionKey?.success) && teamMetaKeys && teamMetaKeys.groupKey && teamMetaKeys.groupKey.length > 0) {
            let decryptionResult = await this.aesDecrypt.aesDecrypt(indexDbPassword.key, teamMetaKeys.groupKey);
            if (decryptionResult.success) {
                passwordDecryptionKey = decryptionResult;
            }
        }








        var promise = new Promise<InMemoryDeletedPassword>((resolve, reject) => {
            // if (teamMetaKeys && teamMetaKeys.groupKey && teamMetaKeys.groupKey.length > 0) {

            //     this.aesDecrypt.aesDecrypt(indexDbPassword.key, teamMetaKeys.groupKey).then((passwordDecryptionResultKey) => {
            // console.log("password: " + indexDbPassword.id + " " + passwordDecryptionResultKey.success + " " + passwordDecryptionResultKey.data);
            if (null != passwordDecryptionKey) {
                this.aesDecrypt.aesDecrypt(indexDbPassword.data, passwordDecryptionKey.data).then((passwordDecryptionResultData) => {
                    // Define the meta key
                    let metadataKey = teamMetaKeys?.metadataKey;

                    // Get the password data
                    if (!passwordDecryptionResultData.success) {
                        console.error(" error decrypting password data: " + indexDbPassword.id + ", error: " + passwordDecryptionResultData.error);
                        reject(password);
                    } else {
                        let passwordData: EncryptedPasswordContents = new EncryptedPasswordContents();
                        try {
                            passwordData = passwordDecryptionResultData.data as unknown as EncryptedPasswordContents;
                        } catch (e) {
                            console.error("error converting to json: " + e);
                        }
                        if (typeof passwordDecryptionResultData.data === 'string') {
                            if (this.consoleLog) console.log("converting to json");
                            passwordData = JSON.parse(passwordDecryptionResultData.data) as EncryptedPasswordContents;
                        }
                        // console.log("password data: ", passwordData);
                        password.id = indexDbPassword.id;
                        password.passwordId = indexDbPassword.passwordId;
                        password.key = (null != passwordDecryptionKey) ? passwordDecryptionKey.data : '';
                        password.metadataKey = metadataKey ? metadataKey : "";
                        password.username = passwordData.username;

                        // Get the name and url

                        if (indexDbPassword.version === PasswordVersionsEnum.V1 && metadataKey) {
                            // console.log("metadatakey: " + metadataKey);
                            // console.log("record.name: " + indexDbPassword.name);
                            password.name = this.blowfishService.decrypt(indexDbPassword.name, metadataKey, indexDbPassword.id + "");
                            password.url = this.blowfishService.decrypt(indexDbPassword.url, metadataKey, indexDbPassword.id + "");
                        } else {
                            password.name = passwordData.name;
                            password.url = passwordData.url;
                        }
                        resolve(password);

                    }
                });
            } else {
                console.error(" error decrypting password key: " + indexDbPassword.id);
                reject(password);
            }
        })

        //     } else {
        //         if (this.consoleLog) console.log("no team meta keys found for keygroup: ");
        //         reject(password);
        //     }
        // });
        return promise;

    }

    async decryptPassword(
        indexDbPassword: Password,
        teamMetaKeys: TeamKeys | undefined,
        vaultDataMap: Map<string, PasswordVaultData>
    ): Promise<InMemoryPassword> {
        const startExecution = performance.now();
        if (this.consoleLog) console.log("starting decrypt: " + indexDbPassword.id);
        let password: InMemoryPassword = new InMemoryPassword();
        let decryptedFromVault: boolean = false;
        let passwordDecryptionKey: DecryptionResult | null = null;

        // If this is a vault password and we have the vault key then use it to get to the key
        if (indexDbPassword.vault && vaultDataMap.has(indexDbPassword.vault)) {
            let vaultData = vaultDataMap.get(indexDbPassword.vault);
            if (vaultData) {
                if (this.consoleLog) console.log(vaultData);

                passwordDecryptionKey = await this.aesDecrypt.aesDecrypt(indexDbPassword.key, vaultData.dataKey);
                if (passwordDecryptionKey && passwordDecryptionKey.success) decryptedFromVault = true;
                if (this.consoleLog) console.log("Password " + indexDbPassword.id + " decrypted from vault: " + decryptedFromVault + " decryption:", passwordDecryptionKey);
            }
        }
        if (this.consoleLog) console.log("Password " + indexDbPassword.id + " decrypted from vault: " + decryptedFromVault + " decryption:", teamMetaKeys)

        // Otherwise, use the team key
        if ((null == passwordDecryptionKey || !passwordDecryptionKey?.success) && teamMetaKeys && teamMetaKeys.groupKey && teamMetaKeys.groupKey.length > 0) {
            let decryptionResult = await this.aesDecrypt.aesDecrypt(indexDbPassword.key, teamMetaKeys.groupKey);
            if (decryptionResult.success) {
                passwordDecryptionKey = decryptionResult;
            }
        }

        var promise = new Promise<InMemoryPassword>((resolve, reject) => {
            if (null != passwordDecryptionKey) {

                if (this.consoleLog) console.log("password: " + indexDbPassword.id);
                // this.aesDecrypt.aesDecrypt(indexDbPassword.key, teamMetaKeys.groupKey).then((passwordDecryptionResultKey) => {
                if (this.consoleLog) console.log("password: " + indexDbPassword.id + " " + passwordDecryptionKey.success + " " + passwordDecryptionKey.data);
                // if (passwordDecryptionResultKey.success) {
                this.aesDecrypt.aesDecrypt(indexDbPassword.data, passwordDecryptionKey.data).then((passwordDecryptionResultData) => {
                    // Define the meta key
                    let metadataKey = teamMetaKeys?.metadataKey;

                    // Get the password data
                    if (!passwordDecryptionResultData.success) {
                        console.error(" error decrypting password data: " + indexDbPassword.id + ", error: " + passwordDecryptionResultData.error);
                        reject(password);
                    } else {
                        let passwordData: EncryptedPasswordContents = new EncryptedPasswordContents();
                        try {
                            passwordData = passwordDecryptionResultData.data as unknown as EncryptedPasswordContents;
                        } catch (e) {
                            console.error("error converting to json: " + e);
                        }
                        if (typeof passwordDecryptionResultData.data === 'string') {
                            if (this.consoleLog) console.log("result: " + passwordDecryptionResultData.data);
                            if (this.consoleLog) console.log(JSON.stringify(passwordDecryptionResultData));
                            passwordData = JSON.parse(passwordDecryptionResultData.data) as EncryptedPasswordContents;
                        }
                        // console.log("password data: ", passwordData);
                        password.id = indexDbPassword.id;
                        password.email = passwordData.email;
                        password.key = passwordDecryptionKey ? passwordDecryptionKey.data : '';
                        password.metadataKey = metadataKey ? metadataKey : "";
                        password.password = passwordData.password;
                        password.sharedNotes = passwordData.sharedNotes;
                        password.username = passwordData.username;
                        password.createdDate = indexDbPassword.createdDate;
                        password.actualCreatedDate = (new Date(indexDbPassword.createdDate.replace(/([.])\w+/, ""))).getTime();
                        password.favorite = indexDbPassword.favorite ? 1 : 0;
                        password.keyGroup = indexDbPassword.keyGroup;
                        password.lastUpdate = indexDbPassword.lastUpdate;
                        password.actualLastUpdate = (new Date(indexDbPassword.lastUpdate.replace(/([.])\w+/, ""))).getTime();
                        password.owner = indexDbPassword.owner ? 1 : 0;
                        password.passwordLastUpdate = indexDbPassword.passwordLastUpdate;
                        password.actualPasswordLastUpdate = (new Date(indexDbPassword.passwordLastUpdate.replace(/([.])\w+/, ""))).getTime();
                        password.stats = indexDbPassword.stats;
                        password.version = indexDbPassword.version;
                        password.extra = passwordData.extra;
                        password.history = passwordData.history;
                        password.vault = indexDbPassword.vault;
                        password.decryptedFromVault = decryptedFromVault;

                        // Stats
                        try {
                            let stats: PasswordStats = new PasswordStats();
                            stats = JSON.parse(indexDbPassword.stats) as PasswordStats;
                            password.score = stats.password.score;
                            if (undefined === password.score) password.score = 0;
                        } catch {
                            console.error("error converting password stats on password: " + password.id + ". setting default to 0");
                            password.score = 0;
                        }

                        // Get the name, url, and , in the future, other data which is stored in different places based on the version
                        try {
                            // Get the name and url
                            if (password.version === 2) {
                                password.name = passwordData.name;
                                password.url = passwordData.url;
                            } else if (metadataKey) {
                                try {
                                    // console.log("metadatakey: " + metadataKey);
                                    // console.log("record.name: " + indexDbPassword.name);

                                    // var bf = new Blowfish(metadataKey);
                                    // var decryptedName = bf.decode(base64_to_bytes(indexDbPassword.name));
                                    // console.log("record name: " + decryptedName);
                                    // password.name = decryptedName.toString();
                                    password.name = this.blowfishService.decrypt(indexDbPassword.name, metadataKey, indexDbPassword.id + "");

                                    // var decryptedUrl = bf.decode(base64_to_bytes(indexDbPassword.url));
                                    // console.log("record url: " + decryptedUrl);
                                    // password.url = decryptedUrl.toString();
                                    password.url = this.blowfishService.decrypt(indexDbPassword.url, metadataKey, indexDbPassword.id + "");
                                } catch (e) {
                                    console.error("error decrypting password: " + indexDbPassword.id + ", error: " + e);
                                    password.name = "Please contact customer support code: namedecrypt, id: " + indexDbPassword.id;
                                }
                            } else {
                                password.name = "Please contact customer support code: mdmissing, id: " + indexDbPassword.id;
                            }
                        } catch (e) {
                            console.error("error decrypting password: " + indexDbPassword.id + ", error: " + e);
                        }


                        // get the password notes
                        if (indexDbPassword.notes) {
                            try {

                                this.aesDecrypt.aesDecrypt(indexDbPassword.notes, password.key).then((passwordDecryptionResultNotes) => {
                                    if (!passwordDecryptionResultNotes.success) {
                                        console.log(" error decrypting password notes: " + indexDbPassword.id + ", error: " + passwordDecryptionResultNotes.error);
                                        // reject(password);
                                        // TODO swallow
                                        resolve(password);
                                    } else {
                                        // console.log("password notes: " + passwordDecryptionResultNotes.data + ", orig: " + indexDbPassword.notes);
                                        password.notes = passwordDecryptionResultNotes.data;
                                        // console.log("Done: resolving with : " + JSON.stringify(password));
                                        const end = performance.now();
                                        const time = end - startExecution;
                                        if (this.consoleLog) console.log("Execution time: " + time);
                                        if (this.consoleLog) console.log("ending decrypt: " + indexDbPassword.id);

                                        resolve(password);
                                    }
                                }).catch((error) => {
                                    console.log("error decrypting password: " + indexDbPassword.id + ", error: " + error);
                                    // reject(password);
                                    // TODO - swallow now
                                    resolve(password);
                                });
                            } catch (e) {
                                console.error("error decrypting notes: " + indexDbPassword.id + ", error: " + e);
                                //reject(password);
                                // TODO - nothing
                                resolve(password);
                            }

                        } else {
                            // console.log("resolving without notes");
                            password.notes = "";
                            const end = performance.now();
                            const time = end - startExecution;
                            if (this.consoleLog) console.log("Execution time: " + time);
                            if (this.consoleLog) console.log("ending decrypt: " + indexDbPassword.id);

                            resolve(password);
                        }
                    }
                }).catch((error) => {
                    console.error("error decrypting password: " + indexDbPassword.id + ", error: " + error);
                    reject(password);
                });
                // } else {
                //     console.error(" error decrypting password key: " + indexDbPassword.id + ", error: " + passwordDecryptionResultKey.error);
                //     reject(password);
                // }
                // }).catch((error) => {
                //     console.error("error decrypting password: " + indexDbPassword.id + ", error: " + error);
                //     reject(password);
                // })

            } else {
                console.error("no team meta keys found for keygroup: " + indexDbPassword.keyGroup);
                reject(password);
            }
        });
        return promise;

    }



}