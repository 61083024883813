import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppDBService } from './AppDbService';
import { StorageService } from './storage.service';
import { StorageActions } from '@app/store/actions/StorageActions';
import { Database } from 'sql.js';
import { SyncStatus } from '@app/model/app/database/SyncStatus';
import { PasswordFeedResponse } from '@app/model/api/dataFeed/PasswordFeedResponse';
import { Password } from '@app/model/app/password/Password';
import { PasswordDecryption } from '../encryption/PasswordDecryption';
import { AccountKeysService } from '../account/AccountKeysService';
import { TeamKeyService } from '../cache/team-key-service.service';
import { TeamActions } from '@app/store/actions/TeamActions';
import { PasswordDetailsService } from '../passwords/PasswordDetailsService';
import { from, mergeMap, Observable, of } from 'rxjs';
import { AccountService } from '../account/account.service';
import { PasswordVersionsEnum } from '@app/model/app/password/PasswordVersionsEnum';
import { PasswordUpdateService } from '../passwords/PasswordUpdateService';
import { PasswordVaultService } from '../passwordVault/password-vault-service.service';

@Injectable({
    providedIn: 'root'
})
export class PasswordFeedStorageService implements OnInit, OnDestroy {


    private consoleLog: boolean = false;
    inprogressDecryptPasswordIds: Set<number> = new Set<number>();

    constructor(
        private store: Store,
        private storageService: StorageService,
        private teamKeyService: TeamKeyService,
        private passwordDecryption: PasswordDecryption,
        private accountKeysService: AccountKeysService,
        private passwordDetailsService: PasswordDetailsService,
        private accountService: AccountService,
        private passwordUpdateService: PasswordUpdateService,
        private passwordVaultService: PasswordVaultService
    ) { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    async addPasswordIdToInProgress(id: number) {
        this.inprogressDecryptPasswordIds.add(id);
    }
    async removePasswordIdFromInProgress(id: number) {
        this.inprogressDecryptPasswordIds.delete(id);
    }

    /**
       * Process the password  feed and store the lastupdate
       * @param data 
       */
    processPasswordFeed(data: PasswordFeedResponse) {
        if (data && data.passwords && data.passwords.length > 0) {
            let toAdd: Password[] = [];
            let toDelete: number[] = [];
            let start = new Date().getTime();


            data.passwords.forEach(password => {
                switch (password.action) {
                    case 1:
                    // add
                    case 2:
                        // update
                        // add or update should upsert
                        let p2 = new Password(password);
                        // explicitely set the sync flag
                        p2.syncedInMemory = SyncStatus.SYNC_STATUS_NOT_SYNCED;
                        toAdd.push(p2);
                        break;
                    case 3:
                        // delete
                        toDelete.push(password.id);
                        break;
                }
            });

            ////////////////////////////////////////////
            // Send to the indexeddb
            ////////////////////////////////////////////
            // Adds or updates
            this.storageService.appDbService?.passwords.bulkPut(toAdd).then() // handle success
                .catch(function (error) { console.log(error); }); // handle failure

            // Deletes
            this.storageService.appDbService?.passwords.bulkDelete(toDelete).then() // handle success
                .catch(function (error) { console.log(error); }); // handle failure

            ////////////////////////////////////////////
            // Send to in memory store
            ////////////////////////////////////////////
            let database = this.storageService.inMemorySQLService?.getDatabase();
            if (database) {
                // Adds
                // done elsewhere
                //this.processInMemorySQLAdds(database, toAdd);

                // Deletes
                this.processInMemorySQLDeletes(database, toDelete);

                // Dump counts to refresh displays
                //this.dumpTables(database);
                this.dumpCounts(database);
            }

            let end = new Date().getTime();
            let adds = toAdd.length;
            let deletes = toDelete.length;
            if (this.consoleLog) console.log("Time to process password feed: " + (end - start) + " ms " + " adds: " + adds + " deletes: " + deletes);

            ////////////////////////////////////////////
            // Notify the store that we are done loading
            // this.store.dispatch(StorageActions.passwordFeedStored());
            // Fire an event here to indicate that the data has changed
            if (this.consoleLog) console.log("pfs triggering local data changed");
            this.store.dispatch(StorageActions.localDataChanged());
        }
    }

    updateTableCounts() {
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) this.dumpCounts(database);
    }


    /**
     * Helper method to dump the counts of rows
     * @param database 
     */
    private dumpCounts(database: Database) {
        let self = this;
        database.each("SELECT count(1) as count FROM passwords;", {},
            function (row) {
                if (self.consoleLog) console.log("passwords rows-> " + row.count);
                self.store.dispatch(StorageActions.updateLoadedPasswordCount({ data: Number(row.count?.toString()) }));
            },
            () => void (0)
        );

        let db = this.storageService.appDbService;
        if (db) {
            let results = db.passwords.count();
            results.then((count) => {
                self.store.dispatch(StorageActions.updatePreloadPasswordCount({ data: Number(count?.toString()) }));
            });
        }


    }

    /**
     * Helper method to dump the contents
     * @param database 
     */
    dumpTables(database: Database) {
        let counter = 0;
        let self = this;
        // database.each("SELECT teamId, passwordId FROM teams2passwords;", {},
        //     function (row) {
        //         console.log("data Table teams2passwords -> " + row.teamId + " " + row.passwordId + " " + ++counter);
        //     },
        //     () => void (0)
        // );

        // console.log("dumping passwords---------------------------------------------------------------");
        // database.each("SELECT * FROM passwords;", {},
        //     function (row) {
        //         console.log("data Table passwords -> " + JSON.stringify(row) + " " + ++counter);
        //     },
        //     () => void (0)
        // );

        // console.log("dumping extra---------------------------------------------------------------");
        // database.each("SELECT * FROM passwordExtrafields;", {},
        //     function (row) {
        //         console.log("data Table passwordExtrafields -> " + JSON.stringify(row) + " " + ++counter);
        //     },
        //     () => void (0)
        // );

        if (this.consoleLog) console.log("dumping history---------------------------------------------------------------");
        database.each("SELECT * FROM passwordHistory;", {},
            function (row) {
                if (self.consoleLog) console.log("data Table passwordHistory -> " + JSON.stringify(row) + " " + ++counter);
            },
            () => void (0)
        );
    }

    dumpTables2() {
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) this.dumpTables(database);
    }

    /**
     *  Removes the entries from the in memory database.
     * @param database 
     * @param toDelete 
     */
    private processInMemorySQLDeletes(database: Database, toDelete: number[]) {
        if (null != toDelete && toDelete.length > 0) {
            ////////////////////////////////////////////////
            // Handle the team2passwords table
            const deletePasswordsSQL = `DELETE FROM passwords where id = :id`;
            const deleteLabel2PasswordsSQL = `DELETE FROM labels2passwords where passwordId = :id`;
            const deleteTeams2PasswordsSQL = `DELETE FROM teams2passwords where passwordId = :id`;
            const deleteExtraFieldsSQL = `DELETE FROM passwordExtrafields where passwordId = :id`;
            const deleteHistorySQL = `DELETE FROM passwordHistory where passwordId = :id`;

            const stmtPasswords = database.prepare(deletePasswordsSQL);
            const stmtLabel = database.prepare(deleteLabel2PasswordsSQL);
            const stmtTeams = database.prepare(deleteTeams2PasswordsSQL);
            const stmtExtra = database.prepare(deleteExtraFieldsSQL);
            const stmtHistory = database.prepare(deleteHistorySQL);

            toDelete.forEach((id) => {
                stmtPasswords.run({ ':id': id });
                stmtLabel.run({ ':id': id });
                stmtTeams.run({ ':id': id });
                stmtExtra.run({ ':id': id });
                stmtHistory.run({ ':id': id });

            });

            stmtPasswords.free();
            stmtLabel.free();
            stmtTeams.free();
            stmtExtra.free();
            stmtHistory.free();

            if (this.consoleLog) console.log("Remove search docs");
        }
    }

    /**
     * This will clean up the secondary tables for the updated password. extra, history, etc.
     * @param pid 
     */
    cleanSecondaryTablesForUpdatedPassword(database: Database | undefined, pid: number) {
        if (database) {
            const deleteExtraFieldsSQL = `DELETE FROM passwordExtrafields where passwordId = :id`;
            const deleteHistorySQL = `DELETE FROM passwordHistory where passwordId = :id`;
            const stmtExtra = database.prepare(deleteExtraFieldsSQL);
            const stmtHistory = database.prepare(deleteHistorySQL);
            stmtExtra.run({ ':id': pid });
            stmtHistory.run({ ':id': pid });
            stmtExtra.free();
            stmtHistory.free();
        }
    }



    /**
     * Finds the unsynced records in the index db and syncs them to the in memory database.
     * @param favoriesOnly
     */
    loadUnsyncedPasswordData(favoriesOnly: boolean): Promise<number> {
        var promise = new Promise<number>((resolve, reject) => {
            try {
                if (this.consoleLog)
                    console.log("loadUnsyncedPasswordData");


                // Load the account keys
                // console.log("accountKeys ", this.accountKeysData);
                let accountKeysData = this.accountKeysService.getAccountKeysData();
                if (accountKeysData && accountKeysData.packingKeyDecryptedPrivateKey && accountKeysData.packingKeyDecryptedPrivateKey.length > 0) {
                    if (this.storageService.appDbService) {
                        let db = this.storageService.appDbService;

                        // // Strategy is to load favorites first then others
                        // if (!this.storageService.passwordFavoritesLoaded) {
                        //     if (this.consoleLog) console.log("FAVORITES ONLY");
                        //     let results: PromiseExtended<Password[]>;
                        //     results = db.passwords.where('syncedInMemory').equals(SyncStatus.SYNC_STATUS_NOT_SYNCED).and(pass => pass.favorite === true).toArray();
                        //     results.then((records) => {
                        //         if (records.length > 0) {
                        //             this.processUnsyncedPassword(db, records);
                        //         }
                        //     }
                        //     );

                        //     // only set this flag if there is something in the database.. This will ensure that we always try to load the favorites first.
                        //     // if (this.lastInMemoryPasswordCount > 0) {
                        //     this.storageService.passwordFavoritesLoaded = true;
                        //     // }
                        // }

                        // After the favorites are loaded, load the rest.  This is the regular method of processing updates.
                        //let results2: PromiseExtended<Password[]>;
                        db.passwords.where('syncedInMemory').equals(SyncStatus.SYNC_STATUS_NOT_SYNCED).sortBy('favorite').then((records) => {
                            if (records.length > 0) {
                                // Experiment - load a subset to see if we have faster loads so the user can start using the system.
                                // take 50 records then process 
                                let recordsToProcess = 100;
                                let recordsProcessed = 0;
                                let mark = new Date().getTime();
                                while (recordsProcessed < records.length) {
                                    let subsetRecords = records.slice(recordsProcessed, recordsProcessed + recordsToProcess);
                                    let immediateSyncAtEndOfAdd = ((recordsProcessed + recordsToProcess) >= records.length);
                                    this.processUnsyncedPasswords(db, subsetRecords, immediateSyncAtEndOfAdd)
                                    // .then(() => {
                                    //     if (this.consoleLog) console.log("subset processed");
                                    // });
                                    // log the elapsed
                                    let elapsed = new Date().getTime() - mark;
                                    if (this.consoleLog) console.log("elapsed: ", elapsed);
                                    recordsProcessed += recordsToProcess;
                                }
                                this.updateTableCounts();
                                // Tell the store we have new data.
                                // this.store.dispatch(StorageActions.updateLastPasswordChangeTimestamp());
                            }

                            // let the caller know we are done.
                            if (this.consoleLog) console.log("done sync");
                            resolve(1);
                        })

                    } else {
                        resolve(1);
                    }
                } else {
                    if (this.consoleLog) console.log("app keys not ready");
                    resolve(1);
                }
            } catch (error) {
                console.error("error loading unsynced password data: ", error);
                reject(0);
            }
        });
        return promise;
    }


    /**
     * When a password is transferred to a new team it is not added directly to the default team. This method will ensure that the password is added to the default team.
     * 
     * Returns the completed flag which indicates it ran.  Upon startup this will not always run because the teams have not fully decrypted and loaded so the default team is undefined.
     */
    ensureOwnedPasswordsAreInDefaultGroup(): boolean {
        let completed: boolean = false;
        if (this.consoleLog) console.log("ensureOwnedPasswordsAreInDefaultGroup");
        let myDefaultGroup = this.passwordDetailsService.retrieveMyDefaultGroup();
        let defaultTeamId = myDefaultGroup?.id;
        try {
            // Ensure this password is in the default team if owned.

            const defaultTeamOwnershipCheckSQL = `
                    SELECT passwords.id from passwords WHERE passwords.owner=1 AND passwords.id NOT IN (
                            SELECT passwords.id FROM passwords
                                    LEFT JOIN teams2passwords ON
                                        passwords.id = teams2passwords.passwordId
                                        WHERE
                                            teams2passwords.teamId =:teamId and
                                            passwords.owner = 1
                        )
                                    `;
            if (this.consoleLog) {
                console.log(defaultTeamOwnershipCheckSQL);
                console.log("defaultTeamId: ", defaultTeamId);
            }
            const defaultTeamOwnershipCheckStmt = this.storageService.inMemorySQLService?.getDatabase().prepare(defaultTeamOwnershipCheckSQL);
            if (defaultTeamOwnershipCheckStmt && defaultTeamId) {
                defaultTeamOwnershipCheckStmt.bind({ ':teamId': defaultTeamId });
                //create a map indicating which passwords have been added.
                let addedPasswords = new Map<number, boolean>();
                let adds: number[] = [];
                let removes: number[] = [];

                while (defaultTeamOwnershipCheckStmt.step()) {
                    let result = defaultTeamOwnershipCheckStmt.getAsObject();
                    let pid = result.id as number;
                    let check = addedPasswords.get(pid);
                    if (check && check === true) {
                        // already added
                    } else {
                        if (this.consoleLog) console.log("NEED add password  ", pid, " to default group");
                        adds.push(pid);
                        addedPasswords.set(pid, true);
                    }
                }
                defaultTeamOwnershipCheckStmt?.free();

                if (myDefaultGroup && myDefaultGroup.id && adds.length > 0) {
                    this.store.dispatch(TeamActions.updatePasswordMembershipInTeam({ groupId: myDefaultGroup.id, adds: adds, removes: removes }));
                }

                completed = true;

            }
        } catch (error) {
            console.error("error ensuring owned passwords are in default group: ",
                error);
        }
        return completed;
    }

    /**
     * Processes the unsynced password group. Decrypts and loads into the in mem db.
     * @param db 
     * @param record 
     * @param decryptedPrivateKey 
     */
    processUnsyncedPasswords(db: AppDBService, records: Password[], immediateSyncAtEndOfAdd: boolean): Promise<number> {
        let vaultMap = this.passwordVaultService.generateIdToVaultsWithEncryptionKeysForCurrentVaults();
        var promise = new Promise<number>((resolve, reject) => {

            let recordsToProcess = records.length;
            let recordsProcessed = 0;
            // let passwordsNeedingV4NotesUpgrade: number[] = [];

            // Handle the passwords table
            let MAX_CONCURRENT = 20;
            // records.forEach((record) => {
            from(records).pipe(
                mergeMap((record: Password): Observable<any> => {

                    // // Test if the user has notes which need conversino after being added to the system.
                    // try {
                    //     if (record.notes && record.notes.startsWith("4#")) {
                    //         if (this.consoleLog) console.log("v4notes: ", record.id);
                    //         passwordsNeedingV4NotesUpgrade.push(record.id);
                    //     }
                    // } catch (error) {
                    //     console.log("error", error);
                    // }

                    if (this.inprogressDecryptPasswordIds.has(record.id)) {
                        console.error("Already decrypting password: " + record.id);
                    }
                    this.addPasswordIdToInProgress(record.id);


                    // console.log("Loading password record: " + record.id);
                    let teamMetaKeys = this.teamKeyService.getTeamMetaKeys(record.keyGroup);
                    let hasVaultKey = record.vault && record.vault.length > 0 && vaultMap.has(record.vault);
                    if (hasVaultKey || (teamMetaKeys && teamMetaKeys.groupKey && teamMetaKeys.groupKey.length > 0)) {
                        try {
                            this.passwordDecryption.decryptPassword(record, teamMetaKeys, vaultMap).then((password) => {
                                try {

                                    if (this.consoleLog) console.log("password", password);

                                    // Clean up the secondary tables first
                                    this.cleanSecondaryTablesForUpdatedPassword(this.storageService.inMemorySQLService?.getDatabase(), password.id);
                                    // Insert the data

                                    const sql = `INSERT OR REPLACE INTO passwords (id, email,name,key,metadataKey,notes,password,sharedNotes,url,username,createdDate,actualCreatedDate,\
                    favorite,keyGroup,lastUpdate,actualLastUpdate,owner,passwordLastUpdate,actualPasswordLastUpdate,stats,score,version, vault, decryptedFromVault) \
                VALUES (:id, :email,:name,:key,:metadataKey,:notes,:password,:sharedNotes,:url,:username,:createdDate,:actualCreatedDate,\
                    :favorite,:keyGroup,:lastUpdate,:actualLastUpdate,:owner,:passwordLastUpdate,:actualPasswordLastUpdate,:stats,:score,:version,:vault, :decryptedFromVault)`;

                                    const stmt = this.storageService.inMemorySQLService?.getDatabase().prepare(sql);
                                    // console.log("Password:--------------> " + password.id + " " + JSON.stringify(password.extra) + " " + JSON.stringify(password.history));
                                    stmt?.run({
                                        ':id': this.checkUndefined(password.id), ':email': this.checkUndefined(password.email), ':name': this.checkUndefined(password.name), ':key': this.checkUndefined(password.key),
                                        ':metadataKey': this.checkUndefined(password.metadataKey), ':notes': this.checkUndefined(password.notes),
                                        ':password': this.checkUndefined(password.password), ':sharedNotes': this.checkUndefined(password.sharedNotes), ':url': this.checkUndefined(password.url),
                                        ':username': this.checkUndefined(password.username),
                                        ':createdDate': this.checkUndefined(password.createdDate), ':actualCreatedDate': this.checkUndefined(password.actualCreatedDate),
                                        ':favorite': this.checkUndefined(password.favorite),
                                        ':keyGroup': this.checkUndefined(password.keyGroup), ':lastUpdate': this.checkUndefined(password.lastUpdate), ':actualLastUpdate': this.checkUndefined(password.actualLastUpdate),
                                        ':owner': this.checkUndefined(password.owner), ':passwordLastUpdate': this.checkUndefined(password.passwordLastUpdate),
                                        ':actualPasswordLastUpdate': this.checkUndefined(password.actualPasswordLastUpdate), ':stats': this.checkUndefined(password.stats), ':score': this.checkUndefined(password.score),
                                        ':version': this.checkUndefined(password.version), ':vault': record.vault, ':decryptedFromVault': password.decryptedFromVault ? 1 : 0
                                    });
                                    stmt?.free();

                                    // Handle the extra fields
                                    if (password.extra && password.extra.length > 0) {
                                        const extraSql = `INSERT OR REPLACE INTO passwordExtrafields (id,data,name,type, passwordId) VALUES (:id,:data,:name,:type, :passwordId)`;
                                        const extraStmt = this.storageService.inMemorySQLService?.getDatabase().prepare(extraSql);

                                        password.extra.forEach((extra) => {
                                            extraStmt?.run({
                                                ':id': this.checkUndefined(extra.id),
                                                ':data': this.checkUndefined(extra.data),
                                                ':name': this.checkUndefined(extra.name),
                                                ':type': this.checkUndefined(extra.type),
                                                ':passwordId': password.id
                                            });
                                        });
                                        extraStmt?.free();
                                    }

                                    // Handle the history
                                    if (password.history && password.history.length > 0) {

                                        const historySql = `INSERT OR REPLACE INTO passwordHistory (passwordId, username, password, email, sharedNotes, deleted, changedDate, changedByCurrentNickname, changedBy) \
                             VALUES (:passwordId,:username,:password,:email,:sharedNotes, :deleted,:changedDate,:changedByCurrentNickname,:changedBy)`;
                                        const historyStmt = this.storageService.inMemorySQLService?.getDatabase().prepare(historySql);
                                        password.history.forEach((history) => {
                                            historyStmt?.run({
                                                ':passwordId': this.checkUndefined(password.id),
                                                ':username': this.checkUndefined(history.username),
                                                ':password': this.checkUndefined(history.password),
                                                ':email': this.checkUndefined(history.email),
                                                ':sharedNotes': (history.sharedNotes?.length > 0) ? history.sharedNotes : "",
                                                ':deleted': this.checkUndefined(history.deleted),
                                                ':changedDate': this.checkUndefined(history.changedDate),
                                                ':changedByCurrentNickname': this.checkUndefined(history.changedByCurrentNickname),
                                                ':changedBy': this.checkUndefined(history.changedBy)
                                            });
                                        }
                                        );
                                        historyStmt?.free();
                                    }

                                    // update the indexdb to indicate it is now loaded
                                    db.transaction('rw', db.passwords, async () => {
                                        db.passwords.update(record.id, { syncedInMemory: SyncStatus.SYNC_STATUS_SYNCED_IN_MEM }).then(() => {
                                            // console.log("updated");
                                        }).catch((error) => { console.error(error); });
                                    });

                                    // Look to see if we need to convert this password to a new version
                                    if (this.consoleLog) console.log("Password: " + password.id + " owner: " + password.owner + " version: " + password.version + " me: " + this.accountService.getCustomerId());
                                    if (password.owner
                                        && password.version === PasswordVersionsEnum.V1) {
                                        if (this.consoleLog) console.log("NEED UPGRADE PASSWORD " + password.id);
                                        this.passwordUpdateService.convertVersion1PasswordToVersion2(password);
                                    }



                                    // If we have processed all the records, update the counts
                                    recordsProcessed++;
                                    if (immediateSyncAtEndOfAdd && recordsProcessed >= recordsToProcess) {
                                        this.updateTableCounts();
                                        this.store.dispatch(StorageActions.updateLastPasswordChangeTimestamp());
                                    }
                                    if (recordsProcessed >= recordsToProcess) {
                                        //this.updateTableCounts();
                                        // Tell the store we have new data.
                                        // TEMP DISABLE - doing in the method "loadUnsyncedPasswordData" this.store.dispatch(StorageActions.updateLastPasswordChangeTimestamp());
                                    }

                                } catch (error) {
                                    console.error("error with password: " + password.id, error);
                                }
                            }).catch((error) => {
                                console.error("error with password: " + record.id, error);
                            });
                        } catch (error) {
                            console.error("error with password: " + record.id, error);
                        }
                    } else {
                        console.warn("No team meta key: ", record.keyGroup);
                    }

                    this.removePasswordIdFromInProgress(record.id);

                    return of(record.id);
                }, MAX_CONCURRENT)
            ).subscribe(
                x => { if (this.consoleLog) console.log(x) }
            )
            // }); // End foreach

            // try {
            //     // Upgrade the notes if needed
            //     if (passwordsNeedingV4NotesUpgrade.length > 0) {
            //         if (this.consoleLog) console.log("upgrading notes: ", passwordsNeedingV4NotesUpgrade);
            //         // this.passwordDetailsService.upgradeV4Notes(passwordsNeedingV4NotesUpgrade);
            //     }
            // } catch (error) {
            //     console.error("error upgrading notes: ", error);
            // }

            resolve(1);
        });
        return promise;
    }


    /**
     * Checks the input for undefined and finds the appropriate default value. SQL cannot handle undefined.
     * @param input 
     * @returns 
     */
    checkUndefined(input: any): string | number {
        if (undefined == input) {
            if (typeof input === 'string') {
                return "UNDEFINED";
            } else return -999999990;
        } else {
            return input;
        }
    }

}

