import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectLoginPageModel } from '@app/store/selectors/LoginSelectors';
import { NavigationActions } from '@app/store/actions/NavigationActions';

@Injectable({ providedIn: 'root' })
export class AuthGuard {

    readonly vm$ = this.store.select(selectLoginPageModel);
    canViewAccount = false;
    isLocked = false;
    consoleLog: boolean = false;

    constructor(
        private router: Router,
        private store: Store,
    ) {
        this.vm$.subscribe(x => {
            this.canViewAccount = x.state.canViewAccount;
            this.isLocked = x.state.locked;
            if (this.consoleLog) console.log(x.state);
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let result = false;

        // See if the user is cleared to view account from the loginfunnelservice
        if (this.canViewAccount && !this.isLocked) {
            this.store.dispatch(NavigationActions.setLastAuthenticatedPageVisited({ page: state.url.toString() }));
            result = true;
        } else if (this.isLocked) {
            this.router.navigate(['/locked'], { queryParams: {} });
            result = false;
        } else {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login'], { queryParams: {} });
            result = false;
        }
        if (this.consoleLog) console.log('AuthGuard#canActivate called with state.url: '
            + state.url + " and route.url: " + route.url
            + " and this.canViewAccount: " + this.canViewAccount
            + " and this.isLocked: " + this.isLocked
            + " and result: " + result);

        return result;
    }

}