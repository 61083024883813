import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { StorageActions } from "../actions/StorageActions";
import { DataFeedService } from "@app/services/DataFeedService";
import { StorageService } from "@app/services/dataStorage/storage.service";
import { PasswordGroupFeedStorageService } from "@app/services/dataStorage/PasswordGroupFeedStorageService";
import { AccountActions } from "../actions/AccountActions";
import { LabelStorageService } from "@app/services/dataStorage/LabelStorageService";
import { PasswordFeedStorageService } from "@app/services/dataStorage/PasswordFeedStorageService";
import { ConnectionFeedStorageService } from "@app/services/dataStorage/ConnectionFeedStorageService";
import { DeletedPasswordFeedStorageService } from "@app/services/dataStorage/DeletedPasswordFeedStorageService";
import { PasswordActions } from "../actions/PasswordActions";
import { TeamActions } from "../actions/TeamActions";
import { LabelActions } from "../actions/LabelActions";
import { VaultFeedStorageService } from "@app/services/dataStorage/VaultFeedStorageService";
import { ArchivedPasswordFeedStorageService } from "@app/services/dataStorage/ArchivedPasswordFeedStorageService";

@Injectable()
export class StorageEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private dataFeedService: DataFeedService,
        private storageService: StorageService,
        private connectionFeedStorageService: ConnectionFeedStorageService,
        private labelStorageService: LabelStorageService,
        private passwordFeedStorageService: PasswordFeedStorageService,
        private deletedPasswordFeedStorageService: DeletedPasswordFeedStorageService,
        private archivedPasswordFeedStorageService: ArchivedPasswordFeedStorageService,
        private vaultFeedStorageService: VaultFeedStorageService,
        private passwordGroupFeedStorageService: PasswordGroupFeedStorageService) { }


    /**
     * Trigger the load of the data feeds
     */
    readonly loadGeneratedDataFeed$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                StorageActions.newDatabaseOpened,
                StorageActions.immediatelyRunFeed,
                PasswordActions.deletePasswordsSuccess,
                PasswordActions.bulkTransferPasswordsSuccess,
                TeamActions.updatePasswordSharingSinglePasswordSuccess,
                TeamActions.updatePasswordSharingSingleVaultPasswordSuccess,
                TeamActions.bulkUpdatePasswordMembershipInTeamsSuccess,
                TeamActions.updateTeamMembershipSuccess,
                TeamActions.updatePasswordMembershipInTeamSuccess,
                LabelActions.newLabelCreated,
                LabelActions.labelDeleted,
                PasswordActions.makePasswordPrivateSuccess,
                PasswordActions.archivePasswordsSuccess,
                PasswordActions.unarchivePasswordsSuccess,

            ),
            tap((action) => this.dataFeedService.syncData()),
        ), { dispatch: false }
    );

    /**
    * Trigger the load of the label data feed
    */
    readonly loadLabelFeed$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                StorageActions.immediatelyRunLabelFeed
            ),
            tap((action) => this.dataFeedService.getLabelFeed("")),
        ), { dispatch: false }
    );

    /**
         * load from disk to memory
         */
    readonly loadUnprocessedData$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                StorageActions.localDataChanged
            ),
            tap((action) => this.dataFeedService.loadUnprocessedData().then(() => { })),
        ), { dispatch: false }
    );

    /**
         * load from disk to memory after keys are loaded
         */
    readonly loadUnprocessedDataAfterKeyLoad$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                AccountActions.unlockPackingKeySuccess
            ),
            tap((action) => this.dataFeedService.loadUnprocessedData(true).then(() => { })),
        ), { dispatch: false }
    );


    /**
     * Processes the vault feed 
     */
    readonly processVaultFeed$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                StorageActions.vaultFeedRetrieved
            ),
            tap((action) => this.vaultFeedStorageService.processVaultFeed(action.data, true)),
        ), { dispatch: false }
    );


    /**
     * Processes the generated data feed 
     */
    readonly processGeneratedDataFeed$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                StorageActions.generatedDataFeedRetrieved
            ),
            tap((action) => this.storageService.processGeneratedDataFeed(action.data)),
        ), { dispatch: false }
    );
    /**
     * Processes the password feed 
     */
    readonly processPasswordFeed$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                StorageActions.passwordFeedRetrieved
            ),
            tap((action) => this.passwordFeedStorageService.processPasswordFeed(action.data)),
        ), { dispatch: false }
    );

    /**
     * Processes the archived password feed 
     */
    readonly processArchivedPasswordFeed$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                StorageActions.archivedPasswordFeedRetrieved
            ),
            tap((action) => this.archivedPasswordFeedStorageService.processArchivedPasswordFeed(action.data)),
        ), { dispatch: false }
    );

    /**
    * Processes the deleted password feed 
    */
    readonly processDeletedPasswordFeed$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                StorageActions.deletedPasswordFeedRetrieved
            ),
            tap((action) => this.deletedPasswordFeedStorageService.processDeletedPasswordFeed(action.data)),
        ), { dispatch: false }
    );

    /**
     * Processes the password group feed 
     */
    readonly processPasswordGroupFeed$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                StorageActions.passwordGroupFeedRetrieved
            ),
            tap((action) => this.passwordGroupFeedStorageService.processPasswordGroupFeed(action.data)),
        ), { dispatch: false }
    );

    /**
     * Processes the action feed 
     */
    readonly processActionFeed$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                StorageActions.actionFeedRetrieved
            ),
            tap((action) => this.storageService.processActionFeed(action.data)),
        ), { dispatch: false }
    );

    /**
     * Processes the label feed 
     */
    readonly processLabelFeed$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                StorageActions.labelFeedRetrieved
            ),
            tap((action) => this.labelStorageService.processLabelFeed(action.data)),
        ), { dispatch: false }
    );

    /**
     * Processes the connection feed 
     */
    readonly processConnectionFeed$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                StorageActions.connectionFeedRetrieved
            ),
            tap((action) => this.connectionFeedStorageService.processConnectionFeed(action.data)),
        ), { dispatch: false }
    );



}
