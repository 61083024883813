import { PasswordVaultData } from "../passwordVault/PasswordVaultData";

export class TeamDisplayInfo {
    id: number;
    name: string = "Team Detail";
    defaultRole: number;
    defaultRoleDescription: string;
    ownerId: number;
    ownerNickname: string;
    individualShare: boolean = false;
    color: string;
    description: string;
    decryptedFromVault: boolean = false;
    vault: PasswordVaultData;
}