import { LoginResponse } from '@app/model/api/login/LoginResponse';
import { AccountKeyDataResponse } from '@app/model/api/account/AccountKeyDataResponse';
import { AccountLicenseSummaryResponse } from '@app/model/api/account/AccountLicenseSummaryResponse';
import { AccountUserInformationResponse } from '@app/model/api/account/AccountUserInformationResponse';
import { GetSettingsDataResponse } from '@app/model/api/account/GetSettingsDataResponse';
import { SubscriptionSummaryResponse } from '@app/model/api/account/billing/SubscriptionSummaryResponse';
import { OOBPreferenceResponse } from '@app/model/api/account/token/OOBPreferenceResponse';
import { AccountKeys } from '@app/model/app/account/AccountKeys';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SystemMessageListResponse } from '@app/model/api/system/SystemMessageListResponse';
import { BaseResponse } from '@app/model/api/BaseResponse';

export const AccountActions = createActionGroup({
    source: 'Account',
    events: {
        // Page opens
        'AccountNoOp': emptyProps(),
        'Packing Key Page Opened': emptyProps(),

        // Other
        'Update Valid Subscription': props<{ validSubscription: boolean }>(),

        'Verify New Account Email': props<{ code: string }>(),
        'Verify New Account Email Success': emptyProps(),
        'Verify New Account Email Error': props<{ error: string }>(),

        'Reload License Summary': emptyProps(),
        'Updated Subscription Renewal Setting': emptyProps(),
        'Subscription Renewed': emptyProps(),
        'Begin Business Trial': emptyProps(),
        'Subscription Upgraded To Business Plan': emptyProps(),
        'Business Trial Cancelled': emptyProps(),

        'Retrieve Account Preferences': emptyProps(),

        'Idle Timeout': emptyProps(),
        'Lock Screen': emptyProps(),
        'Delete Account': emptyProps(),
        'Reload Account Settings': emptyProps(),
        'Reload OOB Preference': emptyProps(),
        'Account Details Loaded': props<{ accountDetails: AccountUserInformationResponse }>(),
        'Account Details Load Error': props<{ error: any }>(),
        'Unlock Packing Key': props<{ packingKey: string }>(),
        'Unlock Packing Key Error': props<{ error: any }>(),
        'Unlock Packing Key Success': props<{ data: AccountKeys }>(),
        'Packing Key Retrieved': props<{ response: AccountKeyDataResponse }>(),
        'Packing Key Retrieval Error': props<{ error: any }>(),
        'Set Need Create Packing Key': props<{ needCreatePackingKey: boolean }>(),
        'License Summary Retrieved': props<{ response: AccountLicenseSummaryResponse }>(),
        'License Retrieval Error': props<{ error: any }>(),
        'OOB Preference Retrieved': props<{ response: OOBPreferenceResponse }>(),
        'OOB Preference Retrieval Error': props<{ error: any }>(),
        'Settings Data Retrieved': props<{ response: GetSettingsDataResponse }>(),
        'Settings Data Retrieval Error': props<{ error: any }>(),
        'Site Messages Loaded': props<{ response: SystemMessageListResponse }>(),
        'Subscription Summary Data Retrieved': props<{ response: SubscriptionSummaryResponse }>(),
        'Subscription Summary Data Retrieval Error': props<{ error: any }>(),
        'Packing Key Decrypted From Storage': props<{ packingKey: string }>(),
        'Need Reload Account Details': emptyProps(),
    },
});