import { StorageActions } from '@app/store/actions/StorageActions';
import { Store } from '@ngrx/store';
import initSqlJs, { Database } from 'sql.js';

export class InMemorySQLService {
    private databaseName: string = 'passpack';
    private database: Database;
    private consoleLog: boolean = false;
    private store: Store;


    constructor(databaseName: string, store: Store) {
        if (this.consoleLog) console.log("initializing inmem with name " + databaseName);
        this.databaseName = databaseName;
        this.store = store;
        this.initializeNewDatabase();
    }

    clean() {
        if (this.consoleLog) console.log("cleaning");
        try {
            if (!this.database) {
                if (this.consoleLog) console.log("no database");
                // this.initializeNewDatabase().then(() => {
                //     console.log("database initialized");
                //     this.wipe();
                // })

            } else {
                // Database exists
                if (this.consoleLog) console.log("database exists");
                this.wipe();
            }

        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Drops all the tables
     */
    wipe() {
        try {
            this.dropTables();
            if (this.consoleLog) console.log("tables dropped");
        } catch (e) {
            console.error(e);
        }
    }

    getDatabase(): Database {
        return this.database;
    }

    /**
     * Initialize a new database
     * - creates the new database object
     * - initializes the tables
     * - runs vacuum to clean up the database
     */
    async initializeNewDatabase() {
        if (this.consoleLog) console.log("initializing new database");
        try {
            const SQL = await initSqlJs({
                // Required to load the wasm binary asynchronously. Of course, you can host it wherever you want
                locateFile: file => '/assets/sql-wasm.wasm'
            });
            this.database = new SQL.Database();
            // clean
            // this.clean();
            // Turn on auto vacuum
            this.database.exec(`PRAGMA auto_vacuum = FULL`);
            this.createTables();
        } catch (error) {
            console.error("error: " + error);
        }
        // this.database.exec('VACUUM');
    }

    createTables() {
        try {
            this.database.run("CREATE TABLE IF NOT EXISTS connections ( \
                id                                 INTEGER,\
                nickname                           TEXT,\
                connectionDate                     INTEGER,\
                individualShare                    INTEGER,\
                userId                             INTEGER,\
                active                             INTEGER,\
                ownedTeams                         TEXT,\
                memberOfTeams                      TEXT,\
                individualTeamSharedToConnection   INTEGER,\
                individualTeamSharedFromConnection INTEGER,\
                PRIMARY KEY(id)\
            )");



            this.database.run("CREATE TABLE IF NOT EXISTS labels2passwords ( \
                passwordId INTEGER,\
                labelId    INTEGER,\
                PRIMARY KEY(passwordId, labelId) \
            ) ");

            this.database.run("CREATE TABLE IF NOT EXISTS labels ( \
                id    INTEGER, \
                label TEXT, \
                color TEXT, \
                PRIMARY KEY(id) \
            )");

            this.database.run("CREATE TABLE IF NOT EXISTS deleted_passwords ( \
                id                       TEXT,\
                passwordId               INTEGER,\
                recoveryId               TEXT,\
                deletedTimestamp         TEXT,\
                initiatorId              INTEGER,\
                initiatorUsername        TEXT,\
                ip                       TEXT,\
                sessionId                TEXT,\
                name                     TEXT,\
                key                      TEXT,\
                metadataKey              TEXT,\
                url                      TEXT,\
                username                 TEXT,\
                geo                      TEXT,\
                vault                    TEXT,\
                version                  INTEGER, \
                PRIMARY KEY(id) \
            )");

            this.database.run(`CREATE INDEX idx_deleted_password_name ON deleted_passwords (name)`);

            this.database.run("CREATE TABLE IF NOT EXISTS archived_passwords ( \
                id                       INTEGER,\
                recoveryId               TEXT,\
                archivedTimestamp        TEXT,\
                initiatorId              INTEGER,\
                initiatorUsername        TEXT,\
                ip                       TEXT,\
                sessionId                TEXT,\
                name                     TEXT,\
                key                      TEXT,\
                metadataKey              TEXT,\
                url                      TEXT,\
                username                 TEXT,\
                geo                      TEXT,\
                vault                    TEXT,\
                version                  INTEGER, \
                PRIMARY KEY(id) \
            )");

            this.database.run(`CREATE INDEX idx_archived_password_name ON archived_passwords (name)`);

            this.database.run("CREATE TABLE IF NOT EXISTS passwords ( \
                id                       INTEGER,\
                email                    TEXT,\
                name                     TEXT,\
                key                      TEXT,\
                metadataKey              TEXT,\
                notes                    TEXT,\
                password                 TEXT,\
                sharedNotes              TEXT,\
                url                      TEXT,\
                username                 TEXT,\
                createdDate              TEXT,\
                actualCreatedDate        INTEGER,\
                favorite                 INTEGER,\
                keyGroup                 INTEGER,\
                lastUpdate               TEXT,\
                actualLastUpdate         INTEGER,\
                owner                    INTEGER,\
                passwordLastUpdate       TEXT,\
                actualPasswordLastUpdate INTEGER,\
                stats                    TEXT,\
                score                    INTEGER,\
                version                  INTEGER,\
                vault                    TEXT,\
                decryptedFromVault       INTEGER,\
                PRIMARY KEY(id) \
            )");

            this.database.run(`CREATE INDEX idx_name ON passwords (name)`);

            this.database.run("CREATE TABLE IF NOT EXISTS passwordVault ( \
                id          TEXT,\
                dataKey     TEXT,\
                encryptionKey TEXT,\
                name        TEXT,\
                description TEXT,\
                vaultType   INTEGER,\
                version     INTEGER,\
                ownerName   TEXT,\
                ownerCustomerId INTEGER,\
                ownerOrganizationId TEXT,\
                decryptedPublicKey TEXT,\
                decryptedPrivateKey TEXT,\
                role        INTEGER,\
                PRIMARY KEY(id) \
            ) ");

            this.database.run("CREATE TABLE IF NOT EXISTS teams ( \
                id          INTEGER, \
                color       TEXT, \
                defaultRole INTEGER, \
                description TEXT, \
                groupKey    TEXT, \
                groupStatus INTEGER, \
                metadataKey TEXT, \
                name        TEXT, \
                owner       TEXT, \
                ownerId     INTEGER, \
                ownerNick   TEXT, \
                role        INTEGER, \
                individual  INTEGER, \
                status      INTEGER, \
                vaultId     TEXT, \
                version     INTEGER,\
                defaultTeam INTEGER,\
                encryptedTeamKey TEXT, \
                decryptedFromVault       INTEGER,\
                PRIMARY KEY(id) \
            )");

            this.database.run("CREATE TABLE IF NOT EXISTS teams2passwords ( \
                passwordId INTEGER,\
                teamId     INTEGER,\
                PRIMARY KEY(passwordId, teamId) \
            ) ");

            this.database.run("CREATE TABLE IF NOT EXISTS passwordExtrafields ( \
                id         text,\
                data       TEXT,\
                name       TEXT,\
                type       TEXT,\
                passwordId INTEGER,\
                PRIMARY KEY(id)\
            )");

            this.database.run(`CREATE INDEX idx_extrafields_password_id ON passwordExtrafields (passwordId)`);

            this.database.run("CREATE TABLE IF NOT EXISTS passwordHistory ( \
                changedBy                TEXT,\
                changedByCurrentNickname TEXT,\
                changedDate              TEXT,\
                deleted                  INTEGER,\
                email                    TEXT,\
                password                 TEXT,\
                username                 TEXT,\
                sharedNotes              TEXT,\
                passwordId               INTEGER,\
                PRIMARY KEY(passwordId, changedDate)\
            )");

            this.database.run(`CREATE INDEX idx_history_password_id ON passwordHistory (passwordId)`);


            this.database.run("CREATE TABLE IF NOT EXISTS teamPermissions ( \
                teamId       INTEGER,\
                userId       INTEGER,\
                username     TEXT,\
                shareType    INTEGER,\
                perm         INTEGER,\
                PRIMARY KEY(teamId, userId)\
            )");

            // Log the tables to the console
            this.logTables();

            // Update the flag telling the system it is ready.
            // Notify the store new database is open so population can begin.
            this.store.dispatch(StorageActions.newDatabaseOpened());
        } catch (e) {
            console.error("Error creating tables: " + e);
        }
    }

    /**
     * Logs which tables are in the database to the console.
     */
    private logTables() {
        let self = this;
        this.database.each("SELECT name FROM sqlite_master WHERE type = :type", { ':type': 'table' },
            function (row) {
                // if (self.consoleLog) 
                if (self.consoleLog) console.log("Created Table -> " + row.name);
            },
            () => void (0)
        );
    }

    private dropTables() {
        let self = this;
        this.database.each("SELECT name FROM sqlite_master WHERE type = :type", { ':type': 'table' },
            function (row) {
                if (self.consoleLog) console.log("Deleting Table -> " + row.name);
                self.database.run(`DROP TABLE ${row.name}`);
            },
            () => void (0)
        );
    }
}