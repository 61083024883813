import { Injectable } from '@angular/core';
import { BaseAPIService } from './BaseAPIService';
import { Observable, Subject } from 'rxjs';
import { UpdateAccountPreferenceResponse } from '@app/model/api/account/UpdateAccountPreferenceResponse';
import { environment } from '@environments/environment';
import { UpdateAccountPreferenceRequest } from '@app/model/api/account/UpdateAccountPreferenceRequest';
import { HttpParams } from '@angular/common/http';
import { GetAccountPreferencesResponse } from '@app/model/api/account/GetAccountPreferencesResponse';
import { GetAccountPreferenceEntry } from '@app/model/api/account/GetAccountPreferenceEntry';


@Injectable({
    providedIn: 'root',
})

export class PreferenceService {
    private preferences: Map<string, string> = new Map<string, string>();
    preferenceActivity = new Subject<string>();

    constructor(private baseAPIService: BaseAPIService,
    ) { }


    savePreference(key: string, value: string): void {
        // save the key value pair
        // console.log("Saving preference: " + key + " = " + value);
        this.preferences.set(key, value);

        // save to the server
        this.updatePreferenceOnServer(key, value).subscribe((response) => {
            console.log("Saved preference: " + key + " = " + value);
            this.updateAllPreferences();
        });

    }

    retrievePreference(key: string): string | undefined {

        // look for the key, and if found return the value. Otherwise return undefined
        if (this.preferences.has(key)) {
            return this.preferences.get(key);
        } else return undefined;

    }

    updateAllPreferences(): void {
        // get all preferences from the server
        this.retrieveAllPreferences().subscribe((response) => {
            this.processPreferences(response);
        });
    }

    processPreferences(response: GetAccountPreferencesResponse): void {
        // process the preferences and save them in the local map
        // console.log(response.preferences);
        if (response.preferences) {
            response.preferences.forEach((entry: GetAccountPreferenceEntry) => {
                this.preferences.set(entry.preference, entry.value);
            });
            // // for (let key in response.preferences) {
            // //     console.log(key);
            // //     // let value = response.preferences[key];
            // //     // console.log(value);

            // //     // if (value && undefined != value) {
            // //     //     this.preferences.set(key, value.key);
            // //     // }
            // // }

            this.preferenceActivity.next("Preferences Updated");
        }
        // console.log(this.preferences);
    }

    retrieveAllPreferences(): Observable<GetAccountPreferencesResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/preferences";
        var params = new HttpParams();
        return this.baseAPIService.getRequest<GetAccountPreferencesResponse>(params, url);

    }

    updatePreferenceOnServer(preference: string, value: string): Observable<UpdateAccountPreferenceResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/preferences";
        let request = new UpdateAccountPreferenceRequest();
        request.preference = preference;
        request.value = value;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<UpdateAccountPreferenceResponse>(body, url);
    }

}