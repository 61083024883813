
export class StatusFeedIds {
    public static readonly FEED_ID_PASSWORD = 1;
    public static readonly FEED_ID_LABEL = 2;
    public static readonly FEED_ID_SETTINGS = 3;
    public static readonly FEED_ID_PASSWORD_GROUP = 4
    public static readonly FEED_ID_SENT_INVITATIONS = 5;
    public static readonly FEED_ID_RECEIVED_INVITATIONS = 6;
    public static readonly FEED_ID_CONNECTION = 7;
    public static readonly FEED_ID_MESSAGE_RECEIVED = 8;
    public static readonly FEED_ID_MESSAGE_SENT = 9;
    public static readonly FEED_ID_LIMITS = 10;
    public static readonly FEED_ID_AUTOSAVE = 11;
    public static readonly FEED_ID_GENERATED_DATA = 12;
    public static readonly FEED_ID_SHARING_STATS = 13;
    public static readonly FEED_ID_LICENSE = 14;
    public static readonly FEED_ID_ORG_LICENSE = 15;
    public static readonly FEED_ID_ACTION = 16;
    public static readonly FEED_ID_DELETED_PASSWORDS = 17;
    public static readonly FEED_ID_VAULTS = 18;
    public static readonly FEED_ID_ARCHIVED_PASSWORDS = 19;

}