import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';

import { GetService } from "./httpMethods/get.service";
import { PostService } from "./httpMethods/post.service";
import { Injectable } from '@angular/core';
import { BaseResponse } from '@app/model/api/BaseResponse';
import { Store } from '@ngrx/store';
import { DeleteService } from './httpMethods/delete.service';
import { PutService } from './httpMethods/put.service';

@Injectable({ providedIn: 'root' })
export class BaseAPIService {
    constructor(
        private _postService: PostService,
        private _getService: GetService,
        private _deleteService: DeleteService,
        private _putService: PutService,
        private store: Store

    ) { }

    // getService(): GetService {
    //     return this._getService;
    // }
    // postService(): PostService {
    //     return this._postService;
    // }

    /**
     * Generic post request - no special handling of response
     * @param params 
     * @param url 
     * @returns 
     */
    postRequest<T extends BaseResponse>(params: Map<string, any>, url: string): Observable<T> {
        return this.postRequestNoErrorHandling<T>(params, url).pipe(map(response => {
            if (!_.isNumber(response) && _.has(response, 'status.code')) {
                // if (response.status.code === 0) {
                //     console.log("request OK");
                // } else {
                // console.log("bad response: " + response.status.code);
                // }
            } else {
                //console.log("data: " + response);
            }

            return response;
        }));
    }
    /**
     * Post Request that does not inspect the response. That is left to the caller.
     * @param params 
     * @param url 
     * @returns 
     */
    postRequestNoErrorHandling<T extends BaseResponse>(params: Map<string, any>, url: string): Observable<T> {
        return this._postService.post(params, url);

    }

    postRequestNoErrorHandlingApplicationJson<T extends BaseResponse>(bodyAsJson: string, url: string): Observable<T> {
        return this._postService.postApplicationJson(bodyAsJson, url);
    }

    postMultiPartFormData<T extends BaseResponse>(body: FormData, url: string): Observable<T> {
        return this._postService.postMultipartFormData(body, url);
    }

    /**
     * Generic get request - no special handling of response 
     * @param params 
     * @param url 
     * @returns 
     */
    getRequest<T extends BaseResponse>(params: HttpParams, url: string): Observable<T> {
        // params = params.set('msgId3', (Math.random() * 1000000).toString());
        return this.getRequestNoErrorHandling<T>(params, url).pipe(map(response => {

            if (!_.isNumber(response) && _.has(response, 'status.code')) {
                // if (response.status.code === 0) {
                //     console.log("request OK");
                // } else {
                //     console.log("bad response " + response.status.code);

                // }
            } else {
                console.log("data " + response);
            }
            return response;
        }));
    }

    /**
     * Does not interpret the results. That is left to the caller.
     * @param params
     * @param url 
     * @returns 
     */
    getRequestNoErrorHandling<T extends BaseResponse>(params: HttpParams, url: string): Observable<T> {
        params = params.set('msgId', (Math.random() * 1000000).toString());
        return this._getService.get(params, url);
    }

    getRequestRawImage(params: HttpParams, url: string): Observable<any> {
        params = params.append('msgId', (Math.random() * 1000000).toString());
        return this._getService.getImage(params, url);
    }



    deleteRequestNoErrorHandling<T extends BaseResponse>(url: string): Observable<T> {
        return this._deleteService.delete(url);

    }

    putRequestNoErrorHandling<T extends BaseResponse>(params: Map<string, any>, url: string): Observable<T> {
        return this._putService.put(params, url);

    }

    putRequestNoErrorHandlingApplicationJson<T extends BaseResponse>(bodyAsJson: string, url: string): Observable<T> {
        return this._putService.putApplicationJson(bodyAsJson, url);
    }
}