import { ActionReducer, createReducer, on } from '@ngrx/store';
import { LoginActions } from '../actions/LoginActions';
import { PasswordVaultState } from '@app/model/app/passwordVault/PasswordVaultState';
import { PasswordVaultActions } from '../actions/PasswordVaultActions';

export const initialState: PasswordVaultState = new PasswordVaultState();

export const passwordVaultReducer: ActionReducer<PasswordVaultState> = createReducer(
    initialState,
    on(LoginActions.logoutComplete, (state, action) => {
        return {
            ...initialState
        };
    }),
    on(LoginActions.logoutInitiate, (state, action) => {
        return {
            ...initialState
        };
    }),
    on(LoginActions.lightweightLogout, (state, action) => {
        return {
            ...initialState
        };
    }),

    on(PasswordVaultActions.newVaults, (state, action) => {
        return {
            ...state,
            vaults: action.data
        };
    }),



);