import { Injectable, OnDestroy } from '@angular/core';
import { BaseAPIService } from '../BaseAPIService';
import { environment } from '@environments/environment';
import { Observable, firstValueFrom } from 'rxjs';
import { BulkUserPublicKeyRequest } from '@app/model/api/teams/MultiManageTeamMembershipRequest';
import { BulkUserPublicKeyResponse } from '@app/model/api/connection/BulkUserPublicKeyResponse';

@Injectable({ providedIn: 'root' })
export class TeamPublicKeyService implements OnDestroy {
    private consoleLog: boolean = false;

    constructor(
        private baseAPIService: BaseAPIService,
    ) {

    }
    ngOnDestroy(): void {

    }
    getConnectionPublicKeys(connectionIds: number[]): Observable<BulkUserPublicKeyResponse> {
        let request = new BulkUserPublicKeyRequest();
        request.connectionIds = connectionIds;
        let url = environment.API_BASE_URL + "v1/secure/lookup/publicKeys";
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<BulkUserPublicKeyResponse>(body, url);
    }


}