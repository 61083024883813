
export class PasswordHistory {
    changedBy: string;
    changedByCurrentNickname: string;
    changedDate: string;
    changedDateDisplay: string;
    deleted: number;
    email: string;
    password: string;
    passwordId: number;
    username: string;
    sharedNotes: string;
}