import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, concatMap, exhaustMap, filter, map, mergeMap, switchMapTo, tap, withLatestFrom } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { NotificationsActions } from "../actions/NotificationsActions";
import { DrawerActions } from "../actions/DrawerActions";
import { NotificationsService } from "@app/services/notifications.service";

@Injectable()
export class NotificationsEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private notificationsService: NotificationsService) { }


    readonly showNotifications$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                NotificationsActions.showNotifications
            ),
            exhaustMap(() => {
                return this.notificationsService.prepareToShowNotifications().pipe(
                    map((response) => DrawerActions.showRightDrawer()
                    )
                );
            })
        )
    );


}
