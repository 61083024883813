import { Injectable, OnInit } from '@angular/core';
import { interval, Subject, Observable } from 'rxjs';
import { switchMap, takeUntil, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectAccountUserInformationDetails } from '@app/store/selectors/AccountSelectors';
import { LoginActions } from '@app/store/actions/LoginActions';
import { DeletedPasswordFeedStorageService } from '../dataStorage/DeletedPasswordFeedStorageService';
import { PasswordFeedStorageService } from '../dataStorage/PasswordFeedStorageService';
import { PasswordGroupFeedStorageService } from '../dataStorage/PasswordGroupFeedStorageService';

@Injectable({
    providedIn: 'root',
})
export class StatsPollerService implements OnInit {
    private readonly executionInterval = 5000; // 5 seconds
    private readonly deadmanCheckInterval = 15000;
    private lastUpdateExecutionTime = 0;
    private shouldRun: boolean = false;
    private restartInProgress = false;
    private stopPolling$ = new Subject<void>();
    private errorSubject = new Subject<string>();
    private customerId: number;
    readonly vma$ = this.store.select(selectAccountUserInformationDetails);

    constructor(
        private passwordGroupFeedStorageService: PasswordGroupFeedStorageService,
        private passwordFeedStorageService: PasswordFeedStorageService,
        private deletedPasswordFeedStorageService: DeletedPasswordFeedStorageService,
        private store: Store
    ) {
        // Start the deadman check
        this.startDeadmanCheck();
        this.vma$.subscribe(x => {
            if (x) {
                this.customerId = x.customerId;
            }
        });
    }

    ngOnInit(): void {
    }

    /**
     * Deadman is a check to see if the status poller is running. It runs every second and checks to see if the status poller should be running.
     */
    startDeadmanCheck(): void {
        interval(this.deadmanCheckInterval)
            .pipe(
                switchMap(() => this.restartPollerIfRequired()),
                catchError((error) => {
                    console.log("error during poll checker:" + error);
                    throw error;
                })
            )
            .subscribe(
                (response) => {
                    //console.log(response);
                }
            );
    }

    /**
    * Checks to see if the status should be chcked and restarts the poller if required.
    * @returns 
    */
    restartPollerIfRequired(): Observable<String> {
        // console.log("restartPollerIfRequired.  Should run: ", this.shouldRun);
        if (this.shouldRun) {
            // console.log("pollerShouldRun is true");

            // Check to see if the last update is twice the interval
            if ((Date.now() - this.lastUpdateExecutionTime) > 4 * this.executionInterval) {
                if (!this.restartInProgress) {
                    this.restartInProgress = true;
                    console.log("Restarting the stats poller");
                    this.stopStatsPoller();
                    this.startStatsPoller();
                    this.restartInProgress = false;
                }
            }
        } else {
            console.log("stats pollerShouldRun is false");
            this.stopStatsPoller();
        }

        return new Observable<String>(observer => { observer.next("OK - deadman"); observer.complete(); });
    }


    startStatsPoller(): void {
        // console.log("Starting stats check for customer: " + this.customerId);

        // If the customerId is not set then we do not start the poller.
        if (this.customerId === undefined || this.customerId === null || this.customerId === 0) {
            return;
        }
        // As we start the service the interval does not fire until the end of the interval which means there is a race condition around the dead man check.
        // To avoid this we set the last update check time to now so that the deadman check will not fire until the interval has fired at least once.
        this.updateLastUpdateExecutionTime();

        // Mark the poller as should be running. This will allow deadman to kick in.
        this.shouldRun = true;

        // Reset the stop polling
        this.stopPolling$ = new Subject<void>();

        // Start the interval service
        interval(this.executionInterval)
            .pipe(
                switchMap(() => this.runStatsPoller()),
                takeUntil(this.stopPolling$),
                catchError((error) => {
                    this.errorSubject.next('Error during poller check. Logging out.');
                    this.store.dispatch(LoginActions.logoutInitiate());
                    throw error;
                })
            )
            .subscribe(
                (response) => {
                    // Need to subscribe to the result otherwise the interval will not fire anything.
                    this.updateLastUpdateExecutionTime();
                }
            );
    }

    /**
     * Convenience method to update the last execution check time.
     */
    updateLastUpdateExecutionTime(): void {
        this.lastUpdateExecutionTime = Date.now();
    }


    /**
     * Stops the update check.
     */
    stopStatsPoller(): void {
        console.log("stop stats polling");
        this.stopPolling$.next();
        this.stopPolling$.complete();

        // Mark the poller as should not be running. This will allow deadman to kick in.
        this.shouldRun = false;
    }

    getErrorObservable(): Observable<string> {
        return this.errorSubject.asObservable();
    }

    runStatsPoller(): Observable<String> {
        this.passwordGroupFeedStorageService.updateTableCounts();
        this.passwordFeedStorageService.updateTableCounts();
        this.deletedPasswordFeedStorageService.updateTableCounts();
        return new Observable<String>(observer => { observer.next("completed"); observer.complete(); });
    }

}
