import { ActionReducer, createReducer, on } from '@ngrx/store';
import { AccountActions } from '../actions/AccountActions';
import { SiteState } from '@app/model/app/site/SiteState';

export const initialState: SiteState = new SiteState();

export const siteReducer: ActionReducer<SiteState> = createReducer(
    initialState,

    on(AccountActions.siteMessagesLoaded, (state, action) => {
        return {
            ...state,
            systemMessages: action.response
        };
    }),



);