import { Geo } from "../Geo";

export class DeletedPasswordFeedEntry {
    id: string;
    passwordId: number;
    recoveryId: string;
    gid: number;
    vault: string;
    deletedTimestamp: string;
    deletedTimestampMs: number;
    initiatorId: number;
    initiatorUsername: string;
    ip: string;
    sessionId: string;
    data: string;
    name: string;
    url: string;
    key: string;
    geo: Geo;
    version: number = 1;

    constructor(deletedPassword: DeletedPasswordFeedEntry) {
        this.id = deletedPassword.id;
        this.gid = deletedPassword.gid;
        this.vault = deletedPassword.vault;
        this.recoveryId = deletedPassword.recoveryId;
        this.deletedTimestamp = deletedPassword.deletedTimestamp;
        this.deletedTimestampMs = deletedPassword.deletedTimestampMs;
        this.initiatorId = deletedPassword.initiatorId;
        this.initiatorUsername = deletedPassword.initiatorUsername;
        this.ip = deletedPassword.ip;
        this.sessionId = deletedPassword.sessionId;
        this.data = deletedPassword.data;
        this.name = deletedPassword.name;
        this.url = deletedPassword.url;
        this.key = deletedPassword.key;
        this.geo = deletedPassword.geo;
        this.passwordId = deletedPassword.passwordId;
        this.version = deletedPassword.version;
    }

}