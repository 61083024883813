import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AuthGuard } from './services/authGuard/auth.guard';
import { RouterModule } from '@angular/router';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  providers: [AuthGuard],
  imports: [RouterModule],
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(

  ) {

  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
