import { LoginResponse } from '@app/model/api/login/LoginResponse';
import { AccountUserInformationResponse } from '@app/model/api/account/AccountUserInformationResponse';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LoginTokenResponse } from '@app/model/api/login/LoginTokenResponse';
import { GetAppFeaturesResponse } from '@app/model/api/app/GetAppFeaturesResponse';

export const LoginActions = createActionGroup({
    source: 'Login',
    events: {
        // Page opens
        'Login Page Opened': emptyProps(),
        'Packing Key Page Opened': emptyProps(),
        'MFA Page Opened': emptyProps(),
        // Other
        'Lock System': emptyProps(),
        'Unlock System': emptyProps(),

        'Reload CSRF Token': emptyProps(),
        'Set Require MFA': props<{ requireMFA: boolean }>(),
        'Set Require Email Verification': props<{ requireEmailVerification: boolean }>(),
        'Set MFA Code Entered': props<{ mfaCodeEntered: boolean }>(),
        'Load Account Details': emptyProps(),

        'Login': props<{ username: string, password: string }>(),
        'Submit MFA': props<{ token: string }>(),

        'MFA Code Entered': props<{ mfaResponse: LoginTokenResponse }>(),
        'Account Login Completed': props<{ loginResponse: LoginResponse }>(),
        'Login Initialized': emptyProps(),

        'Login Success': emptyProps(),
        'Oauth Login Success': emptyProps(),
        'Login Error': props<{ error: any }>(),
        'Login Page Error': props<{ error: any }>(),
        'Logout Initiate': emptyProps(),
        'Lightweight Logout': emptyProps(),
        'Logout Complete': emptyProps(),
        'Set May View Account': props<{ canViewAccount: boolean }>(),
        'App Features Retrieved': props<{ response: GetAppFeaturesResponse }>(),
    },
});