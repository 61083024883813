import { ActionReducer, createReducer, on } from '@ngrx/store';
import { LoginActions } from '../actions/LoginActions';
import { LabelState } from '@app/model/app/labels/LabelState';

export const initialState: LabelState = new LabelState();

export const labelReducer: ActionReducer<LabelState> = createReducer(
    initialState,
    on(LoginActions.logoutComplete, (state, action) => {
        return {
            ...initialState
        };
    }),


);