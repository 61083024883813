import { LoginFunnelState } from "@app/model/app/LoginFunnelState";
import { createFeatureSelector, createSelector } from "@ngrx/store";

const loginFeature = createFeatureSelector<LoginFunnelState>('loginFunnelState');

const selectState = createSelector(
    loginFeature,
    state => state
);

export const selectLoginPageModel = createSelector(
    selectState,
    (state) => ({
        state,
    })
);