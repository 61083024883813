import { Injectable } from '@angular/core';
import { StorageService } from '../dataStorage/storage.service';
import { DisplayService } from '../DisplayService';
import { DisplayLabel } from '@app/model/app/labels/DisplayLabel';
import { LabelItem } from '@app/model/app/labels/LabelItem';

@Injectable({ providedIn: 'root' })
export class LabelQueryService {
    private consoleLog: boolean = false;
    constructor(
        private storageService: StorageService,
    ) {
    }


    /**
     * Generaate the full label list.
     */
    generateFullLabelList(limit: number = 2000, loadPasswordCounts: boolean = true) {
        let fullLabelList: LabelItem[] = [];
        // Get the list of labels
        let queriedData = this.getDisplayLabels("", limit, loadPasswordCounts);
        // Add the entries from fullLabelList to the labelList
        for (let i = 0; i < queriedData.length; i++) {
            let entry = new LabelItem();
            entry.id = queriedData[i].id;
            entry.name = queriedData[i].label;
            entry.color = queriedData[i].color;
            entry.checked = false;
            entry.editing = false;
            entry.passwords = queriedData[i].passwordCount;
            fullLabelList.push(entry);
        }

        return fullLabelList;
    }

    /**
     * 
     * Retrieve the listing of labels matching the search criteria
     * @param nameFragment - search for the fragment in the label name
     * @param searchLimit  - limit on how many results to return
     * @param countPasswords  - should we count passwords in the resulting labels?
     * @returns 
     */
    getDisplayLabels(nameFragment: string, searchLimit: number, countPasswords: boolean): DisplayLabel[] {
        let resultList: DisplayLabel[] = [];
        try {
            let database = this.storageService.inMemorySQLService?.getDatabase();
            if (database) {
                if (this.consoleLog) console.log("Getting labels");

                let passwordCountStatement = database.prepare("SELECT count(1) as count from labels2passwords where labelId=:labelId");
                // get the data
                let stmt = database.prepare("SELECT id, label, color, UPPER(label) as uname FROM labels ORDER BY uname ASC limit :limit");
                stmt.bind({ ':val': '%' + nameFragment + '%', ':limit': searchLimit });
                while (stmt.step()) {
                    let result = stmt.getAsObject();
                    let entry = new DisplayLabel();
                    entry.id = result.id as number;
                    entry.label = result.label?.toString() as string;
                    entry.color = result.color?.toString() as string;

                    if (countPasswords) {
                        ////////////////
                        // Get Password count
                        let passwordCountResult = passwordCountStatement.getAsObject({ ':labelId': entry.id });
                        entry.passwordCount = passwordCountResult.count as number;
                    }

                    resultList.push(entry);
                }
                stmt.free();
                passwordCountStatement.free();
            }
        } catch (error) {
            console.error("Error getting labels: ", error);
        }
        if (this.consoleLog) console.log("Returning labels: ", resultList.length);;
        return resultList;

    }

}
