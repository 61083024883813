import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from "@ngrx/store";
import { NavigationActions } from "../actions/NavigationActions";
import { NavigationService } from "@app/services/NavigationService";
import { tap } from "rxjs";

@Injectable()
export class NavigationEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private navigationService: NavigationService) { }

    readonly persistLastPageVisited$ = createEffect(
        () => this.actions$.pipe(
            ofType(NavigationActions.setLastAuthenticatedPageVisited),
            tap((action) => {
                this.navigationService.setLastAuthenticatedPageVisited(action.page);
            }
            )
        ), { dispatch: false }
    );


}
