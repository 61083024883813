import { SyncStatus } from "@app/model/app/database/SyncStatus";
import { PasswordGroupFeedEntry } from "../../api/dataFeed/PasswordGroupFeedEntry";

export class PasswordGroup extends PasswordGroupFeedEntry {
    syncedInMemory: number = SyncStatus.SYNC_STATUS_NOT_SYNCED;


    constructor(group: PasswordGroupFeedEntry) {
        super(group);
    }
}