
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ReportingActions = createActionGroup({
    source: 'Reporting',
    events: {
        // Page opens
        'User Reporting Page Open': emptyProps(),
        'Org Reporting Page Open': emptyProps(),

        // Other


    },
});