
import { BaseResponse } from '@app/model/api/BaseResponse';
import { MultiAddPasswordLabelResponse } from '@app/model/api/passwords/MultiAddPasswordLabelResponse';
import { MultiDeletePasswordLabelResponse } from '@app/model/api/passwords/MultiDeletePasswordLabelResponse';
import { PasswordGroupInitializationResponse } from '@app/model/api/passwords/PasswordGroupInitializationResponse';
import { TogglePasswordFavoriteFlagRequest } from '@app/model/api/passwords/TogglePasswordFavoriteFlagRequest';
import { TogglePasswordFavoriteFlagResponse } from '@app/model/api/passwords/TogglePasswordFavoriteFlagResponse';
import { DisplayedArchivedPasswordListItem } from '@app/model/app/password/DisplayedArchivedPasswordListItem';
import { DisplayedDeletedPasswordListItem } from '@app/model/app/password/DisplayedDeletedPasswordListItem';
import { InMemoryPassword } from '@app/model/app/password/InMemoryPassword';
import { PasswordSearchCriteria } from '@app/model/app/password/PasswordSearchCriteria';
import { TeamMembershipTuple } from '@app/model/app/teams/TeamMembershipTuple';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const PasswordActions = createActionGroup({
    source: 'Passwords',
    events: {
        // Page opens


        // Other


        'Test': emptyProps(),
        'Toggle Favorite Search': emptyProps(),
        'Toggle Password Favorite Flag': props<{ passwordId: number }>(),
        'Toggle Password Favorite Flag Response': props<{ response: TogglePasswordFavoriteFlagResponse }>(),
        'Toggle Password Favorite Flag Error': props<{ error: string }>(),
        'Bulk Transfer Passwords': props<{ passwordIds: number[], newGroupId: number }>(),
        'Bulk Transfer Passwords Success': props<{ response: BaseResponse | undefined }>(),
        'Bulk Transfer Passwords Error': props<{ error: string }>(),
        'Remove Labels From Passwords': props<{ passwordIds: number[], labelIds: number[] }>(),
        'Remove Labels From Passwords Success': props<{ response: MultiDeletePasswordLabelResponse }>(),
        'Remove Labels From Passwords Error': props<{ error: string }>(),
        'Add Labels To Passwords': props<{ passwordIds: number[], labelIds: number[] }>(),
        'Add Labels To Passwords Success': props<{ response: MultiAddPasswordLabelResponse }>(),
        'Add Labels To Passwords Error': props<{ error: string }>(),
        'Delete Passwords': props<{ passwordIds: number[] }>(),
        'Delete Passwords Success': props<{ passwordIds: number[] }>(),
        'Archive Passwords': props<{ passwords: InMemoryPassword[] }>(),
        'Archive Passwords Success': props<{ passwords: InMemoryPassword[] }>(),
        'Restore Password': props<{ data: DisplayedDeletedPasswordListItem }>(),
        'Unarchive Password': props<{ data: DisplayedArchivedPasswordListItem }>(),
        'Unarchive Passwords Success': emptyProps(),
        'Update Search Criteria': props<{ criteria: PasswordSearchCriteria }>(),
        'Update Archive Search Criteria': props<{ criteria: PasswordSearchCriteria }>(),
        'Check Password Group Initialization Status': props<{ response: PasswordGroupInitializationResponse }>(),

        'Make Password Private': props<{ passwordId: number, passwordKey: string }>(),
        'Make Password Private Success': props<{ response: BaseResponse | undefined }>(),
        'Make Password Private Error': props<{ error: string }>(),

    },
});