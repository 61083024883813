

import { PasswordVaultData } from '@app/model/app/passwordVault/PasswordVaultData';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const PasswordVaultActions = createActionGroup({
    source: 'PasswordVaults',
    events: {
        // Page opens


        // Other



        'New Vaults': props<{ data: PasswordVaultData[] }>(),


    },
});