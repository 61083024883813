
export class ListPasswordVaultEntry {
    data: string;
    encryptionKey: string;
    dataKey: string;
    vaultId: string;
    role: number;
    vaultType: number;
    ownerName: string;
    ownerOrganizationId: string;
    ownerCustomerId: number;
    privateKey: string;
    publicKey: string;

    constructor(entry: ListPasswordVaultEntry) {
        this.data = entry.data;
        this.encryptionKey = entry.encryptionKey;
        this.dataKey = entry.dataKey;
        this.vaultId = entry.vaultId;
        this.role = entry.role;
        this.vaultType = entry.vaultType;
        this.ownerName = entry.ownerName;
        this.ownerOrganizationId = entry.ownerOrganizationId;
        this.ownerCustomerId = entry.ownerCustomerId;
        this.privateKey = entry.privateKey;
        this.publicKey = entry.publicKey;
    }
}