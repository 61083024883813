import { Injectable } from '@angular/core';
import { StorageService } from '../dataStorage/storage.service';
import { TeamDisplayInfo } from '@app/model/app/teams/TeamDisplayInfo';
import { DisplayService } from '../DisplayService';
import { DisplayedPasswordGroupListItem } from '@app/model/app/passwordGroup/DisplayedPasswordGroupListItem';
import { DisplayedPasswordGroupListResult } from '@app/model/app/passwordGroup/DisplayedPasswordGroupListResult';
import { SharingDisplayGroupItem } from '@app/model/app/passwordGroup/SharingDisplayGroupItem';
import { AccountService } from '../account/account.service';
import { TeamForDataExport_v1 } from '@app/model/app/dataManagement/TeamForDataExport_v1';
import { TeamMemberForDataExport_v1 } from '@app/model/app/dataManagement/TeamMemberForDataExport_v1';
import { TeamListEntryForVaultAssociation } from '@app/model/app/passwordGroup/TeamListEntryForVaultAssociation';
import { PasswordVaultData } from '@app/model/app/passwordVault/PasswordVaultData';
import { PasswordVaultService } from '../passwordVault/password-vault-service.service';
import { InMemoryTeam } from '@app/model/app/passwordGroup/InMemoryTeam';

@Injectable({ providedIn: 'root' })
export class TeamQueryService {

    constructor(
        private storageService: StorageService,
        private displayService: DisplayService,
        private accountService: AccountService,
        private passwordVaultService: PasswordVaultService
    ) {
    }


    /**
     * Generates the list of teams which are owned by this user and can be associated with vaults
     * // TODO do not include teams which have already been associated.
     * @returns 
     */
    retrieveMyTeamsForAssociationWithVaults(): TeamListEntryForVaultAssociation[] {
        let teamsList: TeamListEntryForVaultAssociation[] = [];
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {
            let stmt = database.prepare("SELECT t.* FROM teams t WHERE ownerId=:ownerId AND t.name != 'Default'");
            stmt.bind({ ':ownerId': this.accountService.getCustomerId() });
            while (stmt.step()) {
                let result = stmt.getAsObject();
                let entry = new TeamListEntryForVaultAssociation();
                entry.id = result.id as number;
                entry.groupKey = result.groupKey as string;
                entry.individual = result.individual as number;
                entry.name = result.name as string;
                teamsList.push(entry);
            }
            stmt.free();
        }
        return teamsList;
    }

    retrieveTeamForAssociationWithVaults(teamId: number): TeamListEntryForVaultAssociation | undefined {
        let teamData: TeamListEntryForVaultAssociation | undefined = undefined;
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {
            let stmt = database.prepare("SELECT t.* FROM teams t WHERE id=:id AND ownerId=:ownerId AND t.name != 'Default'");
            stmt.bind({ ':ownerId': this.accountService.getCustomerId(), ':id': teamId });
            while (stmt.step()) {
                let result = stmt.getAsObject();
                let entry = new TeamListEntryForVaultAssociation();
                entry.id = result.id as number;
                entry.groupKey = result.groupKey as string;
                entry.individual = result.individual as number;
                entry.name = result.name as string;
                teamData = entry;
            }
            stmt.free();
        }
        return teamData;
    }


    retrieveTeamsForDataExport(): TeamForDataExport_v1[] {
        let teamsList: TeamForDataExport_v1[] = [];
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {
            let stmt = database.prepare("SELECT t.* FROM teams t  WHERE ownerId=:ownerId ORDER BY t.name ASC");
            stmt.bind({ ':ownerId': this.accountService.getCustomerId() });
            while (stmt.step()) {
                let result = stmt.getAsObject();
                let entry = new TeamForDataExport_v1();
                entry.name = result.name as string;
                entry.color = result.color as string;
                entry.defaultRole = result.defaultRole as number;

                entry.members = this.generateTeamMembersForTeam(result.id as number);

                teamsList.push(entry);
            }
            stmt.free();
        }
        // asdasd
        return teamsList;
    }

    generateTeamMembersForTeam(teamId: number): TeamMemberForDataExport_v1[] {
        let membersList: TeamMemberForDataExport_v1[] = [];
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {
            let stmt = database.prepare("SELECT * FROM teamPermissions WHERE teamId=:teamId");
            stmt.bind({ ':teamId': teamId });
            while (stmt.step()) {
                let result = stmt.getAsObject();
                let entry = new TeamMemberForDataExport_v1();
                entry.id = result.userId as number;
                entry.role = result.perm as number;
                entry.shareType = result.shareType as number;
                membersList.push(entry);
            }
            stmt.free();
        }
        return membersList;

    }

    /**
     * Retrieves the list of my teams of which I'm the owner. These are the ones where I can share out.
     * @returns 
     */
    retrievePasswordGroupsForSharingOutNoVault(): SharingDisplayGroupItem[] {
        let teamsList: SharingDisplayGroupItem[] = [];
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {
            let stmt = database.prepare("SELECT t.*,upper(t.name) as uname  FROM teams t  WHERE t.name != 'Default' AND ownerId=:ownerId AND (t.vaultId=null OR t.vaultId='') ORDER BY uname ASC");
            stmt.bind({ ':ownerId': this.accountService.getCustomerId() });
            while (stmt.step()) {
                let result = stmt.getAsObject();
                let entry = new SharingDisplayGroupItem();
                entry.name = result.name as string;
                entry.description = result.description as string;
                entry.id = result.id as number;
                entry.color = result.color as string;
                teamsList.push(entry);
            }
            stmt.free();
        }

        return teamsList;
    }


    /**
     * Retrieves the list of teams which belong to the specified vault.
     */
    retrieveTeamsWhichBelongToVault(vaultId: string): SharingDisplayGroupItem[] {
        let teamsList: SharingDisplayGroupItem[] = [];
        let vaultMap: Map<string, PasswordVaultData> = this.passwordVaultService.generateIdToNameMapForCurrentVaults();

        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {
            let stmt = database.prepare("SELECT t.*,upper(t.name) as uname  FROM teams t WHERE t.vaultId=:vaultId ORDER BY uname ASC");
            stmt.bind({ ':vaultId': vaultId });
            while (stmt.step()) {
                let result = stmt.getAsObject();
                let entry = new SharingDisplayGroupItem();
                entry.name = result.name as string;
                entry.description = result.description as string;
                entry.id = result.id as number;
                entry.color = result.color as string;
                let vaultId = result.vaultId as string;

                // Add the vault data if this is a vault.
                if (vaultId && vaultId.length > 0) {
                    let vault = vaultMap.get(vaultId);
                    if (vault) {
                        entry.vault = vault;
                    }
                }
                teamsList.push(entry);
            }
            stmt.free();
        }

        return teamsList;
    }

    /**
     * Retrieves the list of teams to which I can transfer a password. This is a transformation / convenience method to the getTeamsToWhichICanMakeTransfer
     * @returns 
     */
    retrievePasswordGroupsForTransfer(): SharingDisplayGroupItem[] {
        let teamsList: SharingDisplayGroupItem[] = [];
        let interimResult = this.getTeamsToWhichICanMakeTransfer();
        if (interimResult && interimResult.groups) {
            teamsList = interimResult.groups.map((item) => {
                let entry = new SharingDisplayGroupItem();
                entry.name = item.name;
                entry.description = item.defaultRoleDescription;
                entry.id = item.id;
                entry.color = item.color;
                return entry;
            });
        }

        return teamsList;
    }


    /**
     * Retrieves the list of teams to which I can transfer a password
     * @param myCustomerId 
     * @returns 
     */
    getTeamsToWhichICanMakeTransfer(): DisplayedPasswordGroupListResult {
        let resultList: DisplayedPasswordGroupListResult = new DisplayedPasswordGroupListResult();

        let myCustomerId = this.accountService.getCustomerId();
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {

            // get the data
            // Share type 4 == shared from connection
            // permission = 3 = readWrite.
            let stmt = database.prepare("SELECT t.*, UPPER(t.name) as uname, perms.* FROM teams t LEFT JOIN teamPermissions perms ON t.id=perms.teamId WHERE t.name != 'Default' " +
                "AND perms.shareType=4 AND perms.userId=:myUserId" +
                " AND(( t.defaultRole=3 AND perms.perm=0)" + // this is default read write for a team
                " OR (perms.perm=3))" + // this is explicit read write for a team
                " and perms.perm >= :permId ORDER BY uname ASC");
            stmt.bind({ ':permId': 0, ':myUserId': myCustomerId });
            while (stmt.step()) {
                let result = stmt.getAsObject();
                // console.log("result", result);
                // if (console.log) console.log("data Table password group -> " + JSON.stringify(result));
                let group = new DisplayedPasswordGroupListItem();
                group.id = result.id as number;
                group.name = result.name?.toString() as string;
                group.ownerNickname = result.ownerNick?.toString() as string;
                group.ownerId = result.ownerId as number;
                group.isOwner = (myCustomerId === group.ownerId);
                group.color = result.color as string;
                group.defaultRoleDescription = this.displayService.convertTeamDefaultRoleToDescription(result.defaultRole as number);
                resultList.groups.push(group);
            }
            stmt.free();


        }
        return resultList;

    }

    /**
     * 
     * Retrieve the listing of teams matching the search criteria
     * @param myCustomerId 
     * @param teamNameFragment - search for the fragment in the team name
     * @param searchLimit  - limit on how many results to return
     * @param countPasswords  - should we count passwords in the resulting teams?
     * @param countMembers  = should we count the team members in the resulting teams?
     * @returns 
     */
    getTeams(myCustomerId: number, teamNameFragment: string, searchLimit: number, countPasswords: boolean, countMembers: boolean): DisplayedPasswordGroupListResult {
        let resultList: DisplayedPasswordGroupListResult = new DisplayedPasswordGroupListResult();
        let vaultMap: Map<string, PasswordVaultData> = this.passwordVaultService.generateIdToNameMapForCurrentVaults();

        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {

            // Do not search for the Default group
            let mainSqlBody = "FROM teams where name like :val and name != 'Default' AND defaultTeam!=1";

            // get the total count
            let cntStatement = database.prepare("SELECT count(1) as count " + mainSqlBody);

            let cntResult = cntStatement.getAsObject({ ':val': '%' + teamNameFragment + '%' });
            // if (console.log) console.log("count: " + JSON.stringify(cntResult));
            resultList.totalResults = cntResult.count as number;
            cntStatement.free();


            let passwordCountStatement = database.prepare("SELECT count(1) as count from teams2passwords where teamId=:teamId");
            let memberCountStatement = database.prepare("SELECT count(1) as count from teamPermissions where teamId=:teamId");


            // get the data
            let stmt = database.prepare("SELECT *, UPPER(name) as uname " + mainSqlBody + " ORDER BY uname ASC limit :limit");
            stmt.bind({ ':val': '%' + teamNameFragment + '%', ':limit': searchLimit });
            while (stmt.step()) {
                let result = stmt.getAsObject();
                // console.log("result", result);
                // if (console.log) console.log("data Table password group -> " + JSON.stringify(result));
                let group = new DisplayedPasswordGroupListItem();
                group.id = result.id as number;
                group.name = result.name?.toString() as string;
                group.ownerNickname = result.ownerNick?.toString() as string;
                group.ownerId = result.ownerId as number;
                group.isOwner = (myCustomerId === group.ownerId);
                group.color = result.color as string;
                group.defaultRoleDescription = this.displayService.convertTeamDefaultRoleToDescription(result.defaultRole as number);
                group.defaultRoleDescriptionShort = this.displayService.convertTeamDefaultRoleToDescriptionShort(result.defaultRole as number);
                group.individualShare = (result.individual as number) === 1;
                group.decryptedFromVault = result.decryptedFromVault as number === 1 ? true : false;

                // Load up the vault if it was used.
                if (group.decryptedFromVault) {
                    let vaultId = result.vaultId as string;

                    let vault = vaultMap.get(vaultId);
                    if (vault) {
                        group.vault = vault;
                    }
                }

                if (countPasswords) {
                    ////////////////
                    // Get Password count
                    let passwordCountResult = passwordCountStatement.getAsObject({ ':teamId': group.id });
                    group.passwordCount = passwordCountResult.count as number;
                }

                if (countMembers) {
                    // Get Member count
                    let memberCountResult = memberCountStatement.getAsObject({ ':teamId': group.id });
                    group.memberCount = memberCountResult.count as number;
                }

                resultList.groups.push(group);
            }
            stmt.free();
            passwordCountStatement.free();
            memberCountStatement.free();


        }
        return resultList;

    }
    retrievePasswordGroupsByIds(groupIds: number[]): DisplayedPasswordGroupListItem[] {
        let teamsList: DisplayedPasswordGroupListItem[] = [];
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {
            for (let groupId of groupIds) {
                let teamQueryStmt = database.prepare("SELECT t.* FROM teams t  WHERE t.id=:teamId");
                teamQueryStmt.bind({ ':teamId': groupId });
                while (teamQueryStmt.step()) {
                    let result = teamQueryStmt.getAsObject();
                    let entry = new DisplayedPasswordGroupListItem();
                    entry.name = result.name as string;
                    entry.id = result.id as number;
                    entry.color = result.color as string;
                    teamsList.push(entry);
                }
                teamQueryStmt.free();
            }

        }

        return teamsList;
    }


    /**
     * Retrieves the team display infor for the supplied teamId
     * @param teamId 
     * @returns 
     */
    getTeamDetail(teamId: number): TeamDisplayInfo {
        let newTeamInfo: TeamDisplayInfo | undefined = undefined;
        let vaultMap: Map<string, PasswordVaultData> = this.passwordVaultService.generateIdToNameMapForCurrentVaults();

        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {

            let teamQueryStmt = database.prepare("SELECT t.* FROM teams t  WHERE t.id=:teamId");
            let teamQueryResult = teamQueryStmt.getAsObject({ ':teamId': teamId as number });
            try {

                if (teamQueryResult && teamQueryResult.id && teamQueryResult.id?.toString().length > 0) {
                    newTeamInfo = new TeamDisplayInfo();
                    newTeamInfo.id = Number(teamQueryResult.id?.toString());
                    if (teamQueryResult.name) {
                        newTeamInfo.name = teamQueryResult.name?.toString();
                    }
                    if (teamQueryResult.description) {
                        newTeamInfo.description = teamQueryResult.description?.toString();
                    }
                    if (teamQueryResult.ownerNick) {
                        newTeamInfo.ownerNickname = teamQueryResult.ownerNick?.toString();
                    }
                    if (teamQueryResult.ownerId) {
                        newTeamInfo.ownerId = Number(teamQueryResult.ownerId?.toString());
                    }

                    if (teamQueryResult.individual) {
                        let individual = Number(teamQueryResult.individual?.toString());
                        if (1 === individual) {
                            newTeamInfo.individualShare = true;
                        }
                    }
                    if (teamQueryResult.color) {
                        newTeamInfo.color = teamQueryResult.color?.toString();
                    }

                    newTeamInfo.decryptedFromVault = teamQueryResult?.decryptedFromVault === 1 ? true : false;

                    if (teamQueryResult.vaultId) {
                        // Load up the vault if it was used.
                        if (newTeamInfo.decryptedFromVault) {
                            let vaultId = teamQueryResult.vaultId as string;

                            let vault = vaultMap.get(vaultId);
                            if (vault) {
                                newTeamInfo.vault = vault;
                            }
                        }
                    }
                    newTeamInfo.defaultRole = Number(teamQueryResult.defaultRole?.toString());
                    newTeamInfo.defaultRoleDescription = this.displayService.convertTeamDefaultRoleToDescription(newTeamInfo.defaultRole);
                }
            } catch (error) {
                console.log('Error in getTeamDetail: ' + error);
            }

            teamQueryStmt.free();


        }
        return newTeamInfo as TeamDisplayInfo;
    }

    /**
     * Retrieves the team  infor for the supplied teamId
     * @param teamId 
     * @returns 
     */
    getInMemoryTeam(teamId: number): InMemoryTeam {
        let teamInfo: InMemoryTeam | undefined = undefined;
        let vaultMap: Map<string, PasswordVaultData> = this.passwordVaultService.generateIdToNameMapForCurrentVaults();

        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {

            let teamQueryStmt = database.prepare("SELECT t.* FROM teams t WHERE t.id=:teamId");
            let teamQueryResult = teamQueryStmt.getAsObject({ ':teamId': teamId as number });
            try {

                if (teamQueryResult && teamQueryResult.id && teamQueryResult.id?.toString().length > 0) {
                    teamInfo = new InMemoryTeam();
                    teamInfo.id = Number(teamQueryResult.id?.toString());
                    if (teamQueryResult.name) {
                        teamInfo.name = teamQueryResult.name?.toString();
                    }
                    if (teamQueryResult.description) {
                        teamInfo.description = teamQueryResult.description?.toString();
                    }
                    if (teamQueryResult.ownerNick) {
                        teamInfo.ownerNick = teamQueryResult.ownerNick?.toString();
                    }
                    if (teamQueryResult.ownerId) {
                        teamInfo.ownerId = teamQueryResult.ownerId as number;
                    }

                    if (teamQueryResult.individual) {
                        let individual = teamQueryResult.individual as number;
                        teamInfo.individual = individual > 0 ? true : false;
                        teamInfo.individualShareId = individual;
                    }
                    if (teamQueryResult.color) {
                        teamInfo.color = teamQueryResult.color?.toString();
                    }
                    teamInfo.groupStatus = teamQueryResult.groupStatus as number;
                    teamInfo.metadataKey = teamQueryResult.metadataKey as string;
                    teamInfo.owner = teamQueryResult.owner as string;
                    teamInfo.role = teamQueryResult.role as number;
                    teamInfo.defaultRole = teamQueryResult.defaultRole as number;
                    teamInfo.status = teamQueryResult.status as number;

                    teamInfo.decryptedFromVault = teamQueryResult?.decryptedFromVault === 1 ? true : false;
                    teamInfo.vaultId = teamQueryResult.vaultId as string;
                    teamInfo.version = teamQueryResult.version as number;
                    teamInfo.defaultTeam = teamQueryResult.defaultTeam as number === 1 ? true : false;
                    teamInfo.encryptedTeamKey = teamQueryResult.encryptedTeamKey as string;
                    teamInfo.decryptedGroupKey = teamQueryResult.groupKey as string;
                    teamInfo.decryptedMetadataKey = teamQueryResult.metadataKey as string;
                    teamInfo.decryptedFromVault = teamQueryResult.decryptedFromVault as number === 1 ? true : false;
                }
            } catch (error) {
                console.log('Error in getTeamDetail: ' + error);
            }

            teamQueryStmt.free();


        }
        return teamInfo as InMemoryTeam;
    }
}
