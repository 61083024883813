
export class InMemoryArchivedPassword {
    id: number;
    recoveryId: string;
    archivedTimestamp: string;
    initiatorId: number;
    initiatorUsername: string;
    ip: string;
    sessionId: string;
    name: string;
    key: string;
    metadataKey: string;
    url: string;
    username: string;

}