
import { ActionFeedResponse } from '@app/model/api/dataFeed/ActionFeedResponse';
import { ArchivedPasswordFeedResponse } from '@app/model/api/dataFeed/ArchivedPasswordFeedResponse';
import { ConnectionFeedResponse } from '@app/model/api/dataFeed/ConnectionFeedResponse';
import { DeletedPasswordFeedResponse } from '@app/model/api/dataFeed/DeletedPasswordFeedResponse';
import { GeneratedDataFeedResponse } from '@app/model/api/dataFeed/GeneratedDataFeedResponse';
import { LabelFeedResponse } from '@app/model/api/dataFeed/LabelFeedResponse';
import { PasswordFeedResponse } from '@app/model/api/dataFeed/PasswordFeedResponse';
import { PasswordGroupFeedResponse } from '@app/model/api/dataFeed/PasswordGroupFeedResponse';
import { ListPasswordVaultResponse } from '@app/model/api/passwordVault/ListPasswordVaultResponse';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const StorageActions = createActionGroup({
    source: 'Storage',
    events: {
        // Page opens / Alerts
        'New Database Opened': emptyProps(),

        // Actions
        'Generated Data Feed Retrieved': props<{ data: GeneratedDataFeedResponse }>(),
        'Generated Data Feed Retrieved Error': props<{ error: any }>(),
        'Password Feed Retrieved': props<{ data: PasswordFeedResponse }>(),
        'Password Feed Retrieved Error': props<{ error: any }>(),
        'Archived Password Feed Retrieved': props<{ data: ArchivedPasswordFeedResponse }>(),
        'Archived Password Feed Retrieved Error': props<{ error: any }>(),
        'Deleted Password Feed Retrieved': props<{ data: DeletedPasswordFeedResponse }>(),
        'Deleted Password Feed Retrieved Error': props<{ error: any }>(),
        'Password Group Feed Retrieved': props<{ data: PasswordGroupFeedResponse }>(),
        'Password Group Feed Stored': emptyProps(),
        'Password Group Feed Retrieved Error': props<{ error: any }>(),
        'Vault Feed Retrieved': props<{ data: ListPasswordVaultResponse }>(),
        'Vault Feed Stored': emptyProps(),
        'Vault Feed Retrieved Error': props<{ error: any }>(),
        'Label Feed Retrieved': props<{ data: LabelFeedResponse }>(),
        'Label Feed Retrieved Error': props<{ error: any }>(),
        'Action Feed Retrieved': props<{ data: ActionFeedResponse }>(),
        'Action Feed Retrieved Error': props<{ error: any }>(),
        'Connection Feed Retrieved': props<{ data: ConnectionFeedResponse }>(),
        'Connection Feed Retrieved Error': props<{ error: any }>(),
        'Local Data Changed': emptyProps(),

        'Immediately Run Feed': emptyProps(),
        'Immediately Run Label Feed': emptyProps(),

        'Update Last Password Change Timestamp': emptyProps(),
        'Update Last Archived Password Change Timestamp': emptyProps(),
        'Update Last Deleted Password Change Timestamp': emptyProps(),
        'Update Last Password Group Change Timestamp': emptyProps(),
        'Update Last Label Change Timestamp': emptyProps(),
        'Update Last Vault Change Timestamp': emptyProps(),
        'Update Loaded Password Count': props<{ data: number }>(),
        'Update Loaded Archived Password Count': props<{ data: number }>(),
        'Update Loaded Deleted Password Count': props<{ data: number }>(),
        'Update Loaded Password Group Count': props<{ data: number }>(),
        'Update Preload Password Count': props<{ data: number }>(),
        'Update Preload Archived Password Count': props<{ data: number }>(),
        'Update Preload Deleted Password Count': props<{ data: number }>(),
        'Update Preload Password Group Count': props<{ data: number }>(),
        'Update Preload Vault Count': props<{ data: number }>(),
        'Update Loaded Vault Count': props<{ data: number }>(),

        'Update Password Load State': props<{ data: string }>(),
    },
});