import { PasswordVaultData } from "../passwordVault/PasswordVaultData";
import { PasswordExtraField } from "./PasswordExtraField";
import { PasswordHistory } from "./PasswordHistory";

export class InMemoryPassword {
    id: number = 0;
    newPassword: boolean = true;
    email: string;
    name: string;
    key: string;
    metadataKey: string;
    notes: string;
    password: string;
    sharedNotes: string;
    url: string;
    username: string;
    createdDate: string;
    actualCreatedDate: number;
    favorite: number;
    keyGroup: number;
    lastUpdate: string;
    actualLastUpdate: number;
    owner: number;
    passwordLastUpdate: string;
    actualPasswordLastUpdate: number;
    stats: string;
    score: number;
    version: number;
    vault: string;
    vaultData: PasswordVaultData;
    decryptedFromVault: boolean;
    extra: PasswordExtraField[];
    history: PasswordHistory[];
}