import { Injectable } from "@angular/core";
import { PublicPrivateKey } from "@app/model/app/account/PublicPrivateKey";



@Injectable({
    providedIn: 'root'
})
export class RsaKeyOpsService {
    constructor() { }

    /*
    Existing keys use this format
    version = 0
        protected static final int PART_M = 1;
    protected static final int PART_E = 2;
    protected static final int PART_D = 3;
    protected static final int PART_P = 4;
    protected static final int PART_Q = 5;
    protected static final int PART_DP = 6;
    protected static final int PART_DQ = 7;
    protected static final int PART_U = 8;
    */

    async generateRSAKey(): Promise<PublicPrivateKey> {
        // We only have one type of key right now - version 2
        return this.generateRSAKeyv2();
    }


    /**
         * Generate a legacy Passpack encoded RSA key, version 3
         * @returns 
         */
    async generateRSAKeyV3(): Promise<PublicPrivateKey> {
        let self = this;
        const keys = await window.crypto.subtle.generateKey(
            {
                name: "RSASSA-PKCS1-v1_5",
                modulusLength: 4096, //can be 1024, 2048, or 4096
                publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
                hash: { name: "SHA-512" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
            },
            true, //whether the key is extractable (i.e. can be used in exportKey)
            ["sign", "verify"] //can be any combination of "sign" and "verify"
        );
        const exportedPrivateKey = await window.crypto.subtle.exportKey("jwk", keys.privateKey);
        const exportedPublicKey = await window.crypto.subtle.exportKey("jwk", keys.publicKey);
        let returnValue: PublicPrivateKey = new PublicPrivateKey();
        returnValue.publicKey = self.generatePasspackEncodedPublicKey_v3(exportedPublicKey);
        returnValue.privateKey = self.generatePasspackEncodedPrivateKey_v3(exportedPrivateKey);
        return returnValue;

    }


    /**
     * Generate a legacy Passpack encoded RSA key, version 2
     * @returns 
     */
    async generateRSAKeyv2(): Promise<PublicPrivateKey> {
        let self = this;
        const keys = await window.crypto.subtle.generateKey(
            {
                name: "RSASSA-PKCS1-v1_5",
                modulusLength: 1024, //can be 1024, 2048, or 4096
                publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
                hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
            },
            true, //whether the key is extractable (i.e. can be used in exportKey)
            ["sign", "verify"] //can be any combination of "sign" and "verify"
        );
        const exportedPrivateKey = await window.crypto.subtle.exportKey("jwk", keys.privateKey);
        const exportedPublicKey = await window.crypto.subtle.exportKey("jwk", keys.publicKey);
        let returnValue: PublicPrivateKey = new PublicPrivateKey();
        returnValue.publicKey = self.generatePasspackEncodedPublicKey_v2(exportedPublicKey);
        returnValue.privateKey = self.generatePasspackEncodedPrivateKey_v2(exportedPrivateKey);
        return returnValue;

    }

    generatePasspackEncodedPublicKey_v2(publicKey: JsonWebKey): string {
        let separator = "|";
        let key = "2" + separator
            + this.convertStringToPasspackEncodedString_v2(publicKey.n) + separator
            + this.convertStringToPasspackEncodedString_v2(publicKey.e);
        return key;
    }
    generatePasspackEncodedPublicKey_v3(publicKey: JsonWebKey): string {
        let separator = "|";
        let key = "3" + separator
            + this.convertStringToPasspackEncodedString_v3(publicKey.n) + separator
            + this.convertStringToPasspackEncodedString_v3(publicKey.e);
        return key;
    }

    /**
     * This is how the existing application encodes the private key for transmission.
     * @param privateKey 
     * @returns 
     */
    generatePasspackEncodedPrivateKey_v2(privateKey: JsonWebKey): string {
        let separator = "|";
        let key = "2" + separator
            + this.convertStringToPasspackEncodedString_v2(privateKey.n) + separator
            + this.convertStringToPasspackEncodedString_v2(privateKey.e) + separator
            + this.convertStringToPasspackEncodedString_v2(privateKey.d) + separator
            + this.convertStringToPasspackEncodedString_v2(privateKey.p) + separator
            + this.convertStringToPasspackEncodedString_v2(privateKey.q) + separator
            + this.convertStringToPasspackEncodedString_v2(privateKey.dp) + separator
            + this.convertStringToPasspackEncodedString_v2(privateKey.dq) + separator
            + this.convertStringToPasspackEncodedString_v2(privateKey.qi);

        return key;
    }
    /**
     * This is how the existing application encodes the private key for transmission.
     * @param privateKey 
     * @returns 
     */
    generatePasspackEncodedPrivateKey_v3(privateKey: JsonWebKey): string {
        let separator = "|";
        let key = "3" + separator
            + this.convertStringToPasspackEncodedString_v3(privateKey.n) + separator
            + this.convertStringToPasspackEncodedString_v3(privateKey.e) + separator
            + this.convertStringToPasspackEncodedString_v3(privateKey.d) + separator
            + this.convertStringToPasspackEncodedString_v3(privateKey.p) + separator
            + this.convertStringToPasspackEncodedString_v3(privateKey.q) + separator
            + this.convertStringToPasspackEncodedString_v3(privateKey.dp) + separator
            + this.convertStringToPasspackEncodedString_v3(privateKey.dq) + separator
            + this.convertStringToPasspackEncodedString_v3(privateKey.qi);

        return key;
    }

    /**
     * Converts the input to the expected string
     * 
     * Window.subtle.exportKey exports in base64url format.  Passpack expects regular base64 for legacy encryption.
     * 
     * @param input 
     * @returns 
     */
    convertStringToPasspackEncodedString_v2(input: string | undefined): string {
        if (input) {
            input = input
                .replace(/-/g, '+')
                .replace(/_/g, '/');
            return input;
        } else return "";
    }
    convertStringToPasspackEncodedString_v3(input: string | undefined): string {
        if (input) {
            input = input
                .replace(/-/g, '+')
                .replace(/_/g, '/');
            return input;
        } else return "";
    }


}