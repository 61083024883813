import Dexie, { Table } from 'dexie';
import { PasswordFeedEntry } from '@app/model/api/dataFeed/PasswordFeedEntry';
import { PasswordGroupFeedEntry } from '@app/model/api/dataFeed/PasswordGroupFeedEntry';
import { LabelFeedEntry } from '@app/model/api/dataFeed/LabelFeedEntry';
import { GeneratedDataFeedEntry } from '@app/model/api/dataFeed/GeneratedDataFeedEntry';
import { ConnectionFeedEntry } from '@app/model/api/dataFeed/ConnectionFeedEntry';
import { StatusFeedEntry } from '@app/model/app/status/StatusFeedEntry';
import { DataFeedEntry } from '@app/model/app/status/DataFeedEntry';
import { Password } from '@app/model/app/password/Password';
import { PasswordGroup } from '@app/model/app/passwordGroup/PasswordGroup';
import { DeletedPassword } from '@app/model/app/password/DeletedPassword';
import { ListPasswordVaultEntry } from '@app/model/api/passwordVault/ListPasswordVaultEntry';
import { PasswordVaultEntry } from '@app/model/app/passwordVault/PasswordVaultEntry';


export class AppDBService extends Dexie {
  databaseVersion: number = 4;
  databaseName: string = 'passpack';
  private consoleLog: boolean = false;

  // Table definitions
  passwords!: Table<Password, number>;
  passwordGroups!: Table<PasswordGroup, number>;
  labels!: Table<LabelFeedEntry, number>;
  generatedData!: Table<GeneratedDataFeedEntry, string>;
  connections!: Table<ConnectionFeedEntry, number>;
  statusFeed!: Table<StatusFeedEntry, number>;
  dataFeed!: Table<DataFeedEntry, number>;
  passwordDeletes!: Table<DeletedPassword, string>;
  vaults!: Table<PasswordVaultEntry, string>;

  constructor(databaseName: string) {
    super(databaseName);
    if (this.consoleLog) console.log("initializing appdb with name " + databaseName);
    this.databaseName = databaseName;


    this.version(this.databaseVersion).stores({
      passwords: 'id, syncedInMemory, favorite',
      passwordGroups: 'id, syncedInMemory',
      passwordDeletes: 'id, syncedInMemory',
      vaults: 'vaultId, syncedInMemory',
      labels: 'id',
      generatedData: 'uid',
      connections: 'id',
      statusFeed: 'feed',
      dataFeed: 'feed',
    });

    let self = this;
    this.open().then(function (db) {
      // Database opened successfully
      if (self.consoleLog) console.log("idb database " + databaseName + " opened successfully");
    }).catch(function (err) {
      // Error occurred
      console.error(err);
    });
  }

}
