import { PasswordExtraField } from "./PasswordExtraField";
import { PasswordHistory } from "./PasswordHistory";

export class EncryptedPasswordContents {
    name: string;
    url: string;
    username: string;
    email: string;
    password: string;
    sharedNotes: string;
    extra: PasswordExtraField[];
    history: PasswordHistory[];
}