
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const DrawerActions = createActionGroup({
    source: 'Drawer',
    events: {
        // Page opens


        // Other
        'Show Right Drawer': emptyProps(),
        'Hide Right Drawer': emptyProps(),
    },
});