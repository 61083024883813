import { ActionReducer, createReducer, on } from '@ngrx/store';
import { LoginActions } from '../actions/LoginActions';
import { NotificationsState } from '@app/model/app/notifications/NotificationsState';
import { NotificationsActions } from '../actions/NotificationsActions';

export const initialState: NotificationsState = new NotificationsState();

export const notificationsReducer: ActionReducer<NotificationsState> = createReducer(
    initialState,
    on(LoginActions.logoutComplete, (state, action) => {
        return {
            ...initialState
        };
    }),

    on(NotificationsActions.showNotifications, (state, action) => {
        return {
            ...state,
            showNotifications: true,
        };
    }),
    on(NotificationsActions.hideNotifications, (state, action) => {
        return {
            ...state,
            showNotifications: false,
        };
    }),


);