import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable, of } from "rxjs";
import { tap, withLatestFrom } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { AccountActions } from "../actions/AccountActions";
import { StatusPollerService } from "@app/services/polling/status-poller.service";
import { LoginActions } from "../actions/LoginActions";
import { StatsPollerService } from "@app/services/polling/StatsPollerService";

@Injectable()
export class SystemEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private statusPollerService: StatusPollerService,
        private statsPollerService: StatsPollerService) { }



    /**
     * Tell the status poller we are ready to receive data
     */
    readonly startStatusPoller$ = createEffect(
        () => this.actions$.pipe(
            ofType(AccountActions.accountDetailsLoaded),
            tap(() => {
                this.statusPollerService.startUpdateCheck();
                this.statsPollerService.startStatsPoller();
            }
            ),
        ), { dispatch: false }
    );

    /**
    * Tell the status poller we need to stop receiving data
    */
    readonly stoptStatusPoller$ = createEffect(
        () => this.actions$.pipe(
            ofType(LoginActions.logoutInitiate, LoginActions.lightweightLogout),
            tap(() => this.statusPollerService.stopUpdateCheck()),
        ), { dispatch: false }
    );

}
