import { Injectable } from '@angular/core';
import { StorageKeys } from '@app/model/app/storage/StorageKeys';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {

    constructor() {
    }

    /////////////////////////////// Session Storage ///////////////////////////////

    saveStateToSessionStorage(key: string, value: any) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    loadStateFromSessionStorage(key: string): any {
        return JSON.parse(sessionStorage.getItem(key)!);
    }
    removeStateFromSessionStorage(key: string) {
        sessionStorage.removeItem(key);
    }
    clearAllStateFromSessionStorage() {
        sessionStorage.clear();
    }

    /////////////////////////////// Local Storage ///////////////////////////////
    /**
     * Local Storage 
     * @param key 
     * @param value 
     */
    saveStateToLocalStorage(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }
    loadStateFromLocalStorage(key: string): any {
        return JSON.parse(localStorage.getItem(key)!);
    }
    removeStateFromLocalStorage(key: string) {
        localStorage.removeItem(key);
    }
    clearAllStateFromLocalStorage() {
        localStorage.clear();
    }

    /////////////////////////////// General Functions ///////////////////////////////
    clearAllState() {
        // Store the method of login so it is remembered for the next time they login.
        let preferredMethodOfLogin = this.loadStateFromLocalStorage(StorageKeys.PREFERRED_METHOD_OF_LOGIN);

        this.clearAllStateFromSessionStorage();
        this.clearAllStateFromLocalStorage();

        if (preferredMethodOfLogin) {
            this.saveStateToLocalStorage(StorageKeys.PREFERRED_METHOD_OF_LOGIN, preferredMethodOfLogin);
        }
    }


}