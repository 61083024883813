import { AccountState } from "@app/model/app/account/AccountState";
import { OrganizationState } from "@app/model/app/organization/OrganizationState";
import { createFeatureSelector, createSelector } from "@ngrx/store";

const feature = createFeatureSelector<OrganizationState>('organizationState');

export const selectOrganizationListResponse = createSelector(
    feature,
    state => state.organizationListResponse
);
export const selectActiveOrganization = createSelector(
    feature,
    state => state.activeOrganization
);
export const selectOrganizationLicenses = createSelector(
    feature,
    state => state.organizationLicenseListResponse
);
export const selectOrganizationSettings = createSelector(
    feature,
    state => state.organizationSettingsResponse
);
export const selectState = createSelector(
    feature,
    state => state
);


export const selectOrganizationModel = createSelector(
    selectState,
    (state) => ({
        state,
    })
);