import { SyncStatus } from "@app/model/app/database/SyncStatus";
import { ArchivedPasswordFeedEntry } from "@app/model/api/dataFeed/ArchivedPasswordFeedEntry";

export class ArchivedPassword extends ArchivedPasswordFeedEntry {
    syncedInMemory: number = SyncStatus.SYNC_STATUS_NOT_SYNCED;


    constructor(pass: ArchivedPasswordFeedEntry) {
        super(pass);
    }
}