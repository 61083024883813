import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { SubscriptionSummaryService } from '../subscription/SubscriptionSummaryService';
import { SubscriptionSku } from '@app/model/app/subscription/SubscriptionSku';

@Injectable({ providedIn: 'root' })
export class AuthGuardBusinessSubscriptionRequired {
    consoleLog: boolean = false;

    constructor(
        private router: Router,
        private subscriptionSummaryService: SubscriptionSummaryService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let result = false;
        // See if the user is cleared to view account from the loginfunnelservice
        if (this.subscriptionSummaryService.extractedSubscriptionSku === SubscriptionSku.BUSINESS) {
            result = true;
        } else {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/passwords'], { queryParams: {} });
            result = false;
        }
        if (this.consoleLog) console.log('AuthGuardBusinessSubscriptionRequired#canActivate called with state.url: ' + state.url + " and route.url: " + route.url + " and sub: " + this.subscriptionSummaryService.extractedSubscriptionSku + " and result: " + result);

        return result;
    }

}