import { TeamVersionsEnum } from "@app/model/app/passwordGroup/TeamVersionsEnum";

export class CreateTeamRequest {
    name: string = '';
    color: string = '';
    description: string = '';
    metadataKey: string = '';
    encryptedGroupKey: string = '';
    encryptedTeamKeyForVault: string = '';
    data: string = '';
    version: number = TeamVersionsEnum.V2;
    vaultId: string = '';
    defaultRoleForGroupMembers: number = 0;
    defaultTeam: boolean = false;
}