import { SyncStatus } from "@app/model/app/database/SyncStatus";
import { DeletedPasswordFeedEntry } from "@app/model/api/dataFeed/DeletedPasswordFeedEntry";

export class DeletedPassword extends DeletedPasswordFeedEntry {
    syncedInMemory: number = SyncStatus.SYNC_STATUS_NOT_SYNCED;


    constructor(deletedPassword: DeletedPasswordFeedEntry) {
        super(deletedPassword);
    }
}