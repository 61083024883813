
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const NotificationsActions = createActionGroup({
    source: 'Notifications',
    events: {
        // Page opens


        // Other
        'Show Notifications': emptyProps(),
        'Hide Notifications': emptyProps(),
        'Ready Show Notifications': emptyProps(),
    },
});