import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AesEncrypt } from '../encryption/AesEncrypt';
import { RSAEncrypt } from '../encryption/RsaEncrypt';
import { AccountKeysService } from '../account/AccountKeysService';
import { BaseAPIService } from '../BaseAPIService';
import { CreatePasswordVaultResponse } from '@app/model/api/passwordVault/CreatePasswordVaultResponse';
import { CreatePasswordVaultRequest } from '@app/model/api/passwordVault/CreatePasswordVaultRequest';
import { AccountKeys } from '@app/model/app/account/AccountKeys';
import { environment } from '@environments/environment';
import { StorageActions } from '@app/store/actions/StorageActions';
import { HttpParams } from '@angular/common/http';
import { ListPasswordVaultResponse } from '@app/model/api/passwordVault/ListPasswordVaultResponse';
import { firstValueFrom, Observable, of } from 'rxjs';
import { PasswordVault } from '@app/model/app/passwordVault/PasswordVault';
import { InMemoryPassword } from '@app/model/app/password/InMemoryPassword';
import { PasswordVaultData } from '@app/model/app/passwordVault/PasswordVaultData';
import { AssociatePasswordsWithVaultRequest } from '@app/model/api/passwordVault/AssociatePasswordsWithVaultRequest';
import { AssociatePasswordWithVaultEntry } from '@app/model/api/passwordVault/AssociatePasswordWithVaultEntry';
import { AssociatePasswordsWithVaultResponse } from '@app/model/api/passwordVault/AssociatePasswordsWithVaultResponse';
import { StorageService } from '../dataStorage/storage.service';
import { CreateNewVaultResponse } from '@app/model/app/passwordVault/CreateNewVaultResponse';
import { BulkUserPublicKeyEntry } from '@app/model/api/connection/BulkUserPublicKeyEntry';
import { AddLicenseHoldersToVaultResponse } from '@app/model/api/passwordVault/AddLicenseHoldersToVaultResponse';
import { AddLicenseHoldersToVaultRequest } from '@app/model/api/passwordVault/AddLicenseHoldersToVaultRequest';
import { AddLicenseHolderToVaultEntry } from '@app/model/api/passwordVault/AddLicenseHolderToVaultEntry';
import { RsaKeyOpsService } from '../encryption/RsaKeyOpsService';
import { TeamListEntryForVaultAssociation } from '@app/model/app/passwordGroup/TeamListEntryForVaultAssociation';
import { AssociateTeamsWithVaultRequest } from '@app/model/api/passwordVault/AssociateTeamsWithVaultRequest';
import { AssociateTeamWithVaultRequestEntry } from '@app/model/api/passwordVault/AssociateTeamWithVaultRequestEntry';
import { AssociateTeamsWithVaultResponse } from '@app/model/api/passwordVault/AssociateTeamsWithVaultResponse';
import { VaultTypes } from '@app/model/api/passwordVault/VaultTypes';
import { OrganizationService } from '../organization/Organization.service';
import { VaultVersions } from '@app/model/api/passwordVault/VaultVersions';
import { VaultFeedStorageService } from '../dataStorage/VaultFeedStorageService';
import { OrganizationLicenseService } from '../organization/OrganizationLicenseService';
import { PasswordVaultRolesEnum } from '@app/model/app/passwordVault/PasswordVaultRolesEnum';
import { TeamPublicKeyService } from '../teams/TeamPublicKeyService';
import { QuickConvertTeamToMultiAdminArgs } from '@app/model/app/passwordGroup/QuickConvertTeamToMultiAdminArgs';
import { SubscriptionSummaryService } from '../subscription/SubscriptionSummaryService';
import { PasswordVaultActions } from '@app/store/actions/PasswordVaultActions';
import { AccountService } from '../account/account.service';
import { OrganizationRolesEnum } from '@app/model/app/organization/OrganizationRolesEnum';
import { OrganizationLicenseListEntryDisplay } from '@app/model/app/organization/OrganizationLicenseListEntryDisplay';
import { EncryptionValues } from '@app/model/app/EncryptionValues';
import { RandomStringGeneratorService } from '../utilities/RandomStringGeneratorService';
@Injectable({
  providedIn: 'root'
})
export class PasswordVaultService {
  private consoleLog: boolean = false;
  private vaultsSetup: boolean = false;

  constructor(
    private store: Store,
    private aesEncrypt: AesEncrypt,
    private rsaEncrypt: RSAEncrypt,
    private accountService: AccountService,
    private accountKeysService: AccountKeysService,
    private baseAPIService: BaseAPIService,
    private storageService: StorageService,
    private rsaKeyOps: RsaKeyOpsService,
    private organizationService: OrganizationService,
    private vaultFeedStorageService: VaultFeedStorageService,
    private organizationLicenseService: OrganizationLicenseService,
    private teamPublicKeyService: TeamPublicKeyService,
    private subscriptionSummaryService: SubscriptionSummaryService,
    private randomStringGeneratorService: RandomStringGeneratorService,

  ) {

  }

  getPrivateVault(): PasswordVaultData | null {
    let vaults: PasswordVaultData[] = this.getVaultsFromInMemory();
    let returnVault: PasswordVaultData | null = null;
    for (let i = 0; i < vaults.length; i++) {
      let vault = vaults[i];
      if (vault.vaultType === VaultTypes.PASSWORD_VAULT_PRIVATE) {
        returnVault = vault;
        break;
      }
    }
    return returnVault;
  }

  /**
   * This will return the shared organization vault for the active organization.
   * @returns 
   */
  getMyOrganizationVault(): PasswordVaultData | null {
    return this.getMyOrganizationVaultByType(VaultTypes.PASSWORD_VAULT_SHARED_ORGANIZATION);
  }
  /**
   * Finds the organization vault for archive
   * @returns 
   */
  getOrganizationArchiveVault(): PasswordVaultData | null {
    return this.getMyOrganizationVaultByType(VaultTypes.PASSWORD_VAULT_ORGANIZATION_ARCHIVE);
  }

  /**
   * Finds the specified vault
   * @param vaultType 
   * @returns 
   */
  getMyOrganizationVaultByType(vaultType: number): PasswordVaultData | null {
    let vaults: PasswordVaultData[] = this.getVaultsFromInMemory();
    let returnVault: PasswordVaultData | null = null;
    let myOrganizationId: string = this.organizationService.getWorkingOrganizationId();
    for (let i = 0; i < vaults.length; i++) {
      let vault = vaults[i];
      if (vault.vaultType === vaultType
        && vault.ownerOrganizationId === myOrganizationId) {
        returnVault = vault;
        break;
      }
    }
    return returnVault;
  }

  getVaultData(vaultId: string): PasswordVaultData | null {
    let vaults = this.getVaultsFromInMemory();
    for (let vault of vaults) {
      if (vault.id === vaultId) {
        return vault;
      }
    }
    return null;
  }

  /**
   * This method is called by the store after packing key decryption to check if the vaults have been setup for the user.
   */
  initiateCheckForVaultCreation() {
    // console.log("0000000010010101010100");
    if (!this.vaultsSetup) {
      let myCustomerId = this.accountService.getCustomerId();
      if (myCustomerId > 0) {
        this.setupVaultsIfNotPresent(myCustomerId);
      }
    }
  }

  /**
   * Checks the server to see if the user vaults have been created. 
   * If not, it will create:
   * personal vault: everyone
   */
  async setupVaultsIfNotPresent(myCustomerId: number): Promise<void> {
    let vaults = this.generateIdToVaultsWithEncryptionKeysForCurrentVaults();

    let workingOrganizationOwnerId = this.organizationService.getWorkingOrganizationOwnerId();

    if (this.consoleLog) {
      console.log("setupVaultsIfNotPresent", this.vaultsSetup, vaults, workingOrganizationOwnerId, myCustomerId);
    }
    if (!this.vaultsSetup && vaults.size !== 2 && workingOrganizationOwnerId > 0 && myCustomerId === workingOrganizationOwnerId) {
      if (this.consoleLog) console.log("setupVaultsIfNotPresent");

      let hasSharedOrganizationVault = false;
      let hasOrganizationArchiveVault = false;

      // Ensure we have pulled from the server.
      let serverResponse = await firstValueFrom(this.retrievePasswordVaultsFromServer());
      let processVaultFeedResult = await this.vaultFeedStorageService.processVaultFeed(serverResponse, false);
      let loadUnsyncedVaultsResult = await this.vaultFeedStorageService.loadUnsyncedVaults();

      // Recheck the vaults
      vaults = this.generateIdToVaultsWithEncryptionKeysForCurrentVaults();

      // Determine which vaults need to be created.
      if (this.consoleLog) console.log("vaults", vaults);
      let vaultKeys = vaults.keys();
      // iterate over each of the vault keys of the map vaults and determine if the vaults are present.
      for (let key of vaultKeys) {
        let vault = vaults.get(key);
        if (vault) {

          if (vault.vaultType === VaultTypes.PASSWORD_VAULT_SHARED_ORGANIZATION) {
            hasSharedOrganizationVault = true;
          } else if (vault.vaultType === VaultTypes.PASSWORD_VAULT_ORGANIZATION_ARCHIVE) {
            hasOrganizationArchiveVault = true;
          }
        }
      }

      if (this.consoleLog) console.log("hasSharedOrganizationVault", hasSharedOrganizationVault);

      if (!hasSharedOrganizationVault) {
        let result2: CreateNewVaultResponse = await this.createNewVault(
          "Organization Sharing Vault",
          "Where all the passwords go to share",
          VaultVersions.VERSION_1,
          VaultTypes.PASSWORD_VAULT_SHARED_ORGANIZATION);
        if (this.consoleLog) console.log("Creation of vault result: ", result2);

        // add licensees to the shared organization vault (if it exists)
        if (result2.serverResponse.status.code === 0) {
          await this.addConnectionsAsVaultLicensees(myCustomerId, result2.serverResponse.vaultId, result2.vaultDataKey);
          this.vaultsSetup = true;
        }
      }
      if (!hasOrganizationArchiveVault) {
        let result2: CreateNewVaultResponse = await this.createNewVault(
          "Organization Archive Vault",
          "Archived",
          VaultVersions.VERSION_1,
          VaultTypes.PASSWORD_VAULT_ORGANIZATION_ARCHIVE);
        if (this.consoleLog) console.log("Creation of vault result: ", result2);


        // Need to add other admins
        if (result2.serverResponse.status.code === 0) {
          await this.addExistingAdminsAsVaultMembers(myCustomerId, result2.serverResponse.vaultId, result2.vaultDataKey);
          this.vaultsSetup = true;
        }
      }

    } else {
      if (this.consoleLog) console.log("not creating vaults");
    }
  }

  /**
   * Adds the current connections as licensees to the shared organization vault
   * @param myCustomerId
   * @param vaultId 
   * @param vaultDataKey 
   */
  async addConnectionsAsVaultLicensees(myCustomerId: number, vaultId: string, vaultDataKey: string) {
    // Vault created successfully.  Now find all licensees of this org and add them to the vault as read only.

    let licensedUsers = this.organizationLicenseService.licensedUsers;
    // console.log("licensedUsers", licensedUsers);
    // generate a list of the licensedUserIds and remove the current user
    let licensedUserIds = licensedUsers.map((user) => user.customerId);
    let index = licensedUserIds.indexOf(myCustomerId);
    if (index > -1) {
      licensedUserIds.splice(index, 1);
    }
    if (this.consoleLog) console.log("licensedUserIds", licensedUserIds);
    // get the public keys for each of the licensed users
    let publicKeysResponse = await firstValueFrom(this.teamPublicKeyService.getConnectionPublicKeys(licensedUserIds));
    let connectionKeys = publicKeysResponse.publicKeys;
    if (this.consoleLog) console.log("connectionKeys", connectionKeys);

    //Add the licensees to the shared organization vault
    await this.ensureTeamMemberIsAddedToSharedVault(
      vaultId,
      vaultDataKey,
      PasswordVaultRolesEnum.USER,
      connectionKeys);
  }


  async addExistingAdminsAsVaultMembers(myCustomerId: number, vaultId: string, vaultDataKey: string) {
    // Vault created successfully.  Now find all admins who should also be added

    let licensedUsers = this.organizationLicenseService.licensedUsers;
    let adminUsers: OrganizationLicenseListEntryDisplay[] = [];

    // Iterate over the licensedUSers and find those with the admin role
    for (let i = 0; i < licensedUsers.length; i++) {
      let user = licensedUsers[i];
      // determine if the admin role i found in the roles array of the user and remove the current user
      let roles = user.roles;
      let index = roles.indexOf(OrganizationRolesEnum.ROLE_ADMIN);
      if (index > -1 && user.customerId !== myCustomerId) {
        adminUsers.push(user);
      }
    }

    // console.log("licensedUsers", licensedUsers);
    // generate a list of the licensedUserIds 
    let adminUserIds = adminUsers.map((user) => user.customerId);

    if (this.consoleLog) console.log("adminUserIds", adminUserIds);
    // get the public keys for each of the licensed users
    let publicKeysResponse = await firstValueFrom(this.teamPublicKeyService.getConnectionPublicKeys(adminUserIds));
    let adminKeys = publicKeysResponse.publicKeys;
    if (this.consoleLog) console.log("adminKeys", adminKeys);

    //Add the licensees to the shared organization vault
    await this.ensureTeamMemberIsAddedToSharedVault(
      vaultId,
      vaultDataKey,
      PasswordVaultRolesEnum.ADMIN,
      adminKeys);
  }


  addApiUserToOrgKeys(apiServiceAccountId: number) {
    this.organizationService.addApiUserToOrgKeys(apiServiceAccountId);
    this.addAPIUserAsVaultMembers(apiServiceAccountId);
  }

  async addAPIUserAsVaultMembers(apiServiceAccountId: number) {

    // Creates an array of user account ids which should be allowed access to the vault. For now, this is just the API service account id.
    let apiUserIds = [apiServiceAccountId];

    if (this.consoleLog) console.log("apiUserIds", apiUserIds);
    // get the public keys for each of the licensed users
    let publicKeysResponse = await firstValueFrom(this.teamPublicKeyService.getConnectionPublicKeys(apiUserIds));
    let apiKeys = publicKeysResponse.publicKeys;
    if (this.consoleLog) console.log("apiKeys", apiKeys);

    // Get the shared organization vault
    let vault = this.getMyOrganizationVault();
    if (vault) {
      //Add the licensees to the shared organization vault
      await this.ensureTeamMemberIsAddedToSharedVault(
        vault.id,
        vault.dataKey,
        PasswordVaultRolesEnum.ADMIN,
        apiKeys);
    }
  }


  /**
   * Sets up the teams to be associated with the vault and usable for multi-admin
   * 
   * @param teams
   * @param vaultData 
   */
  async associateSharedTeamsWithVault(teams: TeamListEntryForVaultAssociation[], vaultData: PasswordVaultData): Promise<AssociateTeamsWithVaultResponse> {

    let request = new AssociateTeamsWithVaultRequest();
    request.vaultId = vaultData.id;

    // for each team echo the name
    for (let i = 0; i < teams.length; i++) {
      let team = teams[i];
      if (this.consoleLog) console.log(team);
      // Encrypt the team key with the vault public key.  This will make it so admins with the vault key can decrypt and share the data.
      // This encryptedTeamKey will be stored with the team as well as the vault id.
      let encryptedTeamKey = await this.rsaEncrypt.rsaEncrypt(team.groupKey, vaultData.decryptedPublicKey);
      let entry = new AssociateTeamWithVaultRequestEntry();
      entry.teamId = team.id;
      entry.encryptedTeamKey = encryptedTeamKey.data;
      request.entries.push(entry);
    }

    // // temp clear entries
    // request.entries = [];

    var promise = new Promise<AssociateTeamsWithVaultResponse>((resolve, reject) => {

      let url = environment.API_BASE_URL + "v1/secure/vault/associateTeams";
      let body = JSON.stringify(request);
      this.baseAPIService.putRequestNoErrorHandlingApplicationJson<AssociateTeamsWithVaultResponse>(body, url).subscribe((response) => {
        // // Pull in the new vault
        this.store.dispatch(StorageActions.immediatelyRunFeed());

        // resolve the promise
        resolve(response);
      });
    });

    return promise;

  }




  /**
   * Returns a map comprised of the id and name of the vaults
   * @returns 
   */
  generateIdToNameMapForCurrentVaults(): Map<string, PasswordVaultData> {
    let vaults: PasswordVaultData[] = this.getVaultsFromInMemory();
    let map = new Map<string, PasswordVaultData>();
    for (let i = 0; i < vaults.length; i++) {
      let vault = vaults[i];
      map.set(vault.id, vault);
    }
    return map;
  }

  /**
   * This will return the map of vaults which I have the encryption key for. This signifies that the
   * user is an admin
   * @returns 
   */
  generateIdToVaultsWithEncryptionKeysForCurrentVaults(): Map<string, PasswordVaultData> {
    let vaults: PasswordVaultData[] = this.getVaultsFromInMemory();
    let map = new Map<string, PasswordVaultData>();
    for (let i = 0; i < vaults.length; i++) {
      let vault = vaults[i];
      if (vault.encryptionKey && vault.encryptionKey.length > 0) {
        map.set(vault.id, vault);
      }
    }

    return map;
  }

  /**
  * This will return the map of vaults which I have the encryption key for. This signifies that the
  * user is an admin
  * @param vaultType
  * @returns 
  */
  generateIdToVaultsWithEncryptionKeysForCurrentVaultsWithSpecifiedType(vaultType: number): Map<string, PasswordVaultData> {
    let vaults: PasswordVaultData[] = this.getVaultsFromInMemory();
    let map = new Map<string, PasswordVaultData>();
    for (let i = 0; i < vaults.length; i++) {
      let vault = vaults[i];
      if (vault.encryptionKey && vault.encryptionKey.length > 0 && vault.vaultType === vaultType) {
        map.set(vault.id, vault);
      }
    }

    return map;
  }

  /**
   * Return whether the user is a multi-admin vault user
   */
  isMultiAdminVaultUser(): boolean {
    let vaults = this.generateIdToVaultsWithEncryptionKeysForCurrentVaults();
    // console.log("vaults", vaults);
    // console.log("feature", this.appService.hasFeature(AppFeatureEnum.MULTI_ADMIN));
    // must be on business plan
    //          if (this.subscriptionData && this.subscriptionData.subscriptionSummary && this.subscriptionData.subscriptionSummary.subscriptionSku === "business") {

    if (vaults.size >= 1 && this.subscriptionSummaryService.isBusinessPlanSubscription()) {
      return true;
    } else {
      return false;
    }
  }

  /**
  * Return whether the user is an archive user
  */
  isArchiveUser(): boolean {
    let vaults = this.generateIdToVaultsWithEncryptionKeysForCurrentVaults();

    if (vaults.size >= 1 && this.subscriptionSummaryService.isBusinessPlanSubscription()) {
      // Determine if there is a vault with the archive type
      let vaultKeys = vaults.keys();
      for (let key of vaultKeys) {
        let vault = vaults.get(key);
        if (vault && vault.vaultType === VaultTypes.PASSWORD_VAULT_ORGANIZATION_ARCHIVE) {
          return true;
        }
      }

    }
    return false;
  }

  /**
   * Synchronizes the vault data to the store
   */
  syncVaultsToStore() {
    let currentVaultData: PasswordVaultData[] = this.getVaultsFromInMemory();
    this.store.dispatch(PasswordVaultActions.updateVaultList({ data: currentVaultData }));
  }


  /**
       * Retrieves the vaults from the in memoryDatabase
       * @returns 
       */
  getVaultsFromInMemory(): PasswordVaultData[] {
    let database = this.storageService.inMemorySQLService?.getDatabase();
    let vaults: PasswordVaultData[] = [];
    if (database) {
      let selectVaultSQL = `SELECT * FROM passwordVault`;

      const stmtSelectVault = database.prepare(selectVaultSQL);

      while (stmtSelectVault.step()) {
        let row = stmtSelectVault.getAsObject();
        let vault: PasswordVaultData = new PasswordVaultData();
        vault.id = row.id as string;
        vault.dataKey = row.dataKey as string;
        vault.encryptionKey = row.encryptionKey as string;
        vault.name = row.name as string;
        vault.description = row.description as string;
        vault.vaultType = row.vaultType as number;
        vault.version = row.version as number;
        vault.role = row.role as number;
        vault.ownerName = row.ownerName as string;
        vault.ownerOrganizationId = row.ownerOrganizationId as string;
        vault.ownerCustomerId = row.ownerCustomerId as number;
        vault.decryptedPrivateKey = row.decryptedPrivateKey as string;
        vault.decryptedPublicKey = row.decryptedPublicKey as string;
        vaults.push(vault);
      }
      stmtSelectVault.free();
    }
    return vaults;
  }

  /**
   * Retrieve the password vaults from the server
   * @returns 
   */
  retrievePasswordVaultsFromServer(): Observable<ListPasswordVaultResponse> {
    let url = environment.API_BASE_URL + "v1/secure/vault"
    var params = new HttpParams();
    return this.baseAPIService.getRequest<ListPasswordVaultResponse>(params, url);
  }

  async ensureTeamMemberIsAddedToSharedVault(vaultId: string, dataKey: string, role: number, keys: BulkUserPublicKeyEntry[]): Promise<AddLicenseHoldersToVaultResponse> {
    if (this.consoleLog) console.log("ensureTeamMemberIsAddedToSharedVault ");
    let request = new AddLicenseHoldersToVaultRequest();
    request.vaultId = vaultId;

    // Generate the encrypted data key for each of the keys.
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];

      if (key.publicKey && key.publicKey.length > 0) {
        let entry = new AddLicenseHolderToVaultEntry();

        // If this is an admin then we can set the encryption key.

        if (role === PasswordVaultRolesEnum.ADMIN) {
          let passwordVaultForOrganization = this.getMyOrganizationVault();
          if (passwordVaultForOrganization) {
            let encryptedEncryptionKey = await this.rsaEncrypt.rsaEncrypt(passwordVaultForOrganization.encryptionKey, key.publicKey);
            entry.encryptionKey = encryptedEncryptionKey.data;
          }
        }


        let encryptedDataKey = await this.rsaEncrypt.rsaEncrypt(dataKey, key.publicKey);

        entry.dataKey = encryptedDataKey.data;
        entry.role = role;
        entry.customerId = key.connectionId;
        request.entries.push(entry);
      }
    }

    let url = environment.API_BASE_URL + "v1/secure/vault/addMember";
    let body = JSON.stringify(request);
    var promise = new Promise<AddLicenseHoldersToVaultResponse>((resolve, reject) => {
      resolve(firstValueFrom(this.baseAPIService.putRequestNoErrorHandlingApplicationJson<AddLicenseHoldersToVaultResponse>(body, url)));
    });
    return promise;
  }


  /**
   * Create a new vault with the specified information.
   * @param name 
   * @param description 
   * @param version 
   * @param vaultType 
   * @returns 
   */
  async createNewVault(name: string, description: string, version: number, vaultType: number): Promise<CreateNewVaultResponse> {
    if (this.consoleLog) console.log("createNewVault " + name + ", " + description + ", " + vaultType);

    // create the response object
    let responseObject = new CreateNewVaultResponse();

    // Get my keys
    let accountKeys: AccountKeys = this.accountKeysService.getAccountKeysData();



    // Create the vaultKey which will act as the shared key for the team.
    // Encryption key allows to add new people to the vault. This is reserved for admins.
    // Data key is the key that is used to encrypt the data in the vault. This is for everyone.
    let vaultEncryptionKey = this.randomStringGeneratorService.generateRandomString(EncryptionValues.NEW_RSA_KEY_LENGTH);
    let vaultDataKey = this.randomStringGeneratorService.generateRandomString(EncryptionValues.NEW_RSA_KEY_LENGTH);


    // Create the vault public private keys
    // Generate a new RSA key.
    const newKey = await this.rsaKeyOps.generateRSAKey();
    let encryptedPublicKey = await this.aesEncrypt.aesEncrypt(newKey.publicKey, vaultEncryptionKey);
    let encryptedPrivateKey = await this.aesEncrypt.aesEncrypt(newKey.privateKey, vaultEncryptionKey);
    // console.log("encryptedPublicKey: " + encryptedPublicKey + " decryptedPublicKey: " + newKey.publicKey);
    // console.log("encryptedPrivateKey: " + encryptedPrivateKey + " decryptedPrivateKey: " + newKey.privateKey);

    // Store the keys in the response object in case we need them after this call and before the system syncs
    responseObject.vaultEncryptionKey = vaultEncryptionKey;
    responseObject.vaultDataKey = vaultDataKey;

    var promise = new Promise<CreateNewVaultResponse>((resolve, reject) => {

      let vaultData: PasswordVault = {
        name: name,
        description: description,
        version: version,
        vaultType: vaultType,
      };

      // Encrypt the vault encryption key with the packing key
      this.rsaEncrypt.rsaEncrypt(vaultEncryptionKey, accountKeys.packingKeyPublicKey).then((encryptedVaultEncryptionKey) => {
        if (encryptedVaultEncryptionKey.success) {

          // Encrypt the vault data key  with the packing key.
          this.rsaEncrypt.rsaEncrypt(vaultDataKey, accountKeys.packingKeyPublicKey).then((encryptedVaultDataKey) => {
            if (encryptedVaultDataKey.success) {

              // Encrypt the vault data with the vaultDataKey
              this.aesEncrypt.aesEncrypt(JSON.stringify(vaultData), vaultDataKey).then((encryptedVaultData) => {
                let createVaultRequest = new CreatePasswordVaultRequest();
                createVaultRequest.data = encryptedVaultData;
                createVaultRequest.encryptionKey = encryptedVaultEncryptionKey.data;
                createVaultRequest.dataKey = encryptedVaultDataKey.data;
                createVaultRequest.vaultType = vaultType;
                createVaultRequest.version = version;
                createVaultRequest.publicKey = encryptedPublicKey;
                createVaultRequest.privateKey = encryptedPrivateKey;



                let url = environment.API_BASE_URL + "v1/secure/vault";
                let body = JSON.stringify(createVaultRequest);
                this.baseAPIService.putRequestNoErrorHandlingApplicationJson<CreatePasswordVaultResponse>(body, url).subscribe((createVaultResponse) => {
                  // Pull in the new vault
                  this.store.dispatch(StorageActions.immediatelyRunFeed());

                  // resolve the promise
                  responseObject.serverResponse = createVaultResponse;
                  resolve(responseObject);
                });

              }).catch((error) => {
                console.error("error: " + error);
                reject(error);
              });

            } else {
              if (this.consoleLog) console.log("error: " + encryptedVaultDataKey.error);
              reject(encryptedVaultDataKey.error);
            }
          }).catch((error) => {
            console.error("error: " + error);
            reject(error);
          });

        } else {
          if (this.consoleLog) console.log("error: " + encryptedVaultEncryptionKey.error);
          reject(encryptedVaultEncryptionKey.error);
        }
      }).catch((error) => {
        console.error("error: " + error);
        reject(error);
      });

    });

    return promise;
  }

  async associatePasswordsWithVault(passwords: InMemoryPassword[], vault: PasswordVaultData): Promise<AssociatePasswordsWithVaultResponse> {
    if (this.consoleLog) console.log("associatePasswordsWithVault ", passwords, vault.id);

    // build up the request
    let request = new AssociatePasswordsWithVaultRequest();

    request.vaultId = vault.id;
    if (vault.encryptionKey) {
      for (let i = 0; i < passwords.length; i++) {
        let password = passwords[i];
        // Get the password key and encrypt it with the vault data key.
        let passwordKey = password.key;
        let encryptedPasswordKey = await this.aesEncrypt.aesEncrypt(passwordKey, vault.dataKey);
        let entry = new AssociatePasswordWithVaultEntry();
        entry.passwordId = password.id;
        entry.encryptedPasswordKey = encryptedPasswordKey;
        request.entries.push(entry);
      }
    }

    var promise = new Promise<AssociatePasswordsWithVaultResponse>((resolve, reject) => {

      let url = environment.API_BASE_URL + "v1/secure/vault/associatePasswords";
      let body = JSON.stringify(request);
      this.baseAPIService.putRequestNoErrorHandlingApplicationJson<AssociatePasswordsWithVaultResponse>(body, url).subscribe((response) => {
        // // Pull in the new vault
        // this.store.dispatch(StorageActions.immediatelyRunFeed());

        // resolve the promise
        resolve(response);
      });
    });

    return promise;
  }


  /*
  Performs the work of converting a team to a multi-admin team.  This will associate the team with the vault and the passwords with the vault.
  */
  quickConvertTeamToMultiAdmin(data: QuickConvertTeamToMultiAdminArgs) {


    // now we have the passwords to associate, we need to associate them with the vault
    if (data.passwordsToAssociate && data.vaultData && data.passwordsToAssociate.length > 0) {
      // find the vault id.  This should be the only vault in the list of encrypted vaults
      this.associatePasswordsWithVault(data.passwordsToAssociate, data.vaultData);
    }


    // Now associate the team with the vault.
    // let team: TeamListEntryForVaultAssociation | undefined = this.teamQueryService.retrieveTeamForAssociationWithVaults(data.selectedTeam.id);
    if (data.team && data.team.id > 0) {
      let teams: TeamListEntryForVaultAssociation[] = [];
      teams.push(data.team);
      this.associateSharedTeamsWithVault(teams, data.vaultData);
    }
  }


  updatedVaultList() {

    this.store.dispatch(StorageActions.updateLastVaultChangeTimestamp());

  }

}
