import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PasswordsService } from './PasswordsService';
import { PasswordActions } from '@app/store/actions/PasswordActions';
import { MultiArchivePasswordEntry } from '@app/model/api/passwords/MultiArchivePasswordEntry';
import { PasswordVaultService } from '../passwordVault/password-vault-service.service';
import { PasswordVaultData } from '@app/model/app/passwordVault/PasswordVaultData';
import { MultiDeletePasswordRequest } from '@app/model/api/passwords/MultiDeletePasswordRequest';
import { MultiArchivePasswordRequest } from '@app/model/api/passwords/MultiArchivePasswordRequest';
import { InMemoryPassword } from '@app/model/app/password/InMemoryPassword';
import { AesEncrypt } from '../encryption/AesEncrypt';
import { DisplayedArchivedPasswordListItem } from '@app/model/app/password/DisplayedArchivedPasswordListItem';
import { environment } from '@environments/environment';
import { HttpParams } from '@angular/common/http';
import { BaseAPIService } from '../BaseAPIService';
import { OrgAdminRestorePasswordResponse } from '@app/model/api/organization/OrgAdminRestorePasswordResponse';
import { MultiUnarchivePasswordRequest } from '@app/model/api/passwords/MultiUnarchivePasswordRequest';
import { MultiUnarchivePasswordEntry } from '@app/model/api/passwords/MultiUnarchivePasswordEntry';
import { MultiUnArchivePasswordResponse } from '@app/model/api/passwords/MultiUnarchivePasswordResponse';


@Injectable({
    providedIn: 'root',
})

export class PasswordArchiveService {
    constructor(
        private passwordsService: PasswordsService,
        private store: Store,
        private passwordVaultService: PasswordVaultService,
        private aesEncrypt: AesEncrypt,
        private baseAPIService: BaseAPIService,

    ) {
    }

    /**
     * Archives the passwords by sending a request to the server.
     * @param passwords 
     * @returns 
     */
    async bulkArchivePasswords(passwords: InMemoryPassword[]): Promise<void> {
        let request = new MultiArchivePasswordRequest();

        // Find the archive vault and ensure we have the encryption material.
        let archiveVault: PasswordVaultData | null = this.passwordVaultService.getOrganizationArchiveVault();
        if (!archiveVault || !archiveVault.dataKey || !archiveVault.encryptionKey) {
            return;
        }

        if (archiveVault.encryptionKey) {

            // Generate the request list that adds a tuple of the password id and the encrypted password key.
            console.log("Archiving passwords: ", passwords);
            for (let i = 0; i < passwords.length; i++) {
                let password = passwords[i];
                // Get the password key and encrypt it with the vault data key.
                let passwordKey = password.key;
                let encryptedPasswordKey = await this.aesEncrypt.aesEncrypt(passwordKey, archiveVault.dataKey);
                let entry = new MultiArchivePasswordEntry();
                entry.id = password.id;
                entry.encryptedPasswordKey = encryptedPasswordKey;
                request.entries.push(entry);
            }

            // Send the request to the server.
            this.passwordsService.archivePasswords(request).subscribe((response) => {
                if (response) {
                    this.store.dispatch(PasswordActions.archivePasswordsSuccess({ passwords }));
                }
            });

        } else {
            console.error("No encryption key found for the archive vault.");
        }
    }


    /**
     * Initiate the password restore process
     * @param data 
     * @returns 
     */
    async unarchivePassword(data: DisplayedArchivedPasswordListItem): Promise<void> {
        let url = environment.API_BASE_URL + "v1/secure/password/unarchive";
        console.log(data);
        let request = new MultiUnarchivePasswordRequest();


        let organizationVault: PasswordVaultData | null = this.passwordVaultService.getMyOrganizationVault();
        if (!organizationVault || !organizationVault.dataKey || !organizationVault.encryptionKey) {
            console.error("No organization vault found.");
            return;
        }

        if (organizationVault.encryptionKey) {

            // We need to give the password key encrypted to the organization shared team vault so it can be placed in the main password listing.
            let passwordKey = data.key;
            let encryptedPasswordKey = await this.aesEncrypt.aesEncrypt(passwordKey, organizationVault.dataKey);
            let entry = new MultiUnarchivePasswordEntry();
            entry.id = data.id;
            entry.recoveryId = data.recoveryId;
            entry.encryptedPasswordKey = encryptedPasswordKey;
            request.entries.push(entry);

            let body = JSON.stringify(request);
            this.baseAPIService.postRequestNoErrorHandlingApplicationJson<MultiUnArchivePasswordResponse>(body, url).subscribe(response => {
                if (response) {
                    this.store.dispatch(PasswordActions.unarchivePasswordsSuccess());
                }
            });
        }
    }

}