import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppDBService } from './AppDbService';
import { StorageService } from './storage.service';
import { StorageActions } from '@app/store/actions/StorageActions';
import { Database } from 'sql.js';
import { SyncStatus } from '@app/model/app/database/SyncStatus';
import { PasswordDecryption } from '../encryption/PasswordDecryption';
import { PromiseExtended } from 'dexie';
import { DisplayService } from '../DisplayService';
import { AccountKeysService } from '../account/AccountKeysService';
import { PasswordVaultService } from '../passwordVault/password-vault-service.service';
import { ArchivedPasswordFeedResponse } from '@app/model/api/dataFeed/ArchivedPasswordFeedResponse';
import { ArchivedPassword } from '@app/model/app/password/ArchivedPassword';
@Injectable({
    providedIn: 'root'
})
export class ArchivedPasswordFeedStorageService implements OnInit, OnDestroy {
    consoleLog: boolean = false;

    constructor(
        private store: Store,
        private storageService: StorageService,
        private displayService: DisplayService,
        private passwordDecryption: PasswordDecryption,
        private accountKeysService: AccountKeysService,
        private passwordVaultService: PasswordVaultService
    ) {
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }

    /**
       * Process the deleted password feed and store the lastupdate
       * @param data 
       */
    processArchivedPasswordFeed(data: ArchivedPasswordFeedResponse) {
        if (data && data.passwords && data.passwords.length > 0) {
            let toAdd: ArchivedPassword[] = [];
            let toDelete: number[] = [];

            let start = new Date().getTime();


            data.passwords.forEach(archivedPassword => {


                switch (archivedPassword.action) {
                    case 1:
                    // add
                    case 2:
                        // update
                        // add or update should upsert
                        let passwordEntry = new ArchivedPassword(archivedPassword);
                        passwordEntry.syncedInMemory = SyncStatus.SYNC_STATUS_NOT_SYNCED;
                        toAdd.push(passwordEntry);
                        break;
                    case 3:
                        // delete
                        toDelete.push(archivedPassword.id);
                        break;
                }

            });

            ////////////////////////////////////////////
            // Send to the indexeddb
            ////////////////////////////////////////////
            // Adds or updates
            this.storageService.appDbService?.passwordArchives.bulkPut(toAdd).then() // handle success
                .catch(function (error) { console.log(error); }); // handle failure         

            // Deletes
            this.storageService.appDbService?.passwordArchives.bulkDelete(toDelete).then() // handle success
                .catch(function (error) { console.log(error); }); // handle failure


            let end = new Date().getTime();
            if (this.consoleLog) console.log("Time to process archived password feed: " + (end - start) + " ms");


            ////////////////////////////////////////////
            // Send to in memory store
            ////////////////////////////////////////////
            let database = this.storageService.inMemorySQLService?.getDatabase();
            if (database) {
                // Adds
                // done elsewhere
                // Deletes
                this.processInMemorySQLDeletes(database, toDelete);

                // Dump counts to refresh displays
                //this.dumpTables(database);
                this.dumpCounts(database);
            }

            ////////////////////////////////////////////
            // Fire an event here to indicate that the data has changed
            if (this.consoleLog) console.log("dpfs triggering local data changed");
            this.store.dispatch(StorageActions.localDataChanged());
        }
    }

    updateTableCounts() {
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) this.dumpCounts(database);
    }


    /**
     * Helper method to dump the counts of rows
     * @param database 
     */
    private dumpCounts(database: Database) {
        let self = this;
        database.each("SELECT count(1) as count FROM archived_passwords;", {},
            function (row) {
                if (self.consoleLog) console.log("archived_passwords rows-> " + row.count);
                self.store.dispatch(StorageActions.updateLoadedArchivedPasswordCount({ data: Number(row.count?.toString()) }));
            },
            () => void (0)
        );

        database.each("SELECT * from archived_passwords;", {},
            function (row) {
                if (self.consoleLog) console.log("data Table archived -> ", row);
            },
            () => void (0)
        );


        let db = this.storageService.appDbService;
        if (db) {
            let results = db.passwordArchives.count();
            results.then((count) => {
                self.store.dispatch(StorageActions.updatePreloadArchivedPasswordCount({ data: Number(count?.toString()) }));
            });
        }


    }

    /**
         *  Removes the entries from the in memory database.
         * @param database 
         * @param toDelete 
         */
    private processInMemorySQLDeletes(database: Database, toDelete: number[]) {
        if (null != toDelete && toDelete.length > 0) {
            ////////////////////////////////////////////////
            // Handle the team2passwords table
            const deletePasswordsSQL = `DELETE FROM archived_passwords where id = :id`;
            const stmtPasswords = database.prepare(deletePasswordsSQL);

            toDelete.forEach((id) => {
                stmtPasswords.run({ ':id': id });
            });

            stmtPasswords.free();

            if (this.consoleLog) console.log("Remove search docs");



        }
    }

    /**
     * Finds the unsynced records in the index db and syncs them to the in memory database.
     */
    loadUnsyncedArchivedPasswordData(): Promise<number> {
        var promise = new Promise<number>((resolve, reject) => {
            try {
                if (this.consoleLog) console.log("loadUnsyncedArchivedPasswordData");

                // Load the account keys
                // console.log("accountKeys ", this.accountKeysData);
                let accountKeysData = this.accountKeysService.getAccountKeysData();
                if (accountKeysData && accountKeysData.packingKeyDecryptedPrivateKey && accountKeysData.packingKeyDecryptedPrivateKey.length > 0) {
                    if (this.storageService.appDbService) {
                        let db = this.storageService.appDbService;

                        // Retrieve the records in the database
                        let results: PromiseExtended<ArchivedPassword[]>;
                        results = db.passwordArchives.where('syncedInMemory').equals(SyncStatus.SYNC_STATUS_NOT_SYNCED).toArray();

                        results.then((records) => {
                            if (records.length > 0) {
                                this.processUnsyncedArchivedPassword(db, records);
                            }

                            // // Dump the counts. Note: this is happening likely before all records are synced
                            this.updateTableCounts();
                            resolve(1);

                        });


                    } else {
                        resolve(0);
                    }
                } else {
                    if (this.consoleLog) console.log("app keys not ready");
                    resolve(0);
                }

            } catch (error) {
                console.log("Error in loadUnsyncedArchivedPasswordData: " + error);
                reject(0);
            }
        });
        return promise;
    }




    /**
     * Processes the unsynced archived passwords. Decrypts and loads into the in mem db.
     * @param db 
     * @param record 
     * @param decryptedPrivateKey 
     */
    processUnsyncedArchivedPassword(db: AppDBService, records: ArchivedPassword[]) {
        if (this.consoleLog) console.log("processing unsynced archived passwords ", records.length);
        let vaultMap = this.passwordVaultService.generateIdToVaultsWithEncryptionKeysForCurrentVaults();

        records.forEach((record) => {
            let hasVaultKey = record.vault && record.vault.length > 0 && vaultMap.has(record.vault);

            if (hasVaultKey) {
                this.passwordDecryption.decryptArchivedPassword(record, vaultMap).then((password) => {
                    try {
                        // console.log("Password:--------------> " + JSON.stringify(password));

                        // Create the sql statement
                        const sql = `INSERT OR REPLACE INTO archived_passwords (id, recoveryId, archivedTimestamp, initiatorId, initiatorUsername, ip, sessionId, name,key,metadataKey,url,username, geo, version, vault)\
                            VALUES (:id,  :recoveryId, :archivedTimestamp, :initiatorId, :initiatorUsername, :ip, :sessionId, :name, :key, :metadataKey, :url, :username, :geo, :version, :vault)`;

                        const stmt = this.storageService.inMemorySQLService?.getDatabase().prepare(sql);
                        // console.log("Password:--------------> " + JSON.stringify(password));
                        stmt?.run({
                            ':id': record.id,
                            ':recoveryId': record.recoveryId,
                            ':archivedTimestamp': record.archivedTimestamp,
                            ':initiatorId': record.initiatorId,
                            ':initiatorUsername': record.initiatorUsername,
                            ':ip': record.ip,
                            ':sessionId': record.sessionId,
                            ':name': this.checkUndefined(password.name),
                            ':key': this.checkUndefined(password.key),
                            ':metadataKey': this.checkUndefined(password.metadataKey),
                            ':url': this.checkUndefined(password.url),
                            ':username': this.checkUndefined(password.username),
                            ':geo': this.displayService.geoToDescription(record.geo),
                            ':version': record.version,
                            ':vault': record.vault
                        });
                        stmt?.free();

                        // update the indexdb to indicate it is now loaded
                        db.transaction('rw', db.passwordArchives, async () => {
                            db.passwordArchives.update(record.id, { syncedInMemory: SyncStatus.SYNC_STATUS_SYNCED_IN_MEM }).then(() => {
                                // console.log("updated");
                            }).catch((error) => { console.log(error); });
                        });


                    } catch (error) {
                        console.error(error);
                    }
                }).catch((error) => {
                    console.error(error);
                });

            } else {
                if (this.consoleLog) console.log("No team meta key");
            }
        });

        // Tell the store we have new data
        this.store.dispatch(StorageActions.updateLastArchivedPasswordChangeTimestamp());

    }


    /**
     * Checks the input for undefined and finds the appropriate default value. SQL cannot handle undefined.
     * @param input 
     * @returns 
     */
    checkUndefined(input: any): string | number {
        if (undefined == input) {
            if (typeof input === 'string') {
                return "UNDEFINED";
            } else return -999999990;
        } else {
            return input;
        }
    }

}

