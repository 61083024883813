import { Injectable } from '@angular/core';
import { EncryptionResult } from '@app/model/app/encryption/EncryptionResult';
import { RSAJS } from './RSAJS';

@Injectable({ providedIn: 'root' })

export class RSAEncrypt {
    private consoleLog: boolean = false;
    private worker: Worker | null = null;
    private pendingRequests = new Map<number, { resolve: (value: EncryptionResult) => void, reject: (reason: any) => void }>();
    private nextRequestId = 0;

    private rsaJS = new RSAJS();
    constructor() {
        // Initialize the worker if supported
        if (typeof Worker !== 'undefined') {
            this.initWorker();
        }
    }

    private initWorker() {
        try {
            this.worker = new Worker(new URL('./rsa-encrypt.worker.ts', import.meta.url), { type: 'module' });
            this.worker.onmessage = ({ data }) => {
                const { result, id } = data;
                const request = this.pendingRequests.get(id);
                if (request) {
                    this.pendingRequests.delete(id);
                    request.resolve(result);
                }
            };

            this.worker.onerror = (error) => {
                console.error('Worker error:', error);
                // Resolve all pending requests with an error
                this.pendingRequests.forEach((request) => {
                    const errorResult = new EncryptionResult();
                    errorResult.success = false;
                    errorResult.error = 'Worker error: ' + error.message;
                    request.reject(errorResult);
                });
                this.pendingRequests.clear();

                // Try to reinitialize the worker
                this.worker = null;
                this.initWorker();
            };
        } catch (error) {
            console.error('Failed to initialize worker:', error);
            this.worker = null;
        }
    }
    async rsaEncrypt(data: string, publicKey: string): Promise<EncryptionResult> {
        // If worker is available, use it
        if (this.worker) {
            return this.rsaEncryptWithWorker(data, publicKey);
        } else {
            // Fall back to synchronous decryption
            return this.rsaEncryptSync(data, publicKey);
        }
    }

    private async rsaEncryptWithWorker(data: string, publicKey: string): Promise<EncryptionResult> {
        const requestId = this.nextRequestId++;

        return new Promise<EncryptionResult>((resolve, reject) => {
            this.pendingRequests.set(requestId, { resolve, reject });

            this.worker!.postMessage({
                dataToEncrypt: data,
                publicKey: publicKey,
                id: requestId
            });
        });
    }


    private async rsaEncryptSync(data: string, publicKey: string): Promise<EncryptionResult> {
        if (this.consoleLog) console.log("rsaEncrypt: " + data + " " + publicKey);
        // console.log("Encrypting data sync: " + data + " with public key: " + publicKey);
        var promise = new Promise<EncryptionResult>((resolve, reject) => {
            try {
                var pubKeyParts = publicKey.split("|");

                if (pubKeyParts[0] === '4') {
                    this.rsaJS.rsaEncryptV4(data, publicKey).then((result) => {
                        if (this.consoleLog) console.log("payload: " + JSON.stringify(result));
                        resolve(result);
                    });
                } else if (pubKeyParts[0] === '3') {
                    this.rsaJS.rsaEncryptV3(data, publicKey).then((result) => {
                        if (this.consoleLog) console.log("payload: " + JSON.stringify(result));
                        resolve(result);
                    });
                } else {
                    this.rsaJS.rsaEncryptV2(data, publicKey).then((result) => {
                        if (this.consoleLog) console.log("payload: " + JSON.stringify(result));
                        resolve(result);
                    });
                }

            } catch (error) {
                console.error("error: " + error);
                let encryptionResult: EncryptionResult = new EncryptionResult();
                encryptionResult.success = false;
                encryptionResult.error = "444" + error as unknown as string;
                reject(encryptionResult);
            }
        });

        return promise;
    }



    // private rsaEncryptV2(data: string, publicKey: string): Promise<EncryptionResult> {
    //     let payload: EncryptionResult = new EncryptionResult();
    //     if (this.consoleLog) console.log("RSA Encrypt version 2 starting");
    //     var promise = new Promise<EncryptionResult>((resolve, reject) => {
    //         try {
    //             let publicKeyParts = publicKey.split("|");
    //             let keyData = {
    //                 kty: "RSA",
    //                 alg: "RSA-OAEP-256",  // Your desired JWA algorithm
    //                 ext: true,            // Is key extractable or not
    //                 key_ops: ["encrypt"], // Array of supposed key usages
    //                 // The "e" component, public exponent, spec restricts is to the only value, 65537
    //                 // Public key components
    //                 e: "AQAB",                      // The "e" component, public exponent, spec restricts is to the only value, 65537
    //                 n: this.urlEncodeUrl(publicKeyParts[1]),
    //             }
    //             let algorithm = {   //these are the algorithm options
    //                 name: "RSA-OAEP",
    //                 hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
    //             }
    //             if (this.consoleLog) console.log("keyData: " + JSON.stringify(keyData) + " algorithm: " + JSON.stringify(algorithm) + " data: " + data);

    //             self.crypto.subtle.importKey("jwk", keyData, algorithm, true, ["encrypt"])
    //                 .then(function (key) {
    //                     //returns the symmetric key
    //                     self.crypto.subtle.encrypt(
    //                         {
    //                             name: "RSA-OAEP",
    //                             label: string_to_bytes("zz")
    //                         },
    //                         key, //from generateKey or importKey above
    //                         string_to_bytes(data) //ArrayBuffer of the data
    //                     ).then(function (encrypted) {
    //                         var encryptedData = ByteBuffer.wrap(encrypted).toBase64();
    //                         payload.success = true;
    //                         payload.data = encryptedData;
    //                         resolve(payload);
    //                     }).catch(function (err) {
    //                         console.error("ERROR 3 " + err);
    //                         payload.success = false;
    //                         payload.error = "3 " + err as unknown as string;
    //                         reject(payload);
    //                     });
    //                 })
    //                 .catch(function (err) {
    //                     console.error("Error getting key: " + err);
    //                     payload.success = false;
    //                     payload.error = "2 " + err as unknown as string;
    //                     reject(payload);
    //                 });


    //         } catch (error) {
    //             console.error("error333: " + error);
    //             payload.success = false;
    //             payload.error = error as unknown as string;
    //             reject(payload);
    //         }

    //     });

    //     return promise;
    // }
    // private rsaEncryptV3(data: string, publicKey: string): Promise<EncryptionResult> {
    //     let payload: EncryptionResult = new EncryptionResult();
    //     if (this.consoleLog) console.log("RSA Encrypt version 3 starting");
    //     var promise = new Promise<EncryptionResult>((resolve, reject) => {
    //         try {
    //             let publicKeyParts = publicKey.split("|");
    //             let keyData = {
    //                 kty: "RSA",
    //                 alg: "RSA-OAEP-512",  // Your desired JWA algorithm
    //                 ext: true,            // Is key extractable or not
    //                 key_ops: ["encrypt"], // Array of supposed key usages
    //                 // The "e" component, public exponent, spec restricts is to the only value, 65537
    //                 // Public key components
    //                 e: "AQAB",                      // The "e" component, public exponent, spec restricts is to the only value, 65537
    //                 n: this.urlEncodeUrl(publicKeyParts[1]),
    //             }
    //             let algorithm = {   //these are the algorithm options
    //                 name: "RSA-OAEP",
    //                 hash: { name: "SHA-512" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
    //             }
    //             if (this.consoleLog) console.log("keyData: " + JSON.stringify(keyData) + " algorithm: " + JSON.stringify(algorithm) + " data: " + data);

    //             self.crypto.subtle.importKey("jwk", keyData, algorithm, true, ["encrypt"])
    //                 .then(function (key) {
    //                     //returns the symmetric key
    //                     self.crypto.subtle.encrypt(
    //                         {
    //                             name: "RSA-OAEP",
    //                             label: string_to_bytes("zz")
    //                         },
    //                         key, //from generateKey or importKey above
    //                         string_to_bytes(data) //ArrayBuffer of the data
    //                     ).then(function (encrypted) {
    //                         var encryptedData = ByteBuffer.wrap(encrypted).toBase64();
    //                         payload.success = true;
    //                         payload.data = encryptedData;
    //                         resolve(payload);
    //                     }).catch(function (err) {
    //                         console.error("ERROR 3 " + err);
    //                         payload.success = false;
    //                         payload.error = "3 " + err as unknown as string;
    //                         reject(payload);
    //                     });
    //                 })
    //                 .catch(function (err) {
    //                     console.error("Error getting key: " + err);
    //                     payload.success = false;
    //                     payload.error = "2 " + err as unknown as string;
    //                     reject(payload);
    //                 });


    //         } catch (error) {
    //             console.error("error333: " + error);
    //             payload.success = false;
    //             payload.error = error as unknown as string;
    //             reject(payload);
    //         }

    //     });

    //     return promise;
    // }
    // private rsaEncryptV4(data: string, publicKey: string): Promise<EncryptionResult> {
    //     let payload: EncryptionResult = new EncryptionResult();
    //     if (this.consoleLog) console.log("RSA Encrypt version 4 starting");
    //     var promise = new Promise<EncryptionResult>((resolve, reject) => {
    //         try {
    //             let publicKeyParts = publicKey.split("|");
    //             let keyData = {
    //                 kty: "RSA",
    //                 alg: "RSA-OAEP-512",  // Your desired JWA algorithm
    //                 ext: true,            // Is key extractable or not
    //                 key_ops: ["encrypt"], // Array of supposed key usages
    //                 // The "e" component, public exponent, spec restricts is to the only value, 65537
    //                 // Public key components
    //                 e: "AQAB",                      // The "e" component, public exponent, spec restricts is to the only value, 65537
    //                 n: this.urlEncodeUrl(publicKeyParts[1]),
    //             }
    //             let algorithm = {   //these are the algorithm options
    //                 name: "RSA-OAEP",
    //                 hash: { name: "SHA-512" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
    //             }
    //             if (this.consoleLog) console.log("keyData: " + JSON.stringify(keyData) + " algorithm: " + JSON.stringify(algorithm) + " data: " + data);

    //             self.crypto.subtle.importKey("jwk", keyData, algorithm, true, ["encrypt"])
    //                 .then(function (key) {
    //                     //returns the symmetric key
    //                     self.crypto.subtle.encrypt(
    //                         {
    //                             name: "RSA-OAEP",
    //                             // label: string_to_bytes("zz")
    //                         },
    //                         key, //from generateKey or importKey above
    //                         string_to_bytes(data) //ArrayBuffer of the data
    //                     ).then(function (encrypted) {
    //                         var encryptedData = ByteBuffer.wrap(encrypted).toBase64();
    //                         payload.success = true;
    //                         payload.data = encryptedData;
    //                         resolve(payload);
    //                     }).catch(function (err) {
    //                         console.error("ERROR 3 " + err);
    //                         payload.success = false;
    //                         payload.error = "3 " + err as unknown as string;
    //                         reject(payload);
    //                     });
    //                 })
    //                 .catch(function (err) {
    //                     console.error("Error getting key: " + err);
    //                     payload.success = false;
    //                     payload.error = "2 " + err as unknown as string;
    //                     reject(payload);
    //                 });


    //         } catch (error) {
    //             console.error("error333: " + error);
    //             payload.success = false;
    //             payload.error = error as unknown as string;
    //             reject(payload);
    //         }

    //     });

    //     return promise;
    // }


}