import { Injectable, OnDestroy } from '@angular/core';
import { selectAccountUserInformationDetails } from '@app/store/selectors/AccountSelectors';
import { Store } from '@ngrx/store';
import { AppDBService } from './AppDbService';
import { GeneratedDataFeedResponse } from '@app/model/api/dataFeed/GeneratedDataFeedResponse';
import { GeneratedDataFeedEntry } from '@app/model/api/dataFeed/GeneratedDataFeedEntry';
import { ActionFeedResponse } from '@app/model/api/dataFeed/ActionFeedResponse';
import { ActionFeedEntry } from '@app/model/api/dataFeed/ActionFeedEntry';
import { InMemorySQLService } from './InMemorySQLService';
import { Subscription } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class StorageService implements OnDestroy {
  readonly vmAccountUserInformation$ = this.store.select(selectAccountUserInformationDetails);
  vmAccountUserInformationSubscription: Subscription;
  loggedInCustomerId: number = 0;
  previousLoggedInCustomerId: number = 0;
  databaseName: string = "pck";
  appDbService: AppDBService | null = null;
  inMemorySQLService: InMemorySQLService | null = null;
  consoleLog: boolean = false;
  passwordFavoritesLoaded = false;

  constructor(
    private store: Store,
  ) {

    this.vmAccountUserInformationSubscription = this.vmAccountUserInformation$.subscribe(x => {

      let populateDefault = false;

      // Update the active user data
      if (x) {
        if (x.status.code === 0) {
          this.previousLoggedInCustomerId = this.loggedInCustomerId;
          this.loggedInCustomerId = x.customerId;
        } else {
          populateDefault = true;
        }
      }
      if (populateDefault) {
        this.previousLoggedInCustomerId = 0;
        this.loggedInCustomerId = 0;
      }
    });
  }
  ngOnDestroy(): void {
    if (this.vmAccountUserInformationSubscription) {
      this.vmAccountUserInformationSubscription.unsubscribe();
    }
  }

  /**
   * Performs cleanup for logout
   */
  cleanForLogout() {
    if (this.consoleLog) console.log("cleanForLogout");
    this.cleanDatabase();
  }

  /**
   * Cleans the database
   */
  cleanDatabase() {
    if (this.consoleLog) console.log("----------- Cleaning database -----------");
    if (this.appDbService !== undefined && null !== this.appDbService) {
    } else {
      this.appDbService = new AppDBService(this.databaseName);
    }
    if (this.consoleLog) console.log("----------- Cleaning Dexie database -----------");
    this.appDbService?.delete().then(() => {
      console.log("Database successfully deleted");
      this.appDbService?.close();
      this.appDbService = null;
    });


    if (this.inMemorySQLService !== undefined && null !== this.inMemorySQLService) {
      if (this.consoleLog) console.log("----------- Cleaning Memory database -----------");
      this.inMemorySQLService.clean();
      this.inMemorySQLService = null;
    }




  }

  /**
   * If the database exists it removes everything prior to releasing for use.
   */
  clearAllTablesInDatabase() {
    this.appDbService?.tables.forEach(table => {
      table.clear().then() // handle success
        .catch(function (error) { console.error(error); }); // handle failure
    })

    this.inMemorySQLService?.clean();
  }

  /**
   * Actions to perform when the logged in user changes.
   * 
   * - Clean up the database, if present
   * - open a new database if the user is logged in
   */
  switchLoggedInUser() {
    if (this.consoleLog) console.log("switchLoggedInUser ", this.loggedInCustomerId, this.previousLoggedInCustomerId);
    this.databaseName = "pck";

    // /Set the passwordFavoritesLoaded to false;
    this.passwordFavoritesLoaded = false;

    // close if the appDbService is open and defined
    // this.cleanDatabase();

    // open a new database if the customer id is > 0 === logged in.
    if (this.loggedInCustomerId > 0) {
      this.appDbService = new AppDBService(this.databaseName);
      this.inMemorySQLService = new InMemorySQLService(this.databaseName, this.store);
      //wait for the in memory database to be fully ready. wait for the flag to be marked ready.


      /// Configure the database;
      if (this.appDbService) {
        this.clearAllTablesInDatabase();

        if (this.consoleLog) console.log("database opened successfully");
        // full notification happens in the in memory
      }
    }

  }


  /**
   * Process the generated data feed and store the lastupdate
   * @param data 
   */
  processGeneratedDataFeed(data: GeneratedDataFeedResponse) {

    let toAdd: GeneratedDataFeedEntry[] = [];
    let toDelete: string[] = [];

    data.contents.forEach(entry => {
      switch (entry.action) {
        case 1:
        // add
        case 2:
          // update
          // add or update should upsert
          toAdd.push(entry);
          break;
        case 3:
          // delete
          toDelete.push(entry.uid);
          break;
      }
    });

    // Adds or updates
    this.appDbService?.generatedData.bulkPut(toAdd).then() // handle success
      .catch(function (error) { console.error(error); }); // handle failure

    // Deletes
    this.appDbService?.generatedData.bulkDelete(toDelete).then() // handle success
      .catch(function (error) { console.error(error); }); // handle failure

    // this.store.dispatch(StorageActions.updateDataFeedGeneratedDataLastUpdate({ data: data.lastDate }));
  }




  /**
   * Process the action feed and store the lastupdate
   * @param data 
   */
  processActionFeed(data: ActionFeedResponse) {
    if (this.consoleLog) console.log("TODO processActionFeed ", data);

    let toAdd: ActionFeedEntry[] = [];
    let toDelete: number[] = [];

    // data.entries.forEach(action => {
    //   switch (action.action) {
    //     case 1:
    //     // add
    //     case 2:
    //       // update
    //       // add or update should upsert
    //       toAdd.push(action);
    //       break;
    //     case 3:
    //       // delete
    //       toDelete.push(action.id);
    //       break;
    //   }
    // });

    // // Adds or updates
    // this.appDbService?.labels.bulkPut(toAdd).then() // handle success
    //   .catch(function (error) { console.log(error); }); // handle failure

    // // Deletes
    // this.appDbService?.labels.bulkDelete(toDelete).then() // handle success
    //   .catch(function (error) { console.log(error); }); // handle failure

    // this.store.dispatch(StorageActions.updateDataFeedActionLastUpdate({ data: data.lastDate }));
  }


}
