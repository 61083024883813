import { Injectable } from '@angular/core';
import * as ByteBuffer from 'bytebuffer';
import { Pbkdf2HmacSha256, Pbkdf2HmacSha512 } from 'asmcrypto.js';
import { Encoder } from '@as-com/pson';
import { EncryptionTools } from './EncryptionTools';
import { bytes_to_base64 } from 'asmcrypto.js';
// Must import buffer for PSON to work.
import { Buffer } from "buffer"
import { TeamKeyService } from '../cache/team-key-service.service';

@Injectable({ providedIn: 'root' })
export class AesEncrypt {
    consoleLog: boolean = false;
    private encryptionTools: EncryptionTools = new EncryptionTools();
    readonly ivSaltLengthV4: number = 32;
    readonly ivSaltLengthV1: number = 16;

    constructor(
        private teamKeyService: TeamKeyService
    ) {

    }


    async aesEncryptv4(data: string, iterations: number, keyId: number): Promise<string> {
        if (this.consoleLog) console.log("encrypting data");
        var promise = new Promise<string>((resolve, reject) => {
            let wrapper = { value: data };
            try {

                // Try to find the key
                let teamMetaKeys = this.teamKeyService.getTeamMetaKeys(keyId);
                if (!teamMetaKeys) {
                    reject("Key Not Found: " + keyId);
                } else {

                    let passphrase = teamMetaKeys.groupKey;

                    var iv = new Uint8Array(this.ivSaltLengthV4);
                    var salt = new Uint8Array(this.ivSaltLengthV4);
                    self.crypto.getRandomValues(salt);
                    self.crypto.getRandomValues(iv);
                    //buffer is required to successfully encode. We just put empt string here.
                    const myBuffer = Buffer.from('');
                    // console.log(myBuffer.toString());
                    // Call buffer here so it is loaded and ready for encoder.
                    window.Buffer = window.Buffer || require("buffer").Buffer;
                    let val = new Encoder([], false, {}).encode(wrapper).toArrayBuffer();
                    // Key length is 32 which is 256 bits
                    var key = Pbkdf2HmacSha256(this.encryptionTools.string_to_bytes(passphrase), salt, iterations, 32);
                    self.crypto.subtle.importKey("raw", key, "AES-GCM", true, ["encrypt", "decrypt"])
                        .then(function (key) {
                            //returns the symmetric key
                            self.crypto.subtle.encrypt(
                                {
                                    name: "AES-GCM",
                                    iv: iv, //The initialization vector you used to encrypt
                                },
                                key, //from generateKey or importKey above
                                val //ArrayBuffer of the data
                            )
                                .then(function (encrypted) {
                                    var encodedData = ByteBuffer.wrap(encrypted).toBase64();
                                    var payload = [4, iterations, keyId, bytes_to_base64(iv), bytes_to_base64(salt), encodedData].join("#");
                                    resolve(payload);
                                })
                                .catch(function (err) {
                                    reject(err);
                                });
                        })
                        .catch(function (err) {
                            reject(err);
                        });
                }
            } catch (err) {
                reject(err);
            }

        });
        return promise;

    };

    async aesEncrypt(data: string, passphrase: string): Promise<string> {
        if (this.consoleLog) console.log("encrypting data");
        var promise = new Promise<string>((resolve, reject) => {
            let wrapper = { value: data };
            try {
                var iv = new Uint8Array(this.ivSaltLengthV1);
                var salt = new Uint8Array(this.ivSaltLengthV1);
                self.crypto.getRandomValues(salt);
                self.crypto.getRandomValues(iv);
                //buffer is required to successfully encode. We just put empt string here.
                const myBuffer = Buffer.from('');
                // console.log(myBuffer.toString());
                // Call buffer here so it is loaded and ready for encoder.
                window.Buffer = window.Buffer || require("buffer").Buffer;
                let val = new Encoder([], false, {}).encode(wrapper).toArrayBuffer();
                var key = Pbkdf2HmacSha256(this.encryptionTools.string_to_bytes(passphrase), salt, 0x3E8, 32);
                self.crypto.subtle.importKey("raw", key, "AES-GCM", true, ["encrypt", "decrypt"])
                    .then(function (key) {
                        //returns the symmetric key
                        self.crypto.subtle.encrypt(
                            {
                                name: "AES-GCM",
                                iv: iv, //The initialization vector you used to encrypt
                            },
                            key, //from generateKey or importKey above
                            val //ArrayBuffer of the data
                        )
                            .then(function (encrypted) {
                                var encodedData = ByteBuffer.wrap(encrypted).toBase64();
                                var payload = [3, bytes_to_base64(iv), bytes_to_base64(salt), encodedData].join("#");
                                resolve(payload);
                            })
                            .catch(function (err) {
                                reject(err);
                            });
                    })
                    .catch(function (err) {
                        reject(err);
                    });
            }
            catch (err) {
                reject(err);
            }
        });
        return promise;

    };
}