import { Injectable } from '@angular/core';
import { BaseAPIService } from '../BaseAPIService';
import { ActionUpdateResponse } from '@app/model/api/action/ActionUpdateResponse';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { ActionUpdateRequest } from '@app/model/api/action/ActionUpdateRequest';


@Injectable({ providedIn: 'root' })
export class ActionUpdateService {

    constructor(
        private baseAPIService: BaseAPIService,
    ) {
    }



    /**
     * Posts an event telling the server something happened on the client.
     * @param input 
     * @returns 
     */
    postActionUpdate(input: ActionUpdateRequest): Observable<ActionUpdateResponse> {
        let url = environment.API_BASE_URL + "v1/secure/action/update";
        let jsonBody = JSON.stringify(input);
        // console.log("jsonBody: " + jsonBody);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<ActionUpdateResponse>(jsonBody, url);
    }
}