import { Injectable } from '@angular/core';
import { PasswordHistory } from '@app/model/app/password/PasswordHistory';

import { DisplayService } from '../DisplayService';
import { StorageService } from '../dataStorage/storage.service';
import { PasswordExtraField } from '@app/model/app/password/PasswordExtraField';
import { DisplayedPasswordGroupListItem } from '@app/model/app/passwordGroup/DisplayedPasswordGroupListItem';
import { InMemoryPassword } from '@app/model/app/password/InMemoryPassword';
import { AccountService } from '../account/account.service';
import { SharingDisplayPasswordItem } from '@app/model/app/passwordGroup/SharingDisplayPasswordItem';
import { LabelItem } from '@app/model/app/labels/LabelItem';
import { PasswordForDataExport_v1 } from '@app/model/app/dataManagement/PasswordForDataExport_v1';
import { PasswordExtraForDataExport_v1 } from '@app/model/app/dataManagement/PasswordExtraForDataExport_v1';
import { PasswordHistoryForDataExport_v1 } from '@app/model/app/dataManagement/PasswordHistoryForDataExport_v1';
import { TeamDisplayInfo } from '@app/model/app/teams/TeamDisplayInfo';
import { PasswordVaultData } from '@app/model/app/passwordVault/PasswordVaultData';
import { PasswordVaultService } from '../passwordVault/password-vault-service.service';

@Injectable({
    providedIn: 'root',
})

export class PasswordDetailsService {
    private consoleLog: boolean = false;
    constructor(
        private storageService: StorageService,
        private displayService: DisplayService,
        private passwordVaultService: PasswordVaultService

    ) {
    }


    /**
         * Retrieves the list of my passwords of which I'm the owner. These are the ones where I can share out.
         * @returns 
         */
    retrievePasswordsForSharingOut(selectedTeam: TeamDisplayInfo): SharingDisplayPasswordItem[] {
        let passwordList: SharingDisplayPasswordItem[] = [];
        console.log("retrievePasswordsForSharingOut", selectedTeam);

        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {

            if (selectedTeam.vault && selectedTeam.vault.id.length > 0) {
                // include only the passwords that are in the vault.
                let stmt = database.prepare("SELECT p.*,upper(p.name) as uname FROM passwords p WHERE p.vault = :vault ORDER BY uname ASC");
                stmt.bind({ ':vault': selectedTeam.vault.id });
                while (stmt.step()) {
                    let result = stmt.getAsObject();
                    let entry = new SharingDisplayPasswordItem();
                    entry.name = result.name as string;
                    entry.id = result.id as number;
                    // entry.website = result.url as string;
                    entry.faviconUrl = this.displayService.generateFaviconUrl(result.url?.toString() as string);
                    passwordList.push(entry);
                }
                stmt.free();
            } else {
                let stmt = database.prepare("SELECT p.*,upper(p.name) as uname FROM passwords p WHERE p.owner = 1 AND (p.vault='' or p.vault is null) ORDER BY uname ASC");
                stmt.bind();
                while (stmt.step()) {
                    let result = stmt.getAsObject();
                    let entry = new SharingDisplayPasswordItem();
                    entry.name = result.name as string;
                    entry.id = result.id as number;
                    // entry.website = result.url as string;
                    entry.faviconUrl = this.displayService.generateFaviconUrl(result.url?.toString() as string);
                    passwordList.push(entry);
                }
                stmt.free();
            }
        }

        return passwordList;
    }


    retrieveMyPasswords(): InMemoryPassword[] {
        let passwordList: InMemoryPassword[] = [];
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {
            let stmt = database.prepare("SELECT p.*,upper(p.name) as uname  FROM passwords p WHERE p.owner = 1 ORDER BY uname ASC");
            stmt.bind();
            while (stmt.step()) {
                let result = stmt.getAsObject();
                let entry = new InMemoryPassword();
                entry.id = result.id as number;
                entry.name = result.name as string;
                entry.username = result.username as string;
                entry.password = result.password as string;
                entry.url = result.url as string;
                entry.email = result.email as string;
                entry.notes = result.notes as string;
                entry.sharedNotes = result.sharedNotes as string;
                entry.createdDate = result.createdDate as string;
                entry.actualCreatedDate = result.actualCreatedDate as number;
                entry.favorite = result.favorite as number;
                entry.lastUpdate = result.lastUpdate as string;
                entry.actualLastUpdate = result.actualLastUpdate as number;
                entry.passwordLastUpdate = result.passwordLastUpdate as string;
                entry.actualPasswordLastUpdate = result.actualPasswordLastUpdate as number;
                entry.stats = result.stats as string;
                entry.score = result.score as number;
                entry.owner = result.owner as number;
                entry.keyGroup = result.keyGroup as number;
                entry.key = result.key as string;
                entry.newPassword = false;
                entry.version = result.version as number;
                entry.vault = result.vault as string;
                passwordList.push(entry);
            }
            stmt.free();
        }

        return passwordList;
    }


    /**
     * Retrieves the passwords which may be exported because the user is either the owner of the password or an admin to a shared vault.
     * 
     * @param fullDump 
     * @returns 
     */
    retrievePasswordsForExport(fullDump: boolean): PasswordForDataExport_v1[] {
        let passwordList: PasswordForDataExport_v1[] = [];
        let database = this.storageService.inMemorySQLService?.getDatabase();

        let vaultsWithAdminAccess = this.passwordVaultService.generateIdToVaultsWithEncryptionKeysForCurrentVaults();

        // Add each vault from vaultsWithAdminAccess to a string which may be included in the query to get the passwords.
        let vaultSQL = "";
        if (vaultsWithAdminAccess) {
            vaultsWithAdminAccess.forEach((vault) => {
                vaultSQL += " OR vault='" + vault.id + "'";
            });
        }

        if (database) {
            let query = "SELECT p.*,upper(p.name) as uname FROM passwords p WHERE (p.owner = 1 " + vaultSQL + ") ORDER BY uname ASC";
            if (this.consoleLog) console.log(query);
            let stmt = database.prepare(query);
            stmt.bind();
            while (stmt.step()) {
                let result = stmt.getAsObject();
                // console.log(result);
                let entry = new PasswordForDataExport_v1();
                entry.name = result.name as string;
                entry.username = result.username as string;
                entry.password = result.password as string;
                entry.url = result.url as string;
                entry.email = result.email as string;
                entry.notes = result.notes as string;
                entry.sharedNotes = result.sharedNotes as string;

                if (fullDump) {
                    // retrieve labels
                    let labels = this.retrievePasswordLabels(result.id as number);
                    if (labels && labels.length > 0) {
                        entry.labels = labels.map((label) => label.name);
                    }

                    // retrieve teams.
                    let teams = this.retrievePasswordGroups(result.id as number, true);
                    if (teams && teams.length > 0) {
                        entry.teams = teams.map((team) => team.id);
                    }

                    // Extra
                    let extras = this.retrivePasswordExtra(result.id as number);
                    if (extras && extras.length > 0) {
                        entry.extra = extras.map((extra) => {
                            let extraData = new PasswordExtraForDataExport_v1();
                            extraData.name = extra.name;
                            extraData.data = extra.data;
                            return extraData;
                        });
                    }

                    // History
                    let history = this.retrievePasswordHistory(result.id as number);
                    if (history && history.length > 0) {
                        entry.history = history.map((extra) => {
                            let history = new PasswordHistoryForDataExport_v1();
                            history.changedBy = extra.changedBy;
                            history.changedByCurrentNickname = extra.changedByCurrentNickname;
                            history.changedDate = extra.changedDate;
                            history.changedDateDisplay = extra.changedDateDisplay;
                            history.deleted = extra.deleted;
                            history.email = extra.email;
                            history.password = extra.password;
                            history.username = extra.username;
                            history.sharedNotes = extra.sharedNotes;
                            return history;
                        });
                    }
                }

                passwordList.push(entry);
            }
            stmt.free();
        }

        return passwordList;
    }


    retrievePasswordLabels(pid: number): LabelItem[] {
        let appliedList: LabelItem[] = [];

        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {
            if (this.consoleLog) console.log("Loading applied labels for password: " + pid);

            let stmt = database.prepare("SELECT l.* FROM labels2passwords l2p JOIN labels l ON l.id=l2p.labelId WHERE l2p.passwordId=:passwordId ORDER BY l.label ASC");
            stmt.bind({ ':passwordId': pid });
            while (stmt.step()) {
                let result = stmt.getAsObject();
                // console.log("data Table labels -> " + JSON.stringify(result));
                let label = new LabelItem();
                label.id = result.id as number;
                label.name = result.label?.toString() as string;
                label.color = result.color?.toString() as string;
                appliedList.push(label);
            }
            stmt.free();
        }

        return appliedList;
    }

    retrievePasswordDetail(pid: number): InMemoryPassword {
        let inMemoryPassword = new InMemoryPassword();
        let vaultMap: Map<string, PasswordVaultData> = this.passwordVaultService.generateIdToNameMapForCurrentVaults();


        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {

            // get the data
            let stmt = database.prepare("SELECT * FROM passwords where id=:id");
            // stmt.bind({ 'id': this.passwordState.selectedPasswordId });

            let result = stmt.getAsObject({ ':id': pid });
            // console.log("data Table passwords -> " + JSON.stringify(result));
            inMemoryPassword.id = result.id as number;
            inMemoryPassword.name = result.name as string;
            inMemoryPassword.username = result.username as string;
            inMemoryPassword.password = result.password as string;
            inMemoryPassword.url = result.url as string;
            inMemoryPassword.email = result.email as string;
            inMemoryPassword.notes = result.notes as string;
            inMemoryPassword.sharedNotes = result.sharedNotes as string;
            inMemoryPassword.createdDate = result.createdDate as string;
            inMemoryPassword.actualCreatedDate = result.actualCreatedDate as number;
            inMemoryPassword.favorite = result.favorite as number;
            inMemoryPassword.lastUpdate = result.lastUpdate as string;
            inMemoryPassword.actualLastUpdate = result.actualLastUpdate as number;
            inMemoryPassword.passwordLastUpdate = result.passwordLastUpdate as string;
            inMemoryPassword.actualPasswordLastUpdate = result.actualPasswordLastUpdate as number;
            inMemoryPassword.stats = result.stats as string;
            inMemoryPassword.score = result.score as number;
            inMemoryPassword.owner = result.owner as number;
            inMemoryPassword.keyGroup = result.keyGroup as number;
            inMemoryPassword.key = result.key as string;
            inMemoryPassword.newPassword = false;
            inMemoryPassword.version = result.version as number;
            inMemoryPassword.vault = result.vault as string;
            inMemoryPassword.decryptedFromVault = result.decryptedFromVault as number ? true : false;

            // Add the vault data if this is a vault.
            if (inMemoryPassword.vault && inMemoryPassword.vault.length > 0) {
                let vault = vaultMap.get(inMemoryPassword.vault);
                if (vault) {
                    inMemoryPassword.vaultData = vault;
                }
            }
            stmt.free();

        }
        return inMemoryPassword;
    }

    retrieveMyDefaultGroup(): DisplayedPasswordGroupListItem | undefined {
        let defaultGroup: DisplayedPasswordGroupListItem | undefined = undefined;
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {

            let stmt = database.prepare("SELECT t.id, t.name, t.color FROM teams t WHERE  t.name='Default' ");
            stmt.bind();
            while (stmt.step()) {
                let result = stmt.getAsObject();
                defaultGroup = new DisplayedPasswordGroupListItem();
                defaultGroup.name = result.name as string;
                defaultGroup.id = result.id as number;
                defaultGroup.color = result.color as string;

            }
            stmt.free();
        }

        return defaultGroup;
    }

    retrievePasswordGroups(pid: number, includeDefault: boolean = false): DisplayedPasswordGroupListItem[] {
        let teamsList: DisplayedPasswordGroupListItem[] = [];
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {

            let defaultPart = "";
            if (!includeDefault) {
                defaultPart = " AND t.name != 'Default' ";
            }

            let stmt = database.prepare("SELECT t.id, t.name, upper(t.name) as uname , t.color from teams2passwords t2p JOIN teams t on t2p.teamId=t.id WHERE t2p.passwordId=:passwordId " + defaultPart + " ORDER BY uname ASC");
            stmt.bind({ ':passwordId': pid });
            while (stmt.step()) {
                let result = stmt.getAsObject();
                let entry = new DisplayedPasswordGroupListItem();
                entry.name = result.name as string;
                entry.id = result.id as number;
                entry.color = result.color as string;
                teamsList.push(entry);
            }
            stmt.free();
        }

        return teamsList;
    }

    /**
     * Returns the password groups associated with the password ids
     * @param pids 
     * @param includeDefault 
     * @returns 
     */
    retrievePasswordGroupsForList(pids: number[], includeDefault: boolean = false): DisplayedPasswordGroupListItem[] {
        let teamsList: DisplayedPasswordGroupListItem[] = [];
        if (pids.length > 0) {
            let database = this.storageService.inMemorySQLService?.getDatabase();
            if (database) {

                let defaultPart = "";
                if (!includeDefault) {
                    defaultPart = " t.name != 'Default' ";
                }

                // Convert the array of pids into a string containing the or condition
                let whereClause = "";
                pids.forEach((pid) => {
                    if (whereClause.length > 0) {
                        whereClause += " or  ";
                    }
                    whereClause += "t2p.passwordId = " + pid;
                });
                let stmt = database.prepare("SELECT t.id, t.name, upper(t.name) as uname , t.color, t2p.passwordId from teams2passwords t2p JOIN teams t on t2p.teamId=t.id WHERE " + defaultPart + " AND ( " + whereClause + ")  ORDER BY uname ASC");
                stmt.bind();
                while (stmt.step()) {
                    let result = stmt.getAsObject();
                    // console.log(result);
                    let entry = new DisplayedPasswordGroupListItem();
                    entry.name = result.name as string;
                    entry.id = result.id as number;
                    entry.passwordId = result.passwordId as number;
                    entry.color = result.color as string;
                    teamsList.push(entry);
                }
                stmt.free();
            }
        }

        return teamsList;
    }

    retrievePasswordGroupsForPassword(pid: number, includeDefault: boolean = false): DisplayedPasswordGroupListItem[] {
        let teamsList: DisplayedPasswordGroupListItem[] = [];

        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {

            let defaultPart = "";
            if (!includeDefault) {
                defaultPart = " t.name != 'Default' ";
            } else {
                defaultPart = " 1==1 ";
            }

            // Convert the array of pids into a string containing the or condition
            let whereClause = "t2p.passwordId = " + pid;

            let query = "SELECT t.id, t.name, upper(t.name) as uname , t.color, t2p.passwordId from teams2passwords t2p JOIN teams t on t2p.teamId=t.id WHERE " + defaultPart + " AND  " + whereClause + "  ORDER BY uname ASC";
            console.log(query);
            let stmt = database.prepare(query);
            stmt.bind();
            while (stmt.step()) {
                let result = stmt.getAsObject();
                // console.log(result);
                let entry = new DisplayedPasswordGroupListItem();
                entry.name = result.name as string;
                entry.id = result.id as number;
                entry.passwordId = result.passwordId as number;
                entry.color = result.color as string;
                teamsList.push(entry);
            }
            stmt.free();
        }

        return teamsList;
    }






    retrivePasswordExtra(pid: number): PasswordExtraField[] {
        let extraList: PasswordExtraField[] = [];
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {

            let stmt = database.prepare("SELECT *, upper(name) as uname FROM passwordExtrafields WHERE passwordId=:passwordId ORDER BY uname ASC");
            stmt.bind({ ':passwordId': pid });
            while (stmt.step()) {
                let result = stmt.getAsObject();
                let extra = new PasswordExtraField();
                extra.passwordId = result.passwordId as number;
                extra.data = result.data as string;
                extra.id = result.id as string;
                extra.name = result.name as string;
                extra.type = result.type as string;
                extraList.push(extra);
            }
            stmt.free();
        }
        return extraList;
    }

    /**
     * 
     * Retrieves the password history
     * 
     * IMPORTANT: This must return the history in descending order for things like the password update to work correctly.
     * @param pid 
     * @returns 
     */
    retrievePasswordHistory(pid: number) {
        let entries: PasswordHistory[] = [];
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) {

            let stmt = database.prepare("SELECT * FROM passwordHistory WHERE passwordId=:passwordId ORDER BY changedDate DESC");
            stmt.bind({ ':passwordId': pid });
            while (stmt.step()) {
                let result = stmt.getAsObject();
                let history = new PasswordHistory();
                history.passwordId = result.passwordId as number;
                history.changedBy = result.changedBy as string;
                history.changedByCurrentNickname = result.changedByCurrentNickname as string;
                history.changedDate = result.changedDate as string;
                history.changedDateDisplay = this.displayService.calculateHistoryTimeInMyLocale(result.changedDate as string);
                history.deleted = result.deleted as number;
                history.email = result.email as string;
                history.sharedNotes = result.sharedNotes as string;
                history.password = result.password as string;
                history.username = result.username as string;

                entries.push(history);
            }
            stmt.free();
        }
        return entries;
    }



}