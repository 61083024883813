
export class InMemoryDeletedPassword {
    passwordId: number;
    id: string;
    recoveryId: string;
    deletedTimestamp: string;
    initiatorId: number;
    initiatorUsername: string;
    ip: string;
    sessionId: string;
    name: string;
    key: string;
    metadataKey: string;
    url: string;
    username: string;

}