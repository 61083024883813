import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, concatMap, exhaustMap, filter, map, mergeMap, switchMap, switchMapTo, tap, withLatestFrom } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { DataFeedService } from "@app/services/DataFeedService";
import { PasswordActions } from "../actions/PasswordActions";
import { TeamsUpdateService } from "@app/services/teams/TeamsUpdateService";
import { TeamActions } from "../actions/TeamActions";
import { PasswordVaultService } from "@app/services/passwordVault/password-vault-service.service";

@Injectable()
export class TeamEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private passwordVaultService: PasswordVaultService,
        private teamsUpdateService: TeamsUpdateService) { }



    readonly updateTeamSharing$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                TeamActions.updateTeamMembership
            ),
            exhaustMap(async (action) => {
                try {
                    const response = await this.teamsUpdateService.manageTeamMembership(action.gid, action.adds, action.updates, action.removes);
                    return TeamActions.updateTeamMembershipSuccess();
                } catch (error: any) {
                    return TeamActions.updateTeamMembershipFailure({ error });
                }
            })
        )
    );

    readonly ensureTeamMemberIsPartOfSharedVault$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                TeamActions.ensureTeamMemberIsAddedToSharedVault
            ),
            tap((action) => {
                this.passwordVaultService.ensureTeamMemberIsAddedToSharedVault(action.vaultId, action.dataKey, action.role, action.keys).then(() => {
                    console.log("ensureTeamMemberIsAddedToSharedVault success");
                });
            })

        )
    );

    readonly updatePasswordMembershipInTeam$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                TeamActions.updatePasswordMembershipInTeam
            ),
            exhaustMap((action) => {
                return this.teamsUpdateService.managePasswordsInTeam(action.groupId, action.adds, action.removes).then(
                    (response) => {
                        return TeamActions.updatePasswordMembershipInTeamSuccess();
                    }).catch((error) => {
                        return TeamActions.updatePasswordMembershipInTeamFailure({ error });
                    });
            })
        )
    );



}
