
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const NavigationActions = createActionGroup({
    source: 'Navigation',
    events: {
        // Page opens


        // Other
        'Set Last Authenticated Page Visited': props<{ page: string }>(),
    },
});