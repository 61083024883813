import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable, of } from "rxjs";
import { catchError, concatMap, exhaustMap, filter, map, mergeMap, switchMapTo, tap, withLatestFrom } from "rxjs/operators";
import { LoginActions } from "../actions/LoginActions";
import { Store } from "@ngrx/store";
import { OrganizationService } from "@app/services/organization/Organization.service";
import { OrganizationActions } from "../actions/OrganizationActions";
import { selectOrganizationLicenses, selectOrganizationListResponse } from "../selectors/OrganizationSelectors";
import { ReportingActions } from "../actions/ReportingActions";

@Injectable()
export class OrganizationEffects {
    consoleLog: boolean = false;
    constructor(
        private actions$: Actions,
        private store: Store,
        private organizationService: OrganizationService) { }



    /**
     * Loads the organization List from the server
     */
    readonly loadOrganizationList$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                LoginActions.packingKeyPageOpened,
                LoginActions.setMayViewAccount,
            ),
            concatLatestFrom(() => this.store.select(selectOrganizationListResponse)),
            filter(([, organizationListResponse]) => (undefined === organizationListResponse || organizationListResponse!.status.code !== 0)),
            exhaustMap(() => {
                return this.organizationService.getOrganizationList().pipe(
                    map((response) =>
                        OrganizationActions.organizationListRetrieved({ response })
                    ),
                    catchError((error) => of(OrganizationActions.organizationListRetrievalError({ error })))
                );
            })
        )
    );

    readonly updateSelectedTeamMemberRoles$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                OrganizationActions.updateTeamMemberRoles),
            exhaustMap((action) => {
                return this.organizationService.updateTeamMemberRoles(action.request).pipe(
                    map((response) =>
                        OrganizationActions.updateTeamMemberRolesResponse({ response })
                    ),
                    catchError((error) => of(OrganizationActions.updateTeamMemberRolesError({ error })))
                );
            })
        )
    );


    readonly loadOrganizationListNoLastCheck$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                OrganizationActions.needReloadOrganizationDetails),
            exhaustMap(() => {
                return this.organizationService.getOrganizationList().pipe(
                    map((response) =>
                        OrganizationActions.organizationListRetrieved({ response })
                    ),
                    catchError((error) => of(OrganizationActions.organizationListRetrievalError({ error })))
                );
            })
        )
    );


    /**
     * Loads the organization settings from the server
     */
    readonly loadOrganizationSettings$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                OrganizationActions.organizationSettingsPageLoaded,
                OrganizationActions.loadOrganizationSettings),
            exhaustMap(() => {
                return this.organizationService.getOrganizationSettings().pipe(
                    map((response) =>
                        OrganizationActions.organizationSettingsLoaded({ response })
                    ),
                    catchError((error) => of(OrganizationActions.organizationSettingsLoadError({ error })))
                );
            })
        )
    );

    /**
     * Loads the organization List from the server
     */
    readonly loadLicensedUsers$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                ReportingActions.userReportingPageOpen,
                OrganizationActions.licensePageLoaded,
                OrganizationActions.invitationsSent,
                OrganizationActions.resendInvitationsSuccess,
                OrganizationActions.cancelInvitationsSuccess,
                OrganizationActions.removeOrganizationLicensesSuccess,
                OrganizationActions.removeVerifiedDomainAccountSuccess,
                OrganizationActions.purchasedAdditionalLicenses,
                OrganizationActions.updateTeamMemberRolesResponse),
            // filter(([, licenseList]) => (undefined === licenseList || licenseList!.status.code !== 0)),
            exhaustMap(() => {
                return this.organizationService.getOrganizationLicenseList().pipe(
                    map((response) =>
                        OrganizationActions.organizationLicensesRetrieved({ response })
                    ),
                    catchError((error) => of(OrganizationActions.organizationLicensesRetrievalError({ error })))
                );
            })
        )
    );

    /**
     * Finds the active organization - after the org list is loaded
     */
    readonly findActiveOrganization$ = createEffect(
        () => this.actions$.pipe(
            ofType(OrganizationActions.organizationListRetrieved),
            tap((action) => {
                if (this.consoleLog) console.log('findActiveOrganization$ action', action);
                this.organizationService.findActiveOrganization(action.response);
            }
            )
        ), { dispatch: false }
    )
        ;



    /**
* Resend the selected invitations
*/
    readonly resendSelectedInvitations$ = createEffect(
        () => this.actions$.pipe(
            ofType(OrganizationActions.resendInvitations),
            exhaustMap((action) => {
                return this.organizationService.resendSelectedTeamInvitations(action.customerIds).pipe(
                    map((response) =>
                        OrganizationActions.resendInvitationsSuccess({ response })
                    ),
                    catchError((error) => of(OrganizationActions.resendInvitationsError({ error })))
                );
            })
        )
    );

    /**
* Cancel the selected invitations
*/
    readonly cancelSelectedInvitations$ = createEffect(
        () => this.actions$.pipe(
            ofType(OrganizationActions.cancelInvitations),
            exhaustMap((action) => {
                return this.organizationService.cancelSelectedTeamInvitations(action.customerIds).pipe(
                    map((response) =>
                        OrganizationActions.cancelInvitationsSuccess({ response })
                    ),
                    catchError((error) => of(OrganizationActions.cancelInvitationsError({ error })))
                );
            })
        )
    );

    readonly removeOrganizationLicenses$ = createEffect(
        () => this.actions$.pipe(
            ofType(OrganizationActions.removeOrganizationLicenses),
            exhaustMap((action) => {
                return this.organizationService.removeOrganizationLicenses(action.customerIds).pipe(
                    map((response) =>
                        OrganizationActions.removeOrganizationLicensesSuccess({ response })
                    ),
                    catchError((error) => of(OrganizationActions.removeOrganizationLicensesError({ error })))
                );
            })
        )
    );


    readonly removeVerifiedDomainAccounts$ = createEffect(
        () => this.actions$.pipe(
            ofType(OrganizationActions.removeVerifiedDomainAccount),
            exhaustMap((action) => {
                return this.organizationService.removeVerifiedAccounts(action.customerIds).pipe(
                    map((response) =>
                        OrganizationActions.removeVerifiedDomainAccountSuccess({ response })
                    ),
                    catchError((error) => of(OrganizationActions.removeVerifiedDomainAccountError({ error })))
                );
            })
        )
    );

}
