import { AccountState } from "@app/model/app/account/AccountState";
import { createFeatureSelector, createSelector } from "@ngrx/store";

const accountFeature = createFeatureSelector<AccountState>('accountState');

export const selectPackingKeyData = createSelector(
    accountFeature,
    state => state.packingKeyData
);
export const selectAccountKeysData = createSelector(
    accountFeature,
    state => state.accountKeys
);

export const selectAccountUserInformationDetails = createSelector(
    accountFeature,
    state => state.accountUserInformation
);

export const selectOOBPreference = createSelector(
    accountFeature,
    state => state.oobPreference
);

export const selectSettingsData = createSelector(
    accountFeature,
    state => state.getSettingsDataResponse
);

export const selectSubscriptionSummaryDetails = createSelector(
    accountFeature,
    state => state.subscriptionSummaryResponse
);
export const selectValidSubscription = createSelector(
    accountFeature,
    state => state.validSubscription
);

export const selectState = createSelector(
    accountFeature,
    state => state
);

export const selectAccountModel = createSelector(
    selectState,
    (state) => ({
        state,
    })
);