import { Injectable } from '@angular/core';
import { TeamKeys } from '@app/model/app/teams/TeamKeys';
import { Statement } from 'sql.js';
import { StorageService } from '../dataStorage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class TeamKeyService {

  constructor(
    private storageService: StorageService,
  ) { }



  /**
   * Get the team keys for the specified key group
   * @param keyGroup 
   * @returns 
   */
  getTeamMetaKeys(keyGroup: number): TeamKeys | undefined {
    let rv: TeamKeys | undefined = undefined;
    let teamMetaKeys = "";
    let cacheKey = "tmk:" + keyGroup;
    // if (this.ttlCacheService.hasKey(cacheKey)) {
    //   rv = this.ttlCacheService.get(cacheKey);
    // } else {
    // generate and cache the key
    let selectTeamStatement: Statement | undefined = undefined;
    if (this.storageService.inMemorySQLService) {
      selectTeamStatement = this.storageService.inMemorySQLService.getDatabase().prepare(`SELECT * FROM teams WHERE id = :id`);
    }
    if (null != selectTeamStatement) {
      // console.log("select statement OK");
      const result = selectTeamStatement.getAsObject({ ':id': keyGroup });
      // console.log("result ", result);
      if (result) {
        rv = {
          teamId: result.id as number,
          groupKey: result.groupKey?.toString() || '',
          metadataKey: result.metadataKey?.toString() || ''
        };
      }
      selectTeamStatement.free();
    }

    return rv;

  }


  /**
   * Finds the team keys to my default group/team
   * @returns 
   */
  getMyDefaultTeamMetaKeys(): TeamKeys | undefined {
    let rv: TeamKeys | undefined = undefined;
    let teamMetaKeys = undefined;
    if (!teamMetaKeys || teamMetaKeys == undefined) {
      // generate and cache the key

      let selectTeamStatement: Statement | undefined = undefined;
      if (this.storageService.inMemorySQLService) {
        selectTeamStatement = this.storageService.inMemorySQLService.getDatabase().prepare(`SELECT * FROM teams WHERE name=:name`);
      }
      if (null != selectTeamStatement) {
        // console.log("select statement OK");
        const result = selectTeamStatement.getAsObject({ ':name': "Default" });
        // console.log("result ", result);
        if (result) {
          rv = {
            teamId: result.id as number,
            groupKey: result.groupKey?.toString() || '',
            metadataKey: result.metadataKey?.toString() || ''
          };
        }
        selectTeamStatement.free();
      }
    } else {
      rv = teamMetaKeys;
    }
    return rv;

  }
}
