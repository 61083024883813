import { SyncStatus } from "@app/model/app/database/SyncStatus";
import { PasswordFeedEntry } from "../../api/dataFeed/PasswordFeedEntry";

export class Password extends PasswordFeedEntry {
    syncedInMemory: number = SyncStatus.SYNC_STATUS_NOT_SYNCED;


    constructor(password: PasswordFeedEntry) {
        super(password);
    }
}