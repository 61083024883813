

import { QuickConvertTeamToMultiAdminArgs } from '@app/model/app/passwordGroup/QuickConvertTeamToMultiAdminArgs';
import { PasswordVaultData } from '@app/model/app/passwordVault/PasswordVaultData';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const PasswordVaultActions = createActionGroup({
    source: 'PasswordVaults',
    events: {
        // Page opens


        // Other


        'Sync Vaults To Store': emptyProps(),
        'Update Vault List': props<{ data: PasswordVaultData[] }>(),
        'Quick Convert Team To Multi Admin': props<{ data: QuickConvertTeamToMultiAdminArgs }>(),


    },
});