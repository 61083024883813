import { Injectable } from '@angular/core';
import { BaseAPIService } from './BaseAPIService';
import { GeneratedDataFeedResponse } from '@app/model/api/dataFeed/GeneratedDataFeedResponse';
import { Observable, first, firstValueFrom, from, last, lastValueFrom } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpParams } from '@angular/common/http';
import { PasswordFeedResponse } from '@app/model/api/dataFeed/PasswordFeedResponse';
import { PasswordGroupFeedResponse } from '@app/model/api/dataFeed/PasswordGroupFeedResponse';
import { LabelFeedResponse } from '@app/model/api/dataFeed/LabelFeedResponse';
import { ActionFeedResponse } from '@app/model/api/dataFeed/ActionFeedResponse';
import { ConnectionFeedResponse } from '@app/model/api/dataFeed/ConnectionFeedResponse';
import { Store, select } from '@ngrx/store';
import { selectAccountUserInformationDetails } from '@app/store/selectors/AccountSelectors';
import { LoginActions } from '@app/store/actions/LoginActions';
import { StatusFeedStateManagement } from './dataStorage/StatusFeedStateManagement';
import { StatusResponse } from '@app/model/api/status/StatusResponse';
import { StatusFeedIds } from '@app/model/api/status/StatusFeedIds';
import { StorageActions } from '@app/store/actions/StorageActions';
import { PasswordGroupFeedStorageService } from './dataStorage/PasswordGroupFeedStorageService';
import { PasswordFeedStorageService } from './dataStorage/PasswordFeedStorageService';
import { DeletedPasswordFeedResponse } from '@app/model/api/dataFeed/DeletedPasswordFeedResponse';
import { DeletedPasswordFeedStorageService } from './dataStorage/DeletedPasswordFeedStorageService';
import { StorageService } from './dataStorage/storage.service';
import { OrganizationService } from './organization/Organization.service';
import { OrganizationActions } from '@app/store/actions/OrganizationActions';
import { AccountActions } from '@app/store/actions/AccountActions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PasswordVaultService } from './passwordVault/password-vault-service.service';
import { VaultFeedStorageService } from './dataStorage/VaultFeedStorageService';

@Injectable({
  providedIn: 'root'
})
export class DataFeedService {
  private syncing: boolean = false;
  private loadingUnsyncedData: boolean = false;
  private statusToken: string;
  private consoleLog: boolean = false;
  private lastRunEnsureOwnerPasswordsInDefaultGroup: number = 0;
  appVersion: string = environment.appVersion;
  readonly vma$ = this.store.select(selectAccountUserInformationDetails);
  private numberReloadRequestsSinceFirstDisplayed: number = 0;
  private maxNumberReloadRequestsSinceFirstDisplayed: number = 20; // equals 20 * 15 seconds = 5 minutes.  15 second query for status

  private awaitingFeedPassword: boolean = false;
  private awaitingFeedDeletedPasswords: boolean = false;
  private awaitingFeedLabel: boolean = false;
  private awaitingFeedPasswordGroup: boolean = false;
  private awaitingFeedConnection: boolean = false;
  private awaitingFeedGeneratedData: boolean = false;
  private awaitingFeedAction: boolean = false;
  private awaitingFeedVaults: boolean = false;


  // private loadingVaultData: boolean = false;
  // private loadingGroupData: boolean = false;
  // private loadingPasswordData: boolean = false;
  // private loadingDeletedPasswordData: boolean = false;


  // Keep track how many times we've tried to run the loadUnprocessedData function
  loadUnprocessedDataCounter: number = 0;




  constructor(
    private baseAPIService: BaseAPIService,
    private statusFeedStateManagement: StatusFeedStateManagement,
    private passwordFeedStorageService: PasswordFeedStorageService,
    private passwordGroupFeedStorageService: PasswordGroupFeedStorageService,
    private deletedPasswordFeedStorageService: DeletedPasswordFeedStorageService,
    private organizationService: OrganizationService,
    private storageService: StorageService,
    private passwordVaultService: PasswordVaultService,
    private vaultFeedStorageService: VaultFeedStorageService,
    private store: Store,
    private snackBar: MatSnackBar
  ) {


    // Update the status token
    this.vma$.subscribe(x => {
      if (x) {
        this.statusToken = x.statusToken;
      } else this.statusToken = "";
    });

  }





  /**
     * Checks the update server for any updates.
     * Starts the processing if required.
     * @returns 
     */
  syncData(): Observable<String> {
    this.syncDataBlocking();
    return new Observable<String>(observer => { observer.next("OK - update"); observer.complete(); });
  }

  syncDataBlocking(): void {

    if (this.consoleLog) console.log("checkUpdateServer");
    if (!this.syncing) {
      // Lock the syncing flag
      this.syncing = true;
      let databaseAvailable = false;
      let database = this.storageService.inMemorySQLService?.getDatabase();
      if (database) {
        databaseAvailable = true;
      }


      try {
        if (databaseAvailable) {
          this.retrieveStatusFromServer().subscribe(
            (response) => {
              // console.log(response);
              // Process update response as needed
              if (!response.goodSession) {
                console.log("Bad session. Logging out.");
                this.store.dispatch(LoginActions.logoutInitiate());
              } else {
                let lastUpdates = this.statusFeedStateManagement.getLastUpdatesForStatusFeed();
                lastUpdates.then((lastUpdateResult) => {
                  response.entries.forEach(entry => {
                    if (lastUpdateResult.get(entry.feed) === undefined || lastUpdateResult.get(entry.feed) !== entry.ts) {
                      // console.log("Feed LU: " + lastUpdateResult.get(entry.feed) + ", Entry TS: " + entry.ts + ", Need to process feed: ", entry.feed);
                      switch (entry.feed) {
                        case StatusFeedIds.FEED_ID_PASSWORD:
                          // password feed
                          this.getPasswordFeed(entry.ts);
                          break;
                        case StatusFeedIds.FEED_ID_DELETED_PASSWORDS:
                          // deleted password feed
                          this.getDeletedPasswordFeed(entry.ts);
                          break;
                        case StatusFeedIds.FEED_ID_LABEL:
                          // label feed
                          this.getLabelFeed(entry.ts);
                          break;
                        case StatusFeedIds.FEED_ID_PASSWORD_GROUP:
                          // group feed
                          this.getPasswordGroupFeed(entry.ts);
                          break;
                        case StatusFeedIds.FEED_ID_CONNECTION:
                          // connection feed
                          this.getConnectionFeed(entry.ts);
                          break;
                        case StatusFeedIds.FEED_ID_GENERATED_DATA:
                          // generated data feed
                          this.getGeneratedDataFeed(entry.ts);
                          break;
                        case StatusFeedIds.FEED_ID_LICENSE:
                          // license feed
                          this.getLicenseFeed(entry.ts);
                          break;
                        case StatusFeedIds.FEED_ID_ORG_LICENSE:
                          // org license feed
                          this.getOrgLicenseFeed(entry.ts);
                          break;
                        case StatusFeedIds.FEED_ID_ACTION:
                          // action feed
                          this.getActionFeed(entry.ts);
                          break;
                        case StatusFeedIds.FEED_ID_VAULTS:
                          // vaults feed
                          this.getVaultFeed(entry.ts);
                          break;
                        default:
                          this.statusFeedStateManagement.storeLastUpdateForStatusFeed(entry.feed, entry.ts);
                          break;
                      }
                    }
                  });

                  if (this.consoleLog) console.log("Done syncing feeds.  Loading unprocessed data.");

                  // All feeds should be downloaded.  Send a trigger to load data?  Feeds are processed sequentially, but the results are loaded async
                  // Fire an event here as well as after the feeds are processed.  Probably overkill
                  if (this.consoleLog) console.log("dfs triggering loadUnprocessedData");
                  this.store.dispatch(StorageActions.localDataChanged());

                });

                this.testRequiredClientVersion(response);

              }
            }
          );
        } else {
          console.warn("Database not ready.  Skipping check.");
        }
      } catch (error) {
        console.error("Error during status feed check: ", error);
      } finally {
        // Unlock the syncing flag
        this.syncing = false;
      }
    } else {
      console.log("Syncing.  Skipping check.");
    }

  }


  /**
   * Tests the required client version and if there is a version mismatch then performs the specified action
   * @param response 
   * @returns 
   */
  testRequiredClientVersion(response: StatusResponse) {
    // Test the required client version
    try {
      console.log("AppVersion: " + this.appVersion + ", RCV: " + response.rcv2);

      // test for undefined
      if (response.rcv2 === undefined || response.rcv2 === null || response.rcv2 === "") {
        if (this.consoleLog) console.log("No RCV2.  Skipping version check.");
        return;
      } else {
        if (this.appVersion !== response.rcv2) {
          let action = response.rcva;
          console.log("App version does not match required version. Action: " + action);

          // Do the action if it is defined
          if (action !== undefined && action !== null && action !== "") {
            if ("reloadImmediate" === response.rcva) {
              this.reloadNewVersion();
            } else if ("reload" === response.rcva) {
              this.numberReloadRequestsSinceFirstDisplayed++;
              console.log("Reload request: " + this.numberReloadRequestsSinceFirstDisplayed);
              if (this.numberReloadRequestsSinceFirstDisplayed > this.maxNumberReloadRequestsSinceFirstDisplayed) {
                this.reloadNewVersion();
              } else {
                try {
                  this.snackBar.open("A new version of the application is available.  Please reload the application to get the latest version.", "Reload", {
                    duration: 19000,
                  }).onAction().subscribe(() => {
                    this.reloadNewVersion();
                  });
                } catch (error) {
                  console.error("Error during version check: ", error);
                }
              }
            } else if ("none" === response.rcva) {
              // console.log("No action required.");
            }
          }
        }
      }
    } catch (error) {
      console.error("Error during version check: ", error);
    }
  }

  /**
   * Reloads the new version of the application. Q is a parameter intended to force a reload of the application and break any caching.
   */
  reloadNewVersion() {
    let now = new Date();
    window.location.href = '/' + "?q=" + now.getTime();
  }


  /**
   * Loads data that is in the indexDb but not in the in memory database.
   * Ordering here matters. The data must be loaded in the correct order.
   * Groups should be first to get the keys for the passwords.
   */
  async loadUnprocessedData(favoritesOnly: boolean = false) {

    let logToConsole = this.consoleLog;
    logToConsole = true;
    if (this.loadingUnsyncedData === true) {
      if (logToConsole) console.log("Already loading unsynced data.  Skipping.");
    } else {
      // Temporarily not blocking - this will allow the UI to load while the data is being loaded
      // this.loadingUnsyncedData = true;
      try {
        if (logToConsole) console.log("loading unsynced data. counter: ", this.loadUnprocessedDataCounter);
        this.vaultFeedStorageService.loadUnsyncedVaults()
          .then((res) => this.passwordGroupFeedStorageService.loadUnsyncedGroupData(this.loadUnprocessedDataCounter))
          .then((res) => this.passwordFeedStorageService.loadUnsyncedPasswordData(favoritesOnly))
          .then((res) => this.deletedPasswordFeedStorageService.loadUnsyncedDeletedPasswordData())
          .then((res) => {
            this.loadingUnsyncedData = false;
            // ensure all owned passwords are in default group.  This needs to happen outside of the loadingUnsyncedData flag so the data may actually run. It causes a sync operation.
            // Only allow this to run once per 2 minutes
            let now = Date.now();
            let delay = 2 * 60 * 1000;
            if (now - this.lastRunEnsureOwnerPasswordsInDefaultGroup > delay) {
              this.lastRunEnsureOwnerPasswordsInDefaultGroup = now;
              this.passwordFeedStorageService.ensureOwnedPasswordsAreInDefaultGroup();
            }

            if (logToConsole) console.log("done loading unsynced data. counter: ", this.loadUnprocessedDataCounter);
            this.loadUnprocessedDataCounter++;
          });
      } catch (error) {
        console.error("Error during loading unprocessed data: ", error);
        this.loadingUnsyncedData = false;
      }


    }
  }


  /**
      * Retrieves the status object from the server.
      * @returns 
      */
  retrieveStatusFromServer(): Observable<StatusResponse> {
    if (this.statusToken === undefined || this.statusToken === null || this.statusToken === "") {
      return new Observable<StatusResponse>(observer => { new StatusResponse(); observer.complete(); });
    } else {
      var params = new HttpParams();
      let url = environment.STATUS_BASE_URL + this.statusToken;
      params = params.set("c", environment.CLIENT_ID).set("v", this.appVersion);

      // console.log(environment.CLIENT_ID)
      return this.baseAPIService.getRequest<StatusResponse>(params, url);
    }
  }


  /**
   *  Retrieves the generated data feed
   * @param lastUpdate 
   * @returns 
   */
  getGeneratedDataFeed(statusTimestamp: string): void {
    if (!this.awaitingFeedGeneratedData) {
      this.awaitingFeedGeneratedData = true;
      try {
        let url = environment.API_BASE_URL + "v1/secure/generatedData/feed";
        var params = new Map<string, any>();
        let dataFeedTs = this.statusFeedStateManagement.getLastUpdateForDataFeed(StatusFeedIds.FEED_ID_GENERATED_DATA);
        dataFeedTs.then((result) => {
          params = params.set('lastUpdate', dataFeedTs);
          this.baseAPIService.postRequest<GeneratedDataFeedResponse>(params, url);
          this.awaitingFeedGeneratedData = false;

        });
      } catch (error) {
        console.error("Error during generated data feed retrieval: ", error);
        this.awaitingFeedGeneratedData = false;
      }
    }
  }

  getOrgLicenseFeed(statusTimestamp: string): void {
    // Process the last update
    this.organizationService.getOrganizationLicenseList().subscribe(response => {
      this.store.dispatch(OrganizationActions.organizationLicensesRetrieved({ response: response }));
      // this.statusFeedStateManagement.storeLastUpdateForDataFeed(StatusFeedIds.FEED_ID_ORG_LICENSE, response.lastDate);
      this.statusFeedStateManagement.storeLastUpdateForStatusFeed(StatusFeedIds.FEED_ID_ORG_LICENSE, statusTimestamp);
    });

  }

  getLicenseFeed(statusTimestamp: string): void {
    // Process the last update

    this.store.dispatch(AccountActions.reloadLicenseSummary());
    // this.accountService.getLicenseSummary().subscribe(response => {
    //   this.store.dispatch(OrganizationActions.organizationLicensesRetrieved({ response: response }));
    // this.statusFeedStateManagement.storeLastUpdateForDataFeed(StatusFeedIds.FEED_ID_ORG_LICENSE, response.lastDate);
    this.statusFeedStateManagement.storeLastUpdateForStatusFeed(StatusFeedIds.FEED_ID_LICENSE, statusTimestamp);
    // });

  }



  /**
   *  Retrieves the password feed
   * @returns 
   */
  getPasswordFeed(statusTimestamp: string) {
    if (!this.awaitingFeedPassword) {
      this.awaitingFeedPassword = true;
      let url = environment.API_BASE_URL + "v2/secure/password/feed";
      var params = new HttpParams();

      try {
        let dataFeedTs = this.statusFeedStateManagement.getLastUpdateForDataFeed(StatusFeedIds.FEED_ID_PASSWORD);
        dataFeedTs.then((result) => {
          params = params.set('lastUpdate', result);

          // Process the last update
          this.baseAPIService.getRequest<PasswordFeedResponse>(params, url)
            .subscribe(response => {
              this.store.dispatch(StorageActions.passwordFeedRetrieved({ data: response }));
              this.statusFeedStateManagement.storeLastUpdateForDataFeed(StatusFeedIds.FEED_ID_PASSWORD, response.lastDate);
              this.statusFeedStateManagement.storeLastUpdateForStatusFeed(StatusFeedIds.FEED_ID_PASSWORD, statusTimestamp);
              this.awaitingFeedPassword = false;
            })
        });
      } catch (error) {
        console.error("Error during password feed retrieval: ", error);
        this.awaitingFeedPassword = false;
      }
    } else {
      console.log("Already awaiting password feed.  Skipping.");
    }

  }

  /**
   *  Retrieves the deleted password feed
   * @returns 
   */
  getDeletedPasswordFeed(statusTimestamp: string): void {
    if (!this.awaitingFeedDeletedPasswords) {
      this.awaitingFeedDeletedPasswords = true;
      try {
        let url = environment.API_BASE_URL + "v3/secure/password/deleted/feed";
        var params = new HttpParams();

        let dataFeedTs = this.statusFeedStateManagement.getLastUpdateForDataFeed(StatusFeedIds.FEED_ID_DELETED_PASSWORDS);
        dataFeedTs.then((result) => {
          params = params.set('lastUpdate', result);

          // Process the last update
          this.baseAPIService.getRequest<DeletedPasswordFeedResponse>(params, url).subscribe(response => {
            this.store.dispatch(StorageActions.deletedPasswordFeedRetrieved({ data: response }));
            this.statusFeedStateManagement.storeLastUpdateForDataFeed(StatusFeedIds.FEED_ID_DELETED_PASSWORDS, response.lastDate);
            this.statusFeedStateManagement.storeLastUpdateForStatusFeed(StatusFeedIds.FEED_ID_DELETED_PASSWORDS, statusTimestamp);
            this.awaitingFeedDeletedPasswords = false;
          });
        });
      } catch (error) {
        console.error("Error during deleted password feed retrieval: ", error);
        this.awaitingFeedDeletedPasswords = false;

      }
    }
  }


  /**
   *  Retrieves the password group feed
   * @param lastUpdate 
   * @returns 
   */
  getPasswordGroupFeed(statusTimestamp: string): void {
    if (!this.awaitingFeedPasswordGroup) {
      this.awaitingFeedPasswordGroup = true;
      try {
        let url = environment.API_BASE_URL + "v3/secure/password/group/feed";
        var params = new HttpParams();
        let dataFeedTs = this.statusFeedStateManagement.getLastUpdateForDataFeed(StatusFeedIds.FEED_ID_PASSWORD_GROUP);
        dataFeedTs.then((result) => {
          params = params.set('lastUpdate', result);

          // Process the last update
          this.baseAPIService.getRequest<PasswordGroupFeedResponse>(params, url).subscribe(response => {
            this.store.dispatch(StorageActions.passwordGroupFeedRetrieved({ data: response }));
            this.statusFeedStateManagement.storeLastUpdateForDataFeed(StatusFeedIds.FEED_ID_PASSWORD_GROUP, response.lastDate);
            this.statusFeedStateManagement.storeLastUpdateForStatusFeed(StatusFeedIds.FEED_ID_PASSWORD_GROUP, statusTimestamp);
            this.awaitingFeedPasswordGroup = false;
          });
        });
      } catch (error) {
        console.error("Error during password group feed retrieval: ", error);
        this.awaitingFeedPasswordGroup = false;
      }
    }
  }

  /**
   *  Retrieves the label feed
   * @param lastUpdate 
   * @returns 
   */
  getLabelFeed(statusTimestamp: string): void {
    if (!this.awaitingFeedLabel) {
      this.awaitingFeedLabel = true;
      try {
        let url = environment.API_BASE_URL + "v1/secure/tag/feed";
        var params = new Map<string, any>();
        let dataFeedTs = this.statusFeedStateManagement.getLastUpdateForDataFeed(StatusFeedIds.FEED_ID_LABEL);
        dataFeedTs.then((result) => {
          params = params.set('lastUpdate', result);

          // Process the last update
          this.baseAPIService.postRequest<LabelFeedResponse>(params, url).subscribe(response => {
            this.store.dispatch(StorageActions.labelFeedRetrieved({ data: response }));
            this.statusFeedStateManagement.storeLastUpdateForDataFeed(StatusFeedIds.FEED_ID_LABEL, response.lastDate);
            if (statusTimestamp !== "") {
              this.statusFeedStateManagement.storeLastUpdateForStatusFeed(StatusFeedIds.FEED_ID_LABEL, statusTimestamp);
            }
            this.awaitingFeedLabel = false;
          });
        });

      } catch (error) {
        console.error("Error during label feed retrieval: ", error);
        this.awaitingFeedLabel = false;
      }
    }

  }

  getVaultFeed(statusTimestamp: string): void {
    if (!this.awaitingFeedVaults) {
      this.awaitingFeedVaults = true;
      try {
        this.passwordVaultService.retrievePasswordVaultsFromServer().subscribe(response => {
          this.store.dispatch(StorageActions.vaultFeedRetrieved({ data: response }));
          this.statusFeedStateManagement.storeLastUpdateForDataFeed(StatusFeedIds.FEED_ID_VAULTS, statusTimestamp);
          this.statusFeedStateManagement.storeLastUpdateForStatusFeed(StatusFeedIds.FEED_ID_VAULTS, statusTimestamp);
          this.awaitingFeedVaults = false;
        });
      } catch (error) {
        console.error("Error during vault feed retrieval: ", error);
        this.awaitingFeedVaults = false;
      }
    };
  }

  /**
   *  Retrieves the action feed
   * @param lastUpdate 
   * @returns 
   */
  getActionFeed(statusTimestamp: string): void {
    if (!this.awaitingFeedAction) {
      this.awaitingFeedAction = true;
      try {
        let url = environment.API_BASE_URL + "v1/secure/action/feed";
        var params = new HttpParams();
        let dataFeedTs = this.statusFeedStateManagement.getLastUpdateForDataFeed(StatusFeedIds.FEED_ID_ACTION);
        dataFeedTs.then((result) => {
          params = params.set('lastUpdate', result);
          // Process the last update
          this.baseAPIService.getRequest<ActionFeedResponse>(params, url).subscribe(response => {
            this.store.dispatch(StorageActions.actionFeedRetrieved({ data: response }));
            this.statusFeedStateManagement.storeLastUpdateForDataFeed(StatusFeedIds.FEED_ID_ACTION, response.lastDate);
            this.statusFeedStateManagement.storeLastUpdateForStatusFeed(StatusFeedIds.FEED_ID_ACTION, statusTimestamp);
            this.awaitingFeedAction = false;

          });
        });
      } catch (error) {
        console.error("Error during action feed retrieval: ", error);
        this.awaitingFeedAction = false;
      }
    }
  }

  /**
   *  Retrieves the connection feed
   * @param lastUpdate 
   * @returns 
   */
  getConnectionFeed(statusTimestamp: string): void {
    if (!this.awaitingFeedConnection) {
      this.awaitingFeedConnection = true;
      try {
        let url = environment.API_BASE_URL + "v1/secure/connection/feed";
        var params = new Map<string, any>();
        let dataFeedTs = this.statusFeedStateManagement.getLastUpdateForDataFeed(StatusFeedIds.FEED_ID_CONNECTION);
        dataFeedTs.then((result) => {
          params = params.set('lastUpdate', result);
          // Process the last update
          this.baseAPIService.postRequest<ConnectionFeedResponse>(params, url).subscribe(response => {
            this.store.dispatch(StorageActions.connectionFeedRetrieved({ data: response }));
            this.statusFeedStateManagement.storeLastUpdateForDataFeed(StatusFeedIds.FEED_ID_CONNECTION, response.lastDate);
            this.statusFeedStateManagement.storeLastUpdateForStatusFeed(StatusFeedIds.FEED_ID_CONNECTION, statusTimestamp);
            this.awaitingFeedConnection = false;
          });
        });
      } catch (error) {
        console.error("Error during connection feed retrieval: ", error);
        this.awaitingFeedConnection = false;
      }
    }
  }

}
