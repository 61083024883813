export const environment = {
  production: false,
  appVersion: require('../../package.json').version + '-stg',

  API_BASE_URL: 'https://api-stg.passpack.com/portal/',
  STATUS_BASE_URL: 'https://a-stg.passpack.com/status/v1/status/',
  // API_BASE_URL: 'https://api.passpack.com/portal/',
  // STATUS_BASE_URL: 'https://a.passpack.com/status/v1/status/',
  CLIENT_ID: 'pwm2-stg',
  STRIPE_KEY: 'pk_test_OBxcYU8ZOL0qU2M8PjHwsClR'
};
