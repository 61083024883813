import { GroupDetailRoleEntry } from "@app/model/api/dataFeed/GroupDetailRoleEntry";
import { TeamVersionsEnum } from "./TeamVersionsEnum";

export class InMemoryTeam {
    groupStatus: number;
    encryptedGroupKey: string;
    metadataKey: string;
    id: number;
    name: string;
    color: string;
    description: string;
    owner: string;
    ownerId: number;
    ownerNick: string;
    role: number;
    defaultRole: number;
    status: number;
    individual: boolean;
    individualShareId: number;
    pids: number[];
    vaultId: string;
    encryptedTeamKey: string;
    roles: GroupDetailRoleEntry[];
    action: number;
    version: number = TeamVersionsEnum.V1;
    defaultTeam: boolean = false;

    decryptedGroupKey: string;
    decryptedMetadataKey: string;
    decryptedFromVault: boolean = false;
}