import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppDBService } from './AppDbService';
import { StorageService } from './storage.service';
import { StorageActions } from '@app/store/actions/StorageActions';
import { Database } from 'sql.js';
import { SyncStatus } from '@app/model/app/database/SyncStatus';
import { PasswordGroupFeedStorageService } from './PasswordGroupFeedStorageService';
import { PasswordDecryption } from '../encryption/PasswordDecryption';
import { PromiseExtended } from 'dexie';
import { DeletedPasswordFeedResponse } from '@app/model/api/dataFeed/DeletedPasswordFeedResponse';
import { DeletedPassword } from '@app/model/app/password/DeletedPassword';
import { DisplayService } from '../DisplayService';
import { AccountKeysService } from '../account/AccountKeysService';
import { TeamKeyService } from '../cache/team-key-service.service';
import { PasswordVaultService } from '../passwordVault/password-vault-service.service';
@Injectable({
    providedIn: 'root'
})
export class DeletedPasswordFeedStorageService implements OnInit, OnDestroy {
    consoleLog: boolean = false;

    constructor(
        private store: Store,
        private storageService: StorageService,
        private teamKeyService: TeamKeyService,
        private displayService: DisplayService,
        private passwordDecryption: PasswordDecryption,
        private accountKeysService: AccountKeysService,
        private passwordVaultService: PasswordVaultService

    ) {

    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }

    /**
       * Process the deleted password feed and store the lastupdate
       * @param data 
       */
    processDeletedPasswordFeed(data: DeletedPasswordFeedResponse) {
        if (data && data.deletedPasswords && data.deletedPasswords.length > 0) {
            let toAdd: DeletedPassword[] = [];
            let start = new Date().getTime();

            // This feed is always additive. No need to remove an entry
            data.deletedPasswords.forEach(deletedPassword => {
                let deletedPasswordEntry = new DeletedPassword(deletedPassword);
                deletedPasswordEntry.syncedInMemory = SyncStatus.SYNC_STATUS_NOT_SYNCED;
                toAdd.push(deletedPasswordEntry);
            });

            ////////////////////////////////////////////
            // Send to the indexeddb
            ////////////////////////////////////////////
            // Adds or updates
            this.storageService.appDbService?.passwordDeletes.bulkPut(toAdd).then() // handle success
                .catch(function (error) { console.log(error); }); // handle failure         

            let end = new Date().getTime();
            if (this.consoleLog) console.log("Time to process deleted password feed: " + (end - start) + " ms");

            ////////////////////////////////////////////
            // Notify the store that we are done loading
            // this.store.dispatch(StorageActions.passwordFeedStored());
            // Fire an event here to indicate that the data has changed
            if (this.consoleLog) console.log("dpfs triggering local data changed");
            this.store.dispatch(StorageActions.localDataChanged());
        }
    }

    updateTableCounts() {
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) this.dumpCounts(database);
    }


    /**
     * Helper method to dump the counts of rows
     * @param database 
     */
    private dumpCounts(database: Database) {
        let self = this;
        database.each("SELECT count(1) as count FROM deleted_passwords;", {},
            function (row) {
                if (self.consoleLog) console.log("deleted_passwords rows-> " + row.count);
                self.store.dispatch(StorageActions.updateLoadedDeletedPasswordCount({ data: Number(row.count?.toString()) }));
            },
            () => void (0)
        );

        // let counter = 0;
        // database.each("SELECT * FROM deleted_passwords;", {},
        //     function (row) {
        //         console.log("data Table deleted_passwords -> " + JSON.stringify(row) + " " + ++counter);
        //     },
        //     () => void (0)
        // );

        let db = this.storageService.appDbService;
        if (db) {
            let results = db.passwordDeletes.count();
            results.then((count) => {
                self.store.dispatch(StorageActions.updatePreloadDeletedPasswordCount({ data: Number(count?.toString()) }));
            });
        }


    }


    /**
     * Finds the unsynced records in the index db and syncs them to the in memory database.
     */
    loadUnsyncedDeletedPasswordData(): Promise<number> {
        var promise = new Promise<number>((resolve, reject) => {
            try {
                if (this.consoleLog) console.log("loadUnsyncedDeletedPasswordData");

                // Load the account keys
                // console.log("accountKeys ", this.accountKeysData);
                let accountKeysData = this.accountKeysService.getAccountKeysData();
                if (accountKeysData && accountKeysData.packingKeyDecryptedPrivateKey && accountKeysData.packingKeyDecryptedPrivateKey.length > 0) {
                    if (this.storageService.appDbService) {
                        let db = this.storageService.appDbService;

                        // Retrieve the records in the database
                        let results: PromiseExtended<DeletedPassword[]>;
                        results = db.passwordDeletes.where('syncedInMemory').equals(SyncStatus.SYNC_STATUS_NOT_SYNCED).toArray();

                        results.then((records) => {
                            if (records.length > 0) {
                                this.processUnsyncedDeletedPassword(db, records);
                            }

                            // // Dump the counts. Note: this is happening likely before all records are synced
                            this.updateTableCounts();
                            resolve(1);

                        });


                    } else {
                        resolve(0);
                    }
                } else {
                    if (this.consoleLog) console.log("app keys not ready");
                    resolve(0);
                }

            } catch (error) {
                console.log("Error in loadUnsyncedDeletedPasswordData: " + error);
                reject(0);
            }
        });
        return promise;
    }




    /**
     * Processes the unsynced deleted password. Decrypts and loads into the in mem db.
     * @param db 
     * @param record 
     * @param decryptedPrivateKey 
     */
    processUnsyncedDeletedPassword(db: AppDBService, records: DeletedPassword[]) {
        if (this.consoleLog) console.log("processing unsynced deleted passwords ", records.length);
        let vaultMap = this.passwordVaultService.generateIdToVaultsWithEncryptionKeysForCurrentVaults();

        records.forEach((record) => {
            let teamMetaKeys = this.teamKeyService.getTeamMetaKeys(record.gid);
            let hasVaultKey = record.vault && record.vault.length > 0 && vaultMap.has(record.vault);

            if (hasVaultKey || (teamMetaKeys && teamMetaKeys.groupKey && teamMetaKeys.groupKey.length > 0)) {
                this.passwordDecryption.decryptDeletedPassword(record, teamMetaKeys, vaultMap).then((password) => {
                    try {
                        // console.log("Password:--------------> " + JSON.stringify(password));

                        // Create the sql statement
                        const sql = `INSERT OR REPLACE INTO deleted_passwords (id, passwordId,recoveryId, deletedTimestamp, deletedTimestampMs, initiatorId, initiatorUSername, ip, sessionId, name,key,metadataKey,url,username, geo, version, vault)\
                            VALUES (:id, :passwordId, :recoveryId, :deletedTimestamp, :deletedTimestampMs, :initiatorId, :initiatorUsername, :ip, :sessionId, :name, :key, :metadataKey, :url, :username, :geo, :version, :vault)`;

                        const stmt = this.storageService.inMemorySQLService?.getDatabase().prepare(sql);
                        // console.log("Password:--------------> " + JSON.stringify(password));
                        stmt?.run({
                            ':id': record.id, ':passwordId': record.passwordId, ':recoveryId': record.recoveryId, ':deletedTimestamp': record.deletedTimestamp,
                            ':deletedTimestampMs': record.deletedTimestampMs, ':initiatorId': record.initiatorId, ':initiatorUsername': record.initiatorUsername, ':ip': record.ip,
                            ':sessionId': record.sessionId, ':name': this.checkUndefined(password.name), ':key': this.checkUndefined(password.key),
                            ':metadataKey': this.checkUndefined(password.metadataKey), ':url': this.checkUndefined(password.url), ':username': this.checkUndefined(password.username),
                            ':geo': this.displayService.geoToDescription(record.geo), ':version': record.version, ':vault': record.vault
                        });
                        stmt?.free();

                        // update the indexdb to indicate it is now loaded
                        db.transaction('rw', db.passwordDeletes, async () => {
                            db.passwordDeletes.update(record.id, { syncedInMemory: SyncStatus.SYNC_STATUS_SYNCED_IN_MEM }).then(() => {
                                // console.log("updated");
                            }).catch((error) => { console.log(error); });
                        });


                    } catch (error) {
                        console.log(error);
                    }
                }).catch((error) => {
                    console.log(error);
                });

            } else {
                if (this.consoleLog) console.log("No team meta key");
            }
        });

        // Tell the store we have new data
        this.store.dispatch(StorageActions.updateLastDeletedPasswordChangeTimestamp());


    }



    /**
     * Checks the input for undefined and finds the appropriate default value. SQL cannot handle undefined.
     * @param input 
     * @returns 
     */
    checkUndefined(input: any): string | number {
        if (undefined == input) {
            if (typeof input === 'string') {
                return "UNDEFINED";
            } else return -999999990;
        } else {
            return input;
        }
    }

}

