import { Injectable } from "@angular/core";
import { base64_to_bytes, bytes_to_base64 } from "asmcrypto.js";
import Blowfish from "blowfish-node";

@Injectable({
    providedIn: 'root'
})
export class BlowfishService {
    constructor() { }




    /**
     * 
     * Make the blowfish object and initialize with the key
     * @param key 
     * @returns 
     */
    private makeBlowfish(key: string): Blowfish {
        let blowfish = new Blowfish(key);
        return blowfish;
    }

    encrypt(input: string, key: string): string {
        let blowfish = this.makeBlowfish(key);
        let encrypted = blowfish.encode(input);
        return bytes_to_base64(encrypted);
    }

    /**
     * Decrypt the input with the key
     * @param input 
     * @param key 
     * @returns 
     */
    decrypt(input: string, key: string, uid: string): string {
        let rv = "";
        let hasError: boolean = false;
        try {
            let blowfish = this.makeBlowfish(key);
            let unpackedInput = base64_to_bytes(input);
            //console.log("unpackedInput: " + unpackedInput);
            let decrypted = blowfish.decode(unpackedInput);
            rv = this.trimZeros(decrypted.toString());
            // if (this.containsNonLatinCodepoints(rv)) {
            //     console.log("Giving up on " + uid + " because it contains non-latin codepoints: " + rv);
            //     rv = "redacted-non-latin-" + uid;
            //     hasError = true;
            // }
            //console.log("decrypting: " + input + " with unpacked " + unpackedInput + " with key: " + key + " produces: >" + rv + "<");
        } catch (e) {
            console.error("error decrypting: " + uid + ", error: " + e);
            rv = "redacted-non-latin-" + uid;
        }
        if (hasError) {
            console.error("decrypting: " + uid + " with key: " + key + " produced: >" + rv + "<");
        }
        return rv;
    }

    containsNonLatinCodepoints(input: string): boolean {
        return /[^\u0000-\u00ff]/.test(input);
    }


    /**
     * removes trailing zeroes/nulls
     * @param input 
     * @returns 
     */
    private trimZeros(input: string): string {
        return input.replace(/\0+$/g, "");
    };
}