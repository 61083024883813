
import { GetOrganizationLicensesResponse } from "@app/model/api/organization/GetOrganizationLicensesResponse";
import { GetOrganizationListResponse } from "@app/model/api/organization/GetOrganizationListResponse";
import { GetOrganizationSettingsResponse } from "@app/model/api/organization/GetOrganizationSettingsResponse";
import { OrganizationDataEntry } from "@app/model/api/organization/OrganizationDataEntry";

export class OrganizationState {
    // API Responses
    organizationListResponse: GetOrganizationListResponse | undefined;
    organizationLicenseListResponse: GetOrganizationLicensesResponse | undefined;
    organizationSettingsResponse: GetOrganizationSettingsResponse | undefined;


    // App models
    activeOrganization: OrganizationDataEntry | undefined;


}