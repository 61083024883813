import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, of } from 'rxjs';



@Injectable({
  providedIn: 'root',
})

export class DeleteService {
  constructor(private http: HttpClient) { }


  public delete(url: string): Observable<any> {

    const options = {
      withCredentials: true,
      headers: new HttpHeaders({
        'Accept': 'application/json, text/javascript, */*;',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      })
    };
    return this.http.delete(
      url,
      options
    ).pipe(catchError(this.handleError<any>('DELETE')));
  }

  /**
    * Handle Http operation that failed.
    * Let the app continue.
    *
    * @param operation - name of the operation that failed
    * @param result - optional value to return as the observable result
    */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(`${operation} failed: ${error.message} ${error.status}`); // log to console instead

      // If 403 then send to login
      if (error.status === 403) {
        window.location.href = '/login';
      }

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    }
  };

}
