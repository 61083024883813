import { AccountUserInformationResponse } from "@app/model/api/account/AccountUserInformationResponse";
import { AccountKeyDataResponse } from "../../api/account/AccountKeyDataResponse";
import { AccountKeys } from "./AccountKeys";
import { OOBPreferenceResponse } from "@app/model/api/account/token/OOBPreferenceResponse";
import { AccountLicenseSummaryResponse } from "@app/model/api/account/AccountLicenseSummaryResponse";
import { GetSettingsDataResponse } from "@app/model/api/account/GetSettingsDataResponse";
import { SubscriptionSummaryResponse } from "@app/model/api/account/billing/SubscriptionSummaryResponse";

export class AccountState {
    // API Responses
    packingKeyData: AccountKeyDataResponse | undefined;
    accountUserInformation: AccountUserInformationResponse | undefined;
    oobPreference: OOBPreferenceResponse | undefined;
    accountLicenseSummaryResponse: AccountLicenseSummaryResponse | undefined;
    getSettingsDataResponse: GetSettingsDataResponse | undefined;
    subscriptionSummaryResponse: SubscriptionSummaryResponse | undefined;

    // App models
    accountKeys: AccountKeys = new AccountKeys();

    // Flags
    validSubscription: boolean = false;

}