import { ActionReducer, createReducer, on } from '@ngrx/store';
import { AccountActions } from '../actions/AccountActions';
import { AccountState } from '@app/model/app/account/AccountState';
import { LoginActions } from '../actions/LoginActions';
import { AccountKeys } from '@app/model/app/account/AccountKeys';

export const initialState: AccountState = new AccountState();

export const accountReducer: ActionReducer<AccountState> = createReducer(
    initialState,
    on(LoginActions.logoutComplete, (state, action) => {
        return {
            ...initialState
        };
    }),
    on(AccountActions.accountNoOp, (state) => {
        return { ...state, initialized2: true };
    }),
    on(AccountActions.packingKeyRetrieved, (state, action) => {
        return {
            ...state,
            packingKeyData: action.response
        };
    }),
    // Do not remove keys in idle timeout.  We only lock the screen now.
    // on(AccountActions.idleTimeout, (state, action) => {
    //     return {
    //         ...state,
    //         accountKeys: new AccountKeys()
    //     };
    // }),
    on(AccountActions.unlockPackingKeySuccess, (state, action) => {
        return {
            ...state,
            accountKeys: action.data
        };
    }),
    on(AccountActions.accountDetailsLoaded, (state, action) => {
        return {
            ...state,
            accountUserInformation: action.accountDetails
        };
    }),
    on(AccountActions.accountDetailsLoadError, (state, action) => {
        return {
            ...state,
            accountUserInformation: undefined
        };
    }),
    on(AccountActions.oOBPreferenceRetrieved, (state, action) => {
        return {
            ...state,
            oobPreference: action.response
        };
    }),
    on(AccountActions.licenseSummaryRetrieved, (state, action) => {
        return {
            ...state,
            accountLicenseSummaryResponse: action.response
        };
    }),
    on(AccountActions.settingsDataRetrieved, (state, action) => {
        return {
            ...state,
            getSettingsDataResponse: action.response
        };
    }),
    on(AccountActions.subscriptionSummaryDataRetrieved, (state, action) => {
        return {
            ...state,
            subscriptionSummaryResponse: action.response
        };
    }),
    on(AccountActions.updateValidSubscription, (state, action) => {
        return {
            ...state,
            validSubscription: action.validSubscription
        };
    }),


);