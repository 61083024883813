import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PasswordsService } from './PasswordsService';
import { MultiDeletePasswordEntry } from '@app/model/api/passwords/MultiDeletePasswordEntry';
import { PasswordActions } from '@app/store/actions/PasswordActions';


@Injectable({
    providedIn: 'root',
})

export class PasswordDeleteService {
    constructor(
        private passwordsService: PasswordsService,
        private store: Store,
    ) {
    }

    bulkDeletePasswords(passwordIds: number[]): void {
        let entries: MultiDeletePasswordEntry[] = [];
        // build up the list of password entries to delete
        for (let pid of passwordIds) {
            let entry = new MultiDeletePasswordEntry();
            entry.id = pid;
            entries.push(entry);
        }

        this.passwordsService.deletePasswords(entries).subscribe((response) => {
            if (response) {
                this.store.dispatch(PasswordActions.deletePasswordsSuccess({ passwordIds }));
            }
        });

    }

}