import { Injectable } from '@angular/core';
import { BaseAPIService } from '../BaseAPIService';
import { DisplayedDeletedPasswordListItem } from '@app/model/app/password/DisplayedDeletedPasswordListItem';
import { environment } from '@environments/environment';
import { OrgAdminRestorePasswordResponse } from '@app/model/api/organization/OrgAdminRestorePasswordResponse';
import { HttpParams } from '@angular/common/http';
import { firstValueFrom, Observable, of } from 'rxjs';
import { MultiAddPasswordLabelResponse } from '@app/model/api/passwords/MultiAddPasswordLabelResponse';
import { MultiAddPasswordLabelRequest } from '@app/model/api/passwords/MultiAddPasswordLabelRequest';
import { MultiAddPasswordLabelEntry } from '@app/model/api/passwords/MultiAddPasswordLabelEntry';
import { MultiDeletePasswordLabelEntry } from '@app/model/api/passwords/MultiDeletePasswordLabelEntry';
import { MultiDeletePasswordLabelRequest } from '@app/model/api/passwords/MultiDeletePasswordLabelRequest';
import { MultiDeletePasswordLabelResponse } from '@app/model/api/passwords/MultiDeletePasswordLabelResponse';
import { UpdatePasswordNotesRequest } from '@app/model/api/passwords/UpdatePasswordNotesRequest';
import { UpdatePasswordNotesResponse } from '@app/model/api/passwords/UpdatePasswordNotesResponse';
import { TogglePasswordFavoriteFlagResponse } from '@app/model/api/passwords/TogglePasswordFavoriteFlagResponse';
import { UpdatePasswordRequest } from '@app/model/api/passwords/UpdatePasswordRequest';
import { UpdatePasswordResponse } from '@app/model/api/passwords/UpdatePasswordResponse';
import { MultiAddPasswordEntry } from '@app/model/api/passwords/MultiAddPasswordEntry';
import { MultiAddPasswordResponse } from '@app/model/api/passwords/MultiAddPasswordResponse';
import { MultiAddPasswordRequest } from '@app/model/api/passwords/MultiAddPasswordRequest';
import { MultiDeletePasswordEntry } from '@app/model/api/passwords/MultiDeletePasswordEntry';
import { MultiDeletePasswordRequest } from '@app/model/api/passwords/MultiDeletePasswordRequest';
import { MultiDeletePasswordResponse } from '@app/model/api/passwords/MultiDeletePasswordResponse';
import { TransferPasswordsToGroupRequest } from '@app/model/api/passwords/TransferPasswordsToGroupRequest';
import { BaseResponse } from '@app/model/api/BaseResponse';

import { MultiManagePasswordGroupMembershipResponse } from '@app/model/api/passwords/MultiManagePasswordGroupMembershipResponse';
import { MultiManagePasswordGroupMembershipRequest } from '@app/model/api/passwords/MultiManagePasswordGroupMembershipRequest';
import { MultiManageTeamMembershipRequest } from '@app/model/api/connection/BulkUserPublicKeyRequest';
import { MultiManageTeamMembershipResponse } from '@app/model/api/teams/MultiManageTeamMembershipResponse';
import { RetrievePasswordHistoryResponse } from '@app/model/api/passwords/RetrievePasswordHistoryResponse';
import { RetrievePasswordHistoryRequest } from '@app/model/api/passwords/RetrievePasswordHistoryRequest';
import { BulkAttachPasswordHistoryEntry } from '@app/model/api/passwords/BulkAttachPasswordHistoryEntry';
import { BulkAttachPasswordHistoryResponse } from '@app/model/api/passwords/BulkAttachPasswordHistoryResponse';
import { BulkAttachPasswordHistoryRequest } from '@app/model/api/passwords/BulkAttachPasswordHistoryRequest';
import { MakePasswordPrivateRequest } from '@app/model/api/passwords/MakePasswordPrivateRequest';
import { MakePasswordPrivateResponse } from '@app/model/api/passwords/MakePasswordPrivateResponse';
import { MultiRemovePasswordFromGroupEntry } from '@app/model/api/passwords/MultiRemovePasswordFromGroupEntry';
import { MultiRemovePasswordFromGroupRequest } from '@app/model/api/passwords/MultiRemovePasswordFromGroupRequest';
import { MultiRemovePasswordFromGroupResponse } from '@app/model/api/passwords/MultiRemovePasswordFromGroupResponse';
import { TeamKeyService } from '../cache/team-key-service.service';
import { AesEncrypt } from '../encryption/AesEncrypt';


@Injectable({
    providedIn: 'root',
})

export class PasswordsService {
    constructor(
        private baseAPIService: BaseAPIService,
        private teamKeyService: TeamKeyService,
        private aesEncrypt: AesEncrypt
    ) {
    }

    /**
     * Initiate the password restore process
     * @param data 
     * @returns 
     */
    restorePassword(data: DisplayedDeletedPasswordListItem): void {
        let url = environment.API_BASE_URL + "v1/secure/orgAdmin/password/restore/" + data.passwordId + "/" + data.recoveryId;
        var params = new HttpParams();
        if (data.vault) {
            params = params.set("vault", data.vault.id);
        }
        this.baseAPIService.getRequest<OrgAdminRestorePasswordResponse>(params, url).subscribe(response => {
            if (response) {

            }
        });
    }


    /**
     * Adds the labels to a single password
     * @param passwordId 
     * 
     * @param labelIds 
     * @returns 
     */
    addLabelsToSinglePassword(passwordId: number, labelIds: number[]): Observable<MultiAddPasswordLabelResponse> {
        // console.log("deleting label " + labelId);
        let url = environment.API_BASE_URL + "v1/secure/password/bulkTagCreate";
        let request = new MultiAddPasswordLabelRequest();
        let entry = new MultiAddPasswordLabelEntry();
        entry.id = passwordId;
        entry.tags = labelIds;
        request.entries.push(entry);
        let body = JSON.stringify(request);

        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<MultiAddPasswordLabelResponse>(body, url);
    }

    addSameLabelsToAllPasswords(passwordIds: number[], labelIds: number[]): Observable<MultiAddPasswordLabelResponse> {
        // console.log("deleting label " + labelId);
        let url = environment.API_BASE_URL + "v1/secure/password/bulkTagCreate";
        let request = new MultiAddPasswordLabelRequest();
        passwordIds.forEach((passwordId) => {
            let entry = new MultiAddPasswordLabelEntry();
            entry.id = passwordId;
            entry.tags = labelIds;
            request.entries.push(entry);
        });

        let body = JSON.stringify(request);

        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<MultiAddPasswordLabelResponse>(body, url);
    }

    async makePasswordPrivate(passwordId: number, passwordKey: string): Promise<MakePasswordPrivateResponse> {
        let request = new MakePasswordPrivateRequest();

        var promise = new Promise<MakePasswordPrivateResponse>((resolve, reject) => {

            // We need to encrypt the password key to the default team key to reassociate it with the owner's default team.
            let myDefaultTeamMetaKeys = this.teamKeyService.getMyDefaultTeamMetaKeys();
            if (myDefaultTeamMetaKeys) {
                this.aesEncrypt.aesEncrypt(passwordKey, myDefaultTeamMetaKeys.groupKey).then((encryptedPasswordKey) => {
                    request.encryptedPasswordKey = encryptedPasswordKey;
                    request.gid = myDefaultTeamMetaKeys ? myDefaultTeamMetaKeys.teamId : 0;
                    let url = environment.API_BASE_URL + "v1/secure/password/" + passwordId + "/makePrivate";
                    let body = JSON.stringify(request);
                    resolve(firstValueFrom(this.baseAPIService.postRequestNoErrorHandlingApplicationJson<MakePasswordPrivateResponse>(body, url)));
                });

            } else {
                console.error("Could not get my default team key");
                let response = new MakePasswordPrivateResponse();
                response.status.code = 100;
                reject(response);
            }
        });
        return promise;
    }




    /**
     * removes the labels from a list of passwords
     * @param passwordId 
     * 
     * @param labelIds 
     * @returns 
     */
    removeLabelsFromPasswords(passwordIds: number[], labelIds: number[]): Observable<MultiDeletePasswordLabelResponse> {
        let url = environment.API_BASE_URL + "v1/secure/password/bulkTagRemove";
        let request = new MultiDeletePasswordLabelRequest();
        passwordIds.forEach((passwordId) => {
            let entry = new MultiDeletePasswordLabelEntry();
            entry.id = passwordId;
            entry.tags = labelIds;
            request.entries.push(entry);
        });

        let body = JSON.stringify(request);

        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<MultiDeletePasswordLabelResponse>(body, url);
    }


    prepareToShowPassword(id: number): Observable<boolean> {
        console.log("Preparing to show password: ", id);
        // this.store.dispatch(NotificationsActions.readyShowNotifications());
        return of(true);
    }

    updateMyNotes(passwordId: number, encryptedNotes: string): Observable<UpdatePasswordNotesResponse> {
        console.log("Updating notes for password: ", passwordId);
        let url = environment.API_BASE_URL + "v3/secure/password/update/notes";
        let request = new UpdatePasswordNotesRequest();
        request.passwordId = passwordId;
        request.notes = encryptedNotes;
        let body = JSON.stringify(request);

        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<UpdatePasswordNotesResponse>(body, url);
    }

    togglePasswordFavoriteFlag(passwordId: number): Observable<TogglePasswordFavoriteFlagResponse> {
        let url = environment.API_BASE_URL + "v3/secure/password/" + passwordId + "/update/favorite/toggle";
        var params = new Map<string, any>();
        return this.baseAPIService.putRequestNoErrorHandling<TogglePasswordFavoriteFlagResponse>(params, url);
    }

    createPasswords(entries: MultiAddPasswordEntry[]): Observable<MultiAddPasswordResponse> {
        let url = environment.API_BASE_URL + "v1/secure/password/bulkCreate";
        let request = new MultiAddPasswordRequest();
        // add all entries to the request entries
        request.entries = entries;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<MultiAddPasswordResponse>(body, url);
    }


    updatePassword(request: UpdatePasswordRequest): Observable<UpdatePasswordResponse> {
        let passwordId = request.id;
        let url = environment.API_BASE_URL + "v3/secure/password/" + passwordId + "/update";
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<UpdatePasswordResponse>(body, url);
    }

    transferPasswords(request: TransferPasswordsToGroupRequest): Observable<BaseResponse> {
        let url = environment.API_BASE_URL + "v1/secure/password/group/transferPassword";
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<BaseResponse>(body, url);
    }

    managePasswordGroupMembership(request: MultiManagePasswordGroupMembershipRequest): Observable<MultiManagePasswordGroupMembershipResponse> {
        let url = environment.API_BASE_URL + "v1/secure/team/managePasswordMembership";
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<MultiManagePasswordGroupMembershipResponse>(body, url);
    }

    manageTeamMembership(request: MultiManageTeamMembershipRequest): Observable<MultiManageTeamMembershipResponse> {
        let url = environment.API_BASE_URL + "v1/secure/team/manageMembership";
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<MultiManageTeamMembershipResponse>(body, url);
    }

    deletePasswords(entries: MultiDeletePasswordEntry[]): Observable<MultiDeletePasswordResponse> {
        let url = environment.API_BASE_URL + "v1/secure/password/bulkDelete";
        let request = new MultiDeletePasswordRequest();
        // add all entries to the request entries
        request.entries = entries;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<MultiDeletePasswordResponse>(body, url);
    }

    removePasswordsFromTeam(entries: MultiRemovePasswordFromGroupEntry[]): Observable<MultiRemovePasswordFromGroupResponse> {
        let url = environment.API_BASE_URL + "v1/secure/password/removePasswordFromTeam";
        let request = new MultiRemovePasswordFromGroupRequest();
        // add all entries to the request entries
        request.entries = entries;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<MultiRemovePasswordFromGroupResponse>(body, url);
    }

    getPasswordHistory(passwordId: number, fullHistory: boolean): Observable<RetrievePasswordHistoryResponse> {
        let url = environment.API_BASE_URL + "v1/secure/password/history/" + passwordId;
        let request = new RetrievePasswordHistoryRequest();
        request.fullHistory = fullHistory;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<RetrievePasswordHistoryResponse>(body, url);
    }

    bulkAttachPasswordHistory(passwordId: number, history: BulkAttachPasswordHistoryEntry[]): Observable<BulkAttachPasswordHistoryResponse> {
        let url = environment.API_BASE_URL + "v1/secure/password/history/" + passwordId + "/bulkAttach";
        let request = new BulkAttachPasswordHistoryRequest();
        request.history = history;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<BulkAttachPasswordHistoryResponse>(body, url);
    }



}