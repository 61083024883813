import { ActionReducer, createReducer, on } from '@ngrx/store';
import { LoginActions } from '../actions/LoginActions';
import { StorageState } from '@app/model/app/storage/StorageState';
import { StorageActions } from '../actions/StorageActions';

export const initialState: StorageState = new StorageState();

export const storageReducer: ActionReducer<StorageState> = createReducer(
    initialState,
    on(LoginActions.logoutComplete, (state, action) => {
        return {
            ...initialState
        };
    }),
    on(LoginActions.logoutInitiate, (state, action) => {
        return {
            ...initialState
        };
    }),
    on(LoginActions.lightweightLogout, (state, action) => {
        return {
            ...initialState
        };
    }),

    on(StorageActions.updateLastPasswordChangeTimestamp, (state, action) => {
        return {
            ...state,
            lastPasswordChangeTimestamp: Date.now()
        };
    }),
    on(StorageActions.updateLastDeletedPasswordChangeTimestamp, (state, action) => {
        return {
            ...state,
            lastDeletedPasswordChangeTimestamp: Date.now()
        };
    }),

    on(StorageActions.updateLastPasswordGroupChangeTimestamp, (state, action) => {
        return {
            ...state,
            lastPasswordGroupChangeTimestamp: Date.now()
        };
    }),

    on(StorageActions.updateLastLabelChangeTimestamp, (state, action) => {
        return {
            ...state,
            lastLabelChangeTimestamp: Date.now()
        };
    }),
    on(StorageActions.updateLastVaultChangeTimestamp, (state, action) => {
        return {
            ...state,
            lastVaultChangeTimestamp: Date.now()
        };
    }),

    on(StorageActions.updateLoadedPasswordCount, (state, action) => {
        return {
            ...state,
            loadedPasswordCount: action.data
        };
    }),
    on(StorageActions.updateLoadedDeletedPasswordCount, (state, action) => {
        return {
            ...state,
            loadedDeletedPasswordCount: action.data
        };
    }),

    on(StorageActions.updateLoadedPasswordGroupCount, (state, action) => {
        return {
            ...state,
            loadedPasswordGroupCount: action.data
        };
    }),
    on(StorageActions.updateLoadedVaultCount, (state, action) => {
        return {
            ...state,
            loadedVaultCount: action.data
        };
    }),
    on(StorageActions.updatePreloadVaultCount, (state, action) => {
        return {
            ...state,
            preloadedVaultCount: action.data
        };
    }),
    on(StorageActions.updatePreloadPasswordCount, (state, action) => {
        return {
            ...state,
            preloadedPasswordCount: action.data
        };
    }),
    on(StorageActions.updatePreloadDeletedPasswordCount, (state, action) => {
        return {
            ...state,
            preloadedDeletedPasswordCount: action.data
        };
    }),

    on(StorageActions.updatePreloadPasswordGroupCount, (state, action) => {
        return {
            ...state,
            preloadedPasswordGroupCount: action.data
        };
    }),

    on(StorageActions.updatePasswordLoadState, (state, action) => {
        return {
            ...state,
            passwordLoadState: action.data
        };
    }),

    // on(StorageActions.updateDataFeedGeneratedDataLastUpdate, (state, action) => {
    //     return {
    //         ...state,
    //         lastUpdateGeneratedDataFeed: action.data
    //     };
    // }),
    // on(StorageActions.updateDataFeedPasswordLastUpdate, (state, action) => {
    //     return {
    //         ...state,
    //         lastUpdatePasswordFeed: action.data
    //     };
    // }),
    // on(StorageActions.updateDataFeedPasswordGroupLastUpdate, (state, action) => {
    //     return {
    //         ...state,
    //         lastUpdatePasswordGroupFeed: action.data
    //     };
    // }),
    // on(StorageActions.updateDataFeedLabelLastUpdate, (state, action) => {
    //     return {
    //         ...state,
    //         lastUpdateLabelFeed: action.data
    //     };
    // }),

    // on(StorageActions.updateDataFeedActionLastUpdate, (state, action) => {
    //     return {
    //         ...state,
    //         lastUpdateActionFeed: action.data
    //     };
    // }),



);