// /// <reference types="@angular/localize" />

// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AppModule } from './app/app.module';


// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));


import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { importProvidersFrom, isDevMode } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './app/routes';
import { ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { environment } from './environments/environment';
import { AppState } from '@app/model/app/AppState';
import { loginReducer } from '@app/store/reducers/LoginReducer';
import { accountReducer } from '@app/store/reducers/AccountReducer';
import { organizationReducer } from '@app/store/reducers/OrganizationReducer';

import { AccountEffects } from '@app/store/effects/AccountEffects';
import { LimitsEffects } from '@app/store/effects/LimitsEffects';
import { LoginEffects } from '@app/store/effects/LoginEffects';
import { OrganizationEffects } from '@app/store/effects/OrganizationEffects';
import { PreloadAllModules } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { notificationsReducer } from '@app/store/reducers/NotificationsReducer';
import { NotificationsEffects } from '@app/store/effects/NotificationsEffects';
import { DrawerEffects } from '@app/store/effects/DrawerEffects';
import { drawerReducer } from '@app/store/reducers/DrawerReducer';
import { navigationReducer } from '@app/store/reducers/NavigationReducer';
import { NavigationEffects } from '@app/store/effects/NavigationEffects';
import { CustomPreloadingStrategyService } from '@app/services/CustomPreloadingStrategyService';
import { StorageEffects } from '@app/store/effects/StorageEffects';
import { storageReducer } from '@app/store/reducers/StorageReducer';
import { SystemEffects } from '@app/store/effects/SystemEffects';
import { PasswordEffects } from '@app/store/effects/PasswordEffects';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { passwordReducer } from '@app/store/reducers/PasswordReducer';
import { ActionEffects } from '@app/store/effects/ActionEffects';
import { teamReducer } from '@app/store/reducers/TeamReducer';
import { TeamEffects } from '@app/store/effects/TeamEffects';
import { labelReducer } from '@app/store/reducers/LabelReducer';
import { LabelEffects } from '@app/store/effects/LabelEffects';
import { CsrfInterceptorInterceptor } from '@app/interceptors/csrf-interceptor.interceptor';
import { CsrfEffects } from '@app/store/effects/CsrfEffects';
import { siteReducer } from '@app/store/reducers/SiteReducer';
import { passwordVaultReducer } from '@app/store/reducers/PasswordVaultReducer';
import { PasswordVaultEffects } from '@app/store/effects/PasswordVaultEffects';


/*************************** NGRX STORE ************************** */
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (false) console.log('state->', state, ' action->  ', action);
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = !environment.production ? [debug] : [];

export const reducers: ActionReducerMap<AppState> = {
  loginFunnelState: loginReducer
  , accountState: accountReducer
  , drawerState: drawerReducer
  , labelState: labelReducer
  , notificationsState: notificationsReducer
  , navigationState: navigationReducer
  , organizationState: organizationReducer
  , passwordState: passwordReducer
  , passwordVaultState: passwordVaultReducer
  , siteState: siteReducer
  , storageState: storageReducer
  , teamState: teamReducer
};
export const effects: any[] = [
  AccountEffects,
  ActionEffects,
  CsrfEffects,
  DrawerEffects,
  LabelEffects,
  LimitsEffects,
  LoginEffects,
  NavigationEffects,
  NotificationsEffects,
  OrganizationEffects,
  PasswordEffects,
  PasswordVaultEffects,
  StorageEffects,
  SystemEffects,
  TeamEffects
];
/*************************** END NGRX STORE ************************** */




bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptors([CsrfInterceptorInterceptor])),
    // add the interceptors to the providers
    // { provide: HTTP_INTERCEPTORS, useClass: CsrfInterceptorInterceptor, multi: true },


    importProvidersFrom(

      RouterModule.forRoot(routes,
        {
          // preloadingStrategy: CustomPreloadingStrategyService
          preloadingStrategy: PreloadAllModules
        }),
      BrowserAnimationsModule,
      NgIdleKeepaliveModule.forRoot(),
      // BrowserModule,
      // FormsModule,
      // ReactiveFormsModule,
      // AppRoutingModule,
      // HttpClientModule,
      // MaterialModule,
      NgbModule,
      StoreModule.forRoot(
        reducers,
        { metaReducers },
      ),
      EffectsModule.forRoot(effects),
      !environment.production ? StoreDevtoolsModule.instrument({
        maxAge: 25, // Retains last 25 states
        logOnly: !isDevMode(), // Restrict extension to log-only mode
        autoPause: true, // Pauses recording actions and state changes when the extension window is not open
        trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
        traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
        connectInZone: true // If set to true, the connection is established within the Angular zone
      }) : [],
    ),],
}).catch((err) => console.error(err));