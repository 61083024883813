import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { LoginActions } from "../actions/LoginActions";
import { Store } from "@ngrx/store";
import { tap } from "rxjs";
import { CsrfService } from "@app/services/CsrfService.service";
import { RegistrationActions } from "../actions/RegistrationActions";

@Injectable()
export class CsrfEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private csrfService: CsrfService
    ) { }


    /**
     * Retrieves Csrf Token from the server
     */
    readonly retrieveCsrfToken$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                LoginActions.loginPageOpened,
                RegistrationActions.registrationPageOpened,
                LoginActions.mFAPageOpened,
                LoginActions.reloadCSRFToken
            ),
            tap(() => this.csrfService.retrieveCsrfToken()),
        ), { dispatch: false }
    );

}
