import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { StorageService } from './storage.service';
import { StorageActions } from '@app/store/actions/StorageActions';
import { Database } from 'sql.js';
import { selectAccountUserInformationDetails } from '@app/store/selectors/AccountSelectors';
import { ConnectionFeedResponse } from '@app/model/api/dataFeed/ConnectionFeedResponse';
import { ConnectionFeedEntry } from '@app/model/api/dataFeed/ConnectionFeedEntry';
import { AccountUserInformationResponse } from '@app/model/api/account/AccountUserInformationResponse';
import { AccountKeysService } from '../account/AccountKeysService';
import { AccountService } from '../account/account.service';
@Injectable({
    providedIn: 'root'
})
export class ConnectionFeedStorageService implements OnInit, OnDestroy {

    // readonly selectAccountUserInformationDetails$ = this.store.select(selectAccountUserInformationDetails);
    private addedSelf: boolean = false;
    private consoleLog: boolean = false;

    constructor(
        private store: Store,
        private storageService: StorageService,
        private accountKeysService: AccountKeysService,
        private accountService: AccountService
    ) {

    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }


    /**
   * Process the connection feed and store the lastupdate
   * @param data 
   */
    processConnectionFeed(data: ConnectionFeedResponse) {
        let database = this.storageService.inMemorySQLService?.getDatabase();
        try {
            if (!this.addedSelf) {
                if (database) {
                    // This is a hack to ensure the logged in userId is in the connection table so that lookups against the user id return something
                    this.insertSelfToConnections(database);
                }
            }

            if (data && data.connections && data.connections.length > 0) {

                let toAdd: ConnectionFeedEntry[] = [];
                let toDelete: number[] = [];
                let inMemToDelete: number[] = [];
                let start = new Date().getTime();
                data.connections.forEach(connection => {
                    switch (connection.action) {
                        case 1:
                        // add
                        case 2:
                            // update
                            // add or update should upsert
                            toAdd.push(connection);
                            // All adds first delete in mem
                            inMemToDelete.push(connection.id);
                            break;
                        case 3:
                            // delete
                            toDelete.push(connection.id);
                            break;
                    }
                });

                ////////////////////////////////////////////
                // Send to the indexeddb
                ////////////////////////////////////////////
                // Adds or updates
                this.storageService.appDbService?.connections.bulkPut(toAdd).then() // handle success
                    .catch(function (error) { console.log(error); }); // handle failure

                // Deletes
                this.storageService.appDbService?.connections.bulkDelete(toDelete).then() // handle success
                    .catch(function (error) { console.log(error); }); // handle failure

                ////////////////////////////////////////////
                // Send to in memory store
                ////////////////////////////////////////////
                if (database) {

                    // Deletes go first and also delete the adds so that any reductions are made
                    // Deletes
                    this.processInMemorySQLDeletes(database, toDelete);
                    this.processInMemorySQLDeletes(database, inMemToDelete);

                    // Adds
                    this.processInMemorySQLAdds(database, toAdd);


                    // Dump
                    //this.dumpTables(database);
                    this.dumpCounts(database);
                }

                let end = new Date().getTime();
                if (this.consoleLog) console.log("Time to process connection feed: " + (end - start) + " ms");
                ////////////////////////////////////////////
                // Notify the store that we are done loading
                // Fire an event here to indicate that the data has changed
                if (this.consoleLog) console.log("cfs triggering local data changed");
                this.store.dispatch(StorageActions.localDataChanged());
            }
        } catch (error) {
            console.log(error);
        }
    }


    private dumpCounts2() {
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) this.dumpCounts(database);
    }


    /**
     * Helper method to dump the counts of rows
     * @param database 
     */
    private dumpCounts(database: Database) {
        let self = this;


        let db = this.storageService.appDbService;
        if (db) {
            let results = db.connections.count();
            results.then((count) => {
                if (self.consoleLog) console.log("connections rows-> " + count);
                // self.store.dispatch(StorageActions.updatePreloadPasswordCount({ data: Number(count?.toString()) }));
            });
        }
    }

    /**
     * Add the logged in user to the connections table
     * @param memDatabase 
     */
    private insertSelfToConnections(memDatabase: Database,): void {
        try {
            const connectionsSql = `INSERT OR REPLACE INTO connections (id, nickname ) 
                        VALUES (:id, :nickname)`;

            // Create the statements
            const stmtConnections = memDatabase.prepare(connectionsSql);


            // Insert the user data
            stmtConnections.run({
                ':id': this.accountService.accountUserInformation.userId,
                ':nickname': this.accountService.accountUserInformation.username
            });

            // Free the statement
            stmtConnections.free();

            // Mark as added
            this.addedSelf = true;
        } catch (error) {
            console.log(error);
        }
    }


    /**
     * Adds the entries to the in memory database.
     * @param memDatabase 
     * @param toAdd 
     */
    private processInMemorySQLAdds(memDatabase: Database, toAdd: ConnectionFeedEntry[]) {
        if (null != toAdd && toAdd.length > 0) {
            if (this.consoleLog) console.log("need to process " + toAdd.length + " connections");
            ////////////////////////////////////////////////
            // Handle the passwords table
            const connectionsSql = `INSERT OR REPLACE INTO connections (id, nickname, connectionDate, individualShare, userId, active,ownedTeams, memberOfTeams, individualTeamSharedToConnection, individualTeamSharedFromConnection ) 
                        VALUES (:id, :nickname, :connectionDate, :individualShare, :userId, :active, :ownedTeams, :memberOfTeams, :individualTeamSharedToConnection, :individualTeamSharedFromConnection)`;

            // Create the statements
            const stmtConnections = memDatabase.prepare(connectionsSql);

            // Add the data
            toAdd.forEach((connection) => {

                // TODO add the connection
                stmtConnections.run({
                    ':id': connection.id,
                    ':nickname': connection.nickname,
                    ':connectionDate': "",
                    ':individualShare': 0,
                    ':userId': connection.userId,
                    ':active': connection.active ? 1 : 0,
                    ':ownedTeams': "",
                    ':memberOfTeams': "",
                    ':individualTeamSharedToConnection': "",
                    ':individualTeamSharedFromConnection': ""
                });



            });

            stmtConnections.free();
        }
    }


    /**
     *  Removes the entries from the in memory database.
     * @param database 
     * @param toDelete 
     */
    private processInMemorySQLDeletes(database: Database, toDelete: number[]) {
        if (null != toDelete && toDelete.length > 0) {
            ////////////////////////////////////////////////
            const deleteConnectionsSQL = `DELETE FROM connections where id = :id`;

            const stmtConnections = database.prepare(deleteConnectionsSQL);

            toDelete.forEach((id) => {
                stmtConnections.run({ ':id': id });
            });

            stmtConnections.free();

            if (this.consoleLog) console.log("Remove search docs");
        }
    }


}