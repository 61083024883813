export class MultiAddPasswordEntry {
    url: string;
    name: string;
    // tags: number[] = [];
    notes: string;
    pkey: string;
    dgpkey: string;
    data: string;
    stats: string;
    group: number;
    version: number = 2;
    history: string;

}