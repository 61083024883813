import { Injectable } from '@angular/core';
import { BaseAPIService } from '../BaseAPIService';
import { environment } from '@environments/environment';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PasswordGroupInitializationResponse } from '@app/model/api/passwords/PasswordGroupInitializationResponse';
import { TeamsService } from '../teams/TeamsService';
import { MultiDeletePasswordEntry } from '@app/model/api/passwords/MultiDeletePasswordEntry';
import { PasswordsService } from './PasswordsService';
import { MultiDeletePasswordResponse } from '@app/model/api/passwords/MultiDeletePasswordResponse';
import { PasswordUpdateService } from './PasswordUpdateService';
import { MultiRemovePasswordFromGroupResponse } from '@app/model/api/passwords/MultiRemovePasswordFromGroupResponse';
import { MultiRemovePasswordFromGroupEntry } from '@app/model/api/passwords/MultiRemovePasswordFromGroupEntry';
import { TeamVersionsEnum } from '@app/model/app/passwordGroup/TeamVersionsEnum';

@Injectable({
    providedIn: 'root',
})

export class PasswordGroupService {
    private consoleLog: boolean = false;

    constructor(
        private baseAPIService: BaseAPIService,
        private teamsService: TeamsService,
        private passwordsService: PasswordsService,
        private passwordUpdateService: PasswordUpdateService,
    ) {
    }

    bulkRemovePasswordsFromTeam(passwordIds: number[], teamId: number): Observable<MultiRemovePasswordFromGroupResponse> {
        let entries: MultiRemovePasswordFromGroupEntry[] = [];

        // build up the list of password entries to delete
        for (let pid of passwordIds) {
            let entry = new MultiRemovePasswordFromGroupEntry();
            entry.id = pid;
            entry.gids.push(teamId);
            entries.push(entry);
        }

        return this.passwordsService.removePasswordsFromTeam(entries);
    }


    // Deprecated 10/16/24
    // /**
    //     * Retrieves the list of my teams of which I'm the owner. These are the ones where I can share out.
    //     * @returns 
    //     */
    // retrieveConnectionsForSharing(): TeamMemberDisplayInfo[] {
    //     let connectionList: TeamMemberDisplayInfo[] = [];
    //     let database = this.storageService.inMemorySQLService?.getDatabase();
    //     if (database) {
    //         let stmt = database.prepare("SELECT c.*,upper(c.nickname) as uname  FROM connections c  ORDER BY uname ASC");
    //         stmt.bind();
    //         while (stmt.step()) {
    //             let result = stmt.getAsObject();
    //             if (this.consoleLog) console.log(JSON.stringify(result));
    //             let entry = new TeamMemberDisplayInfo();
    //             entry.username = result.nickname as string;
    //             // entry.description = result.description as string;
    //             entry.id = result.id as number;
    //             // entry.color = result.color as string;
    //             connectionList.push(entry);
    //         }
    //         stmt.free();
    //     }

    //     return connectionList;
    // }


    /**
     * Calls the server to see which default groups need to be created.
     * @returns 
     */
    getPasswordGroupInitializationStatus(): Observable<PasswordGroupInitializationResponse> {
        let url = environment.API_BASE_URL + "v1/secure/password/group/status";
        return this.baseAPIService.getRequest<PasswordGroupInitializationResponse>(new HttpParams(), url);
    }

    checkPasswordGroupInitializationStatus(response: PasswordGroupInitializationResponse) {
        if (this.consoleLog) console.log("PasswordGroupService.checkPasswordGroupInitializationStatus: response=", response);
        if (response && response.status.code === 0) {

            if (response.needCreatePersonal) {
                this.teamsService.createNewTeam("Default", "Default Group", "green-500", 3, TeamVersionsEnum.V2).then((response) => {
                    if (this.consoleLog) console.log("created: response=", response);


                    // Create the first password
                    this.passwordUpdateService.initiateCreateSamplePasswordAfterFirstLogin();

                });

            }
        } else {
            console.error("Error processing response from server for group initialization.");
        }
    }

}