import { Injectable, OnInit } from '@angular/core';
import { interval, Subject, Observable } from 'rxjs';
import { switchMap, takeUntil, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectAccountUserInformationDetails } from '@app/store/selectors/AccountSelectors';
import { DataFeedService } from '../DataFeedService';
import { LoginActions } from '@app/store/actions/LoginActions';

@Injectable({
    providedIn: 'root',
})
export class StatusPollerService implements OnInit {
    private readonly updateCheckInterval = 15000; // 15 seconds
    private readonly deadmanCheckInterval = 30000;
    private lastUpdateCheckTime = 0;
    private shouldRun: boolean = false;
    private restartInProgress = false;
    private stopPolling$ = new Subject<void>();
    private errorSubject = new Subject<string>();
    private customerId: number;
    readonly vma$ = this.store.select(selectAccountUserInformationDetails);

    constructor(
        private dataFeedService: DataFeedService,
        private store: Store
    ) {

        // Start the deadman check
        this.startDeadmanCheck();
        this.vma$.subscribe(x => {
            if (x) {
                this.customerId = x.customerId;
            }
        });
    }

    ngOnInit(): void {
    }

    /**
     * Deadman is a check to see if the status poller is running. It runs every second and checks to see if the status poller should be running.
     */
    startDeadmanCheck(): void {
        interval(this.deadmanCheckInterval)
            .pipe(
                switchMap(() => this.restartPollerIfRequired()),
                catchError((error) => {
                    console.log("error during poll checker:" + error);
                    throw error;
                })
            )
            .subscribe(
                (response) => {
                    //console.log(response);
                }
            );
    }

    /**
    * Checks to see if the status should be chcked and restarts the poller if required.
    * @returns 
    */
    restartPollerIfRequired(): Observable<String> {
        // console.log("restartPollerIfRequired.  Should run: ", this.shouldRun);
        if (this.shouldRun) {
            // console.log("pollerShouldRun is true");

            // Check to see if the last update is twice the interval
            if ((Date.now() - this.lastUpdateCheckTime) > 2 * this.updateCheckInterval) {
                if (!this.restartInProgress) {
                    this.restartInProgress = true;
                    console.log("Restarting the update check");
                    this.stopUpdateCheck();
                    this.startUpdateCheck();
                    this.restartInProgress = false;
                }
            }
        } else {
            console.log("status pollerShouldRun is false");
            this.stopUpdateCheck();
        }

        return new Observable<String>(observer => { observer.next("OK - deadman"); observer.complete(); });
    }


    startUpdateCheck(): void {
        // console.log("Starting update check for customer: " + this.customerId);

        // If the customerId is not set then we do not start the poller.
        if (this.customerId === undefined || this.customerId === null || this.customerId === 0) {
            return;
        }
        // As we start the service the interval does not fire until the end of the interval which means there is a race condition around the dead man check.
        // To avoid this we set the last update check time to now so that the deadman check will not fire until the interval has fired at least once.
        this.updateLastUpdateCheckTime();

        // Mark the poller as should be running. This will allow deadman to kick in.
        this.shouldRun = true;

        // Reset the stop polling
        this.stopPolling$ = new Subject<void>();

        // Start the interval service
        interval(this.updateCheckInterval)
            .pipe(
                switchMap(() => this.dataFeedService.syncData()),
                takeUntil(this.stopPolling$),
                catchError((error) => {
                    this.errorSubject.next('Error during update check. Logging out.');
                    this.store.dispatch(LoginActions.logoutInitiate());
                    throw error;
                })
            )
            .subscribe(
                (response) => {
                    // Need to subscribe to the result otherwise the interval will not fire anything.
                    this.updateLastUpdateCheckTime();

                }
            );
    }

    /**
     * Convenience method to update the last update check time.
     */
    updateLastUpdateCheckTime(): void {
        this.lastUpdateCheckTime = Date.now();
    }


    /**
     * Stops the update check.
     */
    stopUpdateCheck(): void {
        console.log("stop status polling");
        this.stopPolling$.next();
        this.stopPolling$.complete();

        // Mark the poller as should not be running. This will allow deadman to kick in.
        this.shouldRun = false;
    }

    getErrorObservable(): Observable<string> {
        return this.errorSubject.asObservable();
    }

}
