import { BaseResponse } from "../BaseResponse";
import { AccountUserInformationSSO } from "./AccountUserInformationSSO";
import { NameDTO } from "./NameDTO";

export class AccountUserInformationResponse extends BaseResponse {
    username: string;
    name: NameDTO = new NameDTO();
    readOnlyAccess: boolean = false;
    locked: boolean = false;
    registrationDate: string;
    lastPasswordChange: string;
    partnerOfferId: string = "passpack";
    emailAddress: string;
    pendingEmailChange: boolean;
    mobileNumber: string;
    partner: string;
    statusToken: string;
    nickname: string;
    userId: number;
    address: string;
    phone: string;
    vat: string;
    searchable: boolean;
    customerId: number;
    base64Avatar: string;
    sso: AccountUserInformationSSO = new AccountUserInformationSSO();


}
