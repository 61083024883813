

export class StorageState {
    lastPasswordChangeTimestamp: number = 0;
    lastPasswordGroupChangeTimestamp: number = 0;
    lastDeletedPasswordChangeTimestamp: number = 0;
    lastLabelChangeTimestamp: number = 0;
    lastVaultChangeTimestamp: number = 0;
    loadedPasswordCount: number = 0;
    loadedPasswordGroupCount: number = 0;
    loadedDeletedPasswordCount: number = 0;
    loadedVaultCount: number = 0;
    preloadedPasswordCount: number = -1;
    preloadedPasswordGroupCount: number = 0;
    preloadedDeletedPasswordCount: number = 0;
    preloadedVaultCount: number = 0;
    passwordLoadState: string = "none";

}