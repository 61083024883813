import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, catchError } from 'rxjs';
import { BaseResponse } from '@app/model/api/BaseResponse';



@Injectable({
  providedIn: 'root',
})

export class PostService {
  constructor(private http: HttpClient) { }

  makePostParams(params: Map<string, any>): string {
    if (params) {
      return Array.from(params.keys()).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params.get(key))).join('&');
    }
    else {
      return '';
    }
  }

  public postMultipartFormData(body: FormData, url: string): Observable<any> {
    const options = {
      withCredentials: true,
      headers: new HttpHeaders({
        'Accept': 'application/json, */*;',
      })
    };
    console.log(options);
    return this.http.post(
      url,
      body,
      options
    ).pipe(catchError(this.handleError<any>('POST')));
  }

  public postApplicationJson(bodyAsJson: string, url: string): Observable<any> {
    const options = {
      withCredentials: true,
      headers: new HttpHeaders({
        'Accept': 'application/json, text/javascript, */*;',
        'Content-Type': 'application/json; charset=utf-8'
      })
    };
    return this.http.post(
      url,
      bodyAsJson,
      options
    ).pipe(catchError(this.handleError<any>('POST')));
  }

  public post(params: Map<string, any>, url: string): Observable<any> {
    const body = this.makePostParams(params);
    const options = {
      withCredentials: true,
      headers: new HttpHeaders({
        'Accept': 'application/json, text/javascript, */*;',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      })
    };
    return this.http.post(
      url,
      body,
      options
    ).pipe(catchError(this.handleError<any>('POST')));
  }

  /**
    * Handle Http operation that failed.
    * Let the app continue.
    *
    * @param operation - name of the operation that failed
    * @param result - optional value to return as the observable result
    */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(`${operation} failed: ${error.message} ${error.status}`); // log to console instead

      // If 403 then send to login
      if (error.status === 403) {
        //   window.location.href = '/login';
        // code 1 is unauthorized:
        let response = new BaseResponse();
        response.status = { code: 1, codes: [], message: "Unauthorized" };
        return of(response as T);
      } else if (error.status >= 400) {
        let response = new BaseResponse();
        let msg = error.message;
        if (error.error) {
          msg = error.error.message;
        }
        response.status = { code: 100, codes: [], message: msg };
        return of(response as T);
      }

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    }
  };
}
