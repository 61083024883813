
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ActionUpdateRequest } from '@app/model/api/action/ActionUpdateRequest';

export const ActionActions = createActionGroup({
    source: 'Action',
    events: {
        // Page opens
        // Other
        'Send Action Update': props<{ data: ActionUpdateRequest }>(),
    },
});