import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAPIService } from '../BaseAPIService';
import { Observable } from 'rxjs';
import { GetOrganizationKeysResponse } from '@app/model/api/organization/GetOrganizationKeysResponse';
import { environment } from '@environments/environment';
import { HttpParams } from '@angular/common/http';
import { CreateOrganizationKeyResponse } from '@app/model/api/organization/CreateOrganizationKeyResponse';
import { AccountKeysService } from '../account/AccountKeysService';
import { AccountKeys } from '@app/model/app/account/AccountKeys';
import { EncryptionValues } from '@app/model/app/EncryptionValues';
import { OrganizationKey } from '@app/model/app/organization/OrganizationKey';
import { CreateOrganizationKeyRequest } from '@app/model/api/organization/CreateOrganizationKeyRequest';
import { RsaKeyOpsService } from '../encryption/RsaKeyOpsService';
import { RSAEncrypt } from '../encryption/RsaEncrypt';
import { AesEncrypt } from '../encryption/AesEncrypt';
import { StorageActions } from '@app/store/actions/StorageActions';
import { TeamPublicKeyService } from '../teams/TeamPublicKeyService';
import { GetOrganizationKeysEntry } from '@app/model/api/organization/GetOrganizationKeysEntry';
import { RSADecrypt } from '../encryption/RsaDecrypt';
import { AesDecrypt2 } from '../encryption/AesDecrypt2';
import { DecryptedOrganizationKey } from '@app/model/app/organization/DecryptedOrganizationKey';
import { GrantAccessToOrganizationKeyRequest } from '@app/model/api/organization/GrantAccessToOrganizationKeyRequest';
import { GrantAccessToOrganizationKeyResponse } from '@app/model/api/organization/GrantAccessToOrganizationKeyResponse';
import { OrganizationKeyTypeEnum } from '@app/model/app/organization/OrganizationKeyTypeEnum';
import { RandomStringGeneratorService } from '../utilities/RandomStringGeneratorService';
@Injectable({ providedIn: 'root' })
export class OrganizationKeysService implements OnDestroy {

    private consoleLog: boolean = false;

    constructor(
        private store: Store,
        private baseAPIService: BaseAPIService,
        private accountKeysService: AccountKeysService,
        private rsaKeyOps: RsaKeyOpsService,
        private rsaEncrypt: RSAEncrypt,
        private rsaDecrypt: RSADecrypt,
        private aesEncrypt: AesEncrypt,
        private aesDecrypt: AesDecrypt2,
        private teamPublicKeyService: TeamPublicKeyService,
        private randomStringGeneratorService: RandomStringGeneratorService

    ) {
    }

    ngOnDestroy(): void {

    }

    /**
     * Get the organization keys
     * @returns 
     */
    getOrganizationKeys(): Observable<GetOrganizationKeysResponse> {
        let url = environment.API_BASE_URL + "v1/secure/organization/key";
        var params = new HttpParams();
        return this.baseAPIService.getRequestNoErrorHandling<GetOrganizationKeysResponse>(params, url);
    }

    /**
     * Ensure the users have access to the key.
     * @param userIds 
     * @param key 
     */
    ensureUsersHaveAccessToKey(userIds: number[], key: DecryptedOrganizationKey) {
        // Get the public keys for the users
        this.teamPublicKeyService.getConnectionPublicKeys(userIds).subscribe((publicKeys) => {
            // For each public key, encrypt the data key with the public key
            for (let publicKey of publicKeys.publicKeys) {
                //console.log("encrypting with: ", key, publicKey);
                this.rsaEncrypt.rsaEncrypt(key.encryptionKey, publicKey.publicKey).then((encryptedEncryptionKey) => {
                    if (encryptedEncryptionKey.success) {

                        this.rsaEncrypt.rsaEncrypt(key.dataKey, publicKey.publicKey).then((encryptedDataKey) => {
                            if (encryptedDataKey.success) {

                                // Now can create the request.
                                let request = new GrantAccessToOrganizationKeyRequest();
                                request.otherId = publicKey.connectionId;
                                request.organizationId = key.organizationId;
                                request.keyType = key.keyType;
                                request.encryptionKey = encryptedEncryptionKey.data;
                                request.dataKey = encryptedDataKey.data;

                                let url = environment.API_BASE_URL + "v1/secure/organization/key/grant";
                                let body = JSON.stringify(request);
                                this.baseAPIService.putRequestNoErrorHandlingApplicationJson<GrantAccessToOrganizationKeyResponse>(body, url).subscribe((response) => {
                                    if (this.consoleLog) console.log("response: ", response);
                                });

                            }
                        });
                    }
                });

            }
        });



    }



    /**
   * Decrypt the organizationKey 
   * @param vault 
   */
    async decryptOrganizationKey(encryptedKeyData: GetOrganizationKeysEntry): Promise<DecryptedOrganizationKey> {
        if (this.consoleLog) console.log("decryptOrganizationKey ", encryptedKeyData);
        // Get my keys
        let accountKeys: AccountKeys = this.accountKeysService.getAccountKeysData();
        let encryptionKey: string = "";
        let decryptedPublicKey: string = "";
        let decryptedPrivateKey: string = "";

        // Decrypt the vault encryption key using our private packing key if it is present
        if (encryptedKeyData.encryptionKey && encryptedKeyData.encryptionKey.length > 0) {
            let decryptedEncryptionKey = await this.rsaDecrypt.rsaDecrypt(encryptedKeyData.encryptionKey, accountKeys.packingKeyDecryptedPrivateKey);
            if (!decryptedEncryptionKey.success) {
                console.error("error: " + decryptedEncryptionKey.error);
            } else {
                encryptionKey = decryptedEncryptionKey.data;
                if (this.consoleLog) {
                    console.log("Decrypted encryption key: ", encryptionKey);
                    console.log("Encrypted public key: ", encryptedKeyData.publicKey);
                    console.log("Encrypted private key: ", encryptedKeyData.privateKey);
                }
                // If there is a public private key then decrypt it now.
                if (encryptedKeyData.publicKey && encryptedKeyData.publicKey.length > 0) {
                    let decryptedPrivateKeyData = await this.aesDecrypt.aesDecrypt(encryptedKeyData.privateKey, encryptionKey);
                    decryptedPublicKey = encryptedKeyData.publicKey;
                    decryptedPrivateKey = decryptedPrivateKeyData.data;
                }
            }
        }

        var promise = new Promise<DecryptedOrganizationKey>((resolve, reject) => {
            // Decrypt the vault data key using our private packing key
            this.rsaDecrypt.rsaDecrypt(encryptedKeyData.dataKey, accountKeys.packingKeyDecryptedPrivateKey).then((decryptedDataKey) => {
                if (decryptedDataKey.success) {
                    // Decrypt the org key data using the vault key
                    //console.log("Decrypting org key data using the vault key", encryptedKeyData.data, decryptedDataKey.data);
                    this.aesDecrypt.aesDecrypt(encryptedKeyData.data, decryptedDataKey.data).then((decryptedData) => {
                        let organizationKey: OrganizationKey = JSON.parse(decryptedData.data);

                        if (this.consoleLog) {
                            console.log("decryptedData: ", decryptedData);
                            console.log("key Data: ", organizationKey);
                        }

                        let response: DecryptedOrganizationKey = new DecryptedOrganizationKey();

                        response.keyType = organizationKey.keyType;
                        response.version = organizationKey.version;
                        response.name = organizationKey.name;
                        response.description = organizationKey.description;

                        response.dataKey = decryptedDataKey.data;
                        response.encryptionKey = encryptionKey;
                        response.privateKey = decryptedPrivateKey;
                        response.publicKey = decryptedPublicKey;

                        response.organizationId = encryptedKeyData.organizationId;


                        resolve(response);

                    }).catch((error) => {
                        console.error("error: ", error);
                        reject(error)
                    });
                } else {
                    if (this.consoleLog) console.error("error: " + decryptedDataKey.error);
                    reject(decryptedDataKey.error);
                }

            });
        });

        return promise;

    }


    /**
     * Create a new organization key with the specified information.
     * @param name 
     * @param description 
     * @param version 
     * @param vaultType 
     * @returns 
     */
    async createNewOrganizationKey(name: string, description: string, version: number, keyType: number): Promise<CreateOrganizationKeyResponse> {
        if (this.consoleLog) console.log("createNewOrganizationKey " + name + ", " + description + ", " + keyType);

        // create the response object
        let responseObject = new CreateOrganizationKeyResponse();

        // Get my keys
        let accountKeys: AccountKeys = this.accountKeysService.getAccountKeysData();

        // Create the key .
        // Encryption key allows to add new people to the access the key. This is reserved for admins.
        // Data key is the key that is used to encrypt the data in the vault. This is for everyone.
        let encryptionKey = this.randomStringGeneratorService.generateRandomString(EncryptionValues.NEW_RSA_KEY_LENGTH);
        let dataKey = this.randomStringGeneratorService.generateRandomString(EncryptionValues.NEW_RSA_KEY_LENGTH);


        // Create the vault public private keys
        // Generate a new RSA key.
        const newKey = await this.rsaKeyOps.generateRSAKey();
        let encryptedPrivateKey = await this.aesEncrypt.aesEncrypt(newKey.privateKey, encryptionKey);

        // Store the keys in the response object in case we need them after this call and before the system syncs
        responseObject.encryptionKey = encryptionKey;
        responseObject.dataKey = dataKey;

        var promise = new Promise<CreateOrganizationKeyResponse>((resolve, reject) => {

            let organizationKey: OrganizationKey = {
                name: name,
                description: description,
                version: version,
                keyType: keyType,
            };

            // Encrypt the vault encryption key with the packing key
            this.rsaEncrypt.rsaEncrypt(encryptionKey, accountKeys.packingKeyPublicKey).then((encryptedEncryptionKey) => {
                if (encryptedEncryptionKey.success) {

                    // Encrypt the vault data key  with the packing key.
                    this.rsaEncrypt.rsaEncrypt(dataKey, accountKeys.packingKeyPublicKey).then((encryptedDataKey) => {
                        if (encryptedDataKey.success) {

                            // Encrypt the vault data with the vaultDataKey
                            this.aesEncrypt.aesEncrypt(JSON.stringify(organizationKey), dataKey).then((encryptedOrganizationKeyData) => {
                                let createKeyRequest = new CreateOrganizationKeyRequest();
                                createKeyRequest.data = encryptedOrganizationKeyData;
                                createKeyRequest.encryptionKey = encryptedEncryptionKey.data;
                                createKeyRequest.dataKey = encryptedDataKey.data;
                                createKeyRequest.keyType = keyType;
                                createKeyRequest.version = version;
                                createKeyRequest.publicKey = newKey.publicKey;
                                createKeyRequest.privateKey = encryptedPrivateKey;



                                let url = environment.API_BASE_URL + "v1/secure/organization/key";
                                let body = JSON.stringify(createKeyRequest);
                                this.baseAPIService.putRequestNoErrorHandlingApplicationJson<CreateOrganizationKeyResponse>(body, url).subscribe((createKeyResponse) => {
                                    // Pull in the new key
                                    this.store.dispatch(StorageActions.immediatelyRunFeed());

                                    // Ensure the other admins have access to the key
                                    // this.ensureUser/sHaveAccessToKey(userIds, key);

                                    // resolve the promise
                                    responseObject.serverResponse = createKeyResponse;
                                    resolve(responseObject);
                                });

                            }).catch((error) => {
                                console.error("error: " + error);
                                reject(error);
                            });

                        } else {
                            if (this.consoleLog) console.log("error: " + encryptedDataKey.error);
                            reject(encryptedDataKey.error);
                        }
                    }).catch((error) => {
                        console.error("error: " + error);
                        reject(error);
                    });

                } else {
                    if (this.consoleLog) console.log("error: " + encryptedEncryptionKey.error);
                    reject(encryptedEncryptionKey.error);
                }
            }).catch((error) => {
                console.error("error: " + error);
                reject(error);
            });

        });

        return promise;
    }



    /**
     * Downloads and finds the reset key
     * @returns Decrypted packing reset key 
     */
    retrieveAndDecryptOrgPackingResetKey(): Promise<DecryptedOrganizationKey> {

        var promise = new Promise<DecryptedOrganizationKey>((resolve, reject) => {

            this.getOrganizationKeys().subscribe((response) => {
                if (response.status.code === 0) {
                    // this.organizationKeysResponse = response;

                    // Determine if the packing key reset key is present
                    let packingKeyResetKey = response.keys.find(key => key.keyType === OrganizationKeyTypeEnum.PACKING_KEY_RESET);

                    // if the packing key reset key is present then decrypt it.
                    if (packingKeyResetKey) {

                        this.decryptOrganizationKey(packingKeyResetKey).then((decryptedPackingKeyResetKey) => {
                            if (this.consoleLog) console.log("Decrypted packing key reset key: ", decryptedPackingKeyResetKey);
                            resolve(decryptedPackingKeyResetKey);
                        }).catch((error) => {
                            console.error("error: " + error);
                            reject(error);
                        });
                    } else {
                        reject("No packing reset key");
                    }
                } else {
                    reject("Invalid response from server: " + response.status.code);
                }
            });
        });
        return promise;
    }

}