import { Geo } from "../Geo";

export class ArchivedPasswordFeedEntry {
    id: number;
    recoveryId: string;
    gid: number;
    vault: string;
    archivedTimestamp: string;
    initiatorId: number;
    initiatorUsername: string;
    ip: string;
    sessionId: string;
    data: string;
    key: string;
    geo: Geo;
    version: number = 1;
    action: number = 1;

    constructor(archived: ArchivedPasswordFeedEntry) {
        this.id = archived.id;
        this.gid = archived.gid;
        this.vault = archived.vault;
        this.recoveryId = archived.recoveryId;
        this.archivedTimestamp = archived.archivedTimestamp;
        this.initiatorId = archived.initiatorId;
        this.initiatorUsername = archived.initiatorUsername;
        this.ip = archived.ip;
        this.sessionId = archived.sessionId;
        this.data = archived.data;
        this.key = archived.key;
        this.geo = archived.geo;
        this.version = archived.version;
        this.action = archived.action;
    }

}