import { ActionReducer, createReducer, on } from '@ngrx/store';
import { LoginActions } from '../actions/LoginActions';
import { StorageState } from '@app/model/app/storage/StorageState';
import { StorageActions } from '../actions/StorageActions';

export const initialState: StorageState = new StorageState();
const consoleLog: boolean = false;

export const storageReducer: ActionReducer<StorageState> = createReducer(
    initialState,
    on(LoginActions.logoutComplete, (state, action) => {
        return {
            ...initialState
        };
    }),
    on(LoginActions.logoutInitiate, (state, action) => {
        return {
            ...initialState
        };
    }),
    on(LoginActions.lightweightLogout, (state, action) => {
        return {
            ...initialState
        };
    }),

    on(StorageActions.updateLastPasswordChangeTimestamp, (state, action) => {
        if (consoleLog) console.log("updateLastPasswordChangeTimestamp");
        return {
            ...state,
            lastPasswordChangeTimestamp: Date.now()
        };
    }),
    on(StorageActions.updateLastArchivedPasswordChangeTimestamp, (state, action) => {
        if (consoleLog) console.log("updateLastArchivedPasswordChangeTimestamp");

        return {
            ...state,
            lastArchivedPasswordChangeTimestamp: Date.now()
        };
    }),
    on(StorageActions.updateLastDeletedPasswordChangeTimestamp, (state, action) => {
        if (consoleLog) console.log("updateLastDeletedPasswordChangeTimestamp");

        return {
            ...state,
            lastDeletedPasswordChangeTimestamp: Date.now()
        };
    }),

    on(StorageActions.updateLastPasswordGroupChangeTimestamp, (state, action) => {
        if (consoleLog) console.log("updateLastPasswordGroupChangeTimestamp");

        return {
            ...state,
            lastPasswordGroupChangeTimestamp: Date.now()
        };
    }),

    on(StorageActions.updateLastLabelChangeTimestamp, (state, action) => {
        if (consoleLog) console.log("updateLastLabelChangeTimestamp");
        return {
            ...state,
            lastLabelChangeTimestamp: Date.now()
        };
    }),
    on(StorageActions.updateLastVaultChangeTimestamp, (state, action) => {
        if (consoleLog) console.log("updateLastVaultChangeTimestamp");
        return {
            ...state,
            lastVaultChangeTimestamp: Date.now()
        };
    }),

    on(StorageActions.updateLoadedPasswordCount, (state, action) => {
        if (consoleLog) console.log("updateLoadedPasswordCount");
        if (state.loadedPasswordCount !== action.data) {
            return {
                ...state,
                loadedPasswordCount: action.data
            };
        } else {
            if (consoleLog) console.log("ignored");
            return state;
        }
    }),
    on(StorageActions.updateLoadedArchivedPasswordCount, (state, action) => {
        if (consoleLog) console.log("updateLoadedArchivedPasswordCount");
        if (state.loadedArchivedPasswordCount !== action.data) {
            return {
                ...state,
                loadedArchivedPasswordCount: action.data
            };
        } else {
            if (consoleLog) console.log("ignored");
            return state;
        }
    }),
    on(StorageActions.updateLoadedDeletedPasswordCount, (state, action) => {
        if (consoleLog) console.log("updateLoadedDeletedPasswordCount");
        if (state.loadedDeletedPasswordCount !== action.data) {
            return {
                ...state,
                loadedDeletedPasswordCount: action.data
            };
        } else {
            if (consoleLog) console.log("ignored");
            return state;
        }
    }),

    on(StorageActions.updateLoadedPasswordGroupCount, (state, action) => {
        if (consoleLog) console.log("updateLoadedPasswordGroupCount");
        if (state.loadedPasswordGroupCount !== action.data) {
            return {
                ...state,
                loadedPasswordGroupCount: action.data
            };
        } else {
            if (consoleLog) console.log("ignored");
            return state;
        }
    }),
    on(StorageActions.updateLoadedVaultCount, (state, action) => {
        if (consoleLog) console.log("updateLoadedVaultCount");
        if (state.loadedVaultCount !== action.data) {
            return {
                ...state,
                loadedVaultCount: action.data
            };
        } else {
            if (consoleLog) console.log("ignored");
            return state;
        }
    }),
    on(StorageActions.updatePreloadVaultCount, (state, action) => {
        if (consoleLog) console.log("updatePreloadVaultCount");
        if (state.preloadedVaultCount !== action.data) {
            return {
                ...state,
                preloadedVaultCount: action.data
            };
        } else {
            if (consoleLog) console.log("ignored");
            return state;
        }
    }),
    on(StorageActions.updatePreloadPasswordCount, (state, action) => {
        if (consoleLog) console.log("updatePreloadPasswordCount");
        if (state.preloadedPasswordCount !== action.data) {
            return {
                ...state,
                preloadedPasswordCount: action.data
            };
        } else {
            if (consoleLog) console.log("ignored");
            return state;
        }
    }),
    on(StorageActions.updatePreloadArchivedPasswordCount, (state, action) => {
        if (consoleLog) console.log("updatePreloadArchivedPasswordCount " + state.preloadedArchivedPasswordCount + " -> " + action.data);
        if (state.preloadedArchivedPasswordCount !== action.data) {
            return {
                ...state,
                preloadedArchivedPasswordCount: action.data
            };
        } else {
            if (consoleLog) console.log("ignored");
            return state;
        }
    }),
    on(StorageActions.updatePreloadDeletedPasswordCount, (state, action) => {
        if (consoleLog) console.log("updatePreloadDeletedPasswordCount " + state.preloadedDeletedPasswordCount + " -> " + action.data);
        if (state.preloadedDeletedPasswordCount !== action.data) {
            return {
                ...state,
                preloadedDeletedPasswordCount: action.data
            };
        } else {
            if (consoleLog) console.log("ignored");
            return state;
        }
    }),

    on(StorageActions.updatePreloadPasswordGroupCount, (state, action) => {
        if (consoleLog) console.log("updatePreloadPasswordGroupCount");
        if (state.preloadedPasswordGroupCount !== action.data) {
            return {
                ...state,
                preloadedPasswordGroupCount: action.data
            };
        } else {
            if (consoleLog) console.log("ignored");
            return state;
        }
    }),

    on(StorageActions.updatePasswordLoadState, (state, action) => {
        if (consoleLog) console.log("updatePasswordLoadState");
        if (state.passwordLoadState !== action.data) {
            return {
                ...state,
                passwordLoadState: action.data
            };
        } else {
            if (consoleLog) console.log("ignored");
            return state;
        }
    }),


);