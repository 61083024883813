import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { RSAEncrypt } from '../encryption/RsaEncrypt';
import { AccountKeysService } from '../account/AccountKeysService';
import { AesEncrypt } from '../encryption/AesEncrypt';
import { BaseAPIService } from '../BaseAPIService';
import { environment } from '@environments/environment';
import { DeleteTeamResponse } from '@app/model/api/teams/DeleteTeamResponse';
import { Observable } from 'rxjs';
import { UpdateTeamResponse } from '@app/model/api/teams/UpdateTeamResponse';
import { AccountKeys } from '@app/model/app/account/AccountKeys';
import { StorageActions } from '@app/store/actions/StorageActions';
import { PasswordGroupCreateResponse } from '@app/model/api/passwords/PasswordGroupCreateResponse';
import { TeamKeyService } from '../cache/team-key-service.service';
import { CreateTeamRequest } from '@app/model/api/passwords/CreateTeamRequest';
import { GetTeamMembershipListingResponse } from '@app/model/api/teams/GetTeamMembershipListingResponse';
import { PasswordVaultData } from '@app/model/app/passwordVault/PasswordVaultData';
import { InMemoryTeam } from '@app/model/app/passwordGroup/InMemoryTeam';
import { TeamVersionsEnum } from '@app/model/app/passwordGroup/TeamVersionsEnum';
import { TeamEncryptedData } from '@app/model/app/teams/TeamEncryptedData';
import { UpdateTeamRequest } from '@app/model/api/teams/UpdateTeamRequest';
import { TeamQueryService } from './TeamQueryService';
import { RandomStringGeneratorService } from '../utilities/RandomStringGeneratorService';
@Injectable({ providedIn: 'root' })
export class TeamsService implements OnDestroy {
    private consoleLog: boolean = false;
    private newRSAKeyLength: number = 60;//128;
    private newMetadataKeyLength: number = 32; // 32 is a restriction based on the blowfish encryption for name and url

    constructor(
        private store: Store,
        private randomStringGeneratorService: RandomStringGeneratorService,
        private aesEncrypt: AesEncrypt,
        private rsaEncrypt: RSAEncrypt,
        private accountKeysService: AccountKeysService,
        private baseAPIService: BaseAPIService,
        private teamKeyService: TeamKeyService,
        private teamQueryService: TeamQueryService

    ) {

    }
    ngOnDestroy(): void {

    }



    getTeamMembership(teamId: number): Observable<GetTeamMembershipListingResponse> {
        let url = environment.API_BASE_URL + "v1/secure/team/" + teamId + "/membership";
        let body = '';
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<GetTeamMembershipListingResponse>(body, url);
    }

    deleteTeam(teamId: number): Observable<DeleteTeamResponse> {
        let url = environment.API_BASE_URL + "v1/secure/password/group/delete";
        var params = new Map<string, any>();
        params.set("id", teamId);
        return this.baseAPIService.postRequest<DeleteTeamResponse>(params, url);
    }


    async convertVersion1TeamToVersion2(team: InMemoryTeam) {
        console.log("Want convert team to version 2: " + team.id);
        this.updateTeam(team.id, team.name, team.description, team.color, team.defaultRole).then((updateTeamResponse) => {
            if (this.consoleLog) {
                console.log("Team request sent to upgrade to version 2: " + team.id);
                console.log("Response", updateTeamResponse)
            }
        });
    }

    async updateTeam(teamId: number, name: string, description: string, color: string, defaultRole: number): Promise<UpdateTeamResponse> {
        let encryptedData = "";

        // Load the team.
        let team = this.teamQueryService.getInMemoryTeam(teamId);
        if (team) {
            // Build up the data to send.
            let data = new TeamEncryptedData();
            data.version = TeamVersionsEnum.V2;
            data.color = color;
            data.description = description;
            data.name = name;
            data.defaultRole = defaultRole;
            data.defaultTeam = "Default" === name;
            encryptedData = await this.aesEncrypt.aesEncrypt(JSON.stringify(data), team.decryptedGroupKey);
        }

        // Now create the request
        let url = environment.API_BASE_URL + "v1/secure/password/group/update";
        let request = new UpdateTeamRequest();
        request.id = teamId;
        request.version = TeamVersionsEnum.V2;
        request.data = encryptedData;
        request.defaultRoleForGroupMembers = defaultRole;
        let body = JSON.stringify(request);

        var promise = new Promise<UpdateTeamResponse>((resolve, reject) => {

            this.baseAPIService.postRequestNoErrorHandlingApplicationJson<UpdateTeamResponse>(body, url).subscribe((updateTeamResponse) => {
                resolve(updateTeamResponse);
            });
        });

        return promise;

    }

    async createNewTeam(
        name: string,
        description: string,
        color: string,
        defaultRole: number,
        version: number,
        passwordVaultData: PasswordVaultData | null = null
    )
        : Promise<PasswordGroupCreateResponse> {
        if (this.consoleLog) console.log("createNewTeam " + name + " " + description + " " + color + " " + defaultRole);
        let encryptedTeamKeyForVault: string = "";
        let createTeamRequest = new CreateTeamRequest();
        let encryptedData = "";


        // Create the groupKey which will act as the shared key for the team.  With gen 2 keys the max length is 60.
        let groupKey = this.randomStringGeneratorService.generateRandomString(this.newRSAKeyLength);

        // Get my keys
        let accountKeys: AccountKeys = this.accountKeysService.getAccountKeysData();

        if (null != passwordVaultData) {
            // We are going to associate this team with a vault
            let encryptionResult = await this.rsaEncrypt.rsaEncrypt(groupKey, passwordVaultData.decryptedPublicKey);
            if (encryptionResult && encryptionResult.success) {
                encryptedTeamKeyForVault = encryptionResult.data;
            }
        }

        createTeamRequest.defaultTeam = "Default" === name;

        if (version === TeamVersionsEnum.V1) {
            createTeamRequest.version = TeamVersionsEnum.V1;
            createTeamRequest.name = name;
            createTeamRequest.color = color;
            createTeamRequest.description = description;
            createTeamRequest.defaultRoleForGroupMembers = defaultRole;
        } else if (version === TeamVersionsEnum.V2) {
            createTeamRequest.version = TeamVersionsEnum.V2;
            let data = new TeamEncryptedData();
            data.version = TeamVersionsEnum.V2;
            data.color = color;
            data.description = description;
            data.name = name;
            data.defaultRole = defaultRole;
            data.defaultTeam = createTeamRequest.defaultTeam;
            encryptedData = await this.aesEncrypt.aesEncrypt(JSON.stringify(data), groupKey);
            createTeamRequest.data = encryptedData;
        }

        var promise = new Promise<PasswordGroupCreateResponse>((resolve, reject) => {

            this.rsaEncrypt.rsaEncrypt(groupKey, accountKeys.packingKeyPublicKey).then((encryptedGroupKey) => {
                if (encryptedGroupKey.success) {
                    // Once the group key is encrypted with the packing key, we can create a new guid and encrypt it with the group key.
                    let metadataKey = this.randomStringGeneratorService.generateRandomString(this.newMetadataKeyLength);
                    // If the teamName is not Default then pull in the default team metadata key

                    // TODO - once new version is rolled out (11.0.7) then no more need for metadata key
                    if (name !== "Default") {
                        let myDefaultTeamMetaKeys = this.teamKeyService.getMyDefaultTeamMetaKeys();
                        if (myDefaultTeamMetaKeys != null) {
                            metadataKey = myDefaultTeamMetaKeys.metadataKey;
                        }
                    }

                    this.aesEncrypt.aesEncrypt(metadataKey, groupKey).then((encryptedMetadataKey) => {
                        createTeamRequest.metadataKey = encryptedMetadataKey;
                        createTeamRequest.encryptedGroupKey = encryptedGroupKey.data;

                        // if this is a vault, add the information
                        if (null != passwordVaultData && encryptedTeamKeyForVault.length > 0) {
                            createTeamRequest.encryptedTeamKeyForVault = encryptedTeamKeyForVault;
                            createTeamRequest.vaultId = passwordVaultData.id;
                        }

                        let url = environment.API_BASE_URL + "v2/secure/password/group/create";
                        let body = JSON.stringify(createTeamRequest);
                        this.baseAPIService.putRequestNoErrorHandlingApplicationJson<PasswordGroupCreateResponse>(body, url).subscribe((createTeamResponse) => {
                            // Pull in the new team
                            this.store.dispatch(StorageActions.immediatelyRunFeed());

                            // resolve the promise
                            resolve(createTeamResponse);
                        });

                    }).catch((error) => {
                        console.log("error: " + error);
                        reject(error);
                    });

                } else {
                    if (this.consoleLog) console.log("error: " + encryptedGroupKey.error);
                    reject(encryptedGroupKey.error);
                }
            }).catch((error) => {
                console.log("error: " + error);
                reject(error);
            });

        });

        return promise;

    }

}
