import { ActionReducer, createReducer, on } from '@ngrx/store';
import { LoginActions } from '../actions/LoginActions';
import { LoginFunnelState } from '@app/model/app/LoginFunnelState';
import { AccountActions } from '../actions/AccountActions';

export const initialState: LoginFunnelState = new LoginFunnelState();

export const loginReducer: ActionReducer<LoginFunnelState> = createReducer(
    initialState,
    on(LoginActions.logoutComplete, (state, action) => {
        return {
            ...initialState
        };
    }),
    on(LoginActions.logoutInitiate, (state) => {
        return { ...state, loggingOut: true };
    }),
    on(LoginActions.lightweightLogout, (state) => {
        return {
            ...initialState
        };
    }),
    on(LoginActions.lockSystem, (state) => {
        return {
            ...state,
            locked: true,
            errorMessage: "",
        };
    }),
    on(LoginActions.unlockSystem, (state) => {
        return {
            ...state,
            locked: false,
            errorMessage: "",
        };
    }),
    on(LoginActions.loginInitialized, (state) => {
        return { ...state, initialized: true };
    }),

    on(LoginActions.loginPageOpened, state => {
        return state;
    }),

    on(LoginActions.loginSuccess, (state) => {
        return {
            ...state,
            loggedIn: true,
            errorMessage: "",
        };
    }),

    on(LoginActions.setRequireEmailVerification, (state, action) => {
        return {
            ...state,
            needVerifyAccount: action.requireEmailVerification,
            errorMessage: "",
            // set to 0 even though it was 482. This clears the error message on the screen
            lastStatusCode: 0,
            initialized: true
        };
    }),
    on(LoginActions.setRequireMFA, (state, action) => {
        return {
            ...state,
            mfaCodeRequired: action.requireMFA,
            initialized: true
        };
    }),


    // Logged in if success loading or 99 - MFA required
    // This could come as a result of a refresh so need to see if the result from the server indicates 
    // we are still logged in.
    on(AccountActions.accountDetailsLoaded, (state, action) => {
        return {
            ...state,
            initialized: true,
            loggedIn: (action.accountDetails.status.code === 0 || action.accountDetails.status.code === 99) ? true : false,
            lastStatusCode: action.accountDetails.status.code,
        };
    }),
    on(AccountActions.accountDetailsLoadError, (state, action) => {
        return {
            ...state,
            initialized: true,
        };
    }),
    on(LoginActions.loginPageError, (state, action) => {
        return {
            ...state,
            initialized: true,
            computing: false
        };
    }),
    on(LoginActions.login, (state, action) => {
        return {
            ...state,
            initialized: true,
            loggedIn: false,
            lastStatusCode: 0,
            computing: true,
            username: action.username,
            password: action.password
        };
    }),
    on(LoginActions.oauthLoginSuccess, (state) => {
        return {
            ...state,
            loggedIn: true,
            computing: false,
            initialized: true
        };
    }),

    on(AccountActions.verifyNewAccountEmailSuccess, (state, action) => {
        return {
            ...state,
            initialized: true,
            lastStatusCode: 0,
            // computing: false // keep false so the button is unavailable
        };
    }),
    on(LoginActions.accountLoginCompleted, (state, action) => {
        return {
            ...state,
            initialized: true,
            loggedIn: action.loginResponse.status.code === 0 ? true : false,
            lastStatusCode: action.loginResponse.status.code,
            mfaCodeRequired: null != action.loginResponse.oobSettings && action.loginResponse.oobSettings.options && action.loginResponse.oobSettings.options.length > 0,
            computing: false
        };
    }),

    on(LoginActions.mFACodeEntered, (state, action) => {
        return {
            ...state,
            initialized: true,
            mfaCodeEntered: action.mfaResponse.status.code === 0 ? true : false,
            lastStatusCode: action.mfaResponse.status.code,
            computing: false
        };
    }),

    on(AccountActions.setNeedCreatePackingKey, (state, action) => {
        return {
            ...state,
            needCreatePackingKey: action.needCreatePackingKey
        };
    }),
    on(AccountActions.packingKeyRetrieved, (state, action) => {
        return {
            ...state,
            packingKeyLoaded: true
        };
    }),
    on(AccountActions.unlockPackingKey, (state, action) => {
        return {
            ...state,
            computing: true,
            errorMessage: ""
        };
    }),

    on(AccountActions.unlockPackingKeyError, (state, action) => {
        return {
            ...state,
            computing: false,
            errorMessage: action.error
        };
    }),
    on(AccountActions.unlockPackingKeySuccess, (state, action) => {
        return {
            ...state,
            computing: false,
            locked: false,
            packingKeyDecoded: true,
            packingKeyLoaded: true,
            errorMessage: ""
        };
    }),

    on(AccountActions.verifyNewAccountEmail, (state, action) => {
        return {
            ...state,
            computing: true,
            errorMessage: ""
        };
    }),

    on(LoginActions.setMayViewAccount, (state, action) => {
        return {
            ...state,
            canViewAccount: action.canViewAccount
        };
    }),

    on(AccountActions.idleTimeout, (state, action) => {
        return {
            ...state,
            canViewAccount: false,
            locked: true,
        };
    }),


);