import { Injectable } from '@angular/core';



@Injectable({ providedIn: 'root' })
export class EncryptionTools {


    generateRandomStringWithDatePrefix(length: number): string {
        let date = new Date();
        let datePrefix = date.getFullYear().toString() + (date.getMonth() + 1).toString().padStart(2, '0') + date.getDate().toString().padStart(2, '0') + date.getHours().toString().padStart(2, '0') + date.getMinutes().toString().padStart(2, '0');
        return datePrefix + this.generateRandomString(length - datePrefix.length);
    }

    /**
     * Generate a cryptographically secure random string of length X
     * @param length 
     * @returns 
     */
    generateRandomString(length: number): string {
        const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        const randomArray = new Uint8Array(length);
        crypto.getRandomValues(randomArray);
        randomArray.forEach((number) => {
            result += chars[number % chars.length];
        });
        return result;
    }


    //https://stackoverflow.com/questions/9267899/arraybuffer-to-base64-encoded-string/9458996#9458996
    // Faster implementations mentioned in SO article above
    /**
     * Convert an array buffer to String
     * @param buffer 
     * @returns 
     */
    ab2b64(buffer: ArrayBuffer): string {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    // https://stackoverflow.com/a/41106346 or https://stackoverflow.com/a/21797381/9014097
    /**
     * convert a base64 string to an array buffer
     * @param base64string 
     * @returns 
     */
    b642ab(base64string: string): ArrayBuffer {
        return Uint8Array.from(atob(base64string), c => c.charCodeAt(0));
    }

    bytes_to_string(bytes: Uint8Array): string {
        let utf8 = true;//!!utf8;

        var len = bytes.length,
            chars = new Array(len);

        for (var i = 0, j = 0; i < len; i++) {
            var b = bytes[i];
            if (!utf8 || b < 128) {
                chars[j++] = b;
            } else if (b >= 192 && b < 224 && i + 1 < len) {
                chars[j++] = ((b & 0x1f) << 6) | (bytes[++i] & 0x3f);
            } else if (b >= 224 && b < 240 && i + 2 < len) {
                chars[j++] = ((b & 0xf) << 12) | ((bytes[++i] & 0x3f) << 6) | (bytes[++i] & 0x3f);
            } else if (b >= 240 && b < 248 && i + 3 < len) {
                var c = ((b & 7) << 18) | ((bytes[++i] & 0x3f) << 12) | ((bytes[++i] & 0x3f) << 6) | (bytes[++i] & 0x3f);
                if (c <= 0xffff) {
                    chars[j++] = c;
                } else {
                    c ^= 0x10000;
                    chars[j++] = 0xd800 | (c >> 10);
                    chars[j++] = 0xdc00 | (c & 0x3ff);
                }
            } else {
                throw new Error('Malformed UTF8 character at byte offset ' + i);
            }
        }

        var str = '',
            bs = 16384;
        for (var i = 0; i < j; i += bs) {
            str += String.fromCharCode.apply(String, chars.slice(i, i + bs <= j ? i + bs : j));
        }

        return str;
    }


    /**
     * Convert a string to bytes. Taken from asmCrypto
     * @param str 
     * @returns 
     */
    string_to_bytes(str: string): Uint8Array {
        let utf8 = true;//!!utf8;

        var len = str.length,
            bytes = new Uint8Array(utf8 ? 4 * len : len);

        for (var i = 0, j = 0; i < len; i++) {
            var c = str.charCodeAt(i);

            if (utf8 && 0xd800 <= c && c <= 0xdbff) {
                if (++i >= len) throw new Error('Malformed string, low surrogate expected at position ' + i);
                c = ((c ^ 0xd800) << 10) | 0x10000 | (str.charCodeAt(i) ^ 0xdc00);
            } else if (!utf8 && c >>> 8) {
                throw new Error('Wide characters are not allowed.');
            }

            if (!utf8 || c <= 0x7f) {
                bytes[j++] = c;
            } else if (c <= 0x7ff) {
                bytes[j++] = 0xc0 | (c >> 6);
                bytes[j++] = 0x80 | (c & 0x3f);
            } else if (c <= 0xffff) {
                bytes[j++] = 0xe0 | (c >> 12);
                bytes[j++] = 0x80 | ((c >> 6) & 0x3f);
                bytes[j++] = 0x80 | (c & 0x3f);
            } else {
                bytes[j++] = 0xf0 | (c >> 18);
                bytes[j++] = 0x80 | ((c >> 12) & 0x3f);
                bytes[j++] = 0x80 | ((c >> 6) & 0x3f);
                bytes[j++] = 0x80 | (c & 0x3f);
            }
        }

        return bytes.subarray(0, j);
    }
}