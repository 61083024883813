import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, concatMap, exhaustMap, filter, map, mergeMap, switchMapTo, tap, withLatestFrom } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { LabelActions } from "../actions/LabelActions";
import { LabelService } from "@app/services/labels/LabelService";
import { StorageActions } from "../actions/StorageActions";

@Injectable()
export class LabelEffects {
    constructor(
        private actions$: Actions,
        private labelService: LabelService,
        private store: Store,) { }




    readonly deleteLabel$ = createEffect(
        () => this.actions$.pipe(
            ofType(LabelActions.deleteLabel),
            // tap((action) => this.labelService.deleteLabel(action.labelId)),
            exhaustMap((action) => {
                return this.labelService.deleteLabel(action.labelId).pipe(
                    map((response) =>
                        StorageActions.immediatelyRunLabelFeed(),
                    ),
                    // catchError((error) => of(AccountActions.subscriptionSummaryDataRetrievalError({ error })))
                );
            })
        ), { dispatch: false }
    );

    readonly bulkDeleteLabels$ = createEffect(
        () => this.actions$.pipe(
            ofType(LabelActions.bulkDeleteLabels),
            exhaustMap((action) => {
                return this.labelService.bulkDeleteLabels(action.labelIds).pipe(
                    map((response) =>
                        LabelActions.labelDeleted(),
                    ),
                );
            })
        ), { dispatch: false }
    );

}
