import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { StorageService } from './storage.service';
import { StorageActions } from '@app/store/actions/StorageActions';
import { Database } from 'sql.js';
import { SyncStatus } from '@app/model/app/database/SyncStatus';
import { AccountKeysService } from '../account/AccountKeysService';
import { ListPasswordVaultResponse } from '@app/model/api/passwordVault/ListPasswordVaultResponse';
import { PasswordVaultData } from '@app/model/app/passwordVault/PasswordVaultData';
import { PasswordVaultService } from '../passwordVault/password-vault-service.service';
import { ListPasswordVaultEntry } from '@app/model/api/passwordVault/ListPasswordVaultEntry';
import { PasswordVaultEntry } from '@app/model/app/passwordVault/PasswordVaultEntry';
import { AccountKeys } from '@app/model/app/account/AccountKeys';
import { RSADecrypt } from '../encryption/RsaDecrypt';
import { AesDecrypt2 } from '../encryption/AesDecrypt2';
import { PasswordVault } from '@app/model/app/passwordVault/PasswordVault';
import { PasswordVaultActions } from '@app/store/actions/PasswordVaultActions';

@Injectable({
    providedIn: 'root'
})
export class VaultFeedStorageService implements OnInit, OnDestroy {


    private consoleLog: boolean = false;

    constructor(
        private store: Store,
        private storageService: StorageService,
        private accountKeysService: AccountKeysService,
        private aesDecrypt: AesDecrypt2,
        private rsaDecrypt: RSADecrypt,
    ) { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    /**
     * Process the vault feed and store the lastupdate
     * @param data 
     */
    async processVaultFeed(data: ListPasswordVaultResponse, notifyDataChanged: boolean) {
        if (data && data.vaults && data.vaults.length > 0) {
            // Clear indexdb vaults
            this.storageService.appDbService?.vaults.clear().then() // handle success
                .catch(function (error) { console.log(error); }); // handle failure

            // Add Everything to the indexdb
            // Ensure the vaults are marked as not synced
            // This feed is always additive. No need to remove an entry
            let toAdd: PasswordVaultEntry[] = [];
            data.vaults.forEach(vault => {
                let entry = new PasswordVaultEntry(vault);
                entry.syncedInMemory = SyncStatus.SYNC_STATUS_NOT_SYNCED;
                toAdd.push(entry);
            });

            this.storageService.appDbService?.vaults.bulkPut(toAdd).then() // handle success
                .catch(function (error) { console.error(error); }); // handle failure

            // Let the system know that the local data has changed
            if (notifyDataChanged) this.store.dispatch(StorageActions.localDataChanged());
        }
    }

    loadUnsyncedVaults() {
        var promise = new Promise<number>((resolve, reject) => {
            try {
                if (this.consoleLog)
                    console.log("loadUnsyncedVaults");

                // // Get updated counts
                // this.updateTableCounts();


                // Load the account keys
                // console.log("accountKeys ", this.accountKeysData);
                let accountKeysData = this.accountKeysService.getAccountKeysData();
                if (accountKeysData && accountKeysData.packingKeyDecryptedPrivateKey && accountKeysData.packingKeyDecryptedPrivateKey.length > 0) {
                    if (this.storageService.appDbService) {
                        let db = this.storageService.appDbService;
                        // This is the regular method of processing updates.
                        // We expect the data in the indexdb to be bulk loaded and then need bulk insert to memory. No incremental.
                        db.vaults.where('syncedInMemory').equals(SyncStatus.SYNC_STATUS_NOT_SYNCED).sortBy('vaultId').then((records) => {
                            if (records.length > 0) {
                                this.processUnsyncedVaults(records).then(() => {
                                    if (this.consoleLog) console.log("done sync");
                                    resolve(1);
                                });
                            } else {
                                // let the caller know we are done.
                                if (this.consoleLog) console.log("done sync");
                                resolve(1);
                            }
                        })
                    } else {
                        resolve(1);
                    }
                } else {
                    if (this.consoleLog) console.log("app keys not ready");
                    resolve(1);
                }
            } catch (error) {
                console.error("error loading unsynced vault data: ", error);
                reject(0);
            }
        });
        return promise;
    }

    async processUnsyncedVaults(data: ListPasswordVaultEntry[]): Promise<void> {
        let currentVaultData: PasswordVaultData[] = [];

        // Clear all vaults.  When there is anything unsynced then we need to reload everything.
        let db = this.storageService.appDbService;
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database && db) {
            const clearVaultSQL = `DELETE FROM passwordVault`;
            const stmtClearVault = database.prepare(clearVaultSQL);
            stmtClearVault.run();
            stmtClearVault.free();

            // Now add the entries to the vault
            const insertVaultSQL = `REPLACE INTO passwordVault (id, dataKey, encryptionKey, name, description, vaultType, version, ownerName, ownerOrganizationId, ownerCustomerId, role, decryptedPublicKey, decryptedPrivateKey) 
                VALUES (:id, :dataKey, :encryptionKey, :name, :description, :vaultType, :version, :ownerName, :ownerOrganizationId, :ownerCustomerId, :role, :decryptedPublicKey, :decryptedPrivateKey)`;
            const stmtInsertVault = database.prepare(insertVaultSQL);
            // Decrypt each of the entries in the vault response.
            for (let i = 0; i < data.length; i++) {
                try {
                    let vault = data[i];
                    let decryptedVault: PasswordVaultData = await this.decryptVault(vault);
                    //console.log("Storing vault: " + decryptedVault.id);
                    currentVaultData.push(decryptedVault);

                    if (this.consoleLog) console.log(decryptedVault);
                    stmtInsertVault?.run({
                        ':id': decryptedVault.id,
                        ':dataKey': decryptedVault.dataKey,
                        ':encryptionKey': decryptedVault.encryptionKey,
                        ':name': decryptedVault.name,
                        ':description': decryptedVault.description,
                        ':vaultType': decryptedVault.vaultType,
                        ':version': decryptedVault.version,
                        ':ownerName': vault.ownerName,
                        ':ownerOrganizationId': vault.ownerOrganizationId,
                        ':ownerCustomerId': vault.ownerCustomerId,
                        ':role': vault.role,
                        ':decryptedPublicKey': decryptedVault.decryptedPublicKey,
                        ':decryptedPrivateKey': decryptedVault.decryptedPrivateKey
                    });

                    // update the indexdb to indicate it is now loaded
                    db?.transaction('rw', db.vaults, async () => {
                        db?.vaults.update(vault.vaultId, { syncedInMemory: SyncStatus.SYNC_STATUS_SYNCED_IN_MEM }).then(() => {
                            // console.log("updated");
                        }).catch((error) => { console.error(error); });
                    });
                } catch (error) {
                    console.error("error processing unsynced vault data: ", error);
                }
            }
            stmtInsertVault.free();


            // Add the vaults to the store
            this.store.dispatch(PasswordVaultActions.newVaults({ data: currentVaultData }));


            // Moved this from the end of the processUnsyncedPasswords method to here so we don't overload the system
            this.updateTableCounts();
            // Tell the store we have new data.
            this.store.dispatch(StorageActions.updateLastVaultChangeTimestamp());


        };
        var promise = new Promise<void>((resolve, reject) => {

            resolve();
        });

        return promise
    }

    updateTableCounts() {
        let database = this.storageService.inMemorySQLService?.getDatabase();
        if (database) this.dumpCounts(database);
    }


    /**
     * Helper method to dump the counts of rows
     * @param database 
     */
    private dumpCounts(database: Database) {
        let self = this;
        database.each("SELECT count(1) as count FROM passwordVault;", {},
            function (row) {
                if (self.consoleLog) console.log("vaults rows-> " + row.count);

                // self.lastInMemoryPasswordCount = Number(row.count?.toString());

                self.store.dispatch(StorageActions.updateLoadedVaultCount({ data: Number(row.count?.toString()) }));
            },
            () => void (0)
        );

        let db = this.storageService.appDbService;
        if (db) {
            let results = db.vaults.count();
            results.then((count) => {
                self.store.dispatch(StorageActions.updatePreloadVaultCount({ data: Number(count?.toString()) }));
            });
        }


    }


    /**
   * Decrypt the vault 
   * @param vault 
   */
    async decryptVault(vault: ListPasswordVaultEntry): Promise<PasswordVaultData> {
        if (this.consoleLog) console.log("decryptVault ", vault);
        // Get my keys
        let accountKeys: AccountKeys = this.accountKeysService.getAccountKeysData();
        let vaultEncryptionKey: string = "";
        let decryptedPublicKey: string = "";
        let decryptedPrivateKey: string = "";

        // Decrypt the vault encryption key using our private packing key if it is present
        if (vault.encryptionKey && vault.encryptionKey.length > 0) {
            let decryptedVaultEncryptionKey = await this.rsaDecrypt.rsaDecrypt(vault.encryptionKey, accountKeys.packingKeyDecryptedPrivateKey);
            if (!decryptedVaultEncryptionKey.success) {
                console.error("error: " + decryptedVaultEncryptionKey.error);
            } else {
                vaultEncryptionKey = decryptedVaultEncryptionKey.data;

                // If there is a public private key then decrypt it now.
                if (vault.publicKey && vault.publicKey.length > 0) {
                    let decryptedPublicKeyData = await this.aesDecrypt.aesDecrypt(vault.publicKey, vaultEncryptionKey);
                    let decryptedPrivateKeyData = await this.aesDecrypt.aesDecrypt(vault.privateKey, vaultEncryptionKey);
                    decryptedPublicKey = decryptedPublicKeyData.data;
                    decryptedPrivateKey = decryptedPrivateKeyData.data;
                }
            }
        }

        var promise = new Promise<PasswordVaultData>((resolve, reject) => {
            // Decrypt the vault data key using our private packing key
            this.rsaDecrypt.rsaDecrypt(vault.dataKey, accountKeys.packingKeyDecryptedPrivateKey).then((decryptedVaultDataKey) => {
                if (decryptedVaultDataKey.success) {
                    // Decrypt the vault data using the vault key
                    this.aesDecrypt.aesDecrypt(vault.data, decryptedVaultDataKey.data).then((decryptedVaultData) => {
                        let vaultData: PasswordVault = JSON.parse(decryptedVaultData.data);

                        if (this.consoleLog) console.log("decryptedVaultData: ", decryptedVaultData);
                        if (this.consoleLog) console.log("vaultData: ", vaultData);

                        let response: PasswordVaultData = new PasswordVaultData();
                        // copy all the fields from decryptedVaultData.data into response
                        response.id = vault.vaultId;
                        response.name = vaultData.name;
                        response.description = vaultData.description;
                        response.version = vaultData.version;
                        response.vaultType = vaultData.vaultType;
                        response.dataKey = decryptedVaultDataKey.data;
                        response.encryptionKey = vaultEncryptionKey;
                        response.decryptedPrivateKey = decryptedPrivateKey;
                        response.decryptedPublicKey = decryptedPublicKey;

                        resolve(response);

                    }).catch((error) => {
                        console.error("error: " + error);
                        reject(error)
                    });
                } else {
                    if (this.consoleLog) console.error("error: " + decryptedVaultDataKey.error);
                    reject(decryptedVaultDataKey.error);
                }

            });
        });

        return promise;

    }



}

