
import { BulkUserPublicKeyEntry } from '@app/model/api/connection/BulkUserPublicKeyEntry';
import { BulkUpdatePasswordMembershipInTeamItem } from '@app/model/app/password/BulkUpdatePasswordMembershipInTeamItem';
import { TeamMembershipTuple } from '@app/model/app/teams/TeamMembershipTuple';
import { TeamSearchCriteria } from '@app/model/app/teams/TeamSearchCriteria';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const TeamActions = createActionGroup({
    source: 'Teams',
    events: {
        // Page opens


        // Other
        'Update Search Criteria': props<{ criteria: TeamSearchCriteria }>(),

        'Update Team Membership': props<{ gid: number, adds: TeamMembershipTuple[], updates: TeamMembershipTuple[], removes: number[] }>(),
        'Update Team Membership Success': emptyProps(),
        'Update Team Membership Failure': props<{ error: string }>(),

        'Update Password Sharing Single Password': props<{ passwordId: number, adds: number[], removes: number[] }>(),
        'Update Password Sharing Single Password Success': emptyProps(),
        'Update Password Sharing Single Password Failure': props<{ error: string }>(),

        'Update Password Sharing Single Vault Password': props<{ passwordId: number, adds: number[], removes: number[] }>(),
        'Update Password Sharing Single Vault Password Success': emptyProps(),
        'Update Password Sharing Single Vault Password Failure': props<{ error: string }>(),

        'Update Password Membership In Team': props<{ groupId: number, adds: number[], removes: number[] }>(),
        'Update Password Membership In Team Success': emptyProps(),
        'Update Password Membership In Team Failure': props<{ error: string }>(),


        'Bulk Update Password Membership In Teams': props<{ entries: BulkUpdatePasswordMembershipInTeamItem[] }>(),
        'Bulk Update Password Membership In Teams Success': emptyProps(),
        'Bulk Update Password Membership In Teams Failure': props<{ error: string }>(),

        'Ensure Team Member Is Added To Shared Vault': props<{ vaultId: string, dataKey: string, role: number, keys: BulkUserPublicKeyEntry[] }>(),
    },
});