import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { from, of } from "rxjs";
import { catchError, exhaustMap, map, tap, } from "rxjs/operators";

import { PasswordVaultActions } from "../actions/PasswordVaultActions";
import { PasswordVaultService } from "@app/services/passwordVault/password-vault-service.service";
import { AccountActions } from "../actions/AccountActions";

@Injectable()
export class PasswordVaultEffects {
    constructor(
        private actions$: Actions,
        private passwordVaultService: PasswordVaultService
    ) { }

    readonly checkIfVaultsAreCreated$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                AccountActions.unlockPackingKeySuccess
            ),
            tap((action) => this.passwordVaultService.initiateCheckForVaultCreation()),
        ), { dispatch: false }
    );


    readonly quickConvertTeamToMultiAdmin$ = createEffect(
        () => this.actions$.pipe(
            ofType(PasswordVaultActions.quickConvertTeamToMultiAdmin),
            tap((action) => this.passwordVaultService.quickConvertTeamToMultiAdmin(action.data)),
        ), { dispatch: false }
    );

    readonly syncVaultsToStore$ = createEffect(
        () => this.actions$.pipe(
            ofType(PasswordVaultActions.syncVaultsToStore),
            tap((action) => this.passwordVaultService.syncVaultsToStore()),
        ), { dispatch: false }
    );
    readonly updateVaultList$ = createEffect(
        () => this.actions$.pipe(
            ofType(PasswordVaultActions.updateVaultList),
            tap((action) => this.passwordVaultService.updatedVaultList()),
        ), { dispatch: false }
    );

}
