import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectOrganizationLicenses, selectOrganizationListResponse } from '@app/store/selectors/OrganizationSelectors';

import { OrganizationLicenseListEntryDisplay } from '@app/model/app/organization/OrganizationLicenseListEntryDisplay';
import { OrganizationLicenseListEntry } from '@app/model/api/organization/OrganizationLicenseListEntry';

@Injectable({ providedIn: 'root' })
export class OrganizationLicenseService implements OnDestroy {

    private consoleLog: boolean = false;
    readonly vmLicenses$ = this.store.select(selectOrganizationLicenses);
    licenseSubscription: Subscription;
    licensedUsers: OrganizationLicenseListEntryDisplay[] = [];

    constructor(
        private store: Store
    ) {

        this.licenseSubscription = this.vmLicenses$.subscribe(x => {
            this.licensedUsers = new Array<OrganizationLicenseListEntryDisplay>();
            if (x?.status.code === 0) {

                x.licenses.forEach((license: OrganizationLicenseListEntry) => {
                    let entry = new OrganizationLicenseListEntryDisplay();
                    // Determine if this id is checkd in the copy
                    entry.customerId = license.customerId;
                    entry.mfa = license.mfa;
                    entry.name = license.name;
                    entry.email = license.email;
                    entry.roles = license.roles;
                    entry.status = license.status;
                    entry.username = license.username;
                    entry.domainVerified = license.domainVerified;
                    entry.lastLogin = license.lastLogin;
                    // if (license.mfa) {
                    //     entry.mfaIcons = license.mfa.split(' ').map(x => {
                    //         return this.getIconForMFA(x.trim().toLowerCase());
                    //     });
                    // }

                    this.licensedUsers.push(entry);
                });

            }
        });
    }

    ngOnDestroy(): void {
        if (this.licenseSubscription) {
            this.licenseSubscription.unsubscribe();
        }
    }


}