import { PasswordExtraForDataExport_v1 } from "./PasswordExtraForDataExport_v1";
import { PasswordHistoryForDataExport_v1 } from "./PasswordHistoryForDataExport_v1";

export class PasswordForDataExport_v1 {
    name: string;
    password: string;
    username: string;
    url: string;
    email: string;
    notes: string;
    sharedNotes: string;
    labels: string[];
    teams: number[];
    extra: PasswordExtraForDataExport_v1[];
    history: PasswordHistoryForDataExport_v1[];

}