
export class UpdatePasswordRequest {
    id: number;
    website: string;
    name: string;
    notes: string;
    data: string;
    stats: string;
    favorite: boolean;
    usernamePasswordUpdated: boolean;
    version: number = 2;
    history: string;
    conversionOnly: boolean = false;
}