import { PasswordVaultData } from "../passwordVault/PasswordVaultData";

export class DisplayedPasswordGroupListItem {
    id: number;
    passwordId: number;
    name: string;
    defaultRoleDescription: string;
    defaultRoleDescriptionShort: string;
    memberCount: number;
    passwordCount: number;
    ownerId: number;
    isOwner: boolean = false;
    ownerNickname: string;
    color: string;
    individualShare: boolean = false;
    decryptedFromVault: boolean = false;
    vault: PasswordVaultData;
}