import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PasswordSearchCriteria } from '@app/model/app/password/PasswordSearchCriteria';
import { PasswordActions } from '@app/store/actions/PasswordActions';

@Injectable({
    providedIn: 'root',
})

/**
 * Acts like a lookup store for various information so when the user is 
 * switching between routes the information does not need to be calculated 
 * during component initialization.
 * 
 * This avoids flicker and speeds up the display of passwords which have been decoded.
 */
export class PasswordPageService {
    private logConsole: boolean = false;
    lastLoadedPasswordCount = 0;
    lastPasswordChangeTimestamp: number = 0;
    lastLabelChangeTimestamp: number = 0;
    passwordSearchCriteria: PasswordSearchCriteria = new PasswordSearchCriteria();
    checkedPasswords: Map<number, boolean> = new Map<number, boolean>();

    constructor(
        private store: Store,
    ) {
        if (this.logConsole) console.log("construct PasswordPageService");
    }


    logValues(): void {
        if (this.logConsole) console.log("password count: ", this.lastLoadedPasswordCount + ", lastPasswordChangeTimestamp: " + this.lastPasswordChangeTimestamp + ", passwordSearchCriteria: " + this.passwordSearchCriteria);

    }

    /**
     * 
     * Update the label search criteria
     * @param selectedLabels 
     */
    updateLabels(selectedLabels: number[]): void {

        // Update the search criteria
        let copyiedCriteria = { ...this.passwordSearchCriteria };
        copyiedCriteria.selectedLabels = selectedLabels;
        this.store.dispatch(PasswordActions.updateSearchCriteria({ criteria: copyiedCriteria }));
    }
}