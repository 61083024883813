/**
 * Pure JavaScript version of EncryptionTools that can be used in web workers
 * without Angular dependencies
 */
class EncryptionToolsJS {
    keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    constructor() {
        // Initialize any properties here if needed
    }
    /**
     * Generate a string with date prefix followed by random characters
     * @param {number} length - Total length of the string to generate
     * @returns {string} Generated string with date prefix
     */
    generateRandomStringWithDatePrefix(length) {
        let date = new Date();
        let datePrefix = date.getFullYear().toString() +
            (date.getMonth() + 1).toString().padStart(2, '0') +
            date.getDate().toString().padStart(2, '0') +
            date.getHours().toString().padStart(2, '0') +
            date.getMinutes().toString().padStart(2, '0');
        return datePrefix + this.generateRandomString(length - datePrefix.length);
    }

    /**
     * Generate a cryptographically secure random string of length X
     * @param {number} length - Length of the random string to generate
     * @returns {string} Random string
     */
    generateRandomString(length) {
        const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        const randomArray = new Uint8Array(length);
        crypto.getRandomValues(randomArray);
        randomArray.forEach((number) => {
            result += chars[number % chars.length];
        });
        return result;
    }

    /**
     * Convert an array buffer to base64 string
     * @param {ArrayBuffer} buffer - Array buffer to convert
     * @returns {string} Base64 encoded string
     */
    ab2b64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    }

    /**
     * Convert a base64 string to an array buffer
     * @param {string} base64string - Base64 string to convert
     * @returns {ArrayBuffer} Resulting array buffer
     */
    b642ab(base64string) {
        return Uint8Array.from(atob(base64string), c => c.charCodeAt(0));
    }

    /**
     * Convert bytes to a string
     * @param {Uint8Array} bytes - Bytes to convert
     * @returns {string} Resulting string
     */
    bytes_to_string(bytes) {
        let utf8 = true;

        var len = bytes.length,
            chars = new Array(len);

        for (var i = 0, j = 0; i < len; i++) {
            var b = bytes[i];
            if (!utf8 || b < 128) {
                chars[j++] = b;
            } else if (b >= 192 && b < 224 && i + 1 < len) {
                chars[j++] = ((b & 0x1f) << 6) | (bytes[++i] & 0x3f);
            } else if (b >= 224 && b < 240 && i + 2 < len) {
                chars[j++] = ((b & 0xf) << 12) | ((bytes[++i] & 0x3f) << 6) | (bytes[++i] & 0x3f);
            } else if (b >= 240 && b < 248 && i + 3 < len) {
                var c = ((b & 7) << 18) | ((bytes[++i] & 0x3f) << 12) | ((bytes[++i] & 0x3f) << 6) | (bytes[++i] & 0x3f);
                if (c <= 0xffff) {
                    chars[j++] = c;
                } else {
                    c ^= 0x10000;
                    chars[j++] = 0xd800 | (c >> 10);
                    chars[j++] = 0xdc00 | (c & 0x3ff);
                }
            } else {
                throw new Error('Malformed UTF8 character at byte offset ' + i);
            }
        }

        var str = '',
            bs = 16384;
        for (var i = 0; i < j; i += bs) {
            str += String.fromCharCode.apply(String, chars.slice(i, i + bs <= j ? i + bs : j));
        }

        return str;
    }

    /**
     * Convert a string to bytes
     * @param {string} str - String to convert
     * @returns {Uint8Array} Resulting bytes
     */
    string_to_bytes(str) {
        let utf8 = true;

        var len = str.length,
            bytes = new Uint8Array(utf8 ? 4 * len : len);

        for (var i = 0, j = 0; i < len; i++) {
            var c = str.charCodeAt(i);

            if (utf8 && 0xd800 <= c && c <= 0xdbff) {
                if (++i >= len) throw new Error('Malformed string, low surrogate expected at position ' + i);
                c = ((c ^ 0xd800) << 10) | 0x10000 | (str.charCodeAt(i) ^ 0xdc00);
            } else if (!utf8 && c >>> 8) {
                throw new Error('Wide characters are not allowed.');
            }

            if (!utf8 || c <= 0x7f) {
                bytes[j++] = c;
            } else if (c <= 0x7ff) {
                bytes[j++] = 0xc0 | (c >> 6);
                bytes[j++] = 0x80 | (c & 0x3f);
            } else if (c <= 0xffff) {
                bytes[j++] = 0xe0 | (c >> 12);
                bytes[j++] = 0x80 | ((c >> 6) & 0x3f);
                bytes[j++] = 0x80 | (c & 0x3f);
            } else {
                bytes[j++] = 0xf0 | (c >> 18);
                bytes[j++] = 0x80 | ((c >> 12) & 0x3f);
                bytes[j++] = 0x80 | ((c >> 6) & 0x3f);
                bytes[j++] = 0x80 | (c & 0x3f);
            }
        }

        return bytes.subarray(0, j);
    }




    removePaddingChars(input) {
        // console.log("removing padding: " + input);
        // console.log(this.keyStr);
        // console.log("in: " + input.charAt(input.length - 1));
        try {
            // console.log("input: " + input);
            const lkey = this.keyStr.indexOf(input.charAt(input.length - 1));
            if (lkey === 64) {
                return input.substring(0, input.length - 1);
            }
        } catch (e) {
            console.log("removePaddingChars error: " + e);
        }
        return input;
    }


    decode(input) {
        //get last chars to see if are valid - could be 2 padding
        input = this.removePaddingChars(input);
        input = this.removePaddingChars(input);

        let inputLength = input.length;
        const byteCount = (inputLength / 4) * 3;
        let uarray;
        let chr1, chr2, chr3;
        let enc1, enc2, enc3, enc4;
        let j = 0;

        // if (arrayBuffer)
        //     uarray = new Uint8Array(arrayBuffer);
        // else
        uarray = new Uint8Array(byteCount);
        try {
            //console.log("decoding: input: " + input);
            input = input.replace(/[^A-Za-z0-9+/=]/g, "");
            //console.log("input2: " + input);
            for (let i = 0; i < byteCount; i += 3) {
                //get the 3 octects in 4 ascii chars
                enc1 = this.keyStr.indexOf(input.charAt(j++));
                enc2 = this.keyStr.indexOf(input.charAt(j++));
                enc3 = this.keyStr.indexOf(input.charAt(j++));
                enc4 = this.keyStr.indexOf(input.charAt(j++));

                chr1 = (enc1 << 2) | (enc2 >> 4);
                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                chr3 = ((enc3 & 3) << 6) | enc4;

                uarray[i] = chr1;
                if (enc3 !== 64) uarray[i + 1] = chr2;
                if (enc4 !== 64) uarray[i + 2] = chr3;
            }
        } catch (e) {
            console.error("decode error: " + e);
        }

        return uarray;
    }
}
export { EncryptionToolsJS };
