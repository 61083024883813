
export class PasswordFeedEntry {
    sharedTo: number[];
    notes: string;
    key: string;
    keyGroup: number;
    data: string;
    id: number;
    name: string;
    url: string;
    owner: boolean;
    favorite: boolean;
    passwordLastUpdate: string;
    lastUpdate: string;
    createdDate: string;
    stats: string;
    action: number;
    version: number;
    vault: string;

    constructor(password: PasswordFeedEntry) {
        this.sharedTo = password.sharedTo;
        this.notes = password.notes;
        this.key = password.key;
        this.keyGroup = password.keyGroup;
        this.data = password.data;
        this.id = password.id;
        this.name = password.name;
        this.url = password.url;
        this.owner = password.owner;
        this.favorite = password.favorite;
        this.passwordLastUpdate = password.passwordLastUpdate;
        this.lastUpdate = password.lastUpdate;
        this.createdDate = password.createdDate;
        this.stats = password.stats;
        this.action = password.action;
        this.version = password.version;
        this.vault = password.vault;
    }
}